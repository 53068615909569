import React, { createContext, useState } from 'react';

const HeaderContext = createContext();

const HeaderProvider = ({ children }) => {
  const [headerText, setHeaderText] = useState('Header Editable');

  return (
    <HeaderContext.Provider value={{ headerText, setHeaderText }}>
      {children}
    </HeaderContext.Provider>
  );
};

export { HeaderContext, HeaderProvider };
