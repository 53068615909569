import { Box, Popover, Typography } from '@mui/material';
import React, { useState } from 'react'
import Iconify from 'src/components/iconify';

const CardNotePopover = () => {
    const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = anchorEl;
  const id = openpop ? 'simple-popover' : undefined;

  return (
    
    <Box position="absolute">
      <Iconify aria-describedby={id} onClick={handleClick} sx={{ color: '' }} icon="ph:dots-three-bold" />

      <Popover
        id={id}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ borderRadius: '0px', '& .MuiPaper-root': { borderRadius: '5px' } }}
      >
        <Box sx={{ padding: '8px 10px' }} width={'200px'}>
          {/* <Typography variant="body1" sx={{ fontSize: '.875rem' }}> */}
          <Box display={'flex'} gap={'10px'} alignItems={'center'}>
            <Iconify className="dark-text" sx={{ color: '#000' }} icon="octicon:pin-16" />

            <Typography className="dark-text" sx={{ color: '#333', fontSize: '.875rem' }}>Pin note</Typography>
          </Box>
          <Box mt={"12px"} display={'flex'} gap={'10px'} alignItems={'center'}>
            <Iconify className="dark-text" sx={{ color: '#000' }} icon="ri:delete-bin-line" />

            <Typography className="dark-text" sx={{ color: '#333', fontSize: '.875rem' }}>Delete</Typography>
          </Box>
        </Box>
      </Popover>
    </Box>
    
  )
}

export default CardNotePopover