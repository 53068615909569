import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useFetch } from 'src/hooks/useFetch';
import Typography from '@mui/material/Typography';
import { Grid, Avatar, Box, TextField, Divider, Button } from '@mui/material';
import OffButtonGroup from '../../../twobuttongroup/OffButtonGroup';
import CourierprimeDropdown from '../../../courierprimedropdown/CourierprimeDropdown';

const ElementsSceneHeading = ({ script, refreshData }) => {
  const ELEMENT_KEY = 'Scene Heading';

  const { id } = useParams();

  const [updateScript] = useFetch(`api/scripts/${id}`, ``, '', 'PATCH', false);

  const [state, setState] = useState({
    label: ELEMENT_KEY,
    spaceBefore: '',
    spaceAfter: '',
    shortCut: '',
    mode: '',
    nextElement: ELEMENT_KEY,
    timesOfDay: '',
    config: null, // PASS ANY JSON DATA IN THIS FIELD
  });

  useEffect(() => {
    if (script) {
      const currentElement = script?.elements?.find((element) => element.label === ELEMENT_KEY);
      if (currentElement) {
        setState({
          label: currentElement.label || ELEMENT_KEY,
          spaceBefore: currentElement.spaceBefore || '',
          spaceAfter: currentElement.spaceAfter || '',
          shortCut: currentElement.shortCut || '',
          mode: currentElement.mode || '',
          nextElement: currentElement.nextElement || ELEMENT_KEY,
          timesOfDay: currentElement.timesOfDay || '',
          config: currentElement.config || '',
        });
      }
    }
  }, [script]);

  const handleUpdateScript = async (chagedKey, changedValue) => {
    try {
      const payload = { ...state };
      if (chagedKey) {
        payload[chagedKey] = changedValue;
      }

      const updatedElements = findAndReplaceElement(script.elements, payload);

      const response = await updateScript({ elements: updatedElements });
      if (response) refreshData();
    } catch (error) {
      console.log(error);
    }
  };

  // find and replace element from the array
  const findAndReplaceElement = (array, element) => {
    if (!array || array?.length === 0) return [element];
    const index = array?.findIndex((ele) => ele?.label === element?.label);
    if (index !== -1) {
      array[index] = element;
    } else {
      array.push(element);
    }
    return array;
  };

  const handleResetDefaults = async () => {
    try {
      const payload = {
        label: ELEMENT_KEY,
        spaceBefore: '',
        spaceAfter: '',
        shortCut: '',
        mode: '',
        nextElement: ELEMENT_KEY,
        timesOfDay: '',
        config: null,
      };

      setState(payload);
      const updatedElements = findAndReplaceElement(script.elements, payload);
      const response = await updateScript({ elements: updatedElements });
      if (response) refreshData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid mb={'15px'} container>
        <Grid item xs={12}>
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'30px'}
            sx={{ boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)' }}
            width={'100%'}
          >
            <Box fontSize={'.75rem'} height={'30px'} width={'100%'} display={'flex'} justifyContent={''}>
              <Box
                sx={{
                  backgroundColor: '#ffd8a8',
                  width: '20%',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#e8590c',
                }}
              >
                1.5
              </Box>
              <Box
                sx={{
                  backgroundColor: '#a5d8ff',
                  width: '60%',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#1971c2',
                }}
              >
                characters
              </Box>
              <Box
                sx={{
                  backgroundColor: '#ffd8a8',
                  width: '20%',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#e8590c',
                }}
              >
                1.5
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Label
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography fontSize={'.875rem'} sx={{ opacity: '0.8', color: '#ccc', fontFamily: 'inter' }}>
            {ELEMENT_KEY}
          </Typography>
        </Grid>
      </Grid>

      <Grid mt={'15px'} container>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Spacing before
          </Typography>
        </Grid>
        <Grid item md={6}>
          <CourierprimeDropdown
            name={'spacingbefore'}
            option1={'0 lines'}
            option2={'1 line'}
            option3={'1 1/2 lines'}
            option4={'2 lines'}
            option5={'page break'}
            onChange={(value) => setState({ ...state, spaceBefore: value })}
            value={state.spaceBefore}
            onBlur={(value) => {
              handleUpdateScript('spaceBefore', value);
            }}
          />
        </Grid>
      </Grid>

      <Grid mt={'15px'} container>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Shortcut
          </Typography>
        </Grid>
        <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} item md={6}>
          <Typography fontWeight={'.875rem'}>Ctrl+</Typography>
          <CourierprimeDropdown
            name={'shortcut'}
            option1={'1'}
            option2={'2'}
            option3={'3'}
            option4={'4'}
            option5={'5'}
            onChange={(value) => setState({ ...state, shortCut: value })}
            value={state.shortCut}
            onBlur={(value) => {
              handleUpdateScript('shortCut', value);
            }}
          />
        </Grid>
      </Grid>

      <Grid mt={'15px'} container>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Element mode shortcut
          </Typography>
        </Grid>
        <Grid item md={6}>
          {/* <Box
                  className="scren-zoomlvl"
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  sx={{
                    backgroundColor: '#00017806',
                    fontSize: '.75rem',
                    padding: ' .25rem .5rem',
                    borderRadius: '.25rem',
                    width: '100%',
                  }}
                >
                  <p>B</p>

                  <Iconify sx={{}} icon="eva:arrow-ios-downward-fill" width={16} height={24} />
                </Box> */}
          <CourierprimeDropdown
            name={'elementmodeshortcut'}
            option1={'A'}
            option2={'B'}
            option3={'C'}
            option4={'D'}
            option5={'E'}
            option6={'F'}
            option7={'G'}
            onChange={(value) => setState({ ...state, mode: value })}
            value={state.mode}
            onBlur={(value) => {
              handleUpdateScript('mode', value);
            }}
          />
        </Grid>
      </Grid>

      <Grid mt={'15px'} container>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Next element
          </Typography>
        </Grid>
        <Grid item md={6}>
          {/* <Box
                  className="scren-zoomlvl"
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  sx={{
                    backgroundColor: '#00017806',
                    fontSize: '.75rem',
                    padding: ' .25rem .5rem',
                    borderRadius: '.25rem',
                    width: '100%',
                  }}
                >
                  <p>Action</p>

                  <Iconify sx={{}} icon="eva:arrow-ios-downward-fill" width={16} height={24} />
                </Box> */}
          <CourierprimeDropdown
            name={'nextelement'}
            option1={'Scene Heading'}
            option2={'Action'}
            option3={'Character'}
            option4={'parenthetical'}
            option5={'Dialogue'}
            option6={'Shot'}
            onChange={(value) => setState({ ...state, nextElement: value })}
            value={state.nextElement}
            onBlur={(value) => {
              handleUpdateScript('nextElement', value);
            }}
          />
        </Grid>
      </Grid>

      <Grid mt={'15px'} container>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Times of day
          </Typography>
          <Typography fontSize={'.75rem'} sx={{ color: '#b2b3c4', lineHeight: '1.25' }}>
            Add one per line
          </Typography>
        </Grid>
        <Grid item md={6}>
          {/* <Box
                  className="scren-zoomlvl"
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  sx={{
                    backgroundColor: '#00017806',
                    fontSize: '.75rem',
                    padding: ' .25rem .5rem',
                    borderRadius: '.25rem',
                    width: '100%',
                  }}
                >
                  <p> Add one per line</p>

                  <Iconify sx={{}} icon="eva:arrow-ios-downward-fill" width={16} height={24} />
                </Box> */}
          <TextField
            // sx={{maxHeight:"50px",height:"10px"}}
            sx={{ width: '100%', backgroundColor: '#00017806' }}
            id="outlined-multiline-static"
            multiline
            rows={4}
            defaultValue=""
            onChange={(e) => setState({ ...state, timesOfDay: e.target.value })}
            value={state.timesOfDay}
            onBlur={(e) => {
              handleUpdateScript('timesOfDay', e.target.value);
            }}
          />
          <Typography
            sx={{
              textAlign: 'center',
              backgroundColor: '#00017806',
              fontSize: '.75rem',
              padding: '0.375rem',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            mt={'10px'}
            onClick={() => {
              handleResetDefaults();
            }}
          >
            Reset defaults
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ElementsSceneHeading;
