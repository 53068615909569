import { useCallback, useState } from 'react';
import { SERVER_BASE_URL } from 'src/config';

export function useFetch(url = '', successMsg = '', errorMsg = '', method = 'GET', showToasts = true) {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line consistent-return
  const apiCall = useCallback(async (data = {}) => {
    try {
      const token = localStorage.getItem('accessToken') || '';
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', `Bearer ${token}`);
      const requestOptions = {
        method,
        headers: myHeaders,
      };

      if (method !== 'GET') {
        requestOptions.body = JSON.stringify(data);
      }

      const resp = await fetch(`${SERVER_BASE_URL}/${url}`, requestOptions)

      const respData = await resp.json();
      console.log("successMsg", successMsg);
      setLoading(false);
      return respData;
    } catch (e) {
      if (showToasts) {
        alert(errorMsg);
      }
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [apiCall, loading];
}

// USAGE

// POST, PUT, DELETE AND GET
// const [callAPI] = useFetch(
//   '/api/messages',
//   `Messages sent successfully`,
//   '',
//   'POST',
//   false
// );
// const payload = {
//   name: '',
//   content: '',
// };
// const resp = await callAPI(payload);
