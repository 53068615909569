import { Box, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import React, { useState } from 'react';
import Iconify from 'src/components/iconify';
import './TagPopover.css';

const TagPopover = ({name,option1,option2,option3}) => {
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = anchorEl;
  const id = openpop ? 'simple-popover' : undefined;

  return (
    <>
      <Box
        sx={{ '&:hover': { backgroundColor: '#00017806' }, padding: '.25rem' }}
        aria-describedby={id}
        onClick={handleClick}
        display={'flex'}
        gap={'5px'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
      {name === 'tag'?   <Typography fontSize={'.75rem'}>Tag</Typography>:<Typography fontSize={'.75rem'}>{option1}</Typography>
      
      }
        <Iconify sx={{ fontSize: '10px', color: '#333' }} icon="ep:arrow-down-bold" />
      </Box>

      <Popover
        id={id}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ borderRadius: '0px' }}
      >
      {name === 'tag' && <>
        <Box textAlign={'center'} sx={{ padding: '10px', borderRadius: '0px' }}>
          <MenuList>
            <input className="tag-input-style light-bg dark-text" />

            <Box mt={'10px'} justifyContent={'center'} display={'flex'} alignItems={'center'}>
              <Box display={'flex'} alignItems={'center'} gap={'5px'} justifyContent={'space-between'}>
                <Typography sx={{ fontSize: '.75rem' }}>Select</Typography>
                <Box
                className="dark-bg"
                  sx={{ width: '20px', height: '20px', backgroundColor: '#f1f3f5', borderRadius: '5px' }}
                  justifyContent={'center'}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <Iconify className="dark-text" icon="solar:arrow-right-outline" />
                </Box>
              </Box>
              <Box ml={'5px'} display={'flex'} gap={'5px'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography sx={{ fontSize: '.75rem' }}>Select & close</Typography>

                <Box
                className="dark-bg"
                  sx={{ width: '20px', height: '20px', backgroundColor: '#f1f3f5', borderRadius: '5px' }}
                  justifyContent={'center'}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <Iconify icon="solar:arrow-left-outline" />
                </Box>
              </Box>
              <Box ml={'5px'} display={'flex'} alignItems={'center'} gap={'5px'} justifyContent={'space-between'}>
                <Typography sx={{ fontSize: '.75rem' }}>Close</Typography>
                <Box
                className="dark-bg"
                  sx={{ width: '20px', height: '20px', backgroundColor: '#f1f3f5', borderRadius: '5px' }}
                  justifyContent={'center'}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <Typography sx={{ fontSize: '.65rem' }}>Esc</Typography>
                </Box>
              </Box>
            </Box>
            <Typography mt={'5px'} sx={{ fontSize: '.79rem' }}>
              Nothing found for this search.
            </Typography>
          </MenuList>
        </Box>
      </>}
      {name==='writtenby' && (
        <Box width={'200px'}>
              <MenuList>
                <MenuItem sx={{}}>
                  <Typography variant="body1" sx={{ fontSize: '.75rem', }}>
                    {option1}
                    
                  </Typography>
                </MenuItem>
                <MenuItem sx={{}}>
                  <Typography variant="body1" sx={{ fontSize: '.75rem', }}>
                  {option2}
                  </Typography>
                </MenuItem>
                </MenuList>
        </Box>
                )}


                {name==='lastreply' && (
        <Box width={'300px'}>
              <MenuList>
                <MenuItem>
                  <Typography variant="body1" sx={{ fontSize: '.875rem' }}>
                    {option1}
                    
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography variant="body1" sx={{ fontSize: '.875rem' }}>
                  {option2}
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography variant="body1" sx={{ fontSize: '.875rem' }}>
                  {option3}
                  </Typography>
                </MenuItem>
                </MenuList>
        </Box>
                )}


      </Popover>
    </>
  );
};

export default TagPopover;
