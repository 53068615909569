import moment from 'moment';

export const isValidEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};
export const isStrongPassword = (password) => {
  const strongRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
  );
  return strongRegex.test(password);
};
export const isEmpty = (str) => {
  try {
    return str.trim() === '';
  } catch (error) {
    return true;
  }
};


export const getTimeDifferenceFromDate = (inputDate) => {
  // Parse the input date
  const inputMoment = moment(inputDate);

  // Get the current time
  const currentTime = moment();

  // Calculate the difference in hours
  const hoursDifference = currentTime.diff(inputMoment, 'hours');

  // Format the result
  if (hoursDifference < 1) {
    return 'less than hr ago';
  }
  if (hoursDifference === 1) {
    return '1 hour ago';
  }
  return `${hoursDifference} hours ago`;

}

export const getCurrentOrder = (user) => {
  if (!user) {
    return null;
  }
  const orders = user?.Order || [];
  return orders?.find((order) => order?.orderPlan?.id === user?.currentPlanId) || null;
};


export const formatDate = (inputDate) => {
  try {
    const inputMoment = moment(inputDate);
    const formattedDate = inputMoment.format('MMM DD,YYYY');
    return formattedDate;
  } catch (error) {
    return 'Invlaid Date';
  }
}

export const addOneYear = (inputDate) => {
  try {
    const inputMoment = moment(inputDate);
    const dateAfterOneYear = inputMoment.add(1, 'years');
    const formattedDate = dateAfterOneYear.format('YYYY-MM-DD');
    return formattedDate;
  } catch (error) {
    return 'Invlaid Date';
  }
}

export const getCurrentUser = () => JSON.parse(localStorage.getItem('user')) || null;
