import { Box, Typography } from '@mui/material'
import React from 'react'
import BottomRightItemModal from './BottomRightItemModal'


const BottomRightItem = () => (
    <>
        <Box  className="dark-bg" sx={{zIndex:"99",position:"fixed",bottom:"20px",right:"5px",backgroundColor:"#00017806",padding:"0 5px"}}  display={'flex'} alignItems={'center'} gap={"10px"}>
            <Box  display={'flex'} alignItems={'center'} gap={'5px'}>
                <Typography className="dark-text" sx={{fontSize:".875rem",color:"#333"}}>1</Typography>
                <Typography className="dark-text" sx={{fontSize:".875rem",color:"#333"}}>/</Typography>
                <Typography className="dark-text" sx={{fontSize:".875rem",color:"#333"}}>2</Typography>
            </Box>
            <Box>
                <BottomRightItemModal/>
            </Box>
        </Box>
    </>
  )

export default BottomRightItem