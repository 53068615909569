/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import './ButtonGroup.css'; // Import your CSS file for styling
import { changeEditorThemeMode } from 'src/utils/common';
import useSettings from 'src/hooks/useSettings';

const ButtonGroup = () => {
  const { themeMode, onChangeMode } = useSettings();
  const [activeButton, setActiveButton] = useState(themeMode || 'light');

  const handleButtonClick = (buttonText) => {
    if (buttonText === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      onChangeMode('system');
      changeEditorThemeMode('dark');
      setActiveButton('system');
      return;
    }
    onChangeMode(buttonText);
    changeEditorThemeMode(buttonText);
    setActiveButton(buttonText);
  };

  return (
    <div className="button-group">
      <button
        className={`button ${activeButton === 'light' ? 'active' : ''} dark-bg dark-text`}
        onClick={() => handleButtonClick('light')}
      >
        Light
      </button>
      <button
        className={`button ${activeButton === 'system' ? 'active' : ''} dark-bg dark-text`}
        onClick={() => handleButtonClick('system')}
      >
        System
      </button>
      <button
        className={`button ${activeButton === 'dark' ? 'active' : ''} dark-bg dark-text`}
        onClick={() => handleButtonClick('dark')}
      >
        Dark
      </button>
      <div className="indicator">{activeButton}</div>
    </div>
  );
};

export default ButtonGroup;
