/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useFetch } from 'src/hooks/useFetch';
import Typography from '@mui/material/Typography';
import { Grid, Box, Button } from '@mui/material';
import OffButtonGroup from '../../twobuttongroup/OffButtonGroup';

const HeaderFooter = ({ script, refreshData }) => {
  const { id } = useParams();

  const [updateScript] = useFetch(`api/scripts/${id}`, ``, '', 'PATCH', false);

  const [state, setState] = useState({
    isShowOnFirstPage: false,
    introPages: '0',
    showPageBreaks: false,
    config: null,
  });

  useEffect(() => {
    if (script) {
      setState({
        isShowOnFirstPage: script?.headersAndFooters?.isShowOnFirstPage || false,
        introPages: script?.headersAndFooters?.introPages || '0',
        showPageBreaks: script?.headersAndFooters?.showPageBreaks || false,
        config: script?.headersAndFooters?.config || null,
      });
    }
  }, [script]);

  const handleUpdateScript = async (chagedKey, changedValue) => {
    try {
      const payload = { ...state };
      if (chagedKey) {
        payload[chagedKey] = changedValue;
      }
      const response = await updateScript({ headersAndFooters: { ...payload } });
      if (response) refreshData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Show headers/footers on first
            <br />
            page
          </Typography>
        </Grid>
        <Grid item md={6}>
          <OffButtonGroup
            first={'Hide'}
            second={'Show'}
            active={state.isShowOnFirstPage ? 'Show' : 'Hide'}
            onChange={(value) => setState({ ...state, isShowOnFirstPage: value })}
            onBlur={(value) => {
              handleUpdateScript('isShowOnFirstPage', value);
            }}
          />
        </Grid>
      </Grid>

      <Grid mt={'10px'} container>
        <Grid item xs={12} md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Intro pages
          </Typography>
          <Typography fontSize={'.75rem'} sx={{ color: '#b2b3c4', lineHeight: '1.25' }}>
            No headers/footers,don't add to page
            <br />
            count
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Box width={'100%'}>
            <input
              type="tel"
              className="headfoot-vertical-input"
              placeholder="0"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
              value={state.introPages}
              onChange={(e) => {
                setState({ ...state, introPages: e.target.value });
              }}
              onBlur={(e) => {
                handleUpdateScript('introPages', e.target.value);
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid mt={'10px'} container>
        <Grid item xs={12} md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Show page breaks within scene <br />
            as <span className="headfoot-cont">(CONTINUED)</span>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <OffButtonGroup
            first={'Hide'}
            second={'Show'}
            active={state.showPageBreaks ? 'Show' : 'Hide'}
            onChange={(value) => setState({ ...state, showPageBreaks: value })}
            onBlur={(value) => {
              handleUpdateScript('showPageBreaks', value);
            }}
          />
        </Grid>
      </Grid>

      <Grid mt={'10px'} rowSpacing={2} container>
        <Grid item xs={12}>
          <Box
            display={'flex'}
            justifyContent={'space-around'}
            alignItems={'center'}
            sx={{ boxShadow: ' 0 0 5px rgba(59,65,94,0.2)' }}
            width={'100%'}
            height={'150px'}
          >
            <Box sx={{ color: '#7b7c8d', fontWeight: '700', fontStyle: 'italic', fontSize: '.67rem' }}>
              Add left text
            </Box>
            <Box sx={{ color: '#7b7c8d', fontWeight: '700', fontStyle: 'italic', fontSize: '.67rem' }}> Main textt</Box>
            <Box sx={{ color: '#7b7c8d', fontWeight: '700', fontStyle: 'italic', fontSize: '.67rem' }}>
              {' '}
              Add right text
            </Box>
          </Box>
        </Grid>
        <Grid display={'flex'} justifyContent={'center'} alignItems={'center'} item xs={12}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexWrap={'wrap'} gap={'5px'}>
            <Button
              sx={{
                backgroundColor: '#495057',
                padding: '0.25rem 0.5rem',
                textAlign: 'center',
                color: '#f1f3f5',
                fontWeight: '600',
                fontFamily: 'inter',
                borderRadius: '5px',
                fontSize: '.67rem',
              }}
              size="small"
            >
              Current page number
            </Button>
            <Button
              sx={{
                backgroundColor: '#1c7ed6',
                padding: '0.25rem 0.5rem',
                textAlign: 'center',
                color: '#f1f3f5',
                fontWeight: '600',
                fontFamily: 'inter',
                borderRadius: '5px',
                fontSize: '.67rem',
              }}
              size="small"
            >
              Script title
            </Button>
            <Button
              sx={{
                backgroundColor: '#1c7ed6',
                padding: '0.25rem 0.5rem',
                textAlign: 'center',
                color: '#f1f3f5',
                fontWeight: '600',
                fontFamily: 'inter',
                borderRadius: '5px',
                fontSize: '.67rem',
              }}
              size="small"
            >
              Script subtitle
            </Button>
            <Button
              sx={{
                backgroundColor: '#1c7ed6',
                padding: '0.25rem 0.5rem',
                textAlign: 'center',
                color: '#f1f3f5',
                fontWeight: '600',
                fontFamily: 'inter',
                borderRadius: '5px',
                fontSize: '.67rem',
              }}
              size="small"
            >
              Revision color
            </Button>
            <Button
              sx={{
                backgroundColor: '#1c7ed6',
                padding: '0.25rem 0.5rem',
                textAlign: 'center',
                color: '#f1f3f5',
                fontWeight: '600',
                fontFamily: 'inter',
                borderRadius: '5px',
                fontSize: '.67rem',
              }}
              size="small"
            >
              Description of revision{' '}
            </Button>
            <Button
              sx={{
                backgroundColor: '#1c7ed6',
                padding: '0.25rem 0.5rem',
                textAlign: 'center',
                color: '#f1f3f5',
                fontWeight: '600',
                fontFamily: 'inter',
                borderRadius: '5px',
                fontSize: '.67rem',
              }}
              size="small"
            >
              Collated revision{' '}
            </Button>
            <Button
              sx={{
                backgroundColor: '#1c7ed6',
                padding: '0.25rem 0.5rem',
                textAlign: 'center',
                color: '#f1f3f5',
                fontWeight: '600',
                fontFamily: 'inter',
                borderRadius: '5px',
                fontSize: '.67rem',
              }}
              size="small"
            >
              Collated revision with date
            </Button>
            <Button
              sx={{
                backgroundColor: '#1c7ed6',
                padding: '0.25rem 0.5rem',
                textAlign: 'center',
                color: '#f1f3f5',
                fontWeight: '600',
                fontFamily: 'inter',
                borderRadius: '5px',
                fontSize: '.67rem',
              }}
              size="small"
            >
              Description of this draft{' '}
            </Button>
            <Button
              sx={{
                backgroundColor: '#f59f00',
                padding: '0.25rem 0.5rem',
                textAlign: 'center',
                color: '#fff3bf',
                fontWeight: '600',
                fontFamily: 'inter',
                borderRadius: '5px',
                fontSize: '.67rem',
              }}
              size="small"
            >
              Date of export
            </Button>
            <Button
              sx={{
                backgroundColor: '#f76707',
                padding: '0.25rem 0.5rem',
                textAlign: 'center',
                color: '#ffe8cc',
                fontWeight: '600',
                fontFamily: 'inter',
                borderRadius: '5px',
                fontSize: '.67rem',
              }}
              size="small"
            >
              Date of edits
            </Button>
            <Button
              sx={{
                backgroundColor: '#d6336c',
                padding: '0.25rem 0.5rem',
                textAlign: 'center',
                color: '#ffdeeb',
                fontWeight: '600',
                fontFamily: 'inter',
                borderRadius: '5px',
                fontSize: '.67rem',
              }}
              size="small"
            >
              Date of revision
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            display={'flex'}
            justifyContent={'space-around'}
            alignItems={'center'}
            sx={{ boxShadow: ' 0 0 5px rgba(59,65,94,0.2)' }}
            width={'100%'}
            height={'150px'}
          >
            <Box sx={{ color: '#7b7c8d', fontWeight: '700', fontStyle: 'italic', fontSize: '.67rem' }}>
              Add left text
            </Box>
            <Box sx={{ color: '#7b7c8d', fontWeight: '700', fontStyle: 'italic', fontSize: '.67rem' }}> Main textt</Box>
            <Box sx={{ color: '#7b7c8d', fontWeight: '700', fontStyle: 'italic', fontSize: '.67rem' }}>
              {' '}
              Add right text
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default HeaderFooter;
