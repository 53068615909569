import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Iconify from 'src/components/iconify';
import { Divider, Input, TextField } from '@mui/material';
import Switch from '@mui/material/Switch';

import styled from '@emotion/styled';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 450,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 4,
};

export default function DesktopApp() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
    
    <Box
    onClick={handleOpen}
              className="dark-bg"
              my={'40px'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                padding: '1rem',
                backgroundColor: '#fff',
                borderRadius: '.25rem',
                boxShadow: '0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08)',
                cursor: 'pointer',
              }}
            >
      <Typography variant="subtitle1"  sx={{ color: '#c2255c', fontSize: '', fontWeight: '600' }}>
        Open in desktop app
      </Typography>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ fontSize: '1.25rem', fontWeight: '600' }}>We opened the app</Typography>
          <Typography
            mt={'25px'}
            sx={{ fontSize: '1rem', color: '#7B7C8A', padding: '0', paddingBottom: '0.5rem', lineHeight: '1.25' }}
          >
            Please Check for blocked popup warnings and allow popups if required. if it did not appear,please <br />{' '}
            download and install it first.{' '}
          </Typography>
          <Typography
            mt={'15px'}
            p={'0.5rem 2rem'}
            sx={{
              '&:hover': { backgroundColor: '#d0551c' },
              fontSize: '.875rem',
              fontWeight: '600',
              backgroundColor: '#fe6d29',
              color: '#fff',
              borderRadius: '.25rem',
              textAlign: 'center',
              cursor: 'pointer',
            }}
          >
            Download
          </Typography>
          <Typography
            className="dark-text light-bg"
            onClick={handleClose}
            mt={'10px'}
            p={'0.5rem 2rem'}
            sx={{
              '&:hover': { backgroundColor: '#fe6d291a' },
              fontSize: '.875rem',
              fontWeight: '600',
              backgroundColor: '#00017806',
              cursor: 'pointer',
              color: '#333',
              borderRadius: '.25rem',
              textAlign: 'center',
            }}
          >
            Cancel
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
