/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

const VoiceMessage = ({ audioUrl, isPlayingProp, alignCenter }) => {
  const [playingUrl, setPlayingUrl] = useState(null); // Currently playing URL
  const [isPlaying, setIsPlaying] = useState(isPlayingProp); // State to check if audio is playing
  const audioRef = useRef(null); // Ref to hold the audio instance

  // Ensure only one audio plays at a time
  const playAudio = (url) => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
    const audio = new Audio(url);
    audioRef.current = audio;
    audio.play();
    setPlayingUrl(url); // Update the currently playing audio URL
    setIsPlaying(true);
    audio.onended = () => {
      setPlayingUrl(null); // Reset when audio is done
      setIsPlaying(false); // Update playback state
    };
  };

  // Play/pause functionality
  const togglePlay = () => {
    if (isPlaying && audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);

    } else {
      playAudio(audioUrl);
    }
  };

  return (
    <div className={`voice-message`} 
        style={
            alignCenter && {
                width: "100%",
            }
        }
    >
      <div className="custom-audio-player"
             style={
                alignCenter && {
                    justifyContent: "center",
                }
            }
     >
        <button
          className={`play-button `}
          onClick={togglePlay}
        >
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} className="audio-icon2" />
        </button>

        {/* Waveform GIF */}
        <div className="waveform-container">
          <img
            src={isPlaying ? "https://cdn.pixabay.com/animation/2023/10/24/13/50/13-50-26-112_512.gif" : "https://png.pngtree.com/png-vector/20230408/ourmid/pngtree-sound-waves-equalizer-audio-radio-signal-music-recording-vector-png-image_6678910.png"}
            alt="Waveform"
            className="waveform-gif"
          />
        </div>

        {/* Audio element */}
        <audio
          ref={audioRef}
          src={audioUrl}
          onEnded={() => {
            setPlayingUrl(null);
            setIsPlaying(false);
          }}
        />
      </div>
    </div>
  );
};

export default VoiceMessage;
