/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';

import { Box, Button, Typography } from '@mui/material';

import './ScriptBoard.css';

import BoardSectionList from '../BoardSection/BoardSectionList';
import '../ScriptToolbar/Toolbar.css';
import ToolBar from '../ScriptToolbar/ToolBar';

const ScriptBoard = () => {
  const [display, setDisplay] = useState(true);

  return (
    <>
      {display ? (
        <Box
          sx={{ backgroundColor: '' }}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          height={'90vh'}
        >
          <div className="scren-boardcard light-bg">
            <Typography sx={{fontWeight:"200",fontSize:"1.25rem",fontFamily:''}}>Start your beat outline</Typography>
            {/* <Button>Add first beat</Button> */}
            <Button
              sx={{ margin: '30px 0', padding: '5px 30px', backgroundColor: '#fe6d29', borderRadius: '5px' }}
              color="secondary"
              variant="contained"
              onClick={() => setDisplay(!display)}
            >
              Add first beat
            </Button>
            <p className="scren-choosetemp">or choose a template</p>

            <div className="board-buttons">
              <button className="board-button dark-bg dark-text">Basic 3-act</button>
              <button className="board-button dark-bg dark-text">3-act mid midpoint break</button>
              <button className="board-button dark-bg dark-text">5-act TV </button>
            </div>
            <div className="board-buttons">
              <button className="board-button board-button dark-bg dark-text">Syd Field's Paradigm</button>
              <button className="board-button board-button dark-bg dark-text">Save the Cat!</button>
              <button className="board-button board-button dark-bg dark-text">John Truby's 22 Steps</button>
            </div>
          </div>

          <ToolBar />
        </Box>
      ) : (
        <Box
          display={'flex'}
          gap={'10px'}
          width={'100%'}
          height={'100vh'}
          sx={{ backgroundColor: '', padding: '40px' }}
        >
          <BoardSectionList />
        </Box>
      )}
    </>
  );
};

export default ScriptBoard;
