import { Box, Divider, MenuItem, MenuList, Modal, Popover, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import Iconify from 'src/components/iconify';
import { SERVER_BASE_URL } from 'src/config';
import { useFetch } from 'src/hooks/useFetch';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 2,
};

const EditingPopover = ({stared, fetchScripts, selectedId, onEdit, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState();
  const [open, setOpen] = React.useState(false);
  const [projects, setProjects] = React.useState([]);
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  const [pagination, setPagination] = React.useState({
    totalPages: 0,
    currentPage: 1,
    totalRecords: 0,
  });
  const handleOpen = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();

  const [anchorElProjects, setAnchorElProjects] = React.useState(null);
  const handleClickProjects = (event) => {
    setAnchorElProjects(event.currentTarget);
  };
  const handleCloseProjects = () => {
    setAnchorElProjects(null);
  };
  const openProjects = Boolean(anchorElProjects);
  const idProjects = openProjects ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = anchorEl;
  const id = openpop ? 'simple-popover' : undefined;

  const [selectedOptionProjects, setSelectedOptionProjects] = useState(''); // Initial selected o
  const handleSelectOption = (option) => {
    // Handle the selected option here

    setSelectedOptionProjects(option);
    handleCloseProjects(); // Close the popover after selecting an option
  };

  const [getProjects, loading] = useFetch(
    `api/projects?pageSize=50&page=${pagination.currentPage}&ownerId=${user?.id}`,
    ``,
    '',
    'GET',
    false
  );

  React.useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    const response = await getProjects();
    if (response?.data?.projects) {
      setProjects(response?.data?.projects);
      setPagination({
        totalPages: response?.data?.totalPages,
        currentPage: response?.data?.currentPage,
        totalRecords: response?.data?.totalRecords,
      });
    }
  };


  const handleArchive = async () => {
    const response = await fetch(`${SERVER_BASE_URL}/api/scripts/${selectedId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ archieved: true }),
    });
    const json = await response.json();

    if (json.success) {
      setAnchorEl(null);
      fetchScripts();
      enqueueSnackbar('Script Archived Successfully.', { variant: 'success' });
    } else {
      enqueueSnackbar(response?.message || 'Something went wrong while updating the user name', { variant: 'error' });
    }
  }
  
  const handleStared = async () => {
    const response = await fetch(`${SERVER_BASE_URL}/api/scripts/${selectedId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ stared: !stared }),
    });
    const json = await response.json();

    if (json.success) {
      setAnchorEl(null);
      fetchScripts();
    } else {
      enqueueSnackbar(response?.message || 'Something went wrong while updating the user name', { variant: 'error' });
    }
  }


  return (
    <>
      <Iconify
        aria-describedby={id}
        onClick={handleClick}
        sx={{ fontSize: '15px', cursor: 'pointer' }}
        icon="bi:three-dots-vertical"
      />

      <Popover
        id={id}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ borderRadius: '0px', '& .MuiPaper-root': { borderRadius: '5px' } }}
      >
        <Box sx={{ padding: '', cursor: 'pointer' }} width={'200px'}>
          <Box
            onClick={() => {
              if (onEdit) onEdit();
              handleClose();
            }}
            sx={{ '&:hover': { backgroundColor: '#fe6d291a' }, padding: '.5rem' }}
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
          >
            <Iconify sx={{ fontSize: '15px' }} icon="fa6-solid:a" />
            <Typography sx={{ fontSize: '.875rem' }}>Rename</Typography>
          </Box>
          <Box
            onClick={handleStared}
            sx={{ '&:hover': { backgroundColor: '#fe6d291a' }, padding: '.5rem' }}
            // mt={'10px'}
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
          >
            <Iconify sx={{ fontSize: '15px', color: '#ff922b' }} icon="iconamoon:star-light" />
            <Typography sx={{ fontSize: '.875rem' }}>{stared ? 'Unstared' : 'Star' }</Typography>
          </Box>
          <Box
            onClick={handleArchive}
            sx={{ '&:hover': { backgroundColor: '#fe6d291a' }, padding: '.5rem' }}
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
          >
            <Iconify sx={{ fontSize: '15px' }} icon="ion:archive-outline" />
            <Typography sx={{ fontSize: '.875rem' }}>Archive</Typography>
          </Box>
          <Box
            onClick={handleOpen}
            sx={{ '&:hover': { backgroundColor: '#fe6d291a' }, padding: '.5rem' }}
            // mt={'10px'}
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
          >
            <Iconify sx={{ fontSize: '15px' }} icon="fluent:arrow-reply-20-regular" />
            <Typography sx={{ fontSize: '.875rem' }}>Move...</Typography>
          </Box>

          <Box my={'10px'}>
            <Divider />
          </Box>

          <Box
            sx={{ '&:hover': { backgroundColor: '#fe6d291a' }, padding: '.5rem' }}
            // mt={'10px'}
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
            onClick={() => {
              if (onDelete) onDelete();
              handleClose();
            }}
          >
            <Iconify sx={{ fontSize: '15px', color: '#e03131' }} icon="icon-park-outline:delete" />
            <Typography sx={{ fontSize: '.875rem', color: '#e03131' }}>Delete</Typography>
          </Box>
        </Box>
      </Popover>

      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography mb={'25px'} sx={{ fontSize: '1rem' }}>
            Move script to project
          </Typography>

          <Box
            className="scren-zoomlvl"
            aria-describedby={idProjects}
            onClick={handleClickProjects}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              backgroundColor: '#00017806',
              fontSize: '.75rem',
              padding: ' .25rem .5rem',
              borderRadius: '.25rem',
              width: '100%',
            }}
          >
            <p>{selectedOptionProjects || 'Select Project'}</p>
            <Iconify sx={{ marginLeft: '5px' }} icon="eva:arrow-ios-downward-fill" width={16} height={24} />
          </Box>

          <Popover
            id={idProjects}
            open={openProjects}
            anchorEl={anchorElProjects}
            onClose={handleCloseProjects}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box
              sx={{
                width: '260px',
                maxWidth: 400,
                backgroundColor: '',
                // color: "white",
                padding: '10px',
              }}
            >
              <MenuList>
                {
                  projects.length > 0 ?
                    projects.map((item, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleSelectOption(item.name)}
                        sx={{
                          fontSize: '.75rem',
                          padding: '.25rem .5rem',
                          borderRadius: '.25rem',
                          '&:hover': {
                            backgroundColor: '#fe6d291a',
                          },
                        }}
                      >
                        {item.name}
                      </MenuItem>
                    ))
                    :
                    <MenuItem
                      sx={{
                        fontSize: '.75rem',
                        padding: '.25rem .5rem',
                        borderRadius: '.25rem',
                        '&:hover': {
                          backgroundColor: '#fe6d291a',
                        },
                      }}
                    >
                      No Projects
                    </MenuItem>
                }
              </MenuList>
            </Box>
          </Popover>

          <Box mt={'30px'} display={'flex'} width={'100%'} alignItems={'center'} gap={'10px'}>
            <Typography
              sx={{
                textAlign: 'center',
                width: '100%',
                padding: '.5rem 2rem',
                backgroundColor: '#fe6d29',
                color: '#fff',
                borderRadius: '.25rem',
                fontSize: '.875rem',
                '&:hover': {
                  backgroundColor: '#d0551c',
                },
                cursor: 'pointer',
              }}
              onClick={handleCloseModal}
            >
              OK
            </Typography>
            <Typography
              onClick={handleCloseModal}
              sx={{
                textAlign: 'center',
                width: '100%',
                padding: '.5rem 2rem',
                backgroundColor: '#fe6d291a',
                borderRadius: '.25rem',
                fontSize: '.875rem',
                '&:hover': {
                  backgroundColor: '#00017806',
                },
                cursor: 'pointer',
              }}
            >
              Cancel
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EditingPopover;
