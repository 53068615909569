import { Typography, Box, Popover, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import Iconify from 'src/components/iconify';
import './FilterBeatsPopover.css';

const FliterBeatsPopover = () => {
  const [notes, setNotes] = useState([1, 2, 3, 4, 5]);
  const [checked, setChecked] = useState(false);
  const [character, setCharacter] = useState(false);
  const [storyline, setStoryline] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = anchorEl;
  const id = openpop ? 'simple-popover' : undefined;

  //   aria-describedby={id}
  //   onClick={handleClick}

  return (
    <>
      <Tooltip title="Filter beats" arrow>
      <Box
         display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      sx={{ width: '30px', height: '30px','&:hover':{ backgroundColor: '#00017806'}, borderRadius: '5px' }}
       aria-describedby={id} onClick={handleClick}>
        <Iconify sx={{ '&:hover': { color: '#fe6d29' } }} icon="ph:funnel" />
      </Box>
      </Tooltip>

      <Popover
        id={id}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ borderRadius: '0px', '& .MuiPaper-root': { borderRadius: '5px' } }}
      >
        <Box maxHeight={'350px'} sx={{ overflowY: 'auto', padding: '8px 10px' }} width={'280px'}>
          <Box display={'flex'} gap={'10px'} alignItems={'center'}>
            <Box>
              <input className="filter-input dark-bg dark-text" />
            </Box>
            <Box sx={{ whiteSpace: 'nowrap' }}>
              <Typography
                sx={{ color: '#b2b3c4', fontSize: '.67rem', textDecoration: 'underline', fontFamily: 'inter' }}
              >
                Clear filters
              </Typography>
            </Box>
          </Box>

          <Box mt={'10px'} display={'flex'} alignItems={'center'} gap={'10px'}>
            <Box display={'flex'} alignItems={'center'} gap={'5px'}>
              <Typography sx={{ fontSize: '.67rem' }}>Deselect</Typography>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ width: '20px', height: '20px', backgroundColor: '#f1f3f5', borderRadius: '.25rem' }}
              >
                <Iconify sx={{ color: '#495057' }} icon="gg:arrow-right" />
              </Box>
            </Box>
            <Box display={'flex'} alignItems={'center'} gap={'5px'}>
              <Typography sx={{ fontSize: '.67rem' }}>Close</Typography>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ width: '20px', height: '20px', backgroundColor: '#f1f3f5', borderRadius: '.25rem' }}
              >
                <Iconify sx={{ color: '#495057' }} icon="gg:arrow-right" />
              </Box>
            </Box>
          </Box>

          <Typography className="dark-text" mt={'0.5rem'} mb={'.25rem'} sx={{ fontSize: '.75rem', color: '#333' }}>
            Storylines
          </Typography>

          <Box
            onClick={() => setStoryline(!storyline)}
            width={'100%'}
            height={'35px'}
            sx={{ border: '1px solid #fe6d29', padding: '8px', borderRadius: '5px', borderLeft: '4px solid #fe6d29' }}
            display={'flex'}
            justifyContent={'flex-end'}
          >
            {storyline && (
              <>
                <Box
                  sx={{ width: '15px', height: '15px', backgroundColor: '#fe6d29', borderRadius: '50%' }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Iconify sx={{ color: '#fff', fontSize: '10px' }} icon="mdi:check-bold" />
                </Box>
              </>
            )}
          </Box>

          <Typography className="dark-text" mt={'0.5rem'} mb={'.25rem'} sx={{ fontSize: '.75rem', color: '#333' }}>
            Characters
          </Typography>

          <Box
            onClick={() => setCharacter(!character)}
            width={'100%'}
            sx={{ border: '1px solid #fe6d291a', padding: '5px', borderRadius: '5px' }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
              <Box
                sx={{ color: '#fff', width: '25px', height: '25px', backgroundColor: '#12b886', borderRadius: '50%' }}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                T
              </Box>
              <Typography sx={{ color: '#20c997', fontSize: '.875rem' }}>Tobi</Typography>
            </Box>

            {character && (
              <>
                <Box
                  sx={{ width: '15px', height: '15px', backgroundColor: '#fe6d29', borderRadius: '50%' }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Iconify sx={{ color: '#fff', fontSize: '10px' }} icon="mdi:check-bold" />
                </Box>
              </>
            )}
          </Box>

          <Typography className="dark-text" mt={'0.5rem'} mb={'.25rem'} sx={{ fontSize: '.75rem', color: '#333' }}>
            Notes
          </Typography>

          {/* note 1 */}

          {notes.map((e, i) => (
              <>
                <Box
                  onClick={() => setChecked(!checked)}
                  key={i}
                  mt={'10px'}
                  width={'100%'}
                  sx={{ border: '1px solid #fe6d291a', padding: '5px', borderRadius: '5px' }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                    <Box sx={{}} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                      <Iconify sx={{ color: '#868e96' }} icon="ph:note" />
                    </Box>
                    <Typography sx={{ fontSize: '.875rem',color:checked?"#fe6d29":"" }}>New note</Typography>
                  </Box>
                  {checked && (
                    <>
                      <Box
                        sx={{ width: '15px', height: '15px', backgroundColor: '#fe6d29', borderRadius: '50%' }}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <Iconify sx={{ color: '#fff', fontSize: '10px' }} icon="mdi:check-bold" />
                      </Box>
                    </>
                  )}
                </Box>
              </>
            ))}
        </Box>
      </Popover>
    </>
  );
};

export default FliterBeatsPopover;
