import { Box, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import './ElementsCards.css';
import Iconify from 'src/components/iconify';
import CardRemovePopover from './CardRemovePopover';
import CardImageModal from '../CardImageModal';
import CardNoteModal from '../CardNoteModal';

const MyElementsCards = ({ borderColor, onChange, onChangeDescription, descriptionValue, onBlur, value, onRemove }) => {
  const [inputToggle, setInputToggle] = useState(false);

  return (
    <>
      <Box className="main-card-box" sx={{ borderBottom: `4px solid ${borderColor}` }}>
        <Box
          onClick={() => setInputToggle(!inputToggle)}
          sx={{
            display: inputToggle ? 'flex' : '',
            justifyContent: inputToggle ? 'space-between' : '',
            alignItems: inputToggle ? 'center' : '',
          }}
        >
          <input
            value={value}
            className="mod-card-input"
            placeholder="Untitled"
            onChange={(e) => {
              if (onChange) {
                onChange(e.target.value);
              }
            }}
            onBlur={() => {
              if (onBlur) {
                onBlur();
              }
            }}
          />

          {inputToggle && <Iconify className="dark-text" icon="ep:arrow-down-bold" />}
        </Box>
        {inputToggle && (
          <>
            {/* <TextField
          id="outlined-multiline-static"
          multiline
          rows={4}
          sx={{width:"100%",'& .MuiInputBase-root:focus':{border:"1px solid blue"}}}
          placeholder='...'
        /> */}

            <textarea
              className="beautiful-textarea"
              placeholder="..."
              value={descriptionValue}
              rows="5" // You can adjust the number of rows as needed
              onChange={(e) => {
                if (onChangeDescription) {
                  onChangeDescription(e.target.value);
                }
              }}
              onBlur={(e) => {
                if (onChangeDescription) {
                  onBlur(e.target.value);
                }
              }}
            />

            <Box mt={'10px  '} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Box>
                  <CardRemovePopover onRemove={onRemove} />
                </Box>

                <Tooltip title="Open images" arrow>
                  <Box>
                    <CardImageModal />
                  </Box>
                </Tooltip>
                <Box>
                  <CardNoteModal />
                </Box>
              </Box>
              <Box sx={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: borderColor }} />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default MyElementsCards;
