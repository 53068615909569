import styled from '@emotion/styled';
import { Box, Popover, Switch, Typography } from '@mui/material';
import React, { useState } from 'react';
import Iconify from 'src/components/iconify';




const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 20,
    height: 12,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 10,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(5px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(8px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#fe6d29',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#FFF',
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 8,
      height: 8,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));



const AlarmPopover = () => {
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = anchorEl;
  const id = openpop ? 'simple-popover' : undefined;

  //   aria-describedby={id}
  //   onClick={handleClick}
  return (
    <>
      <Iconify
        aria-describedby={id}
        onClick={handleClick}
        sx={{ fontSize: '25px', '&:hover': { color: '#fe6d29' } }}
        icon="bx:alarm"
      />

      <Popover
        id={id}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right',
  }}
  transformOrigin={{
    vertical: 'bottom',
    horizontal: 'left',
  }}
        sx={{ borderRadius: '0px', '& .MuiPaper-root': { borderRadius: '5px' } }}
      >
        <Box sx={{ padding: '8px 10px' }} width={'280px'}>
          <Box display={'flex'} alignItems={'center'} gap={'10px'}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ width: '30px', height: '30px', borderRadius: '5px', '&:hover': { backgroundColor: '#00017806' } }}
            >
              <Typography sx={{ fontSize: '.875rem', color: '' }}>10</Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ width: '30px', height: '30px', borderRadius: '5px', '&:hover': { backgroundColor: '#00017806' } }}
            >
              <Typography sx={{ fontSize: '.875rem', color: '' }}>25</Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ width: '30px', height: '30px', borderRadius: '5px', '&:hover': { backgroundColor: '#00017806' } }}
            >
              <Typography sx={{ fontSize: '.875r', color: '' }}>60</Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ width: '30px', height: '30px', borderRadius: '5px', '&:hover': { backgroundColor: '#00017806' } }}
            >
              <Typography sx={{ fontSize: '.875rem', color: '' }}>90</Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ width: '30px', height: '30px', borderRadius: '5px', '&:hover': { backgroundColor: '#00017806' } }}
            >
              <Typography sx={{ fontSize: '.875rem', color: '' }}>120</Typography>
            </Box>
            <Box
            ml={"10px"}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ width: '30px', height: '30px', borderRadius: '5px', '&:hover': { backgroundColor: '#00017806' } }}
            >
              <Typography sx={{ fontSize: '.875rem', color: '' }}>Minutes</Typography>
            </Box>
          </Box>

          <Box ml={"5px"} display={'flex'} alignItems={'center'} gap={'10px'}>
            <Box>
              <Iconify sx={{ fontSize: '20px', color: '#b2b3c4' }} icon="iconoir:pause" />
            </Box>
            <Box>
              <Iconify sx={{ fontSize: '20px', color: '#b2b3c4' }} icon="ion:stop-outline" />
            </Box>
            <Box mt={"-5px"}  display={'flex'} alignItems={'center'} gap={"10px"}>
              <Typography sx={{ fontSize: '.75rem', color: '#7b7c8d' }}>Nag me when i don't write</Typography>
              <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default AlarmPopover;
