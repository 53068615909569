import PropTypes from 'prop-types';
import { DESK } from 'src/routes/paths';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

NonprotectedGuard.propTypes = {
  children: PropTypes.node,
};

export default function NonprotectedGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const user = localStorage.getItem('user') || null;
  if ( user) {
    return <Navigate to={DESK} />;
  }

  if (!user) {
    return <>{children}</>
  }

}
