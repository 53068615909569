import React from 'react';
import { Grid } from '@mui/material';
import SidebarItems from './Sidebar/Sidebar';
import Dashboard from './Sidebar/dashboard/Dashboard';

const DeskComponent = () => (
  <>
    <Grid container>
      <SidebarItems />
      <Grid item xs={12} md={9} lg={9.8}>
        <Dashboard />
      </Grid>
    </Grid>
  </>
);

export default DeskComponent;
