import React, { useEffect } from 'react';
import { useFetch } from 'src/hooks/useFetch';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'src/utils/helpers';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid, Divider, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import ProfileEmailModal from './emailmodal/ProfileEmailModal';
import ProfilePasswordModal from './passwordmodal/ProfilePasswordModal';
import ProfileSetupModal from './setupmodal/ProfileSetupModal';
import { selectUserImage } from 'src/utils/common';

const Profile = () => {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  const { enqueueSnackbar } = useSnackbar();
  const [account, setAccount] = React.useState(false);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [updateUser] = useFetch(`api/user/${user?.id}`, ``, '', 'PATCH', false);
  const [loadingData, setLoadingData] = React.useState(false);  
  const { logout } = useAuth();

  const handleLogout = async () => {
    await logout();
  };


  useEffect(() => {
    if (user && !loadingData) {
      setName(user.name);
      setEmail(user.email);
      setLoadingData(true);
    }
  }, [user]);

  const handleupdateUser = async () => {
    if (isEmpty(name)) {
      enqueueSnackbar('Name is required', { variant: 'error' });
      return;
    }
    const response = await updateUser({ name });
    if (response.success) {
      localStorage.setItem('user', JSON.stringify(response?.data));
      enqueueSnackbar('Name updated successfully', { variant: 'success' });
    } else {
      enqueueSnackbar(response?.message || 'Something went wrong while updating the user name', { variant: 'error' });
    }
  };

  const handleUpdateEmail = async () => {
    const response = await updateUser({ email });
    if (response.success) {
      enqueueSnackbar('Email updated successfully', { variant: 'success' });
      localStorage.setItem('user', JSON.stringify(response?.data));
    } else {
      enqueueSnackbar(response?.message || 'Something went wrong while updating the user email', { variant: 'error' });
    }
  };

  const selectImage = () => {
    selectUserImage(user, enqueueSnackbar);
  }


  return (
    <>
      {account === true ? (
        <>
          <Typography sx={{ fontSize: '1.25rem' }}>You can continue on the free plan!</Typography>

          <Box mt={'20px'}>
            <Typography sx={{ fontSize: '.875rem' }}>
              It is limited compared to Pro,but still the best screnpla experience available and fully functional.
            </Typography>
            <Typography mt={'10px'} sx={{ fontSize: '.875rem' }}>
              Are you sure you want to cancel?
            </Typography>
          </Box>

          <Box mt={'30px'} display={'flex'} width={'100%'} alignItems={'center'} gap={'10px'}>
            <Typography
              onClick={() => setAccount(false)}
              sx={{
                textAlign: 'center',
                width: '70%',
                padding: '.5rem 2rem',
                backgroundColor: '#fe6d29',
                color: '#fff',
                borderRadius: '.25rem',
                fontSize: '.875rem',
                '&:hover': {
                  backgroundColor: '#d0551c',
                },
              }}
            >
              Stay on the free plan
            </Typography>
            <Typography
              sx={{
                textAlign: 'center',
                width: '100%',
                padding: '.5rem 2rem',
                backgroundColor: '#fe6d291a',
                borderRadius: '.25rem',
                fontSize: '.875rem',
                '&:hover': {
                  backgroundColor: '#00017806',
                },
              }}
            >
              I still want to delete my account
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Box>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <Typography className="dark-text" color={'#333'} fontWeight={'600'} fontSize={'.875rem'}>
                  Account info
                </Typography>
                <Typography mt={'.25rem'} lineHeight={'1.25'} color={'#b2b3c4'} fontSize={'.75rem'}>
                  Your name and profile picture will be visible to your collaborators.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
              <Avatar
                sx={{ bgcolor: '#fe6d2963', color: 'white', cursor: 'pointer', width: '60px', height: '60px' }}
                src={user?.avatar || "/broken-image.jpg"}
                onClick={() => {
                  selectImage();
                }}
              />
              </Grid>
            </Grid>

            <Grid mt={'20px'} container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <Typography className="dark-text" color={'#333'} fontWeight={'600'} fontSize={'.875rem'}>
                  Display Name
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <input
                    className="vertical-input light-bg dark-text"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={handleupdateUser}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid mt={'20px'} container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <Typography className="dark-text" color={'#333'} fontWeight={'600'} fontSize={'.875rem'}>
                  Email
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography
                    className="dark-text"
                    mt={'.25rem'}
                    lineHeight={'1.25'}
                    color={'#b2b3c4'}
                    fontSize={'.75rem'}
                  >
                    Email
                  </Typography>
                  <ProfileEmailModal
                    email={email}
                    handleUpdateEmail={handleUpdateEmail}
                    setEmail={setEmail}
                    enqueueSnackbar={enqueueSnackbar}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid mt={'20px'} container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <Typography className="dark-text" color={'#333'} fontWeight={'600'} fontSize={'.875rem'}>
                  Password
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography
                    className="dark-text"
                    mt={'.25rem'}
                    lineHeight={'1.25'}
                    color={'#b2b3c4'}
                    fontSize={'.75rem'}
                  >
                    Password
                  </Typography>
                  <ProfilePasswordModal enqueueSnackbar={enqueueSnackbar} userId={user.id} displayAt="settingModal" />
                </Box>
              </Grid>
            </Grid>

            {/* Divider */}
            <Box mt={'20px'}>
              <Divider />
            </Box>

            {/* <Grid mt={'10px'} container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <Typography className="dark-text" color={'#333'} fontWeight={'600'} fontSize={'.875rem'}>
                  Custom Templates
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box />
              </Grid>
            </Grid> */}

            {/* Divider */}
            {/* <Box mt={'10px'}>
              <Divider />
            </Box> */}

            {/* <Grid mt={'20px'} container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <Typography className="dark-text" color={'#333'} fontWeight={'600'} fontSize={'.875rem'}>
                  Account info
                </Typography>
                <Typography mt={'.25rem'} lineHeight={'1.25'} color={'#b2b3c4'} fontSize={'.75rem'}>
                  Once connected, we will automatically sync backups of your script to your cloud storage when you make
                  changes to it.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Box className="vertical-change">
                    <p>Change</p>
                  </Box>
                </Box>
              </Grid>
            </Grid> */}

            <Grid mt={'20px'} container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <Typography className="dark-text" color={'#333'} fontWeight={'600'} fontSize={'.875rem'}>
                  Two-factor authentication
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <ProfileSetupModal displayAt="settings" />
                </Box>
              </Grid>
            </Grid>

            <Grid mt={'20px'} container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <Typography className="dark-text" color={'#333'} fontWeight={'600'} fontSize={'.875rem'}>
                  Session
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Box className="vertical-change" onClick={handleLogout}>
                    <p>Sign out from this device </p>
                  </Box>
                  <Box mt={'10px'} className="vertical-change" onClick={handleLogout}>
                    <p>Sign out from all devices </p>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {/* <Grid mt={'20px'} container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <Typography className="dark-text" color={'#333'} fontWeight={'600'} fontSize={'.875rem'}>
                  Account
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Box onClick={() => setAccount(true)} className="vertical-change">
                    <p>Close account</p>
                  </Box>
                </Box>
              </Grid>
            </Grid> */}

            <Grid mt={'20px'} container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <Typography className="dark-text" color={'#333'} fontWeight={'600'} fontSize={'.875rem'}>
                  About
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography mt={'.25rem'} lineHeight={'1.25'} color={'#b2b3c4'} fontSize={'.875rem'}>
                scrēnplā, LLC. 1112 Van Buren Ave, Oxford, United States.
                </Typography>
                <Box sx={{ '& a': { textDecoration: 'none' } }} display={'flex'} gap={'5px'} alignItems={'center'}>
                  <Link target='_blank' to={'https://screnpla.com/privacy'}>
                    <Typography
                      sx={{ borderBottom: '1px solid #b2b3c4' }}
                      mt={'.25rem'}
                      lineHeight={'1.25'}
                      color={'#b2b3c4'}
                      fontSize={'.75rem'}
                    >
                      Privacy Policy.
                    </Typography>
                  </Link>

                  <Typography
                    sx={{ borderBottom: '1px solid #b2b3c4' }}
                    mt={'.25rem'}
                    lineHeight={'1.25'}
                    color={'#b2b3c4'}
                    fontSize={'.75rem'}
                  >
                    |
                  </Typography>
                  <Link sx={{ textDecoration: 'none' }} target='_blank' to={'https://screnpla.com/terms'}>
                    <Typography
                      sx={{ borderBottom: '1px solid #b2b3c4' }}
                      mt={'.25rem'}
                      lineHeight={'1.25'}
                      color={'#b2b3c4'}
                      fontSize={'.75rem'}
                    >
                      Terms of Service
                    </Typography>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default Profile;
