import { Box, Typography } from '@mui/material';
import React from 'react';
import { useSnackbar } from 'notistack';

import { useFetch } from 'src/hooks/useFetch';
import { useNavigate } from 'react-router';
import { getLogoPath } from 'src/utils/common';
import { Signin } from '../../../../../routes/paths';
import '../signup/Signup.css';

const ResetPassword = () => {
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token') || null;
  const secret = query.get('secret') || null;
  const userId = query.get('userId') || null;

  const [password, setPassword] = React.useState('');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [verifyOtp, loading] = useFetch(
    'api/auth/verifyOTP',
    `Get user successfully`,
    'Something went wrong while getting the user',
    'POST',
    false
  );

  if (!token || !secret || !userId) {
    return (
      <>
        <Box mb={'10px'} py={'30px'} display={'flex'} justifyContent={'center'}>
          <img src={getLogoPath()} alt="" width={'200px'} />
        </Box>
        <Box display={'flex'} justifyContent={'center'}>
          <Box p={'10px'} display={'flex'} alignItems={'center'} flexDirection={'column'} width={'440px'}>
            <Typography sx={{ fontSize: '1.52rem', fontFamily: 'inter', color: '' }}>
              Opps Sorry Invalid Url!
            </Typography>
            <Typography
              onClick={() => {
                navigate(Signin);
              }}
              mt={'10px'}
              width={'100%'}
              textAlign={'center'}
              sx={{
                fontSize: '.875rem',
                color: '#fff',
                padding: '1rem 2rem',
                border: '1px solid #fe6d29',
                fontWeight: '400',
                backgroundColor: loading ? '#000178' : '#fe6d29',
                cursor: 'pointer',
                borderRadius: '.25rem',
                fontFamily: 'inter',
                '&:hover': { backgroundColor: '#d0551c' },
              }}
            >
              {'Login'}
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  const message = (
    <div>
      If we recognized this email address,
      <br />
      We have sent you a link for resetting your password.
      <br />
      Please check your email (including spam). <br />
      If you have not received an email,
      <br />
      Please try another address.
    </div>
  );

  const handleClick = async () => {
    if (loading) return;
    if (!token || !secret || !userId) {
      enqueueSnackbar(`Invalid URL.`, {
        variant: 'error',
      });
      return;
    }

    if (!password || password?.trim()?.length < 8) {
      enqueueSnackbar(`Password must be at least 8 characters long.`, {
        variant: 'error',
      });
      return;
    }

    const response = await verifyOtp({ password, token, secret, userId });
    if (!response.success) {
      enqueueSnackbar(response?.message || 'Something went wrong', {
        variant: 'error',
      });
    } else {
      enqueueSnackbar(message, {
        variant: 'success',
      });
      navigate(Signin);
    }
  };

  return (
    <>
      <Box mb={'10px'} py={'30px'} display={'flex'} justifyContent={'center'}>
        <img src={getLogoPath()} alt="" width={'200px'} />
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <Box p={'10px'} display={'flex'} alignItems={'center'} flexDirection={'column'} width={'440px'}>
          <Typography sx={{ fontSize: '1.52rem', fontFamily: 'inter', color: '' }}>Reset Your Password</Typography>

          <Box mt={'20px'} width={'100%'}>
            <Typography sx={{ fontSize: '.875rem', fontFamily: '', color: '' }}>New Password</Typography>
            <input
              className="signup-input"
              type="password"
              required
              placeholder="Create a new password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Box>
          <Typography
            onClick={handleClick}
            mt={'10px'}
            width={'100%'}
            textAlign={'center'}
            sx={{
              fontSize: '.875rem',
              color: '#fff',
              padding: '1rem 2rem',
              border: '1px solid #fe6d29',
              fontWeight: '400',
              backgroundColor: loading ? '#000178' : '#fe6d29',
              cursor: 'pointer',
              borderRadius: '.25rem',
              fontFamily: 'inter',
              '&:hover': { backgroundColor: '#d0551c' },
            }}
          >
            {loading ? 'Loading...' : 'Reset Password'}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ResetPassword;
