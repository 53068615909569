import { Box, Grid, Typography } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import React, { useState } from 'react';
import Iconify from 'src/components/iconify';
import { useFetch } from 'src/hooks/useFetch';
import ScriptTopDrawer from './ScriptTopDrawer';
import Support from './support/Support';
import SidebarSettingsModal from './sidebarsettings/Settings';
import WhatsNew from './whatsnew/WhatsNew';
import ReferFriend from './referafriend/ReferFriend';
import DesktopApp from './desktopapp/DesktopApp';

const SidebarItems = () => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useNavigate();
  const location = window.location.pathname;
  const { logout } = useAuth();



  const handleLogout = async () => {
    await logout();
  };
  const staredScripts = JSON.parse(localStorage.getItem('staredScripts'));



  const toggleSidebar = () => {
    setIsOpen(!isOpen); // Toggle sidebar open/close state
  };
  return (
    <>
      <Button 
        onClick={toggleSidebar} 
        
        sx={{ position: 'fixed', top: '10px', left: '10px', zIndex: 1000, color:"orange",  display: { xs: "block", md: "none" },}}
      >
        <Iconify  icon={isOpen ? 'eva:arrow-ios-back-outline' : 'eva:menu-outline'} />
      {/* {isOpen ?  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M20.328 11v2H7.5l3.243 3.243l-1.415 1.414L3.672 12l5.656-5.657l1.415 1.414L7.5 11z"/></svg>:<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="m13.692 17.308l-.707-.72l4.088-4.088H5v-1h12.073l-4.088-4.088l.707-.72L19 12z"/></svg>} */}
      </Button>

      <Grid
         className={`light-bg deskSidebarDrawer ${isOpen ? 'open' : 'closed'}`}
        minHeight={'100vh'}
        maxHeight={'100vh'}
        item
        xs={12}
        lg={2.2}
        md={3}
        sx={(theme) => ({
          backgroundColor: '#f3f4f9',
          overflowY: 'auto',
          position: 'fixed',
          top: 0,
          left: isOpen ? 0 : '-100%', // Slide effect
          transition: 'left 0.3s ease', // Smooth transition
          zIndex: 999,
          [theme.breakpoints.up('md')]: {
            left: 'unset', // Default behavior for larger screens
            position: 'sticky',
          },
        })}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          height={'100%'}
          p={2}
          pt={8}
          sx={{ backgroundColor: '', border: '1px solid #fe6d291a' }}
        >
          <Box>
            <Box
              onClick={() => { router('/desk') }}
              display={'flex'}
              alignItems={'center'}
              gap={'10px'}
              sx={{
                padding: '0.5rem 1rem',
                backgroundColor: location === '/desk' ? '#fe6d291a' : '',
                color: location === '/desk' ? '#fe6d29' : '',
                borderRadius: '.25rem',
                cursor: 'pointer',
              }}
            >
              <Iconify sx={{ fontSize: '20px' }} icon="radix-icons:dashboard" />
              <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Dashboard</Typography>
            </Box>

            <Box
              onClick={() => { router('/scripts') }}
              mt={'20px'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              gap={'10px'}
              sx={{
                padding: '0.5rem 1rem',
                borderRadius: '.25rem',
                backgroundColor: location === '/scripts' ? '#fe6d291a' : '',
                color: location === '/scripts' ? '#fe6d29' : '',
                cursor: 'pointer',
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Iconify sx={{ fontSize: '20px' }} icon="uil:file-alt" />
                <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Scripts</Typography>
              </Box>
              <ScriptTopDrawer />
            </Box>

            {staredScripts > 0 &&
              <Box
                onClick={() => { router('/stared') }}
                mt={'20px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                gap={'10px'}
                sx={{
                  padding: '0.5rem 1rem',
                  borderRadius: '.25rem',
                  backgroundColor: location === '/stared' ? '#fe6d291a' : '',
                  color: location === '/stared' ? '#fe6d29' : '',
                  cursor: 'pointer',
                }}
              >
                <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                  <Iconify sx={{ fontSize: '20px' }} icon={location === '/stared' ? "ic:sharp-star" : "iconamoon:star-light"} />
                  <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Stared Scripts</Typography>
                </Box>
                <Box sx={{ backgroundColor: '#9178ed', color: "#270e5d", borderRadius: '5%', padding: '0px 5px' }}>
                  <Typography sx={{ fontSize: '.875rem', fontWeight: '600', color: '#fff' }}>
                    {staredScripts}
                  </Typography>
                </Box>
              </Box>}

            <Box
              onClick={() => { router('/projects') }}
              mt={'20px'}
              display={'flex'}
              alignItems={'center'}
              gap={'10px'}
              sx={{
                padding: '0.5rem 1rem',
                borderRadius: '.25rem',
                backgroundColor: location === '/projects' ? '#fe6d291a' : '',
                color: location === '/projects' ? '#fe6d29' : '',
                cursor: 'pointer',
              }}
            >
              <Iconify sx={{}} icon="material-symbols:folder-outline" width={20} height={24} />
              <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Projects</Typography>
            </Box>

            <Box
              onClick={() => { router('/account') }}
              mt={'20px'}
              display={'flex'}
              alignItems={'center'}
              gap={'10px'}
              sx={{
                padding: '0.5rem 1rem',
                borderRadius: '.25rem',
                backgroundColor: location === '/account' ? '#fe6d291a' : '',
                color: location === '/account' ? '#fe6d29' : '',
                cursor: 'pointer',
              }}
            >
              <Iconify sx={{}} icon="bx:user" width={20} height={24} />
              <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Manage Account</Typography>
            </Box>
          </Box>

          <Box
            onClick={() => { 
              // window .location = 'https://gorillafilm.com' on target blank

              window.open('https://gorillafilm.com', '_blank')

             }}
            className="dark-bg"
            my={'40px'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
              padding: '0.5rem',
              backgroundImage: 'linear-gradient(180deg, #ff9500, #ff5e3a)',
              borderRadius: '.25rem',
              boxShadow: '0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08)',
              cursor: 'pointer',
            }}
          >
            <img src="https://app.gorillafilm.com/images/gorilla-logo-large.png" alt="Gorilla Films" width={"60%"} height={25} />
            {/* <Typography variant="subtitle1" sx={{ color: '#ff9500', fontSize: '', fontWeight: '600' }}>
              Gorilla Films
            </Typography> */}
          </Box>

          {/* <DesktopApp /> */}

          <Box>
            <SidebarSettingsModal />
            {/* <WhatsNew /> */}
            <ReferFriend />
            {/* <Support /> */}
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#fe6d29',
                color: '#fff',
                width: '100%',
                borderRadius: '.25rem',
                marginTop: '1rem',
                marginBottom: '1rem',
                boxShadow: '0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08)',
                '&:hover': { backgroundColor: '#d0551c' },
              }}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default SidebarItems;
