import Welcome from 'src/components/slate-editor/components/authentication/welcome/Welcome';
import { useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import SignIn from 'src/components/slate-editor/components/authentication/signin/SignIn';
import Signup from 'src/components/slate-editor/components/authentication/signup/Signup';
import ForgotPassword from 'src/components/slate-editor/components/authentication/forgotpassword/ForgotPassword';
import ResetPassword from 'src/components/slate-editor/components/authentication/resetpassword/ResetPassword';  
import ProjectDetailsComponent from 'src/components/projects/project-details';
import StaredComponent from 'src/components/stared-scripts';
import { initialValue } from '../utils/constants';

// guards
import NonprotectedGuard from '../guards/NonprotectedGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config

// components

import ScriptEditor from '../components/slate-editor/Editor';

import DesktopComponent from '../components/desk';
import ScriptComponent from '../components/scripts';
import ProjectsComponent from '../components/projects';
import AccountsComponent from '../components/account';

// ----------------------------------------------------------------------

export default function Router() {
  const [document, updateDocument] = useState(initialValue);

  return useRoutes([
   // Dashboard Routes
   {
    path: 'desk',
    element: (
      <AuthGuard>
        <DesktopComponent />
      </AuthGuard>
    ),
  },
   {
    path: 'scripts',
    element: (
      <AuthGuard>
        <ScriptComponent />
      </AuthGuard>
    ),
  },
    // Independent Script Editor Route
    {
      path: 'script-editor',
      element: (
        <AuthGuard>
          <ScriptEditor document={document} onChange={updateDocument} />
        </AuthGuard>
      ),
    },
    // Script By Id Route
    {
      path: 'script/:id',
      element: (
        <AuthGuard>
          <ScriptEditor document={document} onChange={updateDocument} />
        </AuthGuard>
      ),
    },
    {
      path: 'projects',
      element: (
        <AuthGuard>
          <ProjectsComponent />
        </AuthGuard>
      ),
    },
    {
      path: 'project/:id',
      element: (
        <AuthGuard>
          <ProjectDetailsComponent />
        </AuthGuard>
      ),
    },
    {
      path: 'stared',
      element: (
        <AuthGuard>
          <StaredComponent />
        </AuthGuard>
      ),
    },
    {
      path: 'account',
      element: (
        <AuthGuard>
          <AccountsComponent />
        </AuthGuard>
      ),
    },
    {
      path: 'signup',
      element: (
        // <AuthGuard>
        <NonprotectedGuard>
          <Signup />
        </NonprotectedGuard>
        // </AuthGuard>
      ),
    },
    {
      path: 'welcome',
      element: (
        // <AuthGuard>
        <NonprotectedGuard>
          <Welcome />
        </NonprotectedGuard>
        // </AuthGuard>
      ),
    },
    {
      path: 'login',
      element: (
        // <AuthGuard>
        <NonprotectedGuard>
          <SignIn />
        </NonprotectedGuard>
        // </AuthGuard>
      ),
    },
    {
      path: 'forgot-password',
      element: (
        // <AuthGuard>
        <NonprotectedGuard>
          <ForgotPassword />
        </NonprotectedGuard>
        // </AuthGuard>
      ),
    },
    {
      path: 'reset-password',
      element: (
        // <AuthGuard>
        <NonprotectedGuard>
          <ResetPassword />
        </NonprotectedGuard>
        // </AuthGuard>
      ),
    },

    {
      path: '/',
      element: <Navigate to="login" replace />,
    },
    // Main Routes

    { path: '*', element: (
      <div style={{paddingTop: '20%', textAlign: 'center'}}>
        <h1>404</h1>
        <p>Page not found</p>
      </div>
    ) },
  ]);
}

// IMPORT COMPONENTS

// Authentication

// Dashboard

// Main
