/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, CircularProgress } from '@mui/material';

class VoiceRecorderComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recordState: null,
      uploadingAudio: false,
      recordings: [],
    };
  }

  componentDidMount() {
    if (!window.MediaRecorder) {
      alert('Your browser does not support the MediaRecorder API');
    }
  }

  start = () => {
    this.props.setRecorderAudioUrl('');
    this.setState({
      recordings: [],
      recordState: RecordState.START,
    });
  };

  stop = () => {
    this.setState({
      recordState: RecordState.STOP,
    });
  };

  onStop = (audioData) => {
    // Check if the recording is valid and has length
    console.log('audioData:', audioData);
    if (audioData.blob.size > 100) {
      this.setState({ uploadingAudio: true });
      const formData = new FormData();
      const generateRandomString = () => Math.random().toString(36).substring(7);
      formData.append('audio', audioData.blob, `recording${generateRandomString()}.mp3`);

      axios
        .post('https://helper.screnpla.com/upload-audio', formData)
        .then((response) => {
          console.log('Audio uploaded successfully:', response.data);
          this.setState({ uploadingAudio: false });
          this.setState(() => ({
            recordings: [audioData],
          }));
          this.props.setRecorderAudioUrl(response.data.url);
        })
        .catch((error) => {
          console.error('Error uploading audio:', error);
        });
    } else {
      console.warn('Recording is empty, not saving.');
    }
  };


  render() {
    const { recordState, recordings } = this.state;
    const { componentStyle, insertVoiceDialogue, setType, recorderAudioUrl } = this.props;
    const isRecording = recordState === RecordState.START;

    return (
      <Box sx={{ textAlign: 'center' }}>
        {
          componentStyle === "commentPopup" ?
            <>
              <Box sx={{
                display: 'flex',
                marginTop: 1,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
                <div
                  className="comment-popup-rec"
                  style={{
                    display: "flex"
                  }}>
                  <IconButton
                    onClick={isRecording ? this.stop : this.start}
                    sx={{
                      fontSize: 22,
                      color: isRecording ? 'red' : 'white',
                      backgroundColor: isRecording ? '#FF5252' : '#fe6d29',
                      width: 30,
                      height: 30,
                      borderRadius: '50%',
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                      '&:hover': {
                        backgroundColor: isRecording ? '#FF5252' : '#4CAF50',
                      },
                      transition: 'background-color 0.3s ease',
                    }}
                  >
                    {isRecording ? <StopIcon fontSize="inherit" /> : <MicIcon fontSize="inherit" />}
                  </IconButton>
                  <Box
                    sx={{
                      display: isRecording ? 'flex' : 'none',
                      marginTop: 2,
                    }}
                  >
                    <AudioReactRecorder state={recordState} onStop={this.onStop}
                      backgroundColor="#fff"
                      foregroundColor="#fe6d29"

                    />
                  </Box>
                </div>
                {/* Uploading Audio */}
                <div style={{
                  display: "flex",
                  gap: "10px"
                }}>

                  {
                    this.state.uploadingAudio &&
                    <Typography sx={{ color: 'green', fontSize: '12px' }}>
                      <CircularProgress
                        size={20}
                        color="warning"
                        sx={{
                          position: 'relative',
                          top: '5px',
                          right: '5px',
                        }}
                        thickness={5}
                      />
                      {this.state.uploadingAudio ? 'Uploading audio...Please wait!' : ''}
                    </Typography>}

                
                </div>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 2 }}>
                  {recordings.map((audio, index) => (
                    <Box key={index} >
                      <audio controls src={audio.url}
                      controlsList="nodownload noremoteplayback nofullscreen noplaybackrate" />
                    </Box>
                  ))}
                </Box>

                { 
                  recorderAudioUrl &&
                  <Box
                    sx={{
                      margin: "auto",
                      marginTop: "5px",
                      marginBottom: "10px",
                      textAlign: "center",
                      justifyContent: "center"
                    }}>
                    <Button
                      size="sm"
                      style={{
                        height: "36px", marginRight: "10px",
                        background: "#fe6d29"
                      }}
                      // className="commentBtn"
                      variant="contained"
                      onClick={() => {
                        insertVoiceDialogue();
                        setType(null);
                      }}>
                      Insert Recording
                    </Button>
                  </Box> }


              </Box>
            </>
            :
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 2, padding: 2,
              alignItems: 'center',
              flexDirection: 'column',
            }}>
              <IconButton
                onClick={isRecording ? this.stop : this.start}
                sx={{
                  fontSize: 60,
                  color: isRecording ? 'red' : 'white',
                  backgroundColor: isRecording ? '#FF5252' : '#fe6d29',
                  width: 100,
                  height: 100,
                  borderRadius: '50%',
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                  '&:hover': {
                    backgroundColor: isRecording ? '#FF5252' : '#4CAF50',
                  },
                  transition: 'background-color 0.3s ease',
                }}
              >
                {isRecording ? <StopIcon fontSize="inherit" /> : <MicIcon fontSize="inherit" />}
              </IconButton>

              {/* Uploading Audio */}

              {
                this.state.uploadingAudio &&
                <Typography sx={{ marginTop: 2, color: 'green', fontSize: '12px' }}>
                  <CircularProgress
                    size={20}
                    color="warning"
                    sx={{
                      position: 'relative',
                      top: '5px',
                      right: '5px',
                    }}
                    thickness={5}
                  />
                  {this.state.uploadingAudio ? 'Uploading audio...Please wait!' : ''}
                </Typography>}

              <Box sx={{
                display: isRecording ? 'block' : 'none',
                marginTop: 2,
              }}>
                <AudioReactRecorder state={recordState} onStop={this.onStop}
                  backgroundColor="#fff"
                  foregroundColor="#fe6d29"

                />
              </Box>
              {!isRecording && recordings.length === 0 && (
                <Typography sx={{ marginTop: 2, color: '#666', fontSize: '16px' }}>
                  Press the button to start recording
                </Typography>
              )}

              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 2 }}>
                {recordings.map((audio, index) => (
                  <Box key={index} sx={{ marginBottom: 2 }}>
                    <audio controls src={audio.url} 
                    controlsList="nodownload noremoteplayback nofullscreen noplaybackrate"/>
                  </Box>
                ))}
              </Box>
            </Box>
        }
      </Box>
    );
  }
}

export default VoiceRecorderComponent;
