import { Box, Modal,  Typography } from '@mui/material';
import React from 'react';
import Iconify from 'src/components/iconify';

import './BottomRightItem.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  maxWidth:600,
  // minHeight: 560,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
 
};

const BottomRightItemModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Iconify
      className="dark-text"
        onClick={handleOpen}
        sx={{ color: '#333', marginTop: '5px',fontSize:"22px", '&:hover': { color: '#fe6d29' } }}
        icon="octicon:question-16"
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Box p={4}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>We are here to help </Typography>

            <Box
              onClick={handleClose}
              sx={{
                width: '20px',
                height: '20px',
                '&:hover': { backgroundColor: '#00017806',color:"#fe6d29" },
                borderRadius: '5px',
              }}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Iconify sx={{fontSize:"30px", color: '' }} icon="ri:close-fill" />
            </Box>
          </Box>

          <Box mt={'30px'} width={'100%'}>
            <input type="text" placeholder="Search our help center..." className="help-input dark-bg dark-text" />
          </Box>
          <Box mt={"20px"}>
            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Common questions</Typography>
          </Box>
          

<Box mt={"10px"} minHeight={"150px"} maxHeight={"150px"} sx={{overflowY:'auto'}}>
  <Box mt={"10px"} display={"flex"} alignItems={"center"} gap={"10px"}>

  <Iconify
            sx={{color:'orange'}}
                  icon="codicon:tag"
                />
    <Typography sx={{fontSize:".875rem",color:"#7b7c8d"}}>How Do I Change the Comment Tag Color?</Typography>
  </Box>
  <Box mt={"10px"} display={"flex"} alignItems={"center"} gap={"10px"}>

  <Iconify
            sx={{color:'orange'}}
                  icon="codicon:tag"
                />
    <Typography sx={{fontSize:".875rem",color:"#7b7c8d"}}>How Do I Change the Comment Tag Color?</Typography>
  </Box>
  <Box mt={"10px"} display={"flex"} alignItems={"center"} gap={"10px"}>

  <Iconify
            sx={{color:'orange'}}
                  icon="codicon:tag"
                />
    <Typography sx={{fontSize:".875rem",color:"#7b7c8d"}}>How Do I Change the Comment Tag Color?</Typography>
  </Box>
  <Box mt={"10px"} display={"flex"} alignItems={"center"} gap={"10px"}>

  <Iconify
            sx={{color:'orange'}}
                  icon="codicon:tag"
                />
    <Typography sx={{fontSize:".875rem",color:"#7b7c8d"}}>How Do I Change the Comment Tag Color?</Typography>
  </Box>
  <Box mt={"10px"} display={"flex"} alignItems={"center"} gap={"10px"}>

  <Iconify
            sx={{color:'orange'}}
                  icon="codicon:tag"
                />
    <Typography sx={{fontSize:".875rem",color:"#7b7c8d"}}>How Do I Change the Comment Tag Color?</Typography>
  </Box>
  <Box mt={"10px"} display={"flex"} alignItems={"center"} gap={"10px"}>

  <Iconify
            sx={{color:'orange'}}
                  icon="codicon:tag"
                />
    <Typography sx={{fontSize:".875rem",color:"#7b7c8d"}}>How Do I Change the Comment Tag Color?</Typography>
  </Box>
  <Box mt={"10px"} display={"flex"} alignItems={"center"} gap={"10px"}>

  <Iconify
            sx={{color:'orange'}}
                  icon="codicon:tag"
                />
    <Typography sx={{fontSize:".875rem",color:"#7b7c8d"}}>How Do I Change the Comment Tag Color?</Typography>
  </Box>
</Box>

          <Box mt={'10px'}>
            <Typography sx={{ fontSize: '.875rem', fontWeight: '600', color: '#fe6d29' }}>Browse all guides</Typography>
          </Box>
</Box>
          <Box
          width={"100%"}
            mt={'20px'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            gap={'10px'}
            sx={{ width: '100%', backgroundColor: '#00017806', padding: '30px 20px' }}
          >
            <Box  className="light-bg" width={"50%"} sx={{ backgroundColor: '#fff' }} p={'20px'} py={"28px"}>
              <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                <Iconify
                  sx={{
                    color: '#fe6d29',
                    fontSize: '',
                  }}
                  icon="material-symbols:electric-bolt"
                />
                <Typography sx={{ fontSize: '.875rem' }}>Report a problem</Typography>
              </Box>
              <Typography sx={{ color: '#b2b3c4', fontSize: '.75rem' }}>Contact support to resolve an issue</Typography>
            </Box>
            <Box className="light-bg" width={"50%"} sx={{ backgroundColor: '#fff' }} p={'20px'}>
              <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                <Iconify
                  sx={{
                    color: '#fe6d29',
                    fontSize: '',
                  }}
                  icon="tabler:heart-filled"
                />
                <Typography sx={{ fontSize: '.875rem' }}>Feature request</Typography>
              </Box>
              <Typography sx={{ color: '#b2b3c4', fontSize: '.75rem' }}>
                Have an idea how we might make your work easier? Tell us!
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default BottomRightItemModal;
