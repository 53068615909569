import { useState, useEffect } from 'react';
import { SERVER_BASE_URL } from 'src/config';

function useScript(id, refresh) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      fetch(`${SERVER_BASE_URL}/api/scripts/${id}`)
        .then((response) => response.json())
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching todos:', error);
          setLoading(false);
        });
    } catch (error) {
      console.error('Error fetching todos:', error);
      setLoading(false);
    }
  }, [id, refresh]);

  return [data, loading];
}

export default useScript;
