/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  MenuItem,
  MenuList,
  Popover,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import './SmTopDrawer.css';
import styled from '@emotion/styled';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 20,
  height: 12,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 10,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(5px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(8px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#fe6d29',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#FFF',
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 8,
    height: 8,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const SmTopDrawer = () => {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState('pdf');
  const [beats, setBeats] = useState(false);
  const [type, setType] = useState('pdf');

  // Function to open the Drawer
  const openDrawer = () => {
    setOpen(true);
  };

  // Function to close the Drawer
  const closeDrawer = () => {
    setOpen(false);
  };

  const handleBackdropClick = () => {
    // Close the drawer when clicking on the backdrop
    closeDrawer();
  };
  return (
    <>
      <Iconify
        fontSize={'20px'}
        className="dark-text"
        onClick={openDrawer}
        sx={{ color: '#000' }}
        icon="mingcute:upload-line"
      />

      <Drawer
        anchor="top"
        open={open}
        onClose={closeDrawer}
        // disableBackdropTransition // Prevent background from being disabled
        PaperProps={{ style: { width: '100%', maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' } }}
        hideBackdrop
      >
        {open && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,

              zIndex: -99, // Behind the drawer content
            }}
            onClick={handleBackdropClick} // Close the drawer on backdrop click
          />
        )}

        <Box
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              padding: '20px',
            },
          })}
          p={'30px'}
        >
          <Typography sx={{ textAlign: 'center', fontSize: '1rem', fontFamily: 'inter' }}>Export document</Typography>

          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={'20px'}>
            <Typography
              sx={{
                fontSize: '.875rem',
                borderBottom: tab === 'pdf' ? '1px solid #fe6d29' : '',
                paddingBottom: '10px',
              }}
              onClick={() => setTab('pdf')}
            >
              PDF
            </Typography>
            <Typography
              sx={{
                fontSize: '.875rem',
                borderBottom: tab === 'fountain' ? '1px solid #fe6d29' : '',
                paddingBottom: '10px',
              }}
              onClick={() => setTab('fountain')}
            >
              Foundation/Text
            </Typography>
            <Typography
              sx={{
                fontSize: '.875rem',
                borderBottom: tab === 'final' ? '1px solid #fe6d29' : '',
                paddingBottom: '10px',
              }}
              onClick={() => setTab('final')}
            >
              Final Draft
            </Typography>
            <Typography
              sx={{
                fontSize: '.875rem',
                borderBottom: tab === 'outline' ? '1px solid #fe6d29' : '',
                paddingBottom: '10px',
              }}
              onClick={() => setTab('outline')}
            >
              Outline
            </Typography>
          </Box>
          <Divider />

          {tab === 'pdf' && (
            <>
              <Box>
                <Box
                  mt={'15px'}
                  sx={{ padding: '1rem', backgroundColor: '#00017806', borderRadius: '.5rem' }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Box>
                    <Typography sx={{ fontSize: '.875rem' }}>Want others to read your script?</Typography>
                    <Typography sx={{ fontSize: '.75rem', color: '#7b7c8d' }}>Request a review instead.</Typography>
                  </Box>
                  <Typography
                    className="light-bg"
                    sx={{
                      fontSize: '.875rem',
                      color: '#fe6d29',
                      backgroundColor: '#fe6d291a',
                      padding: '2px',
                      borerRadius: '.25rem',
                    }}
                  >
                    Request review
                  </Typography>
                </Box>

                <Box
                  mt={'15px'}
                  sx={{ padding: '1rem', backgroundColor: '#00017806', borderRadius: '.5rem' }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Box>
                    <Typography sx={{ fontSize: '.875rem' }}>Want others to edit your script?</Typography>
                    <Typography sx={{ fontSize: '.75rem', color: '#7b7c8d' }}>Add a collaborator instead.</Typography>
                  </Box>
                  <Typography
                    className="light-bg"
                    sx={{
                      fontSize: '.875rem',
                      color: '#fe6d29',
                      padding: '2px',
                      borerRadius: '.25rem',
                      backgroundColor: '#fe6d291a',
                    }}
                  >
                    Add collaborator
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: '.875rem',
                    color: '#fe6d29',
                    padding: '2px',
                    borerRadius: '.25rem',
                    textAlign: 'end',
                  }}
                >
                  Don't show these hints again
                </Typography>
                <Box mt={'15px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography sx={{ fontSize: '.875rem' }}>Watermark</Typography>
                  <input className="sm-top-input light-bg dark-text" />
                </Box>
                <Box mt={'15px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography sx={{ fontSize: '.875rem' }}>Export area</Typography>
                  <input className="sm-top-input light-bg dark-text" />
                </Box>
                <Box mt={'15px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography sx={{ fontSize: '.875rem' }}>Advanced settings</Typography>
                  <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                </Box>

                <Box
                  className="light-bg"
                  mt={'15px'}
                  sx={{ backgroundColor: '#ffe8cc', padding: '1rem', borderRadius: '.25rem', fontSize: '.875rem' }}
                >
                  <Typography className="dark-text" sx={{ fontSize: '.875rem' }}>
                    Your export will open in a pop-up window.
                  </Typography>
                  <Typography className="dark-text" sx={{ fontSize: '.875rem' }}>
                    Make sure these are not blocked.
                  </Typography>
                </Box>

                <Box mt={'15px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography
                    sx={{
                      backgroundColor: '#fe6d29',
                      fontSIze: '.875rem',
                      padding: '0.5rem 2rem',
                      color: '#fff',
                      borderRadius: '.25rem',
                    }}
                  >
                    Export
                  </Typography>
                  <Typography
                    onClick={closeDrawer}
                    sx={{
                      backgroundColor: '#00017806',
                      fontSIze: '.875rem',
                      padding: '0.5rem 2rem',
                      borderRadius: '.25rem',
                    }}
                  >
                    Cancel
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          {tab === 'fountain' && (
            <>
              <Box>
                <Box
                  mt={'15px'}
                  sx={{ padding: '1rem', backgroundColor: '#00017806', borderRadius: '.5rem' }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Box>
                    <Typography sx={{ fontSize: '.875rem' }}>Want others to read your script?</Typography>
                    <Typography sx={{ fontSize: '.75rem', color: '#7b7c8d' }}>Request a review instead.</Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: '.875rem',
                      color: '#fe6d29',
                      backgroundColor: '#fe6d291a',
                      padding: '2px',
                      borerRadius: '.25rem',
                    }}
                  >
                    Request review
                  </Typography>
                </Box>

                <Box
                  mt={'15px'}
                  sx={{ padding: '1rem', backgroundColor: '#00017806', borderRadius: '.5rem' }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Box>
                    <Typography sx={{ fontSize: '.875rem' }}>Want others to edit your script?</Typography>
                    <Typography sx={{ fontSize: '.75rem', color: '#7b7c8d' }}>Add a collaborator instead.</Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: '.875rem',
                      color: '#fe6d29',
                      padding: '2px',
                      borerRadius: '.25rem',
                      backgroundColor: '#fe6d291a',
                    }}
                  >
                    Add collaborator
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: '.875rem',
                    color: '#fe6d29',
                    padding: '2px',
                    borerRadius: '.25rem',
                    textAlign: 'end',
                  }}
                >
                  Don't show these hints again
                </Typography>

                <Box
                  className="light-bg"
                  mt={'15px'}
                  sx={{ backgroundColor: '#ffe8cc', padding: '1rem', borderRadius: '.25rem', fontSize: '.875rem' }}
                >
                  <Typography className="dark-text" sx={{ fontSize: '.875rem' }}>
                    Your export will open in a pop-up window.
                  </Typography>
                  <Typography className="dark-text" sx={{ fontSize: '.875rem' }}>
                    Make sure these are not blocked.
                  </Typography>
                </Box>

                <Box mt={'15px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography
                    sx={{
                      backgroundColor: '#fe6d29',
                      fontSIze: '.875rem',
                      padding: '0.5rem 2rem',
                      color: '#fff',
                      borderRadius: '.25rem',
                    }}
                  >
                    Export
                  </Typography>
                  <Typography
                    onClick={closeDrawer}
                    sx={{
                      backgroundColor: '#00017806',
                      fontSIze: '.875rem',
                      padding: '0.5rem 2rem',
                      borderRadius: '.25rem',
                    }}
                  >
                    Cancel
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          {tab === 'final' && (
            <>
              <Box>
                <Box
                  mt={'15px'}
                  sx={{ padding: '1rem', backgroundColor: '#00017806', borderRadius: '.5rem' }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Box>
                    <Typography sx={{ fontSize: '.875rem' }}>Want others to read your script?</Typography>
                    <Typography sx={{ fontSize: '.75rem', color: '#7b7c8d' }}>Request a review instead.</Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: '.875rem',
                      color: '#fe6d29',
                      backgroundColor: '#fe6d291a',
                      padding: '2px',
                      borerRadius: '.25rem',
                    }}
                  >
                    Request review
                  </Typography>
                </Box>

                <Box
                  mt={'15px'}
                  sx={{ padding: '1rem', backgroundColor: '#00017806', borderRadius: '.5rem' }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Box>
                    <Typography sx={{ fontSize: '.875rem' }}>Want others to edit your script?</Typography>
                    <Typography sx={{ fontSize: '.75rem', color: '#7b7c8d' }}>Add a collaborator instead.</Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: '.875rem',
                      color: '#fe6d29',
                      padding: '2px',
                      borerRadius: '.25rem',
                      backgroundColor: '#fe6d291a',
                    }}
                  >
                    Add collaborator
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: '.875rem',
                    color: '#fe6d29',
                    padding: '2px',
                    borerRadius: '.25rem',
                    textAlign: 'end',
                  }}
                >
                  Don't show these hints again
                </Typography>

                <Box
                  className="light-bg"
                  mt={'10px'}
                  sx={{ backgroundColor: '#ffe8cc', padding: '1rem', borderRadius: '.25rem', fontSize: '.875rem' }}
                >
                  <Typography className="dark-text" sx={{ fontSize: '.875rem' }}>
                    Your export will open in a pop-up window.
                  </Typography>
                  <Typography className="dark-text" sx={{ fontSize: '.875rem' }}>
                    Make sure these are not blocked.
                  </Typography>
                </Box>

                <Box mt={'15px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography
                    sx={{
                      backgroundColor: '#fe6d29',
                      fontSIze: '.875rem',
                      padding: '0.5rem 2rem',
                      color: '#fff',
                      borderRadius: '.25rem',
                    }}
                  >
                    Export
                  </Typography>
                  <Typography
                    onClick={closeDrawer}
                    sx={{
                      backgroundColor: '#00017806',
                      fontSIze: '.875rem',
                      padding: '0.5rem 2rem',
                      borderRadius: '.25rem',
                    }}
                  >
                    Cancel
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          {tab === 'outline' && (
            <>
              <Typography mt={'10px'} sx={{ fontSize: '1rem', fontWeight: '600' }}>
                Export an outline
              </Typography>

              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Box display={'flex'} sx={{ border: '1px solid #fe6d291a', borderRadius: '.25rem' }}>
                  <Typography
                    onClick={() => setType('pdf')}
                    sx={{
                      backgroundColor: '#fe6d291a',
                      fontSize: '.75rem',
                      color: type === 'pdf' ? '#fe6d29' : '',

                      padding: '.25rem 0.5rem',
                      borderRight: '1px solid #fe6d291a',
                    }}
                  >
                    PDF
                  </Typography>
                  <Typography
                  className="dark-text light-bg"

                    onClick={() => setType('fountain')}
                    sx={{
                      backgroundColor: '#fff',
                      fontSize: '.75rem',
                      padding: '.25rem 0.5rem',
                      color: type === 'fountain' ? '#fe6d29' : '#b2b3c4',
                      '&:hover': { border: '1px solid #fe6d29', color: '#fe6d29' },
                      border: '1px solid transparent',
                    }}
                  >
                    Fountain/Test
                  </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                  <Typography sx={{ fontSize: '.875rem' }}>Export inbox beats</Typography>
                  <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                </Box>
              </Box>

              <Box mt={'10px'} gap={'10px'} display={'flex'} alignItems={'center'}>
                <Typography
                  sx={{
                    backgroundColor: '#00017806',
                    fontSize: '.75rem',
                    padding: '.375rem',
                    borderRight: '1px solid #fe6d291a',
                    borderRadius: '.25rem',
                  }}
                >
                  Treatment preset
                </Typography>
                <Typography
                  sx={{
                    backgroundColor: '#00017806',
                    fontSize: '.75rem',
                    padding: '.375rem',
                    borderRight: '1px solid #fe6d291a',
                    borderRadius: '.25rem',
                  }}
                >
                  Outline preset
                </Typography>
                <Typography
                  sx={{
                    backgroundColor: '#00017806',
                    fontSize: '.75rem',
                    padding: '.375rem',
                    borderRight: '1px solid #fe6d291a',
                    borderRadius: '.25rem',
                  }}
                >
                  Basic outline preset
                </Typography>
              </Box>

              <Box mt={'10px'} sx={{ maxHeight: '17rem', overflowY: 'auto' }}>
                {type === 'pdf' ? (
                  <>
                    <Box
                      className="light-bg"
                      sx={{
                        border: '1px solid #fe6d291a',
                        borderRadius: '.25rem .25rem 0 0',
                        padding: '5px',
                      }}
                    >
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box display={'flex'} alignItems={'center'}>
                          <Checkbox size="small" sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />
                          <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Beats</Typography>
                        </Box>
                        <Box
                          onClick={() => setBeats(!beats)}
                          sx={{ width: '30px', height: '30px', borderRadius: '.25rem', backgroundColor: '#fe6d291a' }}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          {beats ? (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-down" />
                          ) : (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-up" />
                          )}
                        </Box>
                      </Box>
                      {beats && (
                        <Box pl={4}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>

                    <Box
                      className="light-bg"
                      sx={{
                        border: '1px solid #fe6d291a',
                        maxHeight: '16rem',
                        overflowY: 'auto',
                        padding: '5px',
                      }}
                    >
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box display={'flex'} alignItems={'center'}>
                          <Checkbox size="small" sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />
                          <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Scenes</Typography>
                        </Box>
                        <Box
                          onClick={() => setBeats(!beats)}
                          sx={{ width: '30px', height: '30px', borderRadius: '.25rem', backgroundColor: '#fe6d291a' }}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          {beats ? (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-down" />
                          ) : (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-up" />
                          )}
                        </Box>
                      </Box>
                      {beats && (
                        <Box pl={4}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Box
                      className="light-bg"
                      sx={{
                        border: '1px solid #fe6d291a',
                        maxHeight: '16rem',
                        overflowY: 'auto',
                        padding: '5px',
                      }}
                    >
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box display={'flex'} alignItems={'center'}>
                          <Checkbox size="small" sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />
                          <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Plots</Typography>
                        </Box>
                        <Box
                          onClick={() => setBeats(!beats)}
                          sx={{ width: '30px', height: '30px', borderRadius: '.25rem', backgroundColor: '#fe6d291a' }}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          {beats ? (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-down" />
                          ) : (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-up" />
                          )}
                        </Box>
                      </Box>
                      {beats && (
                        <Box pl={4}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Box
                      className="light-bg"
                      sx={{
                        border: '1px solid #fe6d291a',
                        maxHeight: '16rem',
                        overflowY: 'auto',
                        padding: '5px',
                      }}
                    >
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box display={'flex'} alignItems={'center'}>
                          <Checkbox size="small" sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />
                          <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Characters</Typography>
                        </Box>
                        <Box
                          onClick={() => setBeats(!beats)}
                          sx={{ width: '30px', height: '30px', borderRadius: '.25rem', backgroundColor: '#fe6d291a' }}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          {beats ? (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-down" />
                          ) : (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-up" />
                          )}
                        </Box>
                      </Box>
                      {beats && (
                        <Box pl={4}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Box
                      className="light-bg"
                      sx={{
                        border: '1px solid #fe6d291a',
                        maxHeight: '16rem',
                        overflowY: 'auto',
                        padding: '5px',
                      }}
                    >
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box display={'flex'} alignItems={'center'}>
                          <Checkbox size="small" sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />
                          <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Locations</Typography>
                        </Box>
                        <Box
                          onClick={() => setBeats(!beats)}
                          sx={{ width: '30px', height: '30px', borderRadius: '.25rem', backgroundColor: '#fe6d291a' }}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          {beats ? (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-down" />
                          ) : (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-up" />
                          )}
                        </Box>
                      </Box>
                      {beats && (
                        <Box pl={4}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      className="light-bg"
                      sx={{
                        border: '1px solid #fe6d291a',
                        borderRadius: '.25rem .25rem 0 0',
                        padding: '5px',
                      }}
                    >
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box display={'flex'} alignItems={'center'}>
                          <Checkbox size="small" sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />
                          <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Beats</Typography>
                        </Box>
                        <Box
                          onClick={() => setBeats(!beats)}
                          sx={{ width: '30px', height: '30px', borderRadius: '.25rem', backgroundColor: '#fe6d291a' }}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          {beats ? (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-down" />
                          ) : (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-up" />
                          )}
                        </Box>
                      </Box>
                      {beats && (
                        <Box pl={4}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>

                    <Box
                      className="light-bg"
                      sx={{
                        border: '1px solid #fe6d291a',
                        maxHeight: '16rem',
                        overflowY: 'auto',
                        padding: '5px',
                      }}
                    >
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box display={'flex'} alignItems={'center'}>
                          <Checkbox size="small" sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />
                          <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Plots</Typography>
                        </Box>
                        <Box
                          onClick={() => setBeats(!beats)}
                          sx={{ width: '30px', height: '30px', borderRadius: '.25rem', backgroundColor: '#fe6d291a' }}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          {beats ? (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-down" />
                          ) : (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-up" />
                          )}
                        </Box>
                      </Box>
                      {beats && (
                        <Box pl={4}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Box
                      className="light-bg"
                      sx={{
                        border: '1px solid #fe6d291a',
                        maxHeight: '16rem',
                        overflowY: 'auto',
                        padding: '5px',
                      }}
                    >
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box display={'flex'} alignItems={'center'}>
                          <Checkbox size="small" sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />
                          <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Characters</Typography>
                        </Box>
                        <Box
                          onClick={() => setBeats(!beats)}
                          sx={{ width: '30px', height: '30px', borderRadius: '.25rem', backgroundColor: '#fe6d291a' }}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          {beats ? (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-down" />
                          ) : (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-up" />
                          )}
                        </Box>
                      </Box>
                      {beats && (
                        <Box pl={4}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Box
                      className="light-bg"
                      sx={{
                        border: '1px solid #fe6d291a',
                        maxHeight: '16rem',
                        overflowY: 'auto',
                        padding: '5px',
                      }}
                    >
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box display={'flex'} alignItems={'center'}>
                          <Checkbox size="small" sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />
                          <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Locations</Typography>
                        </Box>
                        <Box
                          onClick={() => setBeats(!beats)}
                          sx={{ width: '30px', height: '30px', borderRadius: '.25rem', backgroundColor: '#fe6d291a' }}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          {beats ? (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-down" />
                          ) : (
                            <Iconify sx={{ color: '#000' }} icon="fe:arrow-up" />
                          )}
                        </Box>
                      </Box>
                      {beats && (
                        <Box pl={4}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />

                            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Show title</Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </>
                )}
              </Box>

              <Box
                className="light-bg"
                mt={'15px'}
                sx={{ backgroundColor: '#ffe8cc', padding: '1rem', borderRadius: '.25rem', fontSize: '.875rem' }}
              >
                <Typography className="dark-text" sx={{ fontSize: '.875rem' }}>
                  Your export will open in a pop-up window.
                </Typography>
                <Typography className="dark-text" sx={{ fontSize: '.875rem' }}>
                  Make sure these are not blocked.
                </Typography>
              </Box>

              <Box mt={'15px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography
                  sx={{
                    backgroundColor: '#fe6d29',
                    fontSIze: '.875rem',
                    padding: '0.5rem 2rem',
                    color: '#fff',
                    borderRadius: '.25rem',
                  }}
                >
                  Export
                </Typography>
                <Typography
                  onClick={closeDrawer}
                  sx={{
                    backgroundColor: '#00017806',
                    fontSIze: '.875rem',
                    padding: '0.5rem 2rem',
                    borderRadius: '.25rem',
                  }}
                >
                  Cancel
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default SmTopDrawer;
