import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Divider } from '@mui/material';
import Modal from '@mui/material/Modal';
import SettingsIcon from '@mui/icons-material/Settings';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Iconify from 'src/components/iconify';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VerticalTabs from './SettingsVerticalTabs';
// import { CloseIcon } from '../../../theme/overrides/CustomIcons';

// import './Navbar.css'
// import './ScriptBoard.css'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 800,
  minHeight: 600,
  maxHeight: 600,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  paddingLeft: 2,
  paddingTop: 2,
  paddingBottom: 2,
  borderRadius: '5px',
  // p: 4,
  overflowY: 'auto',
  scrollbarWidth: 'none', // Hide the scrollbar for Firefox
  // WebkitOverflowScrolling: 'touch',
};

const SidebarSettingsModal = ({ activeTab }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Box
        onClick={handleOpen}
        mt={'20px'}
        display={'flex'}
        alignItems={'center'}
        gap={'10px'}
        sx={{
          padding: '0.5rem 1rem',
          borderRadius: '.25rem',
          backgroundColor: activeTab === 'settings' ? '#fe6d291a' : '',
          color: activeTab === 'settings' ? '#fe6d29' : '',
          cursor: 'pointer',
        }}
      >
        <Iconify sx={{ fontSize: '20px' }} icon="tabler:settings" />
        <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Settings</Typography>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Settings
          </Typography>
          <Box sx={{ color: '333', padding: '.5rem', borderRadius: '.25rem', backgroundColor: 'transparent' }}>
            <Iconify
            className="dark-text"
              sx={{
                position: 'absolute',
                top: '8px',
                right: '8px',
                cursor: 'pointer',
                color: '#000',
                backgroundColor: 'transparent',
              }}
              icon="eva:close-fill"
              width={20}
              height={20}
              onClick={handleClose}
            />
          </Box>
          <VerticalTabs />
          {/* </Typography> */}
        </Box>
      </Modal>
    </>
  );
};

export default SidebarSettingsModal;
