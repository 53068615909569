import React, { useEffect } from 'react';
import { Slider } from '@mui/material';

const ZoomSliderComponent = ({ setScriptZoom }) => {
  const [zoom, setZoom] = React.useState(100);

  const handleSliderChange = (event, newValue) => {
    setZoom(newValue);
    setScriptZoom(newValue);
    // Call the refreshData function with the new zoom value
    // refreshData(newValue);
  };

  useEffect(() => {
    setScriptZoom(zoom);
  }, [zoom]);

  return (
    <>
      <Slider
        sx={{
          fontSize: '.875rem',
          height: '4px',
          '& .MuiSlider-thumb': {
            backgroundColor: 'white',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
          },
          '& .MuiSlider-root': {
            backgroundColor: '#ccc !important',
          },
          color: '#ccc !important',
        }}
        value={zoom}
        min={60}
        max={160}
        step={10}
        valueLabelDisplay="auto"
        onChange={handleSliderChange}
      />
    </>
  );
};

export default ZoomSliderComponent;
