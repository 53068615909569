import { Box, Divider, Popover, Slider, Switch, Tooltip, Typography } from '@mui/material';
import styled from '@emotion/styled';
import React from 'react';
import Iconify from 'src/components/iconify';
import ZoomSliderComponent from 'src/components/shared-components/ZoomSliderComponent';
import ZoomDropdownValue from '../../zoomdropdownpopover/ZoomDropdownValue';
import ButtonGroup from '../../threebuttongroup/ButtonGroup';
import SettingsModal from '../../editorsettings/SettingsModal';
import CourierprimeDropdown from '../../courierprimedropdown/CourierprimeDropdown';
import HighlightCharactersMod from './HighlightCharactersMod';

// const AntSwitch = styled(Switch)(({ theme }) => ({
//   width: 20,
//   height: 12,
//   padding: 0,
//   display: 'flex',
//   '&:active': {
//     '& .MuiSwitch-thumb': {
//       width: 10,
//     },
//     '& .MuiSwitch-switchBase.Mui-checked': {
//       transform: 'translateX(5px)',
//     },
//   },
//   '& .MuiSwitch-switchBase': {
//     padding: 2,
//     '&.Mui-checked': {
//       transform: 'translateX(8px)',
//       color: '#fff',
//       '& + .MuiSwitch-track': {
//         opacity: 1,
//         backgroundColor: '#fe6d29',
//       },
//     },
//   },
//   '& .MuiSwitch-thumb': {
//     backgroundColor: '#FFF',
//     boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
//     width: 8,
//     height: 8,
//     borderRadius: 6,
//     transition: theme.transitions.create(['width'], {
//       duration: 200,
//     }),
//   },
//   '& .MuiSwitch-track': {
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
//     boxSizing: 'border-box',
//   },
// }));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 35,
  height: 20,
  padding: 0,
  display: 'flex',
  borderRadius: '10px',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 10, // You can adjust this value
      height: 10, // You can adjust this value
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#fe6d29',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    marginLeft: '3px',

    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 15, // You can adjust this value
    height: 15, // You can adjust this value
    borderRadius: 10, // Adjust this value to maintain the button shape
    transition: theme.transitions.create(['width', 'height'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const SettingsPopover = ({ script, refreshData }) => {
  const [anchorElSettings, setAnchorElSettings] = React.useState(null);
  const [scriptZoom, setScriptZoom] = React.useState(100);
  const handleClickSettings = (event) => {
    setAnchorElSettings(event.currentTarget);
  };
  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };
  const openSettings = Boolean(anchorElSettings);
  const idSettings = openSettings ? 'simple-popover' : undefined;

  return (
    <>
      <Tooltip title="Settings" arrow>
        <Box aria-describedby={idSettings} onClick={handleClickSettings} className="scren-settings">
          <Iconify
            className="dark-text"
            sx={{ fontSize: '20px', color: '#333', fontWeight: 'bold' }}
            icon="mi:settings"
          />
        </Box>
      </Tooltip>
      <Popover
        id={idSettings}
        open={openSettings}
        anchorEl={anchorElSettings}
        onClose={handleCloseSettings}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            width: '270px',
            maxWidth: 360,
            backgroundColor: '',
            // color: "white",
            padding: '18px',
            fontSize: '.875rem',
          }}
        >
          <Box mb={'10px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <SettingsModal handleCloseSettings={handleCloseSettings} script={script} refreshData={refreshData} />
            
          </Box>
          <Divider />

          <Typography
            className="dark-text"
            mt={'10px'}
            variant="body1"
            sx={{ color: '#7b7c8d', fontSize: '.875rem', fontFamily: 'inter' }}
            onClick={handleCloseSettings}
          >
            Writing Preferences
          </Typography>
          <Typography
            className="dark-text"
            mb={'5px'}
            mt={'10px'}
            variant="body1"
            sx={{ fontSize: '.875rem', color: '#333', fontWeight: '600' }}
            onClick={handleCloseSettings}
          >
            Theme
          </Typography>
          <ButtonGroup />
          <Divider />

          <Box
            className="dark-text"
            mt={'15px'}
            mb={'5px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ fontSize: '.875rem', color: '#333', fontWeight: '600' }}
          >
            <p>Zoom level</p>
            
            <ZoomDropdownValue scriptZoom={scriptZoom} />
          </Box>

          <Box my={'10px'}>
            <ZoomSliderComponent setScriptZoom={setScriptZoom} />
          </Box>

          <Box
            className="dark-text"
            mt={'15px'}
            mb={'5px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ fontSize: '.875rem', color: '#333', fontWeight: '600' }}
          >
            <p className="fontfam">Script font</p>
            {/* <Box
                  className="scren-zoomlvl"
                  aria-describedby={id}
                  onClick={handleClickAllSettings}
                  display={'flex'}
                  alignItems={'center'}
                  sx={{
                    backgroundColor: '#00017806',
                    fontSize: '.75rem',
                    padding: ' .25rem .5rem',
                    borderRadius: '.25rem',
                  }}
                >
                  <p>{selectedOption}</p>
                  <ArrowDropDownIcon sx={{ marginLeft: '30px' }} />
                </Box> */}
            <CourierprimeDropdown
              name={'courier'}
              option1={'Courier Prime Sans'}
              option2={'Courier Prime Bold'}
              option3={'Courier Prime'}
              option4={'Courier Prime Italic'}
              option5={'Courier System'}
            />
          </Box>
          <Box
            className="dark-text"
            mt={'15px'}
            mb={'5px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ fontSize: '.875rem', color: '#333', fontWeight: '600' }}
          >
            <p>Word Count</p>
            <AntSwitch sx={{ fontSize: '50px' }} defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
          </Box>
          <Box
            className="dark-text"
            mt={'15px'}
            mb={'5px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ fontSize: '.875rem', color: '#333', fontWeight: '600' }}
          >
            <p>Popup formatting palette</p>
            <AntSwitch sx={{ fontSize: '50px' }} defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
          </Box>
          <Box
            className="dark-text"
            mt={'15px'}
            mb={'5px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ fontSize: '.875rem', color: '#333', fontWeight: '600' }}
          >
            <p>Element menu</p>
            <AntSwitch sx={{ fontSize: '50px' }} defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
          </Box>
          <Box
            className="dark-text"
            mt={'15px'}
            mb={'5px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ fontSize: '.875rem', color: '#333', fontWeight: '600' }}
          >
            <p>Headers and footers</p>
            <AntSwitch sx={{ fontSize: '50px' }} defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
          </Box>
          <Box
            className="dark-text"
            mt={'15px'}
            mb={'5px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ fontSize: '.875rem', color: '#333', fontWeight: '600' }}
          >
            <p>Auto capitalize</p>
            <AntSwitch sx={{ fontSize: '50px' }} defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
          </Box>
          <Divider />
          <HighlightCharactersMod />
        </Box>
      </Popover>
    </>
  );
};

export default SettingsPopover;
