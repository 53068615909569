import React, { useEffect, useState } from 'react';
import './OffButtonGroup.css';

const OffButtonGroup = ({ first, second, active, onChange, onBlur }) => {
  const [activeButton, setActiveButton] = useState(first);

  useEffect(() => {
    setActiveButton(active);
  }, [active]);

  const handleButtonClick = (buttonText) => {
    setActiveButton(buttonText);
  };

  return (
    <div className="button-group">
      {/*  eslint-disable-next-line react/button-has-type */}
      <button
        className={`button ${activeButton === first ? 'active' : ''} dark-bg dark-text`}
        onClick={() => {
          handleButtonClick(first);
          if (onChange) {
            onChange(false);
            if (onBlur) onBlur(false);
          }
        }}
      >
        {first}
      </button>
      {/*  eslint-disable-next-line react/button-has-type */}
      <button
        className={`button ${activeButton === second ? 'active' : ''} dark-bg dark-text`}
        onClick={() => {
          handleButtonClick(second);
          if (onChange) {
            onChange(true);
            if (onBlur) onBlur(true);
          }
        }}
      >
        {second}
      </button>
      <div className="of-indicator">{activeButton || first}</div>
    </div>
  );
};

export default OffButtonGroup;
