import { Box, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useFetch } from 'src/hooks/useFetch';
import { getCurrentUser } from 'src/utils/helpers';
import Iconify from 'src/components/iconify';
import NewScriptPoper from '../dashboard/NewScriptPoper';
import { useNavigate } from 'react-router';

const ProjectsDetails = () => {
  const user = getCurrentUser();

  const drawerRef = useRef(null);
  const [defaultScript, setDefaultScript] = React.useState(null);
  
  const router = useNavigate();

  const [test, setTest] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    totalPages: 0,
    currentPage: 1,
    totalRecords: 0,
  });
  const projectId = window.location.pathname.split('/')[2];
  const [getProjects, loading] = useFetch(
    `api/projects?projectId=${projectId}`,
    ``,
    '',
    'GET',
    false
  );

  React.useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    const response = await getProjects();
    console.log("projectResponse", response);
  };


  return (
    <>
 
      <Box sx={{}} mt={'50px'} p={'1rem 2rem'}>
          <>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Box
                  onClick={() => {router('/projects')}}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  sx={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '.25rem',
                    '&:hover': { backgroundColor: '#fe6d291a' },
                  }}
                >
                  <Iconify sx={{ fontSize: '20px' }} icon="ep:arrow-left" />
                </Box>
                <Typography sx={{ fontSize: '1.25rem', fontWeight: '600', fontFamily: 'inter' }}>Test</Typography>
              </Box>

              <Box
                sx={(theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                  },
                })}
                display={'flex'}
                alignItems={'center'}
                gap={'10px'}
              >
                <Typography
                  className="light-bg-hover"
                  sx={(theme) => ({
                    '&:hover': { backgroundColor: '#fe6d291a' },
                    whiteSpace: 'nowrap',
                    fontSize: '.875rem',
                    backgroundColor: '#00017806',
                    fontFamily: 'inter',
                    border: '1px solid #00017806',
                    padding: '0.5rem 2rem',
                    borderRadius: '.25rem',
                    color: '',
                    cursor: 'pointer',
                    [theme.breakpoints.down('sm')]: {
                      padding: '.5rem',
                    },
                  })}
                >
                  Manage Members
                </Typography>
                <NewScriptPoper 
                  ref={drawerRef}
                  defaultScript={defaultScript}
                  setDefaultScript={setDefaultScript}
                />
              </Box>
            </Box>

            <Box
              className="light-bg"
              mt={'30px'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              p={'1rem  1rem'}
              sx={{ border: '1px solid #fe6d291a', borderRadius: '.25rem' }}
            >
              <Box display={'flex'} alignItems={'center'} gap={'15px'}>
                <Iconify sx={{ cursor: 'pointer', fontSize: '20px', color: '#fe6d29' }} icon="jam:book" />
                <Box>
                  <Typography sx={{ cursor: 'pointer', fontSize: '.975rem', fontWeight: '600', fontFamily: 'inter' }}>
                    Season outline & bible
                  </Typography>
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      fontSize: '.75rem',
                      fontWeight: '600',
                      fontFamily: 'inter',
                      color: '#b2b3c4',
                    }}
                  >
                    Last updated at 4 days ago
                  </Typography>
                </Box>
              </Box>

              <Box display={'flex'} alignItems={'center'} gap={'15px'}>
                <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                  <Iconify sx={{ cursor: 'pointer', fontSize: '20px' }} icon="akar-icons:paper" />
                  <Typography sx={{ fontSize: '.975rem' }}>0</Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                  <Iconify sx={{ cursor: 'pointer', fontSize: '20px' }} icon="jam:message-alt" />
                  <Typography sx={{ fontSize: '.975rem' }}>0</Typography>
                </Box>
              </Box>
            </Box>
          </>
      </Box>
    </>
  );
};

export default ProjectsDetails;
