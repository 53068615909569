import React from 'react';

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

import Modal from '@mui/material/Modal';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VerticalTabs from './VerticalTabs';

import Iconify from '../../../iconify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 800,
  minHeight: 600,
  maxHeight: 600,
  bgcolor: 'background.paper',

  boxShadow: 24,
  paddingLeft: 2,
  paddingTop: 2,
  paddingBottom: 2,
  borderRadius: '5px',

  overflowY: 'auto',
  scrollbarWidth: 'none',
};

const SettingsModal = ({ script, refreshData }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Box sx={{ cursor: 'pointer' }} onClick={handleOpen} display={'flex'} alignItems={'center'} gap={''}>
        <SettingsOutlinedIcon fontSize="5px" sx={{ marginTop: '5px' }} />
        <Typography
          className="dark-text"
          mt={'5px'}
          ml={'.5rem'}
          variant="body1"
          sx={{ color: '#333', fontSize: '.75rem' }}
        >
          All Settings
        </Typography>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Settings
          </Typography>
          <Box sx={{ color: '333', padding: '.5rem', borderRadius: '.25rem', backgroundColor: 'transparent' }}>
            <Iconify
              className="dark-text"
              sx={{
                position: 'absolute',
                top: '8px',
                right: '8px',
                cursor: 'pointer',
                color: '#000',
                backgroundColor: 'transparent',
              }}
              icon="eva:close-fill"
              width={20}
              height={20}
              onClick={handleClose}
            />
          </Box>
          <VerticalTabs script={script} refreshData={refreshData} />
          {/* </Typography> */}
        </Box>
      </Modal>
    </>
  );
};

export default SettingsModal;
