import { Document, Packer, Paragraph, Tab, TextRun } from 'docx';

const toText = (doc, text) => new TextRun({
    text: text.text || text,
    ...text,
});

const toParagraph = (doc, para) => new Paragraph({
    children: para.children.map((paragraph) => toText(doc, paragraph)),
});

const toPage = (doc, page) => {
    doc.addSection({
        properties: {},
        children: page.children.map((paragraph) => toParagraph(doc, paragraph)),
    });
};

// Used to export the file into a .docx file

const convertToDoc = async (editor) => {
    const { children } = editor;

    const doc = new Document({
        sections: [
            {
                properties: {},
                children: [
            
                ],
            },
        ],
    });
    children.slice(1).forEach((child) => {
        toPage(doc, child);
    });

    // const docBuffer = await Packer.toBuffer(doc);
    const docBuffer = await Packer.toBlob(doc);
    //   saveAs(blob, "example.docx");
    return docBuffer;
};

export default convertToDoc;
