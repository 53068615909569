import React, { createContext, useState } from 'react';

const FooterContext = createContext();

const FooterProvider = ({ children }) => {
  const [footerText, setFooterText] = useState('Footer Editable');

  return (
    <FooterContext.Provider value={{ footerText, setFooterText }}>
      {children}
    </FooterContext.Provider>
  );
};

export { FooterContext, FooterProvider };
