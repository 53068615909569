import * as React from 'react';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { Checkbox, } from '@mui/material';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 550,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 2,
};

export default function HighlightCharactersMod() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* <Box
      
        sx={{ backgroundColor: '#00017806', borderRadius: '5px' }}
        py={'5px'}
        fontSize={'.75rem'}
        textAlign={'center'}
      >
        Edit Colors...
      </Box> */}
      {/* <Box
        onClick={handleOpen}
            className="light-bg-hover"
            mt={'5px'}
            sx={{
                cursor: 'pointer',
              border: '1px solid #00017806',
              backgroundColor: '#00017806',
              fontSize: '.75rem',
              borderRadius: '.25rem',
              textAlign: 'center',
              padding: ' .375re',
            }}
          >
            <p className="scren-highlight light-bg">Highlight Characters</p>
          </Box> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ fontSize: '1.25rem', fontWeight: '600' }}>Highlight characters</Typography>
          <Box
          p={4}
            mt={'20px'}
            sx={{
              width: '100%',
              maxHeight: '50vh',
              padding: '.5rem',
              overflowY: 'auto',
              borderRadius: '.25rem',
              border: '1px solid #fe6d291a',
            }}
          >
            <Box mt={""}  display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
            <Checkbox sx={{"&.MuiButtonBase-root":{color:"#fe6d29"}}}  />

              <Box sx={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: 'green' }} />
            </Box>
            <Box mt={""}  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Checkbox sx={{"&.MuiButtonBase-root":{color:"#fe6d29"}}} />

              <Box sx={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: 'blue' }} />
            </Box>
            <Box mt={""}  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Checkbox sx={{"&.MuiButtonBase-root":{color:"#fe6d29"}}} />
              <Box sx={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: 'cyan' }} />
            </Box>
            <Box  mt={""} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Checkbox sx={{"&.MuiButtonBase-root":{color:"#fe6d29"}}} />
              <Box sx={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: 'yellow' }} />
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
