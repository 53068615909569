import { Box, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import React, { useState } from 'react';
import Iconify from 'src/components/iconify';

const CardRemovePopover = ({ onRemove }) => {
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = anchorEl;
  const id = openpop ? 'simple-popover' : undefined;

  //   aria-describedby={id}
  //   onClick={handleClick}

  return (
    <>
      <Iconify aria-describedby={id} onClick={handleClick} sx={{ color: '#868e96' }} icon="ph:dots-three-bold" />

      <Popover
        id={id}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ borderRadius: '0px', '& .MuiPaper-root': { borderRadius: '5px' } }}
      >
        <Box sx={{ padding: '8px 10px' }} width={'200px'}>
          {/* <Typography variant="body1" sx={{ fontSize: '.875rem' }}> */}
          <Box display={'flex'} gap={'5px'} alignItems={'center'}>
            <Iconify className="dark-text" sx={{ color: '#000' }} icon="ri:delete-bin-line" />

            <Typography
              className="dark-text"
              sx={{ color: '#333', fontSize: '.875rem', cursor: 'pointer' }}
              onClick={() => {
                if (onRemove) {
                  onRemove();
                }
                handleClose();
              }}
            >
              Remove plot
            </Typography>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default CardRemovePopover;
