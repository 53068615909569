/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Box, Button, Divider, Drawer, MenuItem, MenuList, Popover, Switch, Tooltip, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import './ScriptTopDrawer.css';

const ScriptTopDrawer = () => {
  const [open, setOpen] = useState(false);

  // Function to open the Drawer
  const openDrawer = () => {
    setOpen(true);
  };

  // Function to close the Drawer
  const closeDrawer = () => {
    setOpen(false);
  };

  const handleBackdropClick = () => {
    // Close the drawer when clicking on the backdrop
    closeDrawer();
  };
  return (
    <>
      <Iconify onClick={openDrawer} sx={{ fontSize: '20px' }} icon="majesticons:plus-line" />

      <Drawer
        anchor="top"
        open={open}
        onClose={closeDrawer}
        // disableBackdropTransition // Prevent background from being disabled
        PaperProps={{ style: { width: '100%', maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' } }}
        hideBackdrop
      >
        {open && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,

              zIndex: -99, // Behind the drawer content
            }}
            onClick={handleBackdropClick} // Close the drawer on backdrop click
          />
        )}

        <Box  sx={(theme) => ({
             
                  [theme.breakpoints.down('sm')]: {
                    padding:"20px"
                  },
                })}  p={'40px'}>
          <Typography sx={{ fontSize: '1.25rem', fontFamily: 'inter' }}>New Script</Typography>

          <Box mt={'20px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box display={'flex'} alignItems={'center'} gap={'15px'}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ width: '35px', height: '35px', backgroundColor: '#fe6d29', borderRadius: '50%' }}
              >
                <Typography sx={{ color: '#fff', fontFamily: 'inter' }}>1</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: '1rem', fontFamily: 'inter' }}>Import a script?</Typography>
                <Typography sx={{ fontSize: '.75rem', color: '#b2b3c4' }}>fdx / foundation / pdf / word / celtx / movie magic</Typography>
              </Box>
            </Box>
            <Box className="light-bg" p={'0.5rem 2rem'} sx={{ cursor:"pointer",backgroundColor: '#00017806' }}>
              <Typography sx={{ fontSize: '.875rem', fontFamily: 'inter' }}>Import</Typography>
            </Box>
          </Box>

          <Box mt={'20px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box display={'flex'} alignItems={'center'} gap={'15px'}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ width: '35px', height: '35px', backgroundColor: '#fe6d29', borderRadius: '50%' }}
              >
                <Typography sx={{ color: '#fff', fontFamily: 'inter' }}>2</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: '1rem', fontFamily: 'inter' }}>Additional information</Typography>
                <Typography sx={{ fontSize: '.75rem', color: '#b2b3c4' }}>
                  You can always change this later, feel free to skip any fields
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box ml={'50px'}>
            <Box mt={'10px'}>
              <Typography sx={{ fontSize: '.875rem' }}>Title</Typography>
              <input className="std-input light-bg dark-text" />
            </Box>

            <Box
              mt={'10px'}
              display={'flex'}
              alignItems={'center'}
              gap={'10px'}
              //    justifyContent={'space-between'}
            >
              {/* <Box width={'100%'}>
                <Typography sx={{ fontSize: '.875rem' }}>Subtitle</Typography>
                <input className="std-input light-bg dark-text" />
              </Box> */}
              <Box width={'100%'}>
                <Typography sx={{ fontSize: '.875rem' }}>Written by</Typography>
                <input className="std-input light-bg dark-text" placeholder="Jim Bulian" />
              </Box>
            </Box>

            <Box mt={'10px'} display={'flex'} alignItems={'center'} gap={'10px'}>
              <Box width={'70%'}>
                <Typography sx={{ fontSize: '.875rem' }}>Genre</Typography>
                <input className="std-input dark-text light-bg" />
              </Box>
              <Box width={'30%'}>
                <Typography sx={{ fontSize: '.875rem' }}>Page Target</Typography>
                <input className="std-input dark-text light-bg" placeholder="110" />
              </Box>
            </Box>

            <Box mt={'10px'}>
              <Typography sx={{ fontSize: '.875rem' }}>Logline</Typography>
              <input className="std-input dark-text light-bg" />
            </Box>
          </Box>

          <Box mt={'30px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ width: '35px', height: '35px', backgroundColor: '#fe6d29', borderRadius: '50%' }}
            >
              <Typography sx={{ color: '#fff' }}>3</Typography>
            </Box>

            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
              <Box
                onClick={closeDrawer}
                p={'0.5rem 2rem'}
                sx={{cursor:"pointer", backgroundColor: '#00017806', '&:hover': { backgroundColor: '#fe6d291a' } }}
              >
                <Typography  sx={(theme) => ({
                  fontSize: '.875rem', fontFamily: 'inter', 
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '.75rem'
                  },  
                })} >Cancel</Typography>
              </Box>
              <Box
                p={'0.5rem 2rem'}
                sx={{cursor:"pointer", backgroundColor: '#fe6d29', borderRadius: '.25rem', '&:hover': { backgroundColor: '#d0551c' } }}
              >
                <Typography   sx={(theme) => ({
                  fontSize: '.875rem', fontFamily: 'inter', color: '#fff' ,
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '.75rem'
                  },
                })} >Create script</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default ScriptTopDrawer;
