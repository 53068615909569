import { Box, LinearProgress, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { DESK } from 'src/routes/paths';
import { isEmpty, isStrongPassword } from 'src/utils/helpers';
import SignupHeader from '../header/SignupHeader';

const EmailConfirm = ({ onSubmited }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    name: 'Your name',
    password: '',
  });
  const navigate = useNavigate();
  const [show, setShow] = useState('page1');

  const handleDesk = () => {
    navigate(DESK);
  };

  // eslint-disable-next-line consistent-return
  const handleContinue = () => {
    if (isEmpty(state.name) || isEmpty(state.password)) {
      enqueueSnackbar('Please enter your name and password', {
        variant: 'error',
      });
      return false;
    }

    if (!isStrongPassword(state.password)) {
      enqueueSnackbar(
        'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character',
        {
          variant: 'error',
        }
      );
      return false;
    }

    onSubmited(state);
    setShow('page2');
  };

  return (
    <>
      {show === 'page1' && (
        <>
          <SignupHeader progressValue={50} />
          <Box
            sx={{
              backgroundColor: '',
              '@media screen and (max-width: 768px)': {
                // Styles for screens with a maximum width of 768px
                pl: '20px', // Adjust the padding for smaller screens
                py: '50px', // Adjust the padding for smaller screens
                // You can add more styles here for smaller screens
              },
              '@media screen and (min-width: 60em)': {
                pt: '8rem',
                pl: '16rem',
                pr: '2rem',
              },
              '@media screen and (min-width: 30em) and (max-width: 60em)': {
                pt: '4rem',
                pl: '4rem',
                pr: '4rem',
              },
            }}
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Box sx={{ backgroundColor: '' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: '500', color: '' }}>Email confirmed!</Typography>
              <Typography mb={'1rem'} sx={{ fontSize: '1.5rem', fontWeight: '600', color: '' }}>
                Enter your name and password
              </Typography>
              <Box mt={'10px'} width={'100%'}>
                <input
                  className="welcome-input"
                  placeholder="Your name"
                  type="text"
                  value={state.name}
                  onChange={(e) => setState({ ...state, name: e.target.value })}
                />
              </Box>
              <Box mt={'10px'} width={'100%'}>
                <input
                  className="welcome-input"
                  placeholder="Your password"
                  type='password'
                  value={state.password}
                  onChange={(e) => setState({ ...state, password: e.target.value })}
                />
              </Box>

              <Typography mt={'15px'} sx={{ fontWeight: '300', fontSize: '.875rem', color: '#b2b3c4' }}>
                By clicking “Agree & Continue” you’re agreeing to our
              </Typography>
              <Typography mt={''} sx={{ fontWeight: '300', fontSize: '.875rem', color: '#b2b3c4' }}>
                <Link target='_blank' to="https://screnpla.com/terms" className="alr-con">
                  Terms of Service 
                </Link>
                {" "} and {" "} 
                <Link target='_blank' to="https://screnpla.com/privacy" className="alr-con">
                  Privacy Policy
                </Link>
              </Typography>
              <Typography
                onClick={() => handleContinue()}
                mt={'20px'}
                width={'50%'}
                textAlign={'center'}
                sx={{
                  fontSize: '.875rem',
                  color: '#fff',
                  padding: '.5rem ',
                  border: '1px solid #fe6d29',
                  fontWeight: '400',
                  backgroundColor: '#fe6d29',
                  borderRadius: '.25rem',
                  cursor: 'pointer',
                  fontFamily: 'inter',
                  '&:hover': { backgroundColor: '#d0551c' },
                }}
              >
                Agree and continue
              </Typography>
            </Box>
          </Box>
        </>
      )}

      {show === 'page2' && (
        <>
          <SignupHeader progressValue={100} />
          <Box
            sx={{
              backgroundColor: '',
              '@media screen and (max-width: 768px)': {
                // Styles for screens with a maximum width of 768px
                pl: '20px', // Adjust the padding for smaller screens
                py: '50px', // Adjust the padding for smaller screens
                // You can add more styles here for smaller screens
              },
              '@media screen and (min-width: 60em)': {
                pt: '8rem',
                pl: '16rem',
                pr: '2rem',
              },
              '@media screen and (min-width: 30em) and (max-width: 60em)': {
                pt: '4rem',
                pl: '4rem',
                pr: '4rem',
              },
            }}
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Box>
              <Typography sx={{ fontSize: '1rem', fontWeight: '500', color: '' }}>Got it!</Typography>
              <Typography mb={'1rem'} sx={{ fontSize: '1.5rem', fontWeight: '600', color: '' }}>
                Do you collaborate on your <br /> scripts?
              </Typography>
              <Typography sx={{ fontSize: '1rem', color: '#b2b3c4', fontWeight: '300' }}>
                We will set the app up for minimum friction -{' '}
              </Typography>
              <Typography sx={{ fontSize: '1rem', color: '#b2b3c4', fontWeight: '300' }}>
                choose the first answer that applies
              </Typography>
              <Box onClick={handleDesk}>
                <Typography
                  mt={'10px'}
                  sx={{
                    fontSize: '1rem',
                    paddingRight: '1rem',
                    color: '#868e96',
                    border: '1px solid #fe6d291a',
                    borderRadius: '.25rem',
                    cursor: 'pointer',
                    boxShadow: ' 0 2px 6px rgba(33,37,41,0.12)',
                    '&:hover': { color: '#343a40 !important', backgroundColor: '#00017806' },
                  }}
                  py={'0.5rem'}
                  pl={'1rem'}
                >
                  I co-write with other writers
                </Typography>
                <Typography
                  mt={'10px'}
                  sx={{
                    fontSize: '1rem',
                    paddingRight: '1rem',
                    color: '#868e96',
                    border: '1px solid #fe6d291a',
                    cursor: 'pointer',
                    borderRadius: '.25rem',
                    boxShadow: ' 0 2px 6px rgba(33,37,41,0.12)',
                    '&:hover': { color: '#343a40 !important', backgroundColor: '#00017806' },
                  }}
                  py={'0.5rem'}
                  pl={'1rem'}
                >
                  I get feedback from others
                </Typography>
                <Typography
                  mt={'10px'}
                  sx={{
                    fontSize: '1rem',
                    paddingRight: '1rem',
                    color: '#868e96',
                    cursor: 'pointer',
                    border: '1px solid #fe6d291a',
                    borderRadius: '.25rem',
                    boxShadow: ' 0 2px 6px rgba(33,37,41,0.12)',
                    '&:hover': { color: '#343a40 !important', backgroundColor: '#00017806' },
                  }}
                  py={'0.5rem'}
                  pl={'1rem'}
                >
                  I'm going solo!
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default EmailConfirm;
