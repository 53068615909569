import { Box, Modal, Typography } from '@mui/material';
import React from 'react';

import Iconify from 'src/components/iconify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  minHeight: 500,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 4,
};

const CardImageModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Iconify onClick={handleOpen} sx={{ color: '#868e96' }} icon="solar:gallery-bold" />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ fontSize: '1.25rem' }}>Images for</Typography>

          <Box
            height={'400px'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Typography sx={{ fontSize: '#b2b3c4', color: '#b2b3c4' }}>No images yet</Typography>
            <Typography sx={{ fontSize: '#b2b3c4', color: '#b2b3c4' }}>Drop images here to upload</Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CardImageModal;
