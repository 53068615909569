import * as React from 'react';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Iconify from 'src/components/iconify';


import './BillingReferModal.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 550,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 2,
};



export default function BillingReferModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Box width={'100px'} onClick={handleOpen}>
        <p className="bill-refer">Refer a friend</p>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: '1.25rem', fontWeight: '600' }}>Refer a friend</Typography>
            <Box
              onClick={handleClose}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                width: '30px',
                height: '30px',
                borderRadius: '.25rem',
                '&:hover': { backgroundColor: '#fe6d291a' },
              }}
            >
              <Iconify sx={{ fontSize: '20px' }} icon="ant-design:close-outlined" />
            </Box>
          </Box>

          {/* <Box my={'20px'}>
            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>
              Invite a friend to Screnpla to give them a $25 discount.
            </Typography>
            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>
              You also get $25 Credit if they subscribe.
            </Typography>
          </Box> */}

          <Box>
            <Typography mb={'30px'} sx={{ fontSize: '1rem', fontWeight: '600' }}>
              Copy your personal invite link
            </Typography>
            <Box
              className="light-bg"
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              pb={'.25rem'}
              sx={{ backgroundColor: '#f1f3f5', color: '#fe6d29', padding: '1rem', borderRadius: '.25rem' }}
            >
              <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>
                https://www.screnpla.com/?friend_of=gnome-notebook-updates
              </Typography>
              <Iconify sx={{ fontSize: '20px' }} icon="gridicons:clipboard" />
            </Box>
            <Typography mt={'40px'} pl={'.5rem'} sx={{ fontSize: '.75rem', color: '#7b7c8d' }}>
              'Your name will be shared with people who have access to this link'
            </Typography>
          </Box>

          <Typography mt={'20px'} sx={{ fontSize: '16px', fontFamily: 'inter' }}>
            Or send an invitation by entering their email address
          </Typography>

          <Box mt={'30px'} display={'flex'} alignItems={'center'} gap={'5px'}>
            <input className="refer-inp dark-bg dark-text" placeholder="Enter friends email" />
            <Typography
              className="light-bg"
              p={'0.5rem 2rem'}
              sx={{ color: '#7b7c8d', backgroundColor: '#00017806', fontSize: '.875rem' }}
            >
              invite
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
