/* eslint-disable no-nested-ternary */
import { ReactEditor, useSlate } from 'slate-react';
import { Text } from 'slate';
import { Box, Button, IconButton, TextField } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import ChatIcon from '@mui/icons-material/Chat';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilStateLoadable, useSetRecoilState } from 'recoil';
import Form from 'react-bootstrap/Form';
import { activeCommentThreadIDAtom, commentThreadsState } from '../../../utils/CommentState';

import CommentRow from './CommentRow';
import NodePopover from './NodePopover';
import { getCommentThreadsOnTextNode } from '../../../utils/EditorCommentUtils';
import { getFirstTextNodeAtSelection, sendCommentNotification } from '../../../utils/common';
import './CommentThreadPopover.css';
import VoiceRecorderComponent from 'src/components/desk/Sidebar/dashboard/VoiceRecorder';

export default function CommentThreadPopover({ editorOffsets, selection, updateScriptApi, scriptData, threadID, selectedText }) {
  const editor = useSlate();
  const textNode = getFirstTextNodeAtSelection(editor, selection) || selectedText;
  const inputRef = useRef();
  const setActiveCommentThreadID = useSetRecoilState(activeCommentThreadIDAtom);
  const [threadDataLoadable, setCommentThreadData] = useRecoilStateLoadable(commentThreadsState(threadID));
  const localUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  const [commentText, setCommentText] = useState('');
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);

  const onClick = useCallback(() => {
    setLoading(true);
    if (commentText.length !== 0) {
      setCommentThreadData((threadData) => ({
        ...threadData,
        comments: [...(threadData?.comments || []), {
          text: commentText, author: localUser?.name || 'screnpla-user', creationTime: new Date()
        }],
        id: threadID,
      }));
    }
    updateScriptComments({
      commentData: { text: commentText, author: localUser?.name || 'screnpla-user', creationTime: new Date() },
      id: threadID,
    });
  }, [commentText, setCommentThreadData]);

  useEffect(() => {
    if (scriptData?.comments.length > 0) {
      const threadData = scriptData?.comments.find(comment => comment.contents.id === threadID);
      setCommentThreadData(threadData?.contents);
    }
  }, [threadID]);


  const updateScriptComments = async (threadData) => {
    const scriptComments = scriptData?.comments || [];

    // Check if threadData.id exists in scriptComments
    const existingIndex = scriptComments.findIndex(comment => comment.contents.id === threadData?.id);
    if (existingIndex !== -1) {
      // If id exists, update the existing record

      // Ensure extensibility of the comments array
      const existingComments = scriptComments[existingIndex].contents.comments || [];
      const newComments = [...existingComments, threadData?.commentData];

      // Update the existing record with the new comments
      scriptComments[existingIndex] = {
        ...scriptComments[existingIndex],
        contents: {
          ...scriptComments[existingIndex].contents,
          comments: newComments,
        },
      };
    } else {
      // If id does not exist, add a new record
      scriptComments.push({
        state: 'hasValue',
        contents: {
          comments: [threadData?.commentData],
          id: threadData.id,
        },
      });
    }

    // Call updateScriptApi with the modified scriptComments
    await updateScriptApi({ comments: scriptComments });
    setCommentText('');
    setLoading(false);
    const body = {
      email: localUser?.email,
      scriptURL: window.location.href,
      scriptOwnerId: scriptData?.ownerId,
    }
    await sendCommentNotification(body);

  };


  const onToggleStatus = useCallback(() => {
    const currentStatus = threadDataLoadable?.contents?.status;
    setCommentThreadData((threadData) => ({
      ...threadData,
      status: currentStatus === 'open' ? 'resolved' : 'open',
    }));
  }, [setCommentThreadData, threadDataLoadable?.contents?.status]);

  const onCommentTextChange = useCallback((event) => setCommentText(event.target.value), [setCommentText]);

  const onClickOutside = useCallback(
    (event) => {
      const slateDOMNode = event.target.hasAttribute('data-slate-node')
        ? event.target
        : event.target.closest(`[data-slate-node]`);

      // The click event was somewhere outside the Slate hierarchy
      if (slateDOMNode == null) {
        // console.log('slateDOMNodeddd', slateDOMNode);
        setActiveCommentThreadID(null);
        return;
      }

      const slateNode = ReactEditor.toSlateNode(editor, slateDOMNode);

      // Click is on another commented text node => do nothing.
      if (Text.isText(slateNode) && getCommentThreadsOnTextNode(slateNode).size > 0) {
        return;
      }

      setActiveCommentThreadID(null);
    },
    [editor, setActiveCommentThreadID]
  );

  useEffect(() => {
    inputRef.current?.focus();
    return () => {
      // setActiveCommentThreadID(null);
    };
  }, [textNode]);

  const hasThreadData = threadDataLoadable?.state === 'hasValue';
  const threadData = threadDataLoadable?.contents;

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };


  const [recorderAudioUrl, setRecorderAudioUrl] = useState(null);

  const insertVoiceDialogue = () => {

    if (!recorderAudioUrl) {
      alert('Please record a voice message before inserting it into the script.');
      return;
    }

    setCommentThreadData((threadData) => ({
      ...threadData,
      comments: [...(threadData?.comments || []), {
        text: "", author: localUser?.name || 'screnpla-user', creationTime: new Date()
      }],
      id: threadID,
    }));

    // console.log("recorderAudioUrl", recorderAudioUrl);
    updateScriptComments({
      commentData: { text: "", author: localUser?.name || 'screnpla-user', creationTime: new Date(),
          audioMessage: true,
          audioURL: recorderAudioUrl
       },
      id: threadID,
    });

  };




  return (
    <NodePopover
      editorOffsets={editorOffsets}
      // isBodyFullWidth={true}
      node={textNode}
      className={'comment-thread-popover'}
      // header={
      //   <Header
      //     status={threadData?.status ?? null}
      //     shouldAllowStatusChange={hasThreadData && threadData?.comments?.length > 0}
      //     onToggleStatus={onToggleStatus}
      //   />
      // }
      onClickOutside={onClickOutside}
    >
      <div className={'comment-thread-selection'}>{textNode && truncateText(textNode?.text, 150)}</div>

      {hasThreadData ? (
        <>
          <div className={'comment-list'}>
            {threadData?.comments.map((comment, index) => (
              <CommentRow key={index} selectedText={textNode?.text} comment={comment} />
            ))}
          </div>
          <div className={'comment-input-wrapper'}>
            {
              !type ?
                <div
                  style={{
                    textAlign: "center",
                    margin: "auto",
                    justifyContent: "center"
                  }}
                >

                  <Button
                    size="sm"
                    style={{
                      height: "36px", marginRight: "10px",
                      background: "#fe6d29"
                    }}
                    // className="commentBtn"
                    variant="contained"
                    onClick={
                      () => {
                        setType("dialogue")
                      }}
                  >
                    <MicIcon fontSize="inherit"
                      sx={{
                        marginRight: "10px",
                        fontSize: "18px"
                      }}
                    /> Record Dialogue
                  </Button>

                  <Button
                    size="sm"
                    style={{ height: "36px", background: "#fe6d29" }}
                    // className="commentBtn"
                    variant="contained"
                    onClick={
                      () => {
                        setType("comment")
                      }
                    }
                  >
                    <ChatIcon fontSize="inherit"
                      sx={{
                        marginRight: "10px",
                        fontSize: "18px"
                      }}
                    />
                    Type Comment
                  </Button>

                </div>
                :
                type === "dialogue" ?
                  <div
                    style={{
                      margin: "auto"
                    }}
                  >

                    <VoiceRecorderComponent
                      setRecorderAudioUrl={setRecorderAudioUrl}
                      componentStyle="commentPopup"
                      recorderAudioUrl={recorderAudioUrl}
                      insertVoiceDialogue={insertVoiceDialogue}
                      setType={setType}
                    />

      

                    {
                      recorderAudioUrl &&
                      <Box
                        sx={{
                          fontSize: '12px',
                          marginBottom: '10px',
                          textAlign: 'center',
                          color: 'red',
                        }}
                      >
                        Please listen to the recording before inserting it into the script. If you are not satisfied with the recording, you can record again.
                      </Box>}
                  </div>
                  :
                  <div style={{
                    display: "flex",
                    margin: "auto"
                  }}>
                    <TextField
                      variant="outlined"
                      inputRef={inputRef}
                      placeholder="type a comment"
                      className="commentInput"
                      value={commentText}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          onClick();
                        }
                      }}
                      style={{ height: "16px !important", marginRight: "10px", width: "100%" }}
                      onChange={onCommentTextChange}
                    />
                    <Button
                      size="sm"
                      style={{ height: "36px" }}
                      // className="commentBtn"
                      variant="contained"
                      disabled={commentText.length === 0 || loading}
                      onClick={onClick}
                    >
                      {loading ? 'Sending' : 'Comment'}
                    </Button>
                  </div>
            }


          </div>
        </>
      ) : (
        'Loading'
      )}
    </NodePopover>
  );
}
