import React, { useState } from "react";
import {
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DndContext,
  closestCorners,
  DragEndEvent,
  DragStartEvent,
  DragOverEvent,
  DragOverlay,
  DropAnimation,
  defaultDropAnimation
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates, arrayMove } from "@dnd-kit/sortable";
import { Box } from "@mui/material";
// import { findBoardSectionContainer, initializeBoard } from "../utils/board";
import { v4 as uuidv4 } from "uuid";
import BoardSection from "./BoardSection";
import TaskItem from "./TaskItem";


export const INITIAL_TASKS = [
  {
    id: uuidv4(),
    title: "vehicle",
    description: "Motorbike",
    status: "available",
    completed: false
  },
  {
    id: uuidv4(),
    title: "vehicle",
    description: "Motorbike",
    status: "available",
    completed: false
  },
  {
    id: uuidv4(),
    title: "vehicle",
    description: "Motorbike",
    status: "in progress",
    completed: false
  },
  {
    id: uuidv4(),
    title: "vehicle",
    description: "Motorbike",
    status: "in progress",
    completed: false
  },
  {
    id: uuidv4(),
    title: "vehicle",
    description: "Motorbike",
    status: "in progress",
    completed: false
  }
];

export const BOARD_SECTIONS = {
  available: "available",
  "in progress": "in progress",
  // completed: "completed"
};

export const getTasksByStatus = (tasks, status) => tasks.filter((task) => task.status === status);

export const getTaskById = (tasks, id) => tasks.find((task) => task.id === id);

export const findBoardSectionContainer = (boardSections, id) => {
  if (id in boardSections) {
    return id;
  }

  const container = Object.keys(boardSections).find((key) => boardSections[key].find((item) => item.id === id));
  return container;
};

export const initializeBoard = (tasks) => {
  const boardSections = {};

  Object.keys(BOARD_SECTIONS).forEach((boardSectionKey) => {
    boardSections[boardSectionKey] = getTasksByStatus(tasks, boardSectionKey);
  });

  return boardSections;
};

const BoardSectionList = () => {
  const tasks = INITIAL_TASKS;
  const initialBoardSections = initializeBoard(INITIAL_TASKS);
  const [boardSections, setBoardSections] = useState(initialBoardSections);

  const [activeTaskId, setActiveTaskId] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragStart = ({ active }) => {
    setActiveTaskId(active.id);
  };

  const handleDragOver = ({ active, over }) => {
    // Find the containers
    const activeContainer = findBoardSectionContainer(boardSections, active.id);
    const overContainer = findBoardSectionContainer(boardSections, over?.id);

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }

    setBoardSections((boardSection) => {
      const activeItems = boardSection[activeContainer];
      const overItems = boardSection[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex((item) => item.id === active.id);
      const overIndex = overItems.findIndex((item) => item.id !== over?.id);

      return {
        ...boardSection,
        [activeContainer]: [...boardSection[activeContainer].filter((item) => item.id !== active.id)],
        [overContainer]: [
          ...boardSection[overContainer].slice(0, overIndex),
          boardSections[activeContainer][activeIndex],
          ...boardSection[overContainer].slice(overIndex, boardSection[overContainer].length)
        ]
      };
    });
  };

  const handleDragEnd = ({ active, over }) => {
    const activeContainer = findBoardSectionContainer(boardSections, active.id);
    const overContainer = findBoardSectionContainer(boardSections, over?.id);

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return;
    }

    const activeIndex = boardSections[activeContainer].findIndex((task) => task.id === active.id);
    const overIndex = boardSections[overContainer].findIndex((task) => task.id === over?.id);

    if (activeIndex !== overIndex) {
      // Update the completion flag when the item is dropped in the "completed" column
      const isCompleted = overContainer === "completed";
      setBoardSections((boardSection) => ({
        ...boardSection,
        [overContainer]: arrayMove(boardSection[overContainer], activeIndex, overIndex).map((task) => ({
          ...task,
          completed: isCompleted
        }))
      }));
    }

    setActiveTaskId(null);
  };

  const dropAnimation = {
    ...defaultDropAnimation
  };

  const task = activeTaskId ? getTaskById(tasks, activeTaskId) : null;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}>
      {Object.keys(boardSections).map((boardSectionKey) => (
        <div key={boardSectionKey}>
          <BoardSection id={boardSectionKey} title={boardSectionKey} tasks={boardSections[boardSectionKey]} />
        </div>
      ))}
      <DragOverlay dropAnimation={dropAnimation}>{task ? <TaskItem task={task} /> : null}</DragOverlay>
    </DndContext>
  );
};

export default BoardSectionList;
