import React, { useState } from 'react';
import './InboxCards.css';
import Iconify from 'src/components/iconify';
import { Box, Tooltip } from '@mui/material';
import CardRemovePopover from '../../RightDrawer/components/CardRemovePopover';

const InboxCards = () => {
  const [inputToggle, setInputToggle] = useState(false);
  return (
    <>
      <Box className="inbox-card-box">
        <Box
          onClick={() => setInputToggle(!inputToggle)}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          //    gap={'5px'}
        >
          <Box display={'flex'} alignItems={'center'}>
            <Iconify icon={inputToggle ? 'ep:arrow-down-bold' : 'ep:arrow-right-bold'} />
            <input  className="inbox-card-input dark-bg dark-text" placeholder="Untitled" />
          </Box>
          <Box className="visible" display={'flex'} gap={'5px'} alignItems={'center'}>
            <Tooltip title="Add tags" arrow>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ width: '20px', height: '20px', borderRadius: '5px', '&:hover': { backgroundColor: '#fe6d291a' } }}
                className="hide"
              >
                <Iconify icon="vaadin:hash" />
              </Box>
            </Tooltip>
            <Tooltip title="Add assignee" arrow>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ width: '20px', height: '20px', borderRadius: '5px', '&:hover': { backgroundColor: '#fe6d291a' } }}
                className="hide"
              >
                <Iconify icon="octicon:people-24" />
              </Box>
            </Tooltip>

            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ width: '20px', height: '20px', borderRadius: '5px', '&:hover': { backgroundColor: '#fe6d291a' } }}
              className="hide"
            >
              <Iconify icon="icon-park-outline:loading-one" />
            </Box>

            <Tooltip title="More" arrow>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ width: '20px', height: '20px', borderRadius: '5px', '&:hover': { backgroundColor: '#fe6d291a' } }}
              >
                <Iconify icon="ph:dots-three-bold" />
              </Box>
            </Tooltip>
          </Box>
        </Box>
        {inputToggle && (
          <>
            <textarea className="inbox-beautiful-textarea dark-bg dark-text" placeholder="..." rows="5" />

            <Box mt={'10px  '} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Box>
                  <CardRemovePopover />
                </Box>
                <Box>
                  <Iconify sx={{ color: '#868e96' }} icon="solar:gallery-bold" />
                </Box>
                <Box>
                  <Iconify sx={{ color: '#868e96' }} icon="gridicons:pages" />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default InboxCards;
