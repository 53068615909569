/* eslint-disable */
import "./CommentedText.css";

import { activeCommentThreadIDAtom } from "../../../utils/CommentState";
import classNames from "classnames";
import { getSmallestCommentThreadAtTextNode } from "../../../utils/EditorCommentUtils";
import { useSlate } from "slate-react";
import { useRecoilState } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage, faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import useScript from "../useScript";
import { useParams } from "react-router";

export default function CommentedText(props) {
  const { id } = useParams();
  const [scriptResponse, loading] = useScript(id);
  const script = scriptResponse?.data || null;
  const [showIcon, setShowIcon] = useState(false);

  const editor = useSlate();
  const { commentThreads, textNode, ...otherProps } = props;
  const [activeCommentThreadID, setActiveCommentThreadID] = useRecoilState(
    activeCommentThreadIDAtom
  );

  const commentID = [...commentThreads][0]; // Get the first commentID

  const onClick = () => {
    setActiveCommentThreadID(
      getSmallestCommentThreadAtTextNode(editor, textNode)
    );
  };

  // Check if the first comment has an audioURL
  const firstComment = script?.comments?.find(comment => comment.contents.id === commentID);
  const hasAudioURL = firstComment?.contents.comments?.[0]?.audioMessage;

  useEffect(() => {
    // Use setTimeout to wait for the DOM to fully render
    const timeout = setTimeout(() => {
      // Select all icons with the same matched ID
      const icons = document.querySelectorAll(`.comment-icon[matched="${commentID}"]`);

      // Hide all icons initially
      icons.forEach((icon, index) => {
        if (index !== 0) {
          icon.style.display = 'none';
        }
      });
    }, 100); // Adjust time as necessary

    return () => clearTimeout(timeout);
  }, [commentID]); // Dependency on commentID to reapply when it changes

  return (
    <span>
      <span
        {...otherProps}
        className={classNames({
          comment: true,
          "is-active": commentThreads.has(activeCommentThreadID),
        })}
        onClick={onClick}
      >
        {props.children}
      </span>
      <FontAwesomeIcon
        id={activeCommentThreadID}
        matched={commentID}
        className={`icon-main dropbtn comment-icon `}
        icon={hasAudioURL ? faMicrophone : faMessage} 
        onClick={onClick}
      />
    </span>
  );
}
