import React from 'react';
import { Grid } from '@mui/material';
import SidebarItems from '../desk/Sidebar/Sidebar';
import Scripts from '../desk/Sidebar/scripts/Scripts';

const ScriptComponent = () => (
  <>
    <Grid container>
      <SidebarItems />
      <Grid item xs={12} md={9} lg={9.8}>
        <Scripts />
      </Grid>
    </Grid>
  </>
);

export default ScriptComponent;
