import * as React from 'react';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './PEmailModal.css';
import { isEmpty } from 'lodash';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 2,
};

export default function ProfileNameModal({ name, handleUpdateName, setName, enqueueSnackbar }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    if (isEmpty(name)) {
      enqueueSnackbar('Name is required', { variant: 'error' });
      return;
    }
    handleUpdateName();
    handleClose();
  };

  return (
    <div>
      <Box onClick={handleOpen} className="vertical-change">
        <p
          style={{
            cursor: 'pointer',
          }}
        >
          Change
        </p>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography mb={'25px'} sx={{ fontSize: '1rem' }}>
            New name
          </Typography>
          <input
            className="pro-email-input"
            placeholder="John Doe"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <Box mt={'30px'} display={'flex'} width={'100%'} alignItems={'center'} gap={'10px'}>
            <Typography
              sx={{
                textAlign: 'center',
                width: '100%',
                padding: '.5rem 2rem',
                backgroundColor: '#fe6d29',
                color: '#fff',
                borderRadius: '.25rem',
                fontSize: '.875rem',
                '&:hover': {
                  backgroundColor: '#d0551c',
                },
                cursor: 'pointer',
              }}
              onClick={handleSubmit}
            >
              Ok
            </Typography>
            <Typography
              onClick={handleClose}
              sx={{
                textAlign: 'center',
                width: '100%',
                padding: '.5rem 2rem',
                backgroundColor: '#fe6d291a',
                borderRadius: '.25rem',
                fontSize: '.875rem',
                '&:hover': {
                  backgroundColor: '#00017806',
                },
                cursor: 'pointer',
              }}
            >
              Cancel
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
