import * as React from 'react';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 500,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 2,
};



export default function DocumentPopperModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Box onClick={handleOpen} width={'100%'} display={'flex'} alignItems={'center'} gap={"50px    "} justifyContent={'space-between'}>
        <Typography  variant="body1" sx={{ fontFamily: 'inter', fontSize: '.875rem', color: '#333' }}>
          Save as template
        </Typography>
        <Typography
          ml={'5px'}
          sx={{
            color: '#1971c2',
            backgroundColor: '#d0ebff',
            fontWeight: '600',
            fontSize: '0.75rem',
            fontFamlily: 'inter',
            padding: '0.25rem 0.5rem',
            borderRadius: '0.25rem',
          }}
        >
          Beta
        </Typography>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
         <Box sx={style}>
          <Typography mb={'25px'} sx={{ fontSize: '1rem' }}>
            Choose a name
          </Typography>
          <input className="pro-email-input" />

          <Box mt={'30px'} display={'flex'} width={'100%'} alignItems={'center'} gap={'20px'}>
            <Typography
              sx={{
                textAlign: 'center',
                width: '100%',
                padding: '.5rem 2rem',
                backgroundColor: '#fe6d29',
                color: '#fff',
                borderRadius: '.25rem',
                fontSize: '.875rem',
                "&:hover":{
                    backgroundColor:"#d0551c"
                }
              }}
            >
              Ok
            </Typography>
            <Typography
             onClick={handleClose}
              sx={{
                textAlign: 'center',
                width: '100%',
                padding: '.5rem 2rem',
                backgroundColor: '#fe6d291a',
                borderRadius: '.25rem',
                fontSize: '.875rem',
                "&:hover":{
                    backgroundColor:"#00017806"
                }
              }}
            >
              Cancel
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
