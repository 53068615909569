import { Box, Divider, Popover, Typography } from '@mui/material';
import React, { useState } from 'react';
import Iconify from 'src/components/iconify';

const ProEditPopper = ({ onEdit, onDelete, onArchieve }) => {
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = anchorEl;
  const id = openpop ? 'simple-popover' : undefined;

  return (
    <>
      <Box
        aria-describedby={id}
        onClick={handleClick}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          width: '30px',
          height: '30px',
          borderRadius: '.25rem',
          '&:hover': { backgroundColor: '#fe6d291a' },
        }}
      >
        <Iconify sx={{ fontSize: '15px' }} icon="bi:three-dots-vertical" />
      </Box>
      <Popover
        id={id}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ borderRadius: '0px', '& .MuiPaper-root': { borderRadius: '5px' } }}
      >
        <Box sx={{ padding: '5px .7rem', cursor: 'pointer' }} width={'200px'}>
          <Typography
            onClick={() => {
              if (onEdit) onEdit();
              handleClose();
            }}
            mt={'10px'}
            sx={{
              fontSize: '.875rem',
              paddingRight: '2rem',
              paddingLeft: '.5rem',
              '&:hover': { backgroundColor: '#00017806' },
            }}
          >
            Rename{' '}
          </Typography>
          <Typography
            onClick={() => {
              if (onArchieve) onArchieve();
              handleClose();
            }}
            mt={'10px'}
            sx={{
              fontSize: '.875rem',
              paddingRight: '2rem',
              paddingLeft: '.5rem',
              '&:hover': { backgroundColor: '#00017806' },
            }}
          >
            Archive{' '}
          </Typography>
          <Typography
            onClick={() => {
              if (onDelete) onDelete();
              handleClose();
            }}
            mt={'10px'}
            sx={{
              fontSize: '.875rem',
              paddingRight: '2rem',
              paddingLeft: '.5rem',
              '&:hover': { backgroundColor: '#00017806' },
            }}
          >
            Delete{' '}
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

export default ProEditPopper;
