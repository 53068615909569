import { Box, Popover, Typography } from '@mui/material';
import React, { useState } from 'react';

const ShareDotModal = () => {
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = anchorEl;
  const id = openpop ? 'simple-popover' : undefined;

  return (
    <>
      <Box aria-describedby={id} onClick={handleClick}>
        ...
      </Box>

      <Popover
        id={id}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ borderRadius: '0px', '& .MuiPaper-root': { borderRadius: '5px' } }}
      >
        <Box sx={{ padding: '8px 10px' }} width={'200px'}>
          {/* <Typography variant="body1" sx={{ fontSize: '.875rem' }}> */}
          <Box>
            <Typography className="dark-text" sx={{ color: '#333', fontSize: '.875rem' }}>
              change color
            </Typography>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default ShareDotModal;
