import { Box, Popover, Typography } from '@mui/material';
import React, { useState } from 'react';
import Iconify from 'src/components/iconify';

const OutlinePopover = ({ removeOutline, handleKanBanCard }) => {
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = anchorEl;
  const id = openpop ? 'simple-popover' : undefined;

  //   aria-describedby={id}
  //   onClick={handleClick}
  return (
    <>
      <Iconify aria-describedby={id} onClick={handleClick} sx={{ color: '#ccc' }} icon="ph:dots-three-bold" />

      <Popover
        id={id}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ borderRadius: '0px', '& .MuiPaper-root': { borderRadius: '5px' } }}
      >
        <Box sx={{ padding: '10px 10px' }} width={'200px'}>
          <Box>
            <Typography sx={{ fontSize: '.875rem', cursor: 'pointer' }} ml={'25px'} onClick={() => removeOutline()}>
              Remove act break
            </Typography>
          </Box>
          <Box mt={'10px'} display={'flex'} onClick={() => {
            handleClose()
            handleKanBanCard()}} alignItems={'center'} gap={'10px'}>
            <Iconify sx={{ color: '', cursor: 'pointer' }} icon="iconoir:plus" />
            <Typography sx={{ fontSize: '.875rem', cursor: 'pointer' }}>Start new beat</Typography>
          </Box>
          <Box mt={'10px'} cursor="pointer" onClick={() => removeOutline()} display={'flex'} alignItems={'center'} gap={'10px'}>
            <Iconify sx={{ color: '' }} icon="icon-park-outline:delete" />
            <Typography sx={{ fontSize: '.875rem', cursor: 'pointer' }}>Delete beat,keep script</Typography>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default OutlinePopover;
