import { Box, Typography } from '@mui/material';
import React from 'react';
import { useSnackbar } from 'notistack';

import { useFetch } from 'src/hooks/useFetch';
import { useNavigate } from 'react-router';
import { getLogoPath } from 'src/utils/common';
import { isValidEmail } from 'src/utils/helpers';
import { Signin, Welcome } from '../../../../../routes/paths';
import '../signup/Signup.css';

const ForgotPassword = () => {
  const [email, setEmail] = React.useState('');
  const [isMailSent, setIsMailSent] = React.useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [getUser, loading] = useFetch(
    'api/auth/getUser',
    `Get user successfully`,
    'Something went wrong while getting the user',
    'POST',
    false
  );

  const [sendMail, smLoading] = useFetch('api/auth/generateOtp', ``, '', 'POST', false);

  const message = (
    <div>
      If we recognized this email address,
      <br />
      We have sent you a link for resetting your password.
      <br />
      Please check your email (including spam). <br />
      If you have not received an email,
      <br />
      Please try another address.
    </div>
  );

  const handleClick = async () => {
    const tempEmail = email?.trim()?.toLocaleLowerCase() || '';
    if (isValidEmail(tempEmail) && !loading) {
      const response = await getUser({ email: tempEmail });
      if (response.message === 'User not found!') {
        localStorage.setItem('email', tempEmail);
        enqueueSnackbar(`Email doesn't exists.`, {
          variant: 'error',
        });
      } else {
        // enqueueSnackbar(message, {
        //   variant: 'success',
        // });
        const response = await sendMail({ email: tempEmail });
        if (response.success) {
          setIsMailSent(true);
        }
      }
    } else {
      enqueueSnackbar('Please enter a valid email address', {
        variant: 'error',
      });
    }
  };

  const handleSignin = () => {
    navigate(Signin);
  };

  return (
    <>
      <Box mb={'10px'} py={'30px'} display={'flex'} justifyContent={'center'}>
        <img src={getLogoPath()} alt="" width={'200px'} />
      </Box>

      <Box display={'flex'} justifyContent={'center'}>
        <Box p={'10px'} display={'flex'} alignItems={'center'} flexDirection={'column'} width={'440px'}>
          <Typography sx={{ fontSize: '1.52rem', fontFamily: 'inter', color: '' }}>Reset Your Password</Typography>

          {!isMailSent ? (
            <>
              <Box mt={'20px'} width={'100%'}>
                <input
                  className="signup-input"
                  type="email"
                  required
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Box>
              <Typography
                sx={{ fontSize: '.75rem', color: '#b2b3c4', position: 'relative', right: '44px', marginTop: '2px' }}
              >
                We will send you a link where you can reset your password.
              </Typography>
              <Typography
                onClick={handleClick}
                mt={'10px'}
                width={'100%'}
                textAlign={'center'}
                sx={{
                  fontSize: '.875rem',
                  color: '#fff',
                  padding: '1rem 2rem',
                  border: '1px solid #fe6d29',
                  fontWeight: '400',
                  backgroundColor: loading ? '#000178' : '#fe6d29',
                  cursor: 'pointer',
                  borderRadius: '.25rem',
                  fontFamily: 'inter',
                  '&:hover': { backgroundColor: '#d0551c' },
                }}
              >
                {loading ? 'Loading...' : 'Send password reset email'}
              </Typography>
            </>
          ) : (
            <Typography
              sx={{
                fontSize: '.75rem',
                color: '#b2b3c4',
                position: 'relative',
                textAlign: 'center',
                width: '100%',
                marginTop: '20px',
              }}
            >
              If we recognized this <b>email address</b>, We will send you a link for resetting your password. Please
              check your email (including spam) If you have not received an email, Please try another address.
            </Typography>
          )}

          <Typography mt={'20px'} sx={{ fontSize: '.875rem', color: '#b2b3c4' }}>
            Remembered your password?{' '}
            <a href="" className="signin" onClick={handleSignin}>
              {' '}
              Sign in{' '}
            </a>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;
