import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {

  Popover,
  Divider,
  useMediaQuery,

} from '@mui/material';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';

import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

import Notifications from 'src/components/slate-editor/components/editorsettings/notifications/Notifications';

import 'src/components/slate-editor/components/editorsettings/VerticalTabs.css';
import 'src/components/slate-editor/components/navbar/EditorNavbar.css';
import WritingPreferences from 'src/components/slate-editor/components/editorsettings/writingpreferences/WritingPreferences';
import Profile from 'src/components/slate-editor/components/editorsettings/profile/Profile';
import Billing from 'src/components/slate-editor/components/editorsettings/billing/Billing';






function VerticalTabs() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [isElementToggled, setIsElementToggled] = useState(false);

  const [textVal, setTextVal] = useState('writing');

  const [nestedTabValue, setNestedTabValue] = useState(0);

  const handleTabClick = (value) => {
    setTextVal(value);
    setNestedTabValue(0);
  };

  const handleNestedTabChange = (event, newValue) => {
    setNestedTabValue(newValue);
  };

  const [nestedTextVal, setNestedTextVal] = useState('');

  const handleToggleElement = (e) => {
    setIsElementToggled(!isElementToggled);
  };
  return (
    <>
      <Box sx={{ width: '100%' }} className="vertical-tabs-container">
        <Box sx={{ maxHeight: '500px', overflowY: 'auto' }} width={'30%'}>
          <Box>
            <Divider />
          </Box>
          <Typography mb={"5px"} sx={{ color: '#7b7c8d' }} ml={'6px'} fontSize={'1rem'} mt={'10px'}>
            Account
          </Typography>
          <Tabs
            TabIndicatorProps={{ sx: { display: 'none' } }}
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className="vertical-tabs"
            sx={{
              '& button': {
                marginRight: '20px',
                paddingLeft: '10px',
                minHeight: '5px',
                marginBottom: '10px',
                lineHeight: '1.5',
              },
              '& button .MuiBox-root': { marginRight: '20px', borderRadius: '5px' },
              '& button span': { marginRight: '12px' },
              '& button:hover': { backgroundColor: '#00017806' },
            }}
          >
            {/* <Tab disabled label="Account" /> */}

            <Tab
              onClick={() => setTextVal('writing')}
              sx={{
                borderRadius: '5px',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                width: '100%',
                marginRight: '20px',
              }}
              label={
                <Box
                  sx={{
                    width: '100%',
                    background: textVal === 'writing' ? '#fe6d2917' : 'transparent',
                    color: textVal === 'writing' ? '#fe6d29' : '#333',
                    padding: '.5rem',
                    marginLeft: '2px',
                  }}
                  ml={'-6px'}
                  display={'flex'}
                  alignItems={'center'}
                  gap={'5px'}
                >
                  <RemoveRedEyeOutlinedIcon sx={{ fontSize: '15px' }} />
                  <Box sx={{ fontSize: '.875rem' }}>Writing Preferences</Box>
                </Box>
              }
            />
            <Tab
              onClick={() => setTextVal('profile')}
              sx={{
                borderRadius: '5px',
                width: '100%',
                whiteSpace: 'nowrap',
                marginTop: '-10px',
                marginBottom: '-10px',
                '&.MuiTab-root:focus': {
                  outline: 'none',
                },
              }}
              label={
                <Box
                  sx={{
                    padding: '.5rem',
                    width: '100%',
                    background: textVal === 'profile' ? '#fe6d2917' : 'transparent',
                    color: textVal === 'profile' ? '#fe6d29' : '#333',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  gap={'5px'}
                >
                  <Person2OutlinedIcon sx={{ fontSize: '15px' }} />{' '}
                  <Box width={''} fontSize={'.875rem'}>
                    Profile
                  </Box>
                </Box>
              }
            />
            {/* <Tab
              sx={{ borderRadius: '5px', width: '100%', marginTop: '-10px' }}
              onClick={() => setTextVal('billing')}
              label={
                <Box
                  sx={{
                    width: '100%',
                    background: textVal === 'billing' ? '#fe6d2917' : 'transparent',
                    color: textVal === 'billing' ? '#fe6d29' : '#333',
                    padding: '.5rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  gap={'5px'}
                >
                  <AddCardOutlinedIcon fontSize="20px" />{' '}
                  <Box width={''} sx={{ fontSize: '.875rem' }}>
                    Billing
                  </Box>
                </Box>
              }
            /> */}
            <Tab
              onClick={() => setTextVal('notifications')}
              sx={{
                borderRadius: '5px',
                whiteSpace: 'nowrap',
                marginTop: '-10px',
                marginBottom: '-10px',
                width: '100%',
              }}
              label={
                <Box
                  sx={{
                    width: '100%',
                    background: textVal === 'notifications' ? '#fe6d2917' : 'transparent',
                    color: textVal === 'notifications' ? '#fe6d29' : '#333',
                    padding: '.5rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  gap={'5px'}
                >
                  <NotificationsOutlinedIcon fontSize="20px" />{' '}
                  <Box width={''} sx={{ fontSize: '.875rem' }}>
                    Notifications
                  </Box>
                </Box>
              }
            />
          </Tabs>
        </Box>

        <Box maxHeight={'500px'} width={'70%'} sx={{ overflowY: 'auto' }}>
          <Box>
            <Divider />
          </Box>
          {/* writinig preferences */}
          <TabPanel value={value} index={0}>
            <WritingPreferences />
          </TabPanel>

          {/* Profile */}
          <TabPanel value={value} index={1}>
            <Profile />
          </TabPanel>
          {/* Billing */}
          {/* <TabPanel value={value} index={2}>
            <Billing />
          </TabPanel> */}

          {/* Notifications */}
          <TabPanel value={value} index={2}>
            <Notifications/>
          </TabPanel>
        </Box>

       
      </Box>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className="vertical-tab-panel"
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default VerticalTabs;
