import { Box, LinearProgress } from '@mui/material'
import React,{ useState } from 'react'
import { getLogoPath } from 'src/utils/common'


const SignupHeader = ({progressValue}) => {
    const [progress,setProgress] = useState(progressValue)
  return (
    <>
         <Box py={'20px'} display={'flex'} justifyContent={'center'}>
        <img src={getLogoPath()} alt="" width={'200px'} />
      </Box>
      <LinearProgress variant="determinate" value={progress} sx={{'& .MuiLinearProgress-bar':{backgroundColor:"#fe6d29"},backgroundColor:"#ccc"}} />

    </>
  )
}

export default SignupHeader