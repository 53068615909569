import styled from '@emotion/styled';
import { Box, MenuItem, MenuList, Popover, Switch, Typography } from '@mui/material';
import React, { useState } from 'react';
import Iconify from 'src/components/iconify';

const CardMorePopover = () => {
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = anchorEl;
  const id = openpop ? 'simple-popover' : undefined;

  //   aria-describedby={id}
  //   onClick={handleClick}
  return (
    <>
      <Iconify aria-describedby={id} onClick={handleClick} icon="ph:dots-three-bold" />

      <Popover
        id={id}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ borderRadius: '0px', '& .MuiPaper-root': { borderRadius: '5px' } }}
      >
        <Box sx={{ padding: '8px 10px' }} width={'280px'}>
          <Box mt={'10px'} display={'flex'} alignItems={'center'} gap={'10px'}>
            <Box>
              <Iconify icon="tabler:inbox" />
            </Box>
            <Box>
              <Typography sx={{ fontSize: '.875rem' }}>Move beat to inbox</Typography>
            </Box>
          </Box>
          <Box mt={'10px'} display={'flex'} alignItems={'center'} gap={'10px'}>
            <Box>
              <Iconify icon="icon-park-outline:delete" />
            </Box>
            <Box>
              <Typography sx={{ fontSize: '.875rem' }}>Remove script section</Typography>
            </Box>
          </Box>
          <Box mt={'10px'} ml={'26px'}>
            <Typography sx={{ fontSize: '.875rem' }}>Remove beat,keep script section</Typography>
          </Box>
          <Box mt={'10px'} ml={'26px'}>
            <Typography sx={{ fontSize: '.875rem' }}>Remove act break</Typography>
          </Box>
          <Box mt={'10px'} ml={'26px'}>
            <Typography sx={{ fontSize: '.875rem' }}>Make beginning of sequence</Typography>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default CardMorePopover;
