/* global jsPDF */
import { Box, Divider, Popover, Tooltip, Typography } from '@mui/material';
import React from 'react';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CourierprimeDropdown from '../../courierprimedropdown/CourierprimeDropdown';
import convertToDoc from './convert-to-doc';
import { saveAs } from 'file-saver';
import jsPDF from "jspdf";
import {convertToFdx, convertToXML} from './convert-to-fdx';
import ZoomDropdownValue from '../../zoomdropdownpopover/ZoomDropdownValue';
import "./CourierPrimeCodeRegular-normal";
import { removeClasses } from 'src/utils/common';
import html2canvas from 'html2canvas';

const ExportPopover = ({ editor, scriptName, editorRef }) => {
  const [anchorElSettings, setAnchorElSettings] = React.useState(null);
  const [format, setFormat] = React.useState('PDF Format');
  const handleClickSettings = (event) => {
    setAnchorElSettings(event.currentTarget);
  };
  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };
  const openSettings = Boolean(anchorElSettings);
  const idSettings = openSettings ? 'simple-popover' : undefined;

  const downloadAs = async () => {
    try {
      if (format === 'PDF Format') {
        handleGeneratePdf();
      }
      if (format === 'Word Doc Format') {
        const doc = await convertToDoc(editor);
        saveAs(doc, `${scriptName}.docx`);
      }
      if (format === 'Final Draft Format') {
        const doc = await convertToFdx(editor);
        saveAs(doc, `${scriptName}.fdx`);
      }
      if (format === 'XML Format') {
        const doc = await convertToXML(editor);
        saveAs(doc, `${scriptName}.xml`);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGeneratePdf = async () => {
    const reportElement = editorRef.current;
    
    // Remove unwanted classes and elements temporarily
    const elementsToRemove = [];
    const classPrefixesToRemove = ['.header-page', '.footer-page', '.footerEditable', '.headerEditable', 'comment', 'suggestedMention', 'voice-message'];
    
    classPrefixesToRemove.forEach((prefix) => {
      const elements = reportElement.querySelectorAll(`[class^="${prefix}"]`);
      elements.forEach((item) => {
        elementsToRemove.push(item);
        item.style.display = "none"; // Hide elements instead of removing them
      });
    });

    const marks = reportElement.querySelectorAll('mark');
  marks.forEach((mark) => {
    const parent = mark.parentNode;
    while (mark.firstChild) {
      parent.insertBefore(mark.firstChild, mark);
    }
    parent.removeChild(mark);
  });
  
    // Remove SVG elements temporarily
    const svgElements = reportElement.querySelectorAll('svg');
    svgElements.forEach((item) => {
      elementsToRemove.push(item);
      item.style.display = "none"; // Hide SVG elements
    });
  
    try {
      // Use html2canvas to take a screenshot of the modified content
      const canvas = await html2canvas(reportElement);
      const imgData = canvas.toDataURL("image/png");
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
  
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
  
      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
  
      // Create PDF blob and open the print window
      const pdfBlob = pdf.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const printWindow = window.open(pdfUrl);
  
      // Cleanup the URL object
      setTimeout(() => URL.revokeObjectURL(pdfUrl), 10000);
  
      // Trigger print after a delay
      setTimeout(() => {
        printWindow?.print();
      }, 1000);
      window.location.reload();
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      // Restore the original DOM after PDF generation
      elementsToRemove.forEach((item) => {
        item.style.display = ""; // Reset display to original state
      });
    }
  };
  
  
  return (
    <>
      <Tooltip title="Export" arrow>
        <Box aria-describedby={idSettings} onClick={handleClickSettings} className="scren-settings">
          <div>
            <FileUploadOutlinedIcon />
          </div>
        </Box>
      </Tooltip>
      <Popover
        id={idSettings}
        open={openSettings}
        anchorEl={anchorElSettings}
        onClose={handleCloseSettings}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            width: '270px',
            maxWidth: 360,
            backgroundColor: '',
            // color: "white",
            padding: '18px',
            fontSize: '.875rem',
          }}
        >
          <Typography
            className="dark-text"
            mb={'15px'}
            variant="body1"
            sx={{ color: '#7b7c8d', fontSize: '.875rem', fontFamily: 'inter' }}
            onClick={handleCloseSettings}
          >
            Export Script
          </Typography>
          <Box
          sx={{display: 'none'}}>
          <ZoomDropdownValue scriptZoom={100} />
          </Box>
          <Divider />

          <Box
            className="dark-text"
            mt={'15px'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ fontSize: '.875rem', color: '#333', fontWeight: '600' }}
          >
            <p className="fontfam">Select Format</p>

            <CourierprimeDropdown
              name={'export-script'}
              onChange={(value) => {
                setFormat(value);
              }}
              option1={'PDF Format'}
              option2={'Final Draft Format'}
              option3={'XML Format'}
            />
          </Box>

          <Divider />
          <Box>
            <button
              className="export-cardbtn"
              style={{
                cursor: 'pointer',
                backgroundColor: '#fe6d29',
              }}
              onClick={() => downloadAs()}
            >
              Export Now
            </button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default ExportPopover;
