import React, { useState } from 'react';

const MultiTagInput = ({ placeholder, tags, onTagAdd, onTagRemove }) => {
  const [inputValue, setInputValue] = useState('');

  const handleTagAdd = (tagName) => {
    if (tagName.trim() === '') return;
    const newTag = { id: Date.now(), name: tagName };
    onTagAdd(newTag);
    setInputValue('');
  };

  return (
    <div>
      <input
        type="text"
        placeholder={placeholder}
        value={inputValue}
        className="doc-title light-bg dark-text"
        onBlur={(e) => {
          if (e.currentTarget.value.trim() !== '') {
            handleTagAdd(e.currentTarget.value);
          }
        }}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && handleTagAdd(e.currentTarget.value)}
      />
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        {tags.map((item, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#e5e5e5',
              borderRadius: 4,
              padding: '2px 5px',
              marginRight: 5,
              marginBottom: 5,
            }}
          >
            <span>{item.name}</span>
            <div style={{ marginLeft: 10, marginBottom: 2 }}>
              {/*  eslint-disable-next-line react/button-has-type */}
              <button
                className={'btn btn-sm btn-danger'}
                onClick={() => {
                  onTagRemove(item.id);
                }}
              >
                X
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiTagInput;
