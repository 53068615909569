import React, { useState } from 'react';
import './KanbanCard.css';

import { Box, Tooltip } from '@mui/material';
import Iconify from 'src/components/iconify';
import CardLoadingPopover from './CardLoadingPopover';
import CardMorePopover from './CardMorePopover';
import CardImageModal from '../RightDrawer/CardImageModal';
import CardNoteModal from '../RightDrawer/CardNoteModal';

const BoardKanbanCard = () => {
  const [inputToggle, setInputToggle] = useState(false);
  
  return (
    <>
      <Box  sx={{zIndex:'99'}} className="board-card-box dark-bg">
        <Box
          onClick={() => setInputToggle(!inputToggle)}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          //    gap={'5px'}
        >
          <Box display={'flex'} alignItems={'center'}>
            <Iconify icon={inputToggle ? 'ep:arrow-down-bold' : 'ep:arrow-right-bold'} />
            <input className="board-card-input dark-bg dark-text" placeholder="Untitled" />
          </Box>
          <Box className="visible" display={'flex'} gap={'5px'} alignItems={'center'}>
            <Tooltip title="Add tags" arrow>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ width: '20px', height: '20px', borderRadius: '5px', '&:hover': { backgroundColor: '#fe6d291a' } }}
                className="hide"
              >
                <Iconify icon="vaadin:hash" />
              </Box>
            </Tooltip>
            <Tooltip title="Add assignee" arrow>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ width: '20px', height: '20px', borderRadius: '5px', '&:hover': { backgroundColor: '#fe6d291a' } }}
                className="hide"
              >
                <Iconify icon="octicon:people-24" />
              </Box>
            </Tooltip>

            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ width: '20px', height: '20px', borderRadius: '5px', '&:hover': { backgroundColor: '#fe6d291a' } }}
              className="hide"
            >
              <CardLoadingPopover />
            </Box>

            <Tooltip title="More" arrow>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ width: '20px', height: '20px', borderRadius: '5px', '&:hover': { backgroundColor: '#fe6d291a' } }}
              >
                <CardMorePopover />
              </Box>
            </Tooltip>
          </Box>
        </Box>
        {inputToggle && (
          <>
            <textarea className="board-beautiful-textarea dark-bg dark-text" placeholder="..." rows="5" />

            <Box mt={'10px  '} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Box>
                  <Iconify sx={{ color: '#868e96' }} icon="bi:chat" />
                </Box>
                <Box>
                  <CardImageModal />
                </Box>
                <Box>
                  <CardNoteModal />
                </Box>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Tooltip title="Open beat in script" arrow>
                  <Box>
                    <Iconify sx={{ '&:hover': { color: '#fe6d29' }, color: '#868e96' }} icon="mingcute:aiming-line" />
                  </Box>
                </Tooltip>
                <Tooltip title="Star beat" arrow>
                  <Box>
                    <Iconify sx={{ '&:hover': { color: '#fe6d29' }, color: '#868e96' }} icon="iconamoon:star-light" />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default BoardKanbanCard;
