

export const readFileContent = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    resolve(event.target.result);
  };
  reader.onerror = (error) => {
    reject(error);
  };
  reader.readAsText(file);
});

export const convertFDXContent = (fdxContent) => {
  const xmlDoc = new DOMParser().parseFromString(fdxContent, 'application/xml');
  const slateJSContent = convertXMLToSlateJS(xmlDoc);
  return slateJSContent;
};

const convertXMLToSlateJS = (xmlDoc) => {
  const slateJSContent = [];
  let pageContent = createEmptyPageContent();

  // Check if the xmlDoc is valid
  if (!xmlDoc || xmlDoc.querySelector('parsererror')) {
      console.error('Invalid XML document');
      return slateJSContent;
  }

  // Counter to keep track of children in pageContent
  let childrenCounter = 0;

  // Iterate over Paragraph elements in the XML
  const paragraphElements = xmlDoc.querySelectorAll('Paragraph');
  paragraphElements.forEach((paragraphElement) => {
      const type = paragraphElement.getAttribute('Type');

      // Create an array to hold text nodes within the paragraph
      const textNodes = [];

      // Iterate over child nodes of the paragraph
      paragraphElement.childNodes.forEach((childNode) => {
          if (childNode.nodeName === 'Text') {
              const textContent = childNode.textContent.trim();
              const styleAttribute = childNode.getAttribute('Style');

              // Check if the text node has a style attribute
              const textNode = {
                  text: textContent,
              };

              if (styleAttribute) {
                  if (styleAttribute.includes('Bold')) {
                      textNode.bold = true;
                      // Add space before and after bold text
                      textNode.text = ` ${textNode.text} `;
                  }
                  if (styleAttribute.includes('Italic')) {
                      textNode.italic = true;
                  }
                  if (styleAttribute.includes('AllCaps')) {
                      textNode.uppercase = true;
                  }
              }

              // Push the text node to the array
              textNodes.push(textNode);
          }
      });

      if (type && textNodes.length > 0) {
          const slateJSType = mapFDXTypeToSlateJSType(type);
          if (slateJSType) {
              const slateJSNode = {
                  type: slateJSType,
                  children: textNodes, // Use the array of text nodes
              };

              // Check if childrenCounter reaches 27
              if (childrenCounter === 27) {
                  // Add the current pageContent to slateJSContent
                  slateJSContent.push(pageContent);

                  // Create a new pageContent with an empty children array
                  pageContent = createEmptyPageContent();

                  // Reset the childrenCounter
                  childrenCounter = 0;
              }

              // Push slateJSNode to pageContent
              pageContent.children.push(slateJSNode);
              childrenCounter += 1;
          } else {
              console.warn(`Unsupported Final Draft type: ${type}. Skipping.`);
          }
      }
  });

  // Add the last pageContent to slateJSContent
  if (pageContent.children.length > 0) {
      slateJSContent.push(pageContent);
  }

  return slateJSContent;
};

const createEmptyPageContent = () => ({
  type: "page",
  children: [],
});

const mapFDXTypeToSlateJSType = (fdxType) => {
  // Map Final Draft types to SlateJS types (customize as needed)
  const typeMapping = {
    'Script Title': 'title',
    'Normal': 'normal',
    'Action': 'action',
    'Paragraph': 'paragraph',
    'Scene Heading': 'sceneHeading',
    'Character': 'character',
    'Dialogue': 'dialogue',
    'Parenthetical': 'parenthetical',
    'Transition': 'transition',
    'Shot': 'shot',
    'General': 'paragraph',
    'End of Script': 'paragraph',
    // ... Add more mappings as needed
  };

  return typeMapping[fdxType] || 'normal';
};




// Function to convert PDF content to Slate.js format
export const convertPDFToSlateJS = (pdfContent) => {
  if (!pdfContent) {
    return [];
  }

  const slateJSContent = [];

  // Split the PDF content into paragraphs
  const paragraphs = pdfContent.split('\n');

  // Initialize pageContent
  let pageContent = createEmptyPageContent();

  // Counter to keep track of children in pageContent
  let childrenCounter = 0;

  // Iterate over paragraphs
  paragraphs.forEach((paragraph) => {
    const trimmedParagraph = paragraph.trim();

    if (trimmedParagraph !== '') {
      const slateJSNode = {
        type: "normal",
        children: [
          {
            text: trimmedParagraph,
          },
        ],
      };

      // Check if childrenCounter reaches 27
      if (childrenCounter === 27) {
        // Add the current pageContent to slateJSContent
        slateJSContent.push(pageContent);

        // Create a new pageContent with an empty children array
        pageContent = createEmptyPageContent();

        // Reset the childrenCounter
        childrenCounter = 0;
      }

      // Push slateJSNode to pageContent
      pageContent.children.push(slateJSNode);
      childrenCounter += 1;
    }
  });

  // Add the last pageContent to slateJSContent
  if (pageContent.children.length > 0) {
    slateJSContent.push(pageContent);
  }

  return slateJSContent;

};
