import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './StashModal.css';
import { getCurrentUser, getTimeDifferenceFromDate } from 'src/utils/helpers';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
 maxWidth: 550,
  width:"100%",
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 0,
};

export default function StashModal({ stashInputData, setStashInputData, script, updateScript }) {
  const user = getCurrentUser();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const timeDiiferece = getTimeDifferenceFromDate(script?.updatedAt);
  return (
    <div>
      <Typography
        onClick={handleOpen}
        sx={{
          backgroundColor: '#00017806',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          fontSize: '.75rem',
          fontFamily: 'inter',
          fontWeight: '600',
        }}
      >
        {' '}
        {user?.name || ''}
        <span className="right-user-time"> {timeDiiferece || ''}</span>
      </Typography>

      <Box
        onClick={handleOpen}
        className="dark-bg"
        sx={{ backgroundColor: '#fff', padding: '1rem', fontSize: '.875rem' }}
      >
        {stashInputData}
      </Box>
      <Modal
        className="outline"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          '& .MuiBox-root:focus': { border: '1px solid purple' },
          '& .MuiBox-root:active': { border: '1px solid purple' },
        }}
      >
        <Box sx={style}>
          <Box className="dark-bg">
            {/* <input value={stashInputData} onChange={(e)=>setStashInputData(e.target.value)} className="stash-input-style " /> */}
            <textarea
              value={stashInputData}
              onChange={(e) => {
                setStashInputData(e.target.value);
              }}
              onBlur={(e) => {
                updateScript({ stashText: e.target.value });
              }}
              className="stash-beautiful-textarea dark-bg dark-text"
              placeholder="Enter your text here..."
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
