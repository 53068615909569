import { Box, Divider, Popover, Typography } from '@mui/material';
import React from 'react';
import Iconify from 'src/components/iconify';
import AlternateDraftModal from '../../LeftDrawer/components/AlternateDraftModal';
import SnapDrawer from '../../LeftDrawer/SnapshotDrawer/SnapDrawer';

const MainDraftPopover = ({script, updateScriptApi}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Box display={'flex'} aria-describedby={id} onClick={handleClick}>
        <h3 className="scren-main">Main Draft</h3>{' '}
        <Iconify sx={{ marginLeft: '2px',color:"#000" }} icon="eva:arrow-ios-downward-fill" width={16} height={24} />
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ zIndex: 99 }}
      >
        <Box
          sx={{
            cursor:"pointer",
            width: '180px',
            maxWidth: 360,
            backgroundColor: '',
            // color: "white",
            padding: '10px',
          }}
        >
          {/* <Typography mt={'5px'} variant="body1" sx={{ fontSize: '.9rem' }} onClick={handleClose}>
            Rename
          </Typography> */}

          <SnapDrawer position={"top"} handleClose={handleClose} script={script} 
                    updateScriptApi={updateScriptApi} />
        
          <AlternateDraftModal position={"top"} script={script} 
                    updateScriptApi={updateScriptApi} />

          {/* <Divider />
          <Typography mt={'10px'} variant="body1" sx={{ fontSize: '.9rem' }} onClick={handleClose}>
            List one
          </Typography> */}
        </Box>
      </Popover>
    </>
  );
};

export default MainDraftPopover;
