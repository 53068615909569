import { createContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { SERVER_BASE_URL } from 'src/config';

import { useSnackbar } from 'notistack';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  error: '',
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, error } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      error,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    error: '',
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  forgetPassword: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  loading: false
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false)
  // const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken') || "";

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const user = JSON.parse(window.localStorage.getItem('user')) || null;
          const response = await axios.post(`${SERVER_BASE_URL}/api/auth/getUser`, {
            id: user.id
          }, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const json = response.data || null;
          if (json?.success) {
            localStorage.setItem('user', JSON.stringify(json?.data));
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user: json?.data || null,
                error: '',
              },
            });
          }

        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              error: '',
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            error: err.error,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password, enqueueSnackbar) => {
    try {
      setLoading(true)
      const response = await axios.post(`${SERVER_BASE_URL}/api/auth/login`, {
        email,
        password,
      });
      const json = response.data || null;
      if (json?.success) {
        const user = json?.data || '';
        const token = json?.data?.token || '';
        localStorage.setItem('user', JSON.stringify(user));
        enqueueSnackbar('Login success', {
          variant: 'success',
        });
        setSession(token);
        setLoading(false);
        dispatch({
          type: 'LOGIN',
          payload: {
            user,
          },
        });
      } else {
        setLoading(false);
        const message = <div>
          <p>Invalid credentials!</p>
          <p>But luckily email was found in our database.</p>
          <p>Please make sure your login method.</p>
        </div>
        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
      });
    }

  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/user/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const forgetPassword = async (email) => {
    const response = await axios.post('/api/user/forget-password', {
      email,
    });
    return response.data;
  };

  const resetPassword = async (password, token) => {
    const response = await axios.post(`/api/user/reset-password/${token}`, {
      password,
      passwordConfirm: password,
    });
    console.log(response);
    return {
      statusCode: response.status,
      message: response.data.message,
    };
  };

  const logout = async () => {
    setSession(null);
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('accessToken');
    window.localStorage.clear();
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        forgetPassword,
        resetPassword,
        loading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
