import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useFetch } from 'src/hooks/useFetch';
import Typography from '@mui/material/Typography';
import { Grid, Box } from '@mui/material';
import CourierprimeDropdown from '../../../courierprimedropdown/CourierprimeDropdown';

const Transition = ({ script, refreshData }) => {
  const ELEMENT_KEY = 'Transition';

  const { id } = useParams();

  const [updateScript] = useFetch(`api/scripts/${id}`, ``, '', 'PATCH', false);

  const [state, setState] = useState({
    label: ELEMENT_KEY,
    spaceBefore: '',
    spaceAfter: '',
    shortCut: '',
    mode: '',
    nextElement: ELEMENT_KEY,
    timesOfDay: '',
    config: null, // PASS ANY JSON DATA IN THIS FIELD
  });

  useEffect(() => {
    if (script) {
      const currentElement = script?.elements?.find((element) => element.label === ELEMENT_KEY);
      if (currentElement) {
        setState({
          label: currentElement.label || ELEMENT_KEY,
          spaceBefore: currentElement.spaceBefore || '',
          spaceAfter: currentElement.spaceAfter || '',
          shortCut: currentElement.shortCut || '',
          mode: currentElement.mode || '',
          nextElement: currentElement.nextElement || ELEMENT_KEY,
          timesOfDay: currentElement.timesOfDay || '',
          config: currentElement.config || '',
        });
      }
    }
  }, [script]);

  const handleUpdateScript = async (chagedKey, changedValue) => {
    try {
      const payload = { ...state };
      if (chagedKey) {
        payload[chagedKey] = changedValue;
      }

      const updatedElements = findAndReplaceElement(script.elements, payload);

      const response = await updateScript({ elements: updatedElements });
      if (response) refreshData();
    } catch (error) {
      console.log(error);
    }
  };

  // find and replace element from the array
  const findAndReplaceElement = (array, element) => {
    if (!array || array?.length === 0) return [element];
    const index = array?.findIndex((ele) => ele?.label === element?.label);
    if (index !== -1) {
      array[index] = element;
    } else {
      array.push(element);
    }
    return array;
  };

  return (
    <>
      <Grid mb={'15px'} container>
        <Grid item xs={12}>
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'30px'}
            sx={{ boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)' }}
            width={'100%'}
          >
            <Box fontSize={'.75rem'} height={'30px'} width={'100%'} display={'flex'} justifyContent={''}>
              <Box
                sx={{
                  backgroundColor: '#ffd8a8',
                  width: '20%',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#e8590c',
                }}
              >
                1.5
              </Box>
              <Box
                sx={{
                  backgroundColor: '#a5d8ff',
                  width: '60%',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#1971c2',
                }}
              >
                characters
              </Box>
              <Box
                sx={{
                  backgroundColor: '#ffd8a8',
                  width: '20%',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#e8590c',
                }}
              >
                1.5
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Label
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography fontSize={'.875rem'} sx={{ opacity: '0.8', color: '#ccc', fontFamily: 'inter' }}>
            {ELEMENT_KEY}
          </Typography>
        </Grid>
      </Grid>
      <Grid mt={'15px'} container>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Spacing before
          </Typography>
        </Grid>
        <Grid item md={6}>
          <CourierprimeDropdown
            name={'spacingbefore'}
            option1={'0 lines'}
            option2={'1 line'}
            option3={'1 1/2 lines'}
            option4={'2 lines'}
            option5={'page break'}
            onChange={(value) => handleUpdateScript('spaceBefore', value)}
            value={state.spaceBefore}
            onBlur={(value) => {
              handleUpdateScript('spaceBefore', value);
            }}
          />
        </Grid>
      </Grid>

      <Grid mt={'15px'} container>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Shortcut
          </Typography>
        </Grid>
        <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} item md={6}>
          <Typography fontWeight={'.875rem'}>Ctrl+</Typography>
          <CourierprimeDropdown
            name={'shortcut'}
            option1={'1'}
            option2={'2'}
            option3={'3'}
            option4={'4'}
            option5={'5'}
            onChange={(value) => handleUpdateScript('shortCut', value)}
            value={state.shortCut}
            onBlur={(value) => {
              handleUpdateScript('shortCut', value);
            }}
          />
        </Grid>
      </Grid>

      <Grid mt={'15px'} container>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Element mode shortcut
          </Typography>
        </Grid>
        <Grid item md={6}>
          <CourierprimeDropdown
            name={'elementmodeshortcut'}
            option1={'A'}
            option2={'B'}
            option3={'C'}
            option4={'D'}
            option5={'E'}
            option6={'F'}
            option7={'G'}
            onChange={(value) => handleUpdateScript('mode', value)}
            value={state.mode}
            onBlur={(value) => {
              handleUpdateScript('mode', value);
            }}
          />
        </Grid>
      </Grid>

      <Grid mt={'15px'} container>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Next element
          </Typography>
        </Grid>
        <Grid item md={6}>
          <CourierprimeDropdown
            name={'nextelement'}
            option1={'Scene Heading'}
            option2={'Action'}
            option3={'Character'}
            option4={'parenthetical'}
            option5={'Dialogue'}
            option6={'Shot'}
            onChange={(value) => handleUpdateScript('nextElement', value)}
            value={state.nextElement}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Transition;
