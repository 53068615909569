import * as React from 'react';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import './PageLayoutModal.css';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  maxWidth:500,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 2,
};



export default function PageLayoutModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Box onClick={handleOpen} className="vertical-change">
        <p className="pagelay-first">Auto-adjust layout to reach page goal</p>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography mb={'25px'} sx={{ fontSize: '1.5rem',fontFamily:"inter"}}>
            Auto-adjust layout
          </Typography>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography mb={'10px'} sx={{ fontSize: '1rem' }}>
              Pages/target
            </Typography>
            <Typography mb={'25px'} sx={{ fontSize: '1rem' }}>
              1/100 <span className="page-m-tag">-109</span>
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: '.875rem' }}>Decrease lines per page</Typography>
            <Typography sx={{ fontSize: '.875rem' }}>54 ⇾ 52 lines</Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: '.875rem' }}>Increase scene spacing</Typography>
            <Typography sx={{ fontSize: '.875rem' }}>1 ⇾ 2 lines</Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: '.875rem' }}>Decrease dialogue width</Typography>
            <Typography sx={{ fontSize: '.875rem' }}>35 ⇾ 32 characters </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: '.875rem' }}>Decrease action width</Typography>
            <Typography sx={{ fontSize: '.875rem' }}>60 ⇾ 58 characters </Typography>
          </Box>
          <Box mt={'10px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: '1rem' }}>Pages after</Typography>
            <Typography sx={{ fontSize: '1rem' }}>
              1 <span className="page-m-tag">-109</span>
            </Typography>
          </Box>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Box
              mt={'30px'}
              display={'flex'}
            //   width={'100%'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              gap={'10px'}
            >
              <Typography
                onClick={handleClose}
                sx={{
                  textAlign: 'center',
                //   width: '30%',
                  padding: '.5rem 2rem',
                  backgroundColor: '#fe6d291a',
                  borderRadius: '.25rem',
                  fontSize: '.875rem',
                  '&:hover': {
                    backgroundColor: '#00017806',
                  },
                }}
              >
                Cancel
              </Typography>
              <Typography
                sx={{
                  textAlign: 'center',
                  width: '70%',
                  padding: '.5rem 3rem',
                  backgroundColor: '#fe6d29',
                  color: '#fff',
                  borderRadius: '.25rem',
                  fontSize: '.875rem',
                  '&:hover': {
                    backgroundColor: '#d0551c',
                  },
                }}
              >
                Adjust Layout
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
