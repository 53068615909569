/* eslint-disable */
import {
  faImage,
  faUser,
  faArrowRightFromBracket,
  faComment,
  faPersonWalking,
  faBookBookmark,
  faCogs,
  faComments,
  faMicrophone,
} from '@fortawesome/free-solid-svg-icons';
import { Editor, Transforms, Text, Element as SlateElement, Range, Point } from 'slate';
import { useFocused, useSelected, useSlate } from 'slate-react';
import { Input } from 'reactstrap';
import ReactDOM from 'react-dom';
import { DuoDialogueCharacters, LIST_TYPES, TEXT_ALIGN_TYPES, artifactList, blocks } from './constants';
import {
  Heading,
  Action,
  Dialogue,
  Character,
  Parenthetical,
  Transition,
  PageBreakElement,
  PageNumber,
  GettingStartedElement,
  DuoDialogue,
  VoiceMessage,
} from '../components/slate-editor/customElements';
import { Button, Icon, IconToolbar } from '../components/slate-editor/Components';
import { getCommentThreadsOnTextNode } from './EditorCommentUtils';
import CommentedText from '../components/slate-editor/commenting/CommentedText';
import { Tooltip } from '@mui/material';
import axios from 'axios';
import Iconify from 'src/components/iconify';
import { SERVER_BASE_URL } from 'src/config';
import { RiBold, RiDoubleQuotesL, RiItalic, RiStrikethrough, RiUnderline } from "react-icons/ri";
import { LuAlignCenter, LuAlignJustify, LuAlignLeft, LuAlignRight, LuHighlighter } from "react-icons/lu";
import { RxLetterCaseCapitalize, RxLetterCaseUppercase } from "react-icons/rx";
import { PiChatsTeardropDuotone } from "react-icons/pi";

export const Paragraph = (props) => {
  return <p {...props.attributes}>{props.children}</p>;
};

export const renderElement = ({ element, ...props }) => {
  const block = blocks.find((block) => block.type == element.type);
  if (block) {
    return block.renderBlock({ element, ...props });
  } else {
    return <Paragraph element={element} {...props} />;
  }
};

export const getIcon = (type) => {
  switch (type) {
    case 'sceneHeading':
      return faImage;
    case 'action':
      return faPersonWalking;
    case 'character':
      return faUser;
    case 'parenthetical':
      return faArrowRightFromBracket;
    case 'transition':
      return faBookBookmark;
    case 'dialogue':
      return faComment;
    case 'duo-dialogue':
      return faComments;
    case 'voice-dialogue':
      return faMicrophone;
    default:
      return faCogs;
  }
};

export const toggleBlock = (editor, format) => {
  console.log('format', format);
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes(editor, newProperties);
  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const getLastNode = (editor) => {
  const lastNode = editor.children[editor.children.length - 1];

  if (lastNode) {
    return lastNode;
  }

  return null;
};

export const FormatButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button reversed active={isMarkActive(editor, format)} onClick={() => toggleMark(editor, format)}>
      <Icon>{icon}</Icon>
    </Button>
  );
};

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const isCursorOnBlock = (editor) => {
  const { selection } = editor;
  if (!selection) return false;
  const temp = Array.from(
    Editor.nodes(editor, {
      at: Editor.range(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n),
    })
  );
  let [match] = temp;
  return selection.anchor.path[0] == match[1][0];
};

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};


const Mention = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  const style = {
   
    boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
  }
  // See if our empty text child has any styling marks applied and apply those
  if (element.children[0].bold) {
    style.fontWeight = 'bold'
  }
  if (element.children[0].italic) {
    style.fontStyle = 'italic'
  }
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element.character.replace(' ', '-')}`}
      style={style}
      className='suggestedMention'
    >
      {element.character}
      {children}
    </span>
  )
}

export const Element = ({ ...props }) => {
  const { attributes, children, element } = props;
  const style = {
    textAlign: element.align,
    paddingTop: '5px',
    paddingBottom: '5px',
    position: 'relative',
    display: element.children[0].text == '' && 'flex',
    // display: 'flex',
  };
  switch (element.type) {
    case 'mention':
      return <Mention {...props} />
    case 'block-quote':
      return (
        <blockquote style={style} attributes={attributes}>
          {children}
        </blockquote>
      );
    case 'sceneHeading':
      return <Heading style={style} children={children} attributes={attributes} />;
    case 'action':
      return <Action style={style} children={children} attributes={attributes} />;
    case 'character':
      return <Character style={style} children={children} attributes={attributes} />;
    case 'page-break':
      return <PageBreakElement attributes={attributes}>{children}</PageBreakElement>;
    case 'parenthetical':
      return <Parenthetical style={style} children={children} attributes={attributes} />;
    case 'transition':
      return <Transition style={style} children={children} attributes={attributes} />;
    case 'dialogue':
      return <Dialogue style={style} children={children} attributes={attributes} />;
    case 'table':
      return (
        <table>
          <tbody {...attributes}>{children}</tbody>
        </table>
      )
    case 'table-row':
      return <tr {...attributes}>{children}</tr>
    case 'table-cell':
      return <td {...attributes}>{children}</td>
    case 'page-number':
      return <PageNumber style={style} children={children} attributes={attributes} />;
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case 'getting-started-element':
      return <GettingStartedElement children={children} attributes={attributes} />;
    case 'voice-message': 
      return  <VoiceMessage style={style} children={children} attributes={attributes} element={element} />;
    
    default:
      return (
        <span style={style} {...attributes}>
          {children}
        </span>
      );
  }
};

export const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.range(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
    })
  );
  return !!match;
};

export const Leaf = ({ attributes, children, leaf, scriptData }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.highlight) {
    children = <mark>{children}</mark>;
  }

  if (leaf.capitalize) {
    children = <span style={{ textTransform: 'Capitalize' }}>{children}</span>;
  }

  if (leaf.uppercase) {
    children = <span style={{ textTransform: 'Uppercase' }}>{children}</span>;
  }

  if (leaf.color) {
    children = <span style={{ color: leaf.color || 'red' }}>{children}</span>;
  }

  if (leaf.strikeout) {
    children = <span style={{ textDecoration: 'line-through' }}>{children}</span>;
  }

  if (leaf.blockquote) {
    children = <span style={{ borderLeft: '1px solid #ccc', paddingLeft: '10px' }}>{children}</span>;
  }

  const commentThreads = getCommentThreadsOnTextNode(leaf);

  if (commentThreads.size > 0) {
    return (
      <CommentedText {...attributes} commentThreads={commentThreads} textNode={leaf}>
        {children}
      </CommentedText>
    );
  }

  return <span {...attributes}>{children}</span>;
};



export const returnIcon = (icon) => {
  return icon === 'format_bold' ? <RiBold /> : 
         icon === 'format_italic' ? <RiItalic /> : 
         icon === 'format_underlined' ? <RiUnderline /> :
         icon === 'highlight' ? <LuHighlighter /> :
         icon === 'strikethrough_s' ? <RiStrikethrough /> :
         icon === 'text_fields' ? <RxLetterCaseCapitalize /> :
         icon === 'title' ? <RxLetterCaseUppercase /> :
         icon === 'format_quote' ? <RiDoubleQuotesL /> :
         icon === 'format_align_left' ? <LuAlignLeft /> :
         icon === 'format_align_center' ? <LuAlignCenter /> :
         icon === 'format_align_right' ? <LuAlignRight /> :
         icon === 'format_align_justify' ? <LuAlignJustify /> :
         icon
}

export const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

export const BlockButtonToolbar = ({ format, icon, tooltip }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {tooltip ?
        <Tooltip title={tooltip} arrow placement='bottom-end'>
          <IconToolbar>
            {returnIcon(icon)}
          </IconToolbar>
        </Tooltip>
        : <IconToolbar>{icon}</IconToolbar>
      }
    </Button>
  );
};

export const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

export const MarkButtonToolbar = ({ format, icon, tooltip }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {tooltip ?
        <Tooltip title={tooltip} arrow placement='bottom-end'>
          <IconToolbar>
            {returnIcon(icon)}          
          </IconToolbar>
        </Tooltip> : <IconToolbar>{icon}</IconToolbar>}
    </Button>
  );
};

export const DDButtonToolbar = ({ tooltip }) => {
  const editor = useSlate();
  const { selection } = editor

  const addDuoDialogue = () => {
  if (selection && Range.isCollapsed(selection)) {
    const [cell] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        (n.type !== 'getting-started' || n.type !== 'getting-started-element'),
    })
    if (cell.length > 0 && cell[0].type === 'page') {
      Transforms.insertNodes(
        editor,
        DuoDialogueCharacters,
        { at: Editor.end(editor), select: true }
      );
    }
  }
}

  return (
    <Button
      onClick={(event) => {
        event.preventDefault();
        addDuoDialogue();
      }}
    >
      
        <Tooltip title={tooltip} arrow placement='bottom-end'>
          <IconToolbar>
          <PiChatsTeardropDuotone /> 
          </IconToolbar>
        </Tooltip>
    </Button>
  );
};

export const CustomFormatMain = ({ }) => {
  const editor = useSlate();

  return (
    <Input
      onChange={(event) => {
        toggleBlock(editor, event.target.value);
      }}
      type="select"
      style={{
        width: '10%',
        height: '30px',
        fontSize: '12px',
      }}
    >
      {artifactList.map((item, index) => {
        return (
          <option key={'rondom101' + index} value={item.value}>
            {item.name}
          </option>
        );
      })}
    </Input>
  );
};

export const Portal = ({ children }) => {
  return typeof document === 'object' ? ReactDOM.createPortal(children, document.body) : null;
};

export function getMarkForCommentThreadID(threadID) {
  return `commentThread_${threadID}`;
}

export function getFirstTextNodeAtSelection(editor, selection) {
  const selectionForNode = selection ?? editor.selection;

  if (selectionForNode == null) {
    return null;
  }

  const textNodeEntry = Editor.nodes(editor, {
    at: selectionForNode,
    mode: 'lowest',
  }).next().value;
  return textNodeEntry != null && Text.isText(textNodeEntry[0]) ? textNodeEntry[0] : null;
}

export const nameInitials = (name) => {
  if (!name) return '';

  const names = name.split(' ');
  const firstName = names[0];
  const lastName = names.length > 1 ? names[names.length - 1] : '';

  if (firstName && lastName) {
    return firstName.charAt(0) + lastName.charAt(0);
  } else if (firstName) {
    return firstName.charAt(0);
  } else {
    return '';
  }
};

export const changeEditorThemeMode = (mode) => {
  const baseScreenElements = document.getElementsByTagName('body');
  if (mode === 'dark' || (mode === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    for (const element of baseScreenElements) {
      element.classList.add('dark-mode');
    }
  }
  else {
    for (const element of baseScreenElements) {
      element.classList.remove('dark-mode');
    }
  }
}


export const getLogoPath = () => {
  const bodyElement = document.getElementsByTagName('body')[0];
  const isDarkMode = bodyElement.classList.contains('dark-mode');

  // Define the paths for the light and dark mode logos
  const lightModeLogoPath = './logo/logo-light.PNG';
  const darkModeLogoPath = './logo/logo-dark.PNG';

  // Determine the logo path based on the presence of 'dark-mode' class
  const logoPath = isDarkMode ? lightModeLogoPath : darkModeLogoPath;

  return logoPath;
};

const getUserDetails = async (accessToken) => {
  try {
    const response = await axios.get('https://www.googleapis.com/oauth2/v2/userinfo', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user details:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const googleAuthenticator = async (code) => {
  try {
    // Exchange the authorization code for tokens
    const response = await axios.post('https://oauth2.googleapis.com/token', {
      code,
      client_id: process.env.REACT_APP_GG_CLIENT_ID,
      client_secret: process.env.REACT_APP_GG_SECRET_ID,
      redirect_uri: "https://app.screnpla.com", // Should match the one used during authorization
      grant_type: 'authorization_code',
    });
    const { access_token: accessToken } = response.data;
    const userDetails = await getUserDetails(accessToken);
    return userDetails;

  } catch (error) {
    console.error(error);
  }
}

export const disallowedIconsOn = (type) => {
  return (type === 'getting-started-element' || type === 'table' || type === 'table-row' || type === 'table-cell')
}

export const getFileExtension = (fileName) => {
  const parts = fileName.split('.');
  if (parts.length > 1) {
    return parts[parts.length - 1].toLowerCase();
  }
  return null; // No extension found
};

export const getFileNameWithoutExtension = (fileName) => {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    return fileName.slice(0, lastDotIndex);
  }
  return fileName; // No extension found
};


export const selectUserImage = (user, enqueueSnackbar) => {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'image/*';
  input.onchange = async () => {
    const file = input.files[0];
    // convert file to base64
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const base64 = reader.result;
      const data = { avatar: base64 };
      await fetch(`${SERVER_BASE_URL}/api/user/${user?.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.success) {
            enqueueSnackbar('Image updated successfully', { variant: 'success' });
            localStorage.setItem('user', JSON.stringify(json?.data));
            window.location.reload();
          } else {
            enqueueSnackbar(json?.message || 'Something went wrong while updating the user image', { variant: 'error' });
          }
        });
    };
  };
  input.click();
}




export const enableScriptNotification = async (e, user, enqueueSnackbar) => {
  try {
    const response = await fetch(`${SERVER_BASE_URL}/api/user/${user?.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ scriptNotifications: e ? 'enabled' : 'disabled'}),
    });
    const json = await response.json();

    if (json.success) {
      enqueueSnackbar('Script notification updated successfully.', { variant: 'success' });
      localStorage.setItem('user', JSON.stringify(json?.data));
    } else {
      enqueueSnackbar(response?.message || 'Something went wrong while updating the user script notification', {
        variant: 'error',
      });
    }
  }
  catch (error) {
    enqueueSnackbar('Something went wrong while updating the user script notification', {
      variant: 'error',
    });
  }
}


export const sendCommentNotification = async (body) => {
  try {
    const response = await fetch(`${SERVER_BASE_URL}/api/scripts/scriptCommented`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const json = await response.json();
    console.log('json', json);
  }
  catch (error) {
    console.log('error', error);
  }
}



export const verifyEmailAddress = async (body) => {
  try {
    const response = await fetch(`${SERVER_BASE_URL}/api/auth/verifyEmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const json = await response.json();
    return json;
  }
  catch (error) {
    console.log('error', error);
  }
}


export const writingScheduleEmail = async (body) => {
  try {
    const response = await fetch(`${SERVER_BASE_URL}/api/scripts/writingSchedule`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const json = await response.json();
    return json;
  }
  catch (error) {
    console.log('error', error);
  }
}

export const isNoneOfSpecialKeys = (key) => {
  switch (key) {
    case 'Tab':
    case 'ArrowDown':
    case 'ArrowUp':
    case 'ArrowLeft':
    case 'ArrowRight':
    case 'Backspace':
    case 'Delete':
    case 'Shift':
    case 'Control':
    case 'Meta':
    case 'Alt':
      return false;
    default:
      return true;
  }
}


export const extractAudioUrls = (content) => {
  const audioUrls = [];

  const extractUrls = (nodes) => {
      nodes.forEach(node => {
          if (node.type === 'voice-message') {
              const voiceMessageElement = node.children.find(child => child.type === 'voice-message-element');
              if (voiceMessageElement && voiceMessageElement.url) {
                  audioUrls.push(voiceMessageElement.url);
              }
          } else if (node.children) {
              extractUrls(node.children);
          }
      });
  };

  extractUrls(content);
  return audioUrls;
};



export const playAudioSequentially = (urls) => {
  let index = 0;

  const playNext = () => {
      if (index < urls.length) {
          const audio = new Audio(urls[index]);
          audio.play();
          audio.onended = () => {
              index++;
              playNext();
          };
      }
  };

  playNext();
};

export const formatEditorContent = (content) => {
  // Initialize an array to hold the formatted content
  const formattedContent = [];

  // Helper function to recursively extract and flatten children of 'page' type nodes
  const formatContent = (nodes) => {
    nodes.forEach((node) => {
      // If node is a 'page', push its children directly to the result array
      if (node.type === 'page') {
        formattedContent.push(...node.children); // Spread the children of 'page'
      } else {
        // If the node doesn't have type 'page', push the original node
        formattedContent.push(node);
      }
    });
  };

  // Start formatting the content from the root
  formatContent(content);

  // Return the formatted array
  return formattedContent;
};

export const withEmbeds = editor => {
  const { isVoid } = editor
  editor.isVoid = element => (element.type === 'voice-message' ? true : isVoid(element))
  return editor
}
