import * as React from 'react';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import {MenuItem,} from '@mui/material';

import './DraftRenameModal.css';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border:"none",
  outline:"none",   
  p: 4,
};



export default function DraftRenameModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
    
    <MenuItem onClick={()=>{handleOpen()}} >

    <Typography  variant="body1" sx={{ fontSize: '.9rem' }}>
                Rename
              </Typography>

       </MenuItem>



      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
      <Box>
      <p className='d-rename-head'>Rename "Main Draft</p>

<Box mt={"10px"}>
<input className="input-style" />
</Box>
      
      <Box mt={"10px"} display={'flex'} alignItems={'center'} gap={"20px"}>
              <button className="alt-create">Ok</button>
              <button className="alt-cancel" onClick={handleClose}>Cancel</button>
            </Box>
      
      </Box>
        </Box>
      </Modal>
    </div>
  );
}
