import React, { useEffect } from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import './Account.css';
import { useFetch } from 'src/hooks/useFetch';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { writingSchedule } from 'src/utils/constants';
import { debounce } from 'lodash';
import { writingScheduleEmail } from 'src/utils/common';

const ReminderDays = () => {
  const localUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  const [days, setDays] = React.useState(localUser?.scheduleWriting.length > 0 ? localUser?.scheduleWriting :  writingSchedule );

  const { enqueueSnackbar } = useSnackbar();
  const [updateUser] = useFetch(`api/user/${localUser?.id}`, ``, '', 'PATCH', false);
  
  const changeSchedule = async (days) => {
    const response = await updateUser({
      scheduleWriting: days,
    });
    if (response.success) {
      localStorage.setItem('user', JSON.stringify(response?.data));
      enqueueSnackbar('Writing Schedule Updated Successfully', { variant: 'success' });
    } else {
      enqueueSnackbar(response?.message || 'Something went wrong while updating the user writing schedule', {
        variant: 'error',
      });
    }
  };

  // Debounce the changeSchedule function
  const debouncedChangeSchedule = debounce(changeSchedule, 500);
  const debouncedWritingSchedule = debounce(writingScheduleEmail, 3000);

  const handleDayClick = (index) => {
    const updatedDays = [...days];
    updatedDays[index].checked = !updatedDays[index].checked;
    debouncedChangeSchedule(updatedDays);
    debouncedWritingSchedule({
      email: localUser?.email,
    })
    setDays(updatedDays);
  };

  const renderDays = days.map((day, index) => (
    <Grid
      key={day.name}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      item
      sx={6}
      md={1.7}
      onClick={() => handleDayClick(index)}
      style={{ cursor: 'pointer' }}
    >
      <Avatar
        className={day.checked ? 'avatar-active' : 'not-avatar'}
        sx={{
          fontWeight: '600',
          fontSize: '.75rem',
          width: '4rem',
          height: '4rem',
        }}
      >
        {day.name}
      </Avatar>
    </Grid>
  ));

  return (
    <>
      <Grid display={'flex'} justifyContent={'space-between'} mt={'15px'} container columnSpacing={0}>
        {renderDays}
      </Grid>

      <Grid mt={'10px'} container columnSpacing={2}>
        <Grid display={'flex'} gap={'10px'} item xs={12}>
          <Typography
            sx={{
              backgroundColor: '#00017806',
              color: '#9b9bae',
              padding: '2px .5rem',
              borderRadius: '.5rem',
              transition: 'all linear .5s',
              cursor: 'pointer',
              '&:hover': { backgroundColor: '#fe6d29', color: '#fff !important' },
            }}
            mt={'.25rem'}
            lineHeight={'1.25'}
            onClick={() => {
              debouncedChangeSchedule(days.map((day) => ({ ...day, checked: true })));
              debouncedWritingSchedule({
                email: localUser?.email,
              })
              setDays(days.map((day) => ({ ...day, checked: true })));
            }}
            fontSize={'.75rem'}
          >
            Every day
          </Typography>
          <Typography
            sx={{
              backgroundColor: '#00017806',
              color: '#9b9bae',
              padding: '2px .5rem',
              borderRadius: '.5rem',
              transition: 'all linear .5s',
              '&:hover': { backgroundColor: '#fe6d29', color: '#fff !important' },
              cursor: 'pointer',
            }}
            mt={'.25rem'}
            onClick={() => {
              debouncedChangeSchedule(days.map((day, index) => ({ ...day, checked: index < 5 })));
              debouncedWritingSchedule({
                email: localUser?.email,
              })
              setDays(days.map((day, index) => ({ ...day, checked: index < 5 })));
            }}
            lineHeight={'1.25'}
            fontSize={'.75rem'}
          >
            Weeks days
          </Typography>
          <Typography
            sx={{
              backgroundColor: '#00017806',
              color: '#9b9bae',
              padding: '2px .5rem',
              borderRadius: '.5rem',
              transition: 'all linear .5s',
              cursor: 'pointer',
              '&:hover': { backgroundColor: '#fe6d29', color: '#fff !important' },
            }}
            mt={'.25rem'}
            onClick={() => {
              debouncedChangeSchedule(days.map((day, index) => ({ ...day, checked: index > 4 })));
              debouncedWritingSchedule({
                email: localUser?.email,
              })
              setDays(days.map((day, index) => ({ ...day, checked: index > 4 })));
            }}
            lineHeight={'1.25'}
            fontSize={'.75rem'}
          >
            Weekends
          </Typography>
          <Typography
            sx={{
              backgroundColor: '#00017806',
              color: '#9b9bae',
              padding: '2px .5rem',
              borderRadius: '.5rem',
              transition: 'all linear .5s',
              '&:hover': { backgroundColor: '#fe6d29', color: '#fff !important' },
              cursor: 'pointer',
            }}
            mt={'.25rem'}
            lineHeight={'1.25'}
            fontSize={'.75rem'}
            onClick={() => {
              debouncedChangeSchedule(days.map((day) => ({ ...day, checked: false })));
              debouncedWritingSchedule({
                email: localUser?.email,
              })
              setDays(days.map((day) => ({ ...day, checked: false })));
            }}
          >
            Never
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ReminderDays;
