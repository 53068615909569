import React from 'react';
import { useSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import { getCurrentUser } from 'src/utils/helpers';
import Box from '@mui/material/Box';
import { SERVER_BASE_URL } from 'src/config';
import CheckIcon from '@mui/icons-material/Check';
import { Grid, Divider } from '@mui/material';
import BillingReferModal from './BillingReferModal';
import BillingGiftModal from './BillingGiftModal';

export const AvailablePlans = {
  Free: 'Free',
  Essential: 'Essential',
  Pro: 'Pro',
  Expired: 'Expired',
};

const Billing = () => {
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const user = getCurrentUser();
  const currentPlanName = user?.currentPlan?.name || 'Free';

  const handleUpgrade = async (plan) => {
    try {
      const isExists = Object.values(AvailablePlans).includes(plan);

      if (!isExists) {
        return;
      }
      setLoading(true);
      const res = await fetch(`${SERVER_BASE_URL}/api/user/${user.id}/checkout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ plan }),
      });
      const data = await res.json();
      if (data.success) {
        setLoading(false);
        const url = data?.url || null;
        if (url) {
          window.location.href = url;
        }
      } else {
        setLoading(false);
        enqueueSnackbar(data?.message || 'Something went wrong while fetching the stripe url', {
          variant: 'error',
        });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Somthing went wrong while getting a plan', {
        variant: 'error',
      });
    }
  };

  const isDisabled = (plan) => {
    if (loading) {
      return true;
    }
    if (plan === currentPlanName) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Typography className="bill-firsthead dark-text">Your current plan:</Typography>
      <Typography fontSize={'1.25rem'} className="bill-free dark-text">
        {currentPlanName}
      </Typography>
      <Typography fontSize={'.875rem'} className="bill-third" mt={'10px'}>
        {currentPlanName === AvailablePlans.Free
          ? 'Upgrade now to get access to the best screenwriting platform on the market.'
          : `You have all the ${currentPlanName} features. `}
      </Typography>

      {/* card */}
      <Grid mt={'20px'} container columnSpacing={3}>
        <Grid item xs={12} md={6}>
          <Box
            className={'light-bg'}
            sx={{ border: '1px solid #fe6d291a', padding: '1rem' }}
            width={'250px'}
            minHeight={'200px'}
          >
            <Typography mb={'1rem'} fontWeight={'700'} fontSize={'.875rem'}>
              Essentials
            </Typography>
            <Box>
              <p className="bill-cardprice">
                $49 <span className="bill-cardpricespan"> $69</span>
              </p>
              <p className="bill-cardfour">Renews at $69 annually</p>
              <p className="bill-cardDis">Discount expires in 11 days</p>
            </Box>
            <Box mt={'15px'}>
              <p className="bill-freeplus">Everything in Free, plus:</p>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Box>
                  <CheckIcon sx={{ color: '#2b8a3e' }} fontSize="5px" />
                </Box>
                <p className="bill-cardflex bill-cardflexone">
                  Advanced industry <br /> standard formatting
                </p>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Box>
                  <CheckIcon sx={{ color: '#2b8a3e' }} fontSize="5px" />
                </Box>
                <p className="bill-cardflex">Unlimited scripts</p>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Box>
                  <CheckIcon sx={{ color: '#2b8a3e' }} fontSize="5px" />
                </Box>
                <p className="bill-cardflex">Desktop & mobile apps</p>
              </Box>
            </Box>
            <Box mt={'2.4rem'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <button
                className="bill-cardbtn"
                disabled={currentPlanName === AvailablePlans.Essential || loading}
                style={{
                  cursor: !isDisabled(AvailablePlans.Essential) ? 'pointer' : 'not-allowed',
                  backgroundColor: !isDisabled(AvailablePlans.Essential) ? '#fe6d29' : 'gray',
                }}
                onClick={() => handleUpgrade(AvailablePlans.Essential)}
              >
                {' '}
                Upgrade to Essentials
              </button>
            </Box>
          </Box>
        </Grid>

        {/* card2 */}

        <Grid item xs={12} md={6}>
          <Box
            className={'light-bg'}
            sx={{ border: '1px solid #fe6d291a', padding: '1rem' }}
            width={'250px'}
            minHeight={'200px'}
          >
            <Typography mb={'1rem'} fontWeight={'700'} fontSize={'.875rem'}>
              PRO
            </Typography>
            <Box>
              <p className="bill-cardprice">
                $79 <span className="bill-cardpricespan"> $99</span>
              </p>
              <p className="bill-cardfour">Renews at $99 annually</p>
              <p className="bill-cardDis">Discount expires in 11 days</p>
            </Box>
            <Box mt={'15px'}>
              <p className="bill-freeplus">Everything in Essentials, plus:</p>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Box>
                  <CheckIcon sx={{ color: '#2b8a3e' }} fontSize="5px" />
                </Box>
                <p className="bill-cardflex bill-cardflexone">
                  Real-time <br /> collaboration
                </p>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Box>
                  <CheckIcon sx={{ color: '#2b8a3e' }} fontSize="5px" />
                </Box>
                <p className="bill-cardflex">Outlining tools</p>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Box>
                  <CheckIcon sx={{ color: '#2b8a3e' }} fontSize="5px" />
                </Box>
                <p className="bill-cardflex">
                  History,Custom <br /> Formats and more!
                </p>
              </Box>
            </Box>
            <Box mt={'1.1rem'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <button
                className="bill-cardbtn"
                style={{
                  cursor: !isDisabled(AvailablePlans.Pro) ? 'pointer' : 'not-allowed',
                  backgroundColor: !isDisabled(AvailablePlans.Pro) ? '#fe6d29' : 'gray',
                }}
                disabled={currentPlanName === AvailablePlans.Pro || loading}
                onClick={() => handleUpgrade(AvailablePlans.Pro)}
              >
                {' '}
                Upgrade to Pro
              </button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid mt={'1rem'} container columnSpacing={2}>
        <Grid item md={6}>
          <p className="bill-redeem">Redeem a gift</p>
        </Grid>
        <Grid item md={6}>
          <BillingReferModal />
        </Grid>
      </Grid>

      <Box mt={'10px'}>
        <Divider />
      </Box>

      <Grid mt={'1rem'} display={'flex'} alignItems={'center'} container columnSpacing={2}>
        <Grid item md={6}>
          <p className="bill-subs">Gift a subscription</p>
        </Grid>
        <Grid item md={6}>
          <Box width={'200px'}>
            <BillingGiftModal />
            <p className="bill-99">
              $99 <span className="bill-79">$79</span>
            </p>
            <p className="bill-20of">$20 off in the first year!</p>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Billing;
