import React from 'react';
import { Grid } from '@mui/material';
import SidebarItems from '../desk/Sidebar/Sidebar';
import Account from '../desk/Sidebar/accounts/Account';

const AccountsComponent = () => (
  <>
    <Grid container>
      <SidebarItems />
      <Grid item xs={12} md={9} lg={9.8}>
        <Account />
      </Grid>
    </Grid>
  </>
);

export default AccountsComponent;
