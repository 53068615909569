import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Iconify from 'src/components/iconify';
import { Divider, Input, TextField } from '@mui/material';
import Switch from '@mui/material/Switch';

import styled from '@emotion/styled';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width:"90%",
  maxWidth: 550,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 4,
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 24,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 20,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(5px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(8px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#fe6d29',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#FFF',
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export default function WhatsNew({activeTab}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>

<Box
onClick={handleOpen}
                mt={'20px'}
                display={'flex'}
                alignItems={'center'}
                gap={'10px'}
                sx={{
                  padding: '0.5rem 1rem',
                  borderRadius: '.25rem',
                  backgroundColor: activeTab === 'whatsnew' ? '#fe6d291a' : '',
                  color: activeTab === 'whatsnew' ? '#fe6d29' : '',
                  cursor: 'pointer',
                }}
              >
                <Iconify sx={{ fontSize: '20px' }} icon="ri:vip-diamond-line" />
      <Typography  sx={{ fontSize: '.875rem', fontWeight: '600' }}>
        What's new?
      </Typography>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box mb={'10px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: '1.25rem', fontWeight: '600' }}>What's new?</Typography>

            <Box
            onClick={handleClose}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                width: '30px',
                height: '30px',
                borderRadius: '.25rem',
                '&:hover': { backgroundColor: '#fe6d291a' },
              }}
            >
              <Iconify sx={{ fontSize: '20px' }} icon="ant-design:close-outlined" />
            </Box>
          </Box>

          <Divider />

          <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
            <Typography mt={'10px'} sx={{ fontSize: '1.25rem', fontWeight: '600' }}>
              What's new?
            </Typography>
            <Typography mt={'10px'} sx={{ fontSize: '1.25rem', fontWeight: '600' }}>
              What's new?
            </Typography>
            <Typography mt={'10px'} sx={{ fontSize: '1.25rem', fontWeight: '600' }}>
              What's new?
            </Typography>
            <Typography mt={'10px'} sx={{ fontSize: '1.25rem', fontWeight: '600' }}>
              What's new?
            </Typography>
            <Typography mt={'10px'} sx={{ fontSize: '1.25rem', fontWeight: '600' }}>
              What's new?
            </Typography>
            <Typography mt={'10px'} sx={{ fontSize: '1.25rem', fontWeight: '600' }}>
              What's new?
            </Typography>
            <Typography mt={'10px'} sx={{ fontSize: '1.25rem', fontWeight: '600' }}>
              What's new?
            </Typography>
            <Typography mt={'10px'} sx={{ fontSize: '1.25rem', fontWeight: '600' }}>
              What's new?
            </Typography>
            <Typography mt={'10px'} sx={{ fontSize: '1.25rem', fontWeight: '600' }}>
              What's new?
            </Typography>
            <Typography mt={'10px'} sx={{ fontSize: '1.25rem', fontWeight: '600' }}>
              What's new?
            </Typography>
            <Typography mt={'10px'} sx={{ fontSize: '1.25rem', fontWeight: '600' }}>
              What's new?
            </Typography>
          </Box>

          <Box mt={'10px'} display={'flex'} alignItems={'center'} gap={'10px'}>
            <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
            <Typography sx={{ color: '#7b7c8d', fontSize: '.875rem' }}>Show after updates</Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
