import React, { useState } from 'react';
import { Box, Checkbox, Popover, Switch, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styled from '@emotion/styled';
import CustomAntSwitch from '../customantswitch/CustomAntSwitch';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 18,
  padding: 0,
  display: 'flex',
  borderRadius:"10px",
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 10, // You can adjust this value
      height: 10, // You can adjust this value
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#fe6d29',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    marginLeft:"2px",
    
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 13, // You can adjust this value
    height: 13, // You can adjust this value
    borderRadius: 10, // Adjust this value to maintain the button shape
    transition: theme.transitions.create(['width', 'height'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));


const NavChangePopover = () => {
  const [anchorElChange, setAnchorElChange] = React.useState(null);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [checkboxValue2, setCheckboxValue2] = useState(false);
  const [checkboxValue3, setCheckboxValue3] = useState(false);
  const [checkboxValue4, setCheckboxValue4] = useState(false);
  const handleClickChange = (event) => {
    setAnchorElChange(event.currentTarget);
  };
  const handleCloseChange = () => {
    setAnchorElChange(null);
  };
  const openChange = Boolean(anchorElChange);
  const idChange = openChange ? 'simple-popover' : undefined;

  const handleCheckboxChange = (event) => {
    const newValue = event.target.checked;
    setCheckboxValue(newValue);
  };
  const handleCheckboxChange2 = (event) => {
    const newValue = event.target.checked;
    setCheckboxValue2(newValue);
  };
  const handleCheckboxChange3 = (event) => {
    const newValue = event.target.checked;
    setCheckboxValue3(newValue);
  };
  const handleCheckboxChange4 = (event) => {
    const newValue = event.target.checked;
    setCheckboxValue4(newValue);
  };

  return (
    <>
      <button className="scren-change" aria-describedby={idChange} onClick={handleClickChange}>
        change
        <ArrowDropDownIcon aria-describedby={idChange} onClick={handleClickChange} />
        <span className="scren-dot">
        <AntSwitch sx={{ fontSize: '50px' }} defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
        </span>
      </button>

      <Popover
        id={idChange}
        open={openChange}
        anchorEl={anchorElChange}
        onClose={handleCloseChange}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            width: '350px',
            maxWidth: 360,
            backgroundColor: '',
            // color: "white",
            padding: '20px',
          }}
        >
          <Box display={'flex'} alignItems={'start'} justifyContent={'space-between'}>
          <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }}  onChange={handleCheckboxChange} checked={checkboxValue} />
            <Box>
              <Typography mt={'5px'} fontWeight={'600'} variant="body1" sx={{ fontSize: '.875rem' }}>
                Hide marks for me
              </Typography>
              <Typography sx={{ color: '#b2b3c4', fontSize: '.75rem' }} paragraph>
                It will keep marketing changes for others,but hide the change marks for you.
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'start'} justifyContent={'flex-start'}>
          <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }}  onChange={handleCheckboxChange2} checked={checkboxValue2} />
            <Box>
              <Typography
                fontWeight={'600'}
                mt={'10px'}
                variant="body1"
                sx={{ fontSize: '.875rem' }}
                onClick={() => {
                  handleCheckboxChange();
                }}
              >
                By writer
              </Typography>
              <Typography sx={{ color: '#b2b3c4', fontSize: '.75rem' }} paragraph>
                It will keep marketing changes for others,but hide the change marks for you.
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'start'} justifyContent={'flex-start'}>
          <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }}  onChange={handleCheckboxChange3} checked={checkboxValue3} />
            <Box>
              <Typography fontWeight={'600'} mb={'5px'} mt={'10px'} variant="body1" sx={{ fontSize: '.875rem' }}>
                By revision
              </Typography>
              <Typography sx={{ color: '#b2b3c4', fontSize: '.75rem' }} paragraph>
                Mark changes in blue
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'start'} justifyContent={'flex-start'}>
          <Checkbox sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }}  onChange={handleCheckboxChange4} checked={checkboxValue4} />
            <Box>
              <Typography fontWeight={'600'} mt={'10px'} variant="body1" sx={{ fontSize: '.875rem' }}>
                Simple
              </Typography>
              <Typography sx={{ color: '#b2b3c4', fontSize: '.75rem' }} paragraph>
                Mark changes in blue
              </Typography>
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default NavChangePopover;
