import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Iconify from 'src/components/iconify';
import { Divider, Input, TextField } from '@mui/material';
import Switch from '@mui/material/Switch';

import styled from '@emotion/styled';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width:"100%",
  maxWidth: 450,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 4,
};

export default function ProSubsModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Typography
      onClick={handleOpen} 
                  sx={{
                    fontSize: '.875rem',
                    padding: '1rem 2rem',
                    fontFamily:"inter",
                    fontWeight:"600",
                    backgroundColor: '#fe6d29',
                    borderRadius: '.25rem',
                    color: '#fff',
                    cursor:"pointer",
                    "&:hover":{backgroundColor:"#d0551c"}
                  }}
                >
                  Give a pro subscription as a gift
                </Typography>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ fontSize: '1.25rem', fontWeight: '600' }}>Give the gift of screnpla</Typography>
          <Typography
            mt={'25px'}
            sx={{ fontSize: '1rem', color: '#7B7C8A', padding: '0', paddingBottom: '0.5rem', lineHeight: '1.25' }}
          >
            Clicking okat will take you to checkout for a Pro gift subscription for $79 (off $29 in the first year).You will receive a secret gift-code that you can share. 
          </Typography>
          <Box display={'flex'} gap={"10px"} alignItems={'center'}>
          <Typography
            mt={'15px'}
            p={'0.5rem 2rem'}
            sx={{
              '&:hover': { backgroundColor: '#d0551c' },
              fontSize: '.875rem',
              fontWeight: '600',
              backgroundColor: '#fe6d29',
              color: '#fff',
              borderRadius: '.25rem',
              textAlign: 'center',
              cursor:"pointer",
            }}
          >
            Purchase a gift subscription
          </Typography>
          <Typography
          className="dark-text light-bg"
          onClick={handleClose}
            mt={'10px'}
            p={'0.5rem 2rem'}
            sx={{
              '&:hover': { backgroundColor: '#fe6d291a' },
              fontSize: '.875rem',
              fontWeight: '600',
              backgroundColor: '#00017806',
              color: '#333',
              borderRadius: '.25rem',
              textAlign: 'center',
              cursor:"pointer",
            }}
          >
            Cancel
          </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
