/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useFetch } from 'src/hooks/useFetch';
import Typography from '@mui/material/Typography';
import { Grid, Box, Divider } from '@mui/material';
import OffButtonGroup from '../../twobuttongroup/OffButtonGroup';
import CourierprimeDropdown from '../../courierprimedropdown/CourierprimeDropdown';

// eslint-disable-next-line react/prop-types
const Production = ({ script, refreshData }) => {
  const { id } = useParams();

  const [updateScript] = useFetch(`api/scripts/${id}`, ``, '', 'PATCH', false);
  const [state, setState] = useState({
    isLockedPages: false,
    showRighMargin: false,
    displayStyle: '6 > 6A > 6AA > 6AAA',
    prefix: 'NA',
    suffix: 'NA',
    zeroPadding: 'NA',
  });

  useEffect(() => {
    if (script) {
      setState({
        isLockedPages: script?.production?.isLockedPages || false,
        showRighMargin: script?.production?.showRighMargin || false,
        displayStyle: script?.production?.displayStyle || '6 > 6A > 6AA > 6AAA',
        prefix: script?.production?.prefix || 'NA',
        suffix: script?.production?.suffix || 'NA',
        zeroPadding: script?.production?.zeroPadding || 'NA',
      });
    }
  }, [script]);

  const handleUpdateScript = async (changeKey, changedKeyValue) => {
    try {
      const payload = { ...state };

      if (changeKey) {
        payload[changeKey] = changedKeyValue;
      }

      const response = await updateScript({ production: { ...payload } });
      if (response) refreshData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item md={6}>
          <Box display={'flex'} alignItems={'center'}>
            <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontWeight: '600' }}>
              Locked Pages
            </Typography>
            <Typography
              ml={'5px'}
              sx={{
                color: '#1971c2',
                backgroundColor: '#d0ebff',
                fontWeight: '600',
                fontSize: '0.75rem',
                fontFamlily: 'inter',
                padding: '0.25rem 0.5rem',
                borderRadius: '0.25rem',
              }}
            >
              Beta
            </Typography>
          </Box>
          {state.isLockedPages ? (
            <Typography fontSize={'.875rem'} sx={{ color: '#7b7c8d' }}>
              Pages are unlocked
            </Typography>
          ) : (
            <Typography fontSize={'.75rem'} sx={{ color: '#7b7c8d' }}>
              1 locked, 0 unlocked (locked 1 time(s))
            </Typography>
          )}
        </Grid>
        <Grid item md={6}>
          {state.isLockedPages ? (
            <Box
              onClick={() => {
                setState({ ...state, isLockedPages: false });
                handleUpdateScript('isLockedPages', false);
              }}
              className="vertical-change"
              sx={{ cursor: 'pointer' }}
            >
              <p>Lock pages</p>
            </Box>
          ) : (
            <Grid container columnSpacing={2}>
              <Grid item md={6}>
                <Box
                  onClick={() => {
                    setState({ ...state, isLockedPages: true });
                    handleUpdateScript('isLockedPages', true);
                  }}
                  className="vertical-change"
                  sx={{ cursor: 'pointer' }}
                >
                  <p>unLock pages</p>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box
                  onClick={() => {
                    setState({ ...state, isLockedPages: true });
                    handleUpdateScript('isLockedPages', true);
                  }}
                  className="vertical-change"
                  sx={{ cursor: 'pointer' }}
                >
                  <p>Re-Lock pages</p>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Box mt={'10px'}>
        <Divider />
      </Box>

      <Grid mt={'15px'} container>
        <Grid item xs={12}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontWeight: '600' }}>
            Scene numbers
          </Typography>
        </Grid>
      </Grid>

      <Grid mt={'15px'} container columnSpacing={2}>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontWeight: '600' }}>
            Also show in right margin
          </Typography>
        </Grid>
        <Grid item md={6}>
          <OffButtonGroup
            first={'Hide'}
            second={'Show'}
            active={state.showRighMargin ? 'Show' : 'Hide'}
            onChange={(value) => setState({ ...state, showRighMargin: value })}
            onBlur={(value) => {
              handleUpdateScript('showRighMargin', value);
            }}
          />
        </Grid>
      </Grid>

      <Grid mt={'15px'} container columnSpacing={2}>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontWeight: '600' }}>
            Display style
          </Typography>
        </Grid>
        <Grid item md={6}>
          <CourierprimeDropdown
            name={'display'}
            option1={'6 > 6A > 6AA > 6AAA'}
            option2={'6 > A6 > A6A > A6AA'}
            onChange={(value) => setState({ ...state, displayStyle: value })}
            value={state.displayStyle}
            onBlur={(value) => {
              handleUpdateScript('displayStyle', value);
            }}
          />
        </Grid>
      </Grid>

      <Grid mt={'15px'} container columnSpacing={2}>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontWeight: '600' }}>
            Prefix
          </Typography>
          <Typography fontSize={'.875rem'} sx={{ color: '#7b7c8d' }}>
            Prepended to every number
          </Typography>
        </Grid>
        <Grid item md={6}>
          <input
            className="doc-title light-bg dark-text"
            placeholder=""
            value={state.prefix}
            onChange={(e) => setState({ ...state, prefix: e.target.value })}
            onBlur={handleUpdateScript}
          />
        </Grid>
      </Grid>

      <Grid mt={'15px'} container columnSpacing={2}>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontWeight: '600' }}>
            Zero-padding
          </Typography>
          <Typography fontSize={'.875rem'} sx={{ color: '#7b7c8d' }}>
            Minimun total digits per scene number
          </Typography>
        </Grid>
        <Grid item md={6}>
          <input
            className="doc-title light-bg dark-text"
            placeholder=""
            value={state.zeroPadding}
            onChange={(e) => setState({ ...state, zeroPadding: e.target.value })}
            onBlur={handleUpdateScript}
          />
        </Grid>
      </Grid>

      <Grid mt={'10px'} container columnSpacing={2}>
        <Grid item md={4}>
          <Box className="vertical-change">
            <p>Assign missing</p>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box className="vertical-change">
            <p>Reassign all</p>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box className="vertical-change">
            <p>Clear</p>
          </Box>
        </Grid>
      </Grid>

      <Box mt={'10px'}>
        <Divider />
      </Box>

      <Grid mt={'15px'} container columnSpacing={2}>
        <Grid item xs={12}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontWeight: '600' }}>
            Dialog Numbers
          </Typography>
        </Grid>
      </Grid>

      <Grid mt={'10px'} container columnSpacing={2}>
        <Grid item md={4}>
          <Box className="vertical-change">
            <p>Assign missing</p>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box className="vertical-change">
            <p>Reassign all</p>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box className="vertical-change">
            <p>Clear</p>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Production;
