import { Box, Modal, Tooltip, Typography } from '@mui/material';
import React from 'react';
import Iconify from 'src/components/iconify';
import CardNotePopover from './components/CardNotePopover';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  minHeight: 560,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 2,
};

const CardNoteModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <>
        <Iconify onClick={handleOpen} sx={{ color: '#868e96' }} icon="gridicons:pages" />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Note on </Typography>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
              <Tooltip title="Open in right side" arrow>
                <Box
                  sx={{
                    width: '20px',
                    height: '20px',
                    '&:hover': { backgroundColor: '#00017806' },
                    borderRadius: '5px',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Iconify sx={{ color: '' }} icon="gg:arrow-right" />
                </Box>
                </Tooltip>
                
                <Box
                  sx={{
                    width: '20px',
                    height: '20px',
                    '&:hover': { backgroundColor: '#00017806' },
                    borderRadius: '5px',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                <CardNotePopover/>
                </Box>
                <Tooltip title="New note" arrow>
                <Box
                  sx={{
                    width: '20px',
                    height: '20px',
                    '&:hover': { backgroundColor: '#00017806' },
                    borderRadius: '5px',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Iconify sx={{ color: '' }} icon="majesticons:plus" />
                </Box>
                </Tooltip>
                <Box
                  onClick={handleClose}
                  sx={{
                    width: '20px',
                    height: '20px',
                    '&:hover': { backgroundColor: '#00017806' },
                    borderRadius: '5px',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Iconify sx={{ color: '' }} icon="ri:close-fill" />
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      </>
    </>
  );
};

export default CardNoteModal;
