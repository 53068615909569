import { Transforms,Element,Text, Editor} from 'slate'
import { ReactEditor } from 'slate-react'
// import toggleBlock from './toggle-block'


export const emptyPage ={type:'page',children:[{type:'paragraph',children:[{type:'text',text:''}]}]}

function withCustomNormalize(editor) {
  // can include custom normalisations--- 
 const {normalizeNode}=editor


 editor.normalizeNode = entry => {

  const [node, path] = entry

  if(Text.isText(node)) return normalizeNode(entry)

  // console.log(node);

  // if the node is Page
  if (Element.isElement(node) && node.type === 'page') {
    let PageNode;
    // afaik pageNode if inserted as new page is not available here as a dom node because it hasnt rendered yet
    try{
      PageNode= ReactEditor.toDOMNode(editor,node)
    }catch(e){
      // eslint-disable-next-line consistent-return
      return
    // return normalizeNode(entry)
    }
    
      // if next node is page, and now page is not fill, shou move next page child node
      const nextElement = Editor.next(editor, { at: path });
      const [nextPageNode, nextPagePath] = nextElement || [];
      const hasNextPage = nextPageNode && nextPageNode.type === 'page' && nextPagePath;

    const style = window.getComputedStyle(PageNode)
    const computedHeight = PageNode.offsetHeight
    const padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight)

    const pageHeight=computedHeight - padding 

    let currentPageHeight=0

    const children=Array.from( PageNode.children)
    
    children.forEach((child, index) =>{
   
      const childHeight = computeChildHeight(child);
      
      currentPageHeight+=childHeight
      if(currentPageHeight>pageHeight){
        if (hasNextPage && nextPagePath) {
          moveChildToNextPage(editor, index, path, nextPagePath);
        }
        else {
          Transforms.liftNodes(editor)
          Transforms.splitNodes(editor)
          Transforms.wrapNodes(editor,emptyPage)
        }
    }
    })

  }
  

  // Fall back to the original `normalizeNode` to enforce other constraints.
  return normalizeNode(entry)
}
  return editor
}


function computeChildHeight(child) {
  const childStyles= window.getComputedStyle(child)
  const computedChildHeight = child.clientHeight
  const childMargin = parseFloat(childStyles.marginTop) + parseFloat(childStyles.marginBottom)
  const childPadding = parseFloat(childStyles.paddingBottom) + parseFloat(childStyles.paddingTop)
  const childBorder = parseFloat(childStyles.borderLeftWidth) + parseFloat(childStyles.borderRightWidth)+ parseFloat(childStyles.borderTopWidth) + parseFloat(childStyles.borderBottomWidth)
 
  const childHeight=computedChildHeight+childMargin+childPadding+childBorder
  return childHeight;
}


export function moveChildToNextPage(editor, splitIndex, formPath, toPath) {
  Transforms.moveNodes(editor, {
    at: formPath,
    match(n) {
      if (!Editor.isEditor(n) && Element.isElement(n) && n.type !== 'page') {
        let path = null;
        try {
          path = ReactEditor.findPath(editor, n);
        } catch (error) {
          // i dont know, if node path equal to selection, will throw error
          path = (editor.selection && editor.selection.anchor.path) || [];
        }
        return path[1] >= splitIndex;
      }
      return false;
    },
    to: toPath.concat([0]),
  });
}


export default withCustomNormalize
