import React from 'react';
import { Grid } from '@mui/material';
import SidebarItems from '../desk/Sidebar/Sidebar';
import StaredScripts from '../desk/Sidebar/scripts/StaredScripts';

const StaredComponent = () => (
  <>
    <Grid container>
      <SidebarItems />
      <Grid item xs={12} md={9} lg={9.8}>
        <StaredScripts />
      </Grid>
    </Grid>
  </>
);

export default StaredComponent;
