import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ZoomSliderComponent from 'src/components/shared-components/ZoomSliderComponent';
import { Slider, Grid, Divider, useMediaQuery } from '@mui/material';
import ButtonGroupp from '../../threebuttongroup/ButtonGroup';
import OffButtonGroup from '../../twobuttongroup/OffButtonGroup';
import ZoomDropdownValue from '../../zoomdropdownpopover/ZoomDropdownValue';
import CourierprimeDropdown from '../../courierprimedropdown/CourierprimeDropdown';
import EditColorsModal from './EditColorsModal';


const WritingPreferences = () => {
  const isXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [scriptZoom, setScriptZoom] = React.useState(100);
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={'600'} fontSize={'.875rem'}>
            Theme
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <ButtonGroupp />
        </Grid>
      </Grid>

      <Grid container display={'flex'} alignItems={'center'} my={'20px'} spacing={isXs ? 0 : 1}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={'600'} fontSize={'.875rem'}>
            Page Zoom Level
          </Typography>
        </Grid>
        <Grid md={6} item display={'flex'} gap={'10px'}>
          <ZoomDropdownValue scriptZoom={scriptZoom} />

          <Box width={'130px'} my={'0'}>
            <ZoomSliderComponent setScriptZoom={setScriptZoom} />
          </Box>
        </Grid>
      </Grid>

      <Grid mt={'5px'} container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={'600'} fontSize={'.875rem'}>
            Script font
          </Typography>
          <Typography fontSize={'.75rem'} paragraph>
            Only affects editor, not exports
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box width={'100%'}>
            <CourierprimeDropdown
              name={'courier'}
              option1={'Courier Prime'}
              option2={'Courier Prime Bold'}
              option3={'Courier Prime Sans'}
              option4={'Courier Prime Italic'}
              option5={'Courier System'}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid mt={'5px'} container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={'600'} fontSize={'.875rem'}>
            Show word count
          </Typography>
          <Typography fontSize={'.75rem'} paragraph>
            Appears in lower-right corner
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <OffButtonGroup first={'Off'} second={'On'} />
        </Grid>
      </Grid>

      <Grid mt={'5px'} container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={'600'} fontSize={'.875rem'}>
            Formatting Palette
          </Typography>
          <Typography paragraph fontSize={'.75rem'}>
            Popup appears when text is selected
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <OffButtonGroup first={'Fixed'} second={'Popup'} />
        </Grid>
      </Grid>

      <Grid mt={'5px'} container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={'600'} fontSize={'.875rem'}>
            Element Menu
          </Typography>
          <Typography paragraph fontSize={'.75rem'}>
            Icon next to paragraphs
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <OffButtonGroup first={'Off'} second={'On'} />
        </Grid>
      </Grid>

      <Grid mt={'5px'} container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={'600'} fontSize={'.875rem'}>
            Headers and footers
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <OffButtonGroup first={'Condensed'} second={'Full'} />
        </Grid>
      </Grid>

      <Divider sx={{ marginTop: '30px' }} />

      <Grid display={'flex'} alignItems={'flex-start'} mt={'5px'} container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={'600'} fontSize={'.875rem'}>
            Hightlight Characters
          </Typography>
          <Typography fontSize={'.75rem'} paragraph>
            Color hightlight characters lines in the script
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <EditColorsModal />
        </Grid>
      </Grid>

      <Grid mt={'5px'} container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={'600'} fontSize={'.875rem'}>
            Auto Capitalize
          </Typography>
          <Typography paragraph fontSize={'.75rem'}>
            Automatically capitalize the first letter of each sentence.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <OffButtonGroup first={'Off'} second={'On'} />
        </Grid>
      </Grid>

      <Grid mt={'5px'} container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={'600'} fontSize={'.875rem'}>
            Action is followed by
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <CourierprimeDropdown
            name={'action'}
            option1={'Scene Heading'}
            option2={'Action'}
            option3={'Character'}
            option4={'Parenthetical'}
          />
        </Grid>
      </Grid>

      <Grid mt={'5px'} container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={'600'} fontSize={'.875rem'}>
            Dialogue is followed by
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <CourierprimeDropdown name={'dialogue'} option1={'Scene Heading'} option2={'Action'} />
        </Grid>
      </Grid>
    </>
  );
};

export default WritingPreferences;
