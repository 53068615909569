import { Box, LinearProgress, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import React, { useState } from 'react';
import { isValidEmail } from 'src/utils/helpers';
import { useNavigate } from 'react-router';
import { useFetch } from 'src/hooks/useFetch';
import './Welcome.css';
import SignupHeader from '../header/SignupHeader';
import EmailConfirm from '../emailconfirmation';
import { verifyEmailAddress } from 'src/utils/common';

const Welcome = () => {
  const { login } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = useState('welcome');
  const [emailToken, setEmailToken] = useState('');
  const [callRegister] = useFetch(
    'api/auth/register',
    `Register in successfully`,
    'Something went wrong while registering',
    'POST',
    false
  );

  const navigate = useNavigate();

  // eslint-disable-next-line consistent-return
  const handleSignUp = async (state) => {
    const { name, password } = state;
    const email = localStorage.getItem('email') || null;
    if (!name || !password) {
      enqueueSnackbar('Please enter your name and password', {
        variant: 'error',
      });
      return false;
    }
    if (email && !isValidEmail(email)) {
      enqueueSnackbar('Please enter a valid email address', {
        variant: 'error',
      });
      return false;
    }
    const payload = {
      name,
      email: email.toString(),
      password,
      avatar: null,
    };
    const response = await callRegister(payload);
    if (response?.success) {
      window.localStorage.clear();
      await login(email, password, enqueueSnackbar);
    } else {
      enqueueSnackbar('Something went wrong while creating the user', {
        variant: 'error',
      });
    }
  };

  const verifyToken = async () => {
    try {
      const email = localStorage.getItem('email') || null;
      const response = await verifyEmailAddress({ email, eToken: emailToken });
      if (response?.success) {
        setShow('confirm');
      }
      else {
        enqueueSnackbar('Invalid token', {
          variant: 'error',
        });
      }
      setEmailToken('');
    } catch (e) {
      enqueueSnackbar('Invalid token', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      {/* <SignupHeader/> */}
      {show === 'welcome' && (
        <>
          <SignupHeader progressValue={30} />
          <Box
            sx={{
              backgroundColor: '',
              '@media screen and (max-width: 768px)': {
                // Styles for screens with a maximum width of 768px
                pl: '20px', // Adjust the padding for smaller screens
                py: '50px', // Adjust the padding for smaller screens
                // You can add more styles here for smaller screens
              },
              '@media screen and (min-width: 60em)': {
                pt: '8rem',
                pl: '16rem',
                pr: '2rem',
              },
              '@media screen and (min-width: 30em) and (max-width: 60em)': {
                pt: '4rem',
                pl: '4rem',
                pr: '4rem',
              },
            }}
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Box sx={{ backgroundColor: '' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: '500', color: '' }}>Let's get you set up</Typography>
              <Typography mb={'1rem'} sx={{ fontSize: '1.5rem', fontWeight: '600', color: '' }}>
                Please confirm your email
              </Typography>
              <Typography sx={{ fontWeight: '500', fontSize: '1rem', color: '#b2b3c4' }}>
                Enter the special token we sent to:
              </Typography>
              <Typography sx={{ fontWeight: '500', fontSize: '1rem', color: '#7b7c8d' }}>
                {localStorage.getItem('email')}
              </Typography>

              <Box display={'flex'} sx={{ backgroundColor: '' }} pt={'2rem'} alignItems={'center'} gap={'10px'}>
                <Box width={'100%'}>
                  <input
                    className="welcome-input"
                    value={emailToken}
                    onChange={(e) => {
                      setEmailToken(e.target.value);
                    }}
                    placeholder="green-bird-house-gentle"
                  />
                </Box>

                <Typography
                  onClick={() => {
                    verifyToken();
                  }}
                  // width={'30%'}
                  textAlign={'center'}
                  sx={{
                    fontSize: '.875rem',
                    color: '#fff',
                    padding: '.7rem 2rem',
                    border: '1px solid #fe6d29',
                    fontWeight: '400',
                    backgroundColor: '#fe6d29',
                    borderRadius: '.25rem',
                    fontFamily: 'inter',
                    '&:hover': { backgroundColor: '#d0551c' },
                    cursor: 'pointer',
                  }}
                >
                  Confirm
                </Typography>
              </Box>
              <Typography mt={'15px'} sx={{ fontWeight: '300', fontSize: '.875rem', color: '#b2b3c4' }}>
                Don't forget to check your spam folder!
              </Typography>
              <Typography mt={''} sx={{ fontWeight: '300', fontSize: '.875rem', color: '#b2b3c4' }}>
                <Box sx={{ cursor: 'pointer', textDecoration: 'underline' }} className="alr-con">
                  {' '}
                  I already confirmed it{' '}
                </Box>
              </Typography>
              <Box>
                <Typography
                  mt={'15px'}
                  sx={{ fontWeight: '500', fontSize: '.875rem', color: '#868e96', cursor: 'pointer' }}
                  onClick={() => {
                    navigate('/signup');
                  }}
                >
                  Back
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}

      {show === 'confirm' && (
        <EmailConfirm
          onSubmited={(state) => {
            handleSignUp(state);
          }}
        />
      )}
      {show !== 'welcome' && show !== 'confirm' && <></>}
    </>
  );
};

export default Welcome;
