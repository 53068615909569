import styled from '@emotion/styled';
import { Avatar, Box, Divider, Grid, Switch, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import { addOneYear, getCurrentOrder, getCurrentUser } from 'src/utils/helpers';
import { Link } from 'react-router-dom';
import { SERVER_BASE_URL } from 'src/config';
import ProfileEmailModal from 'src/components/slate-editor/components/editorsettings/profile/emailmodal/ProfileEmailModal';
import ProfileNameModal from 'src/components/slate-editor/components/editorsettings/profile/emailmodal/ProfileNameModal';
import ProfilePasswordModal from 'src/components/slate-editor/components/editorsettings/profile/passwordmodal/ProfilePasswordModal';
import ProfileSetupModal from 'src/components/slate-editor/components/editorsettings/profile/setupmodal/ProfileSetupModal';
import ProSubsModal from './ProSubsModal';
import ReminderDays from './Reminder';
import { enableScriptNotification, selectUserImage } from 'src/utils/common';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#fe6d29',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const Account = () => {
  const { logout } = useAuth();
  const user = getCurrentUser();
  const [scriptNotification, setScriptNotification] = React.useState(user ? user?.scriptNotifications : false);
  const localUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    await logout();
  };

  const currentPlanName = user?.currentPlan?.name || 'Free';
  const currentOrder = getCurrentOrder(user) || null;
  const currentPlanDate = currentOrder ? addOneYear(currentOrder?.createdAt) : '--';

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [loadingData, setLoadingData] = React.useState(false);
  useEffect(() => {
    if (user && !loadingData) {
      setName(user.name);
      setEmail(user.email);
      setLoadingData(true);
    }
  }, [user]);

  const handleUpdateEmail = async () => {
    const response = await fetch(`${SERVER_BASE_URL}/api/user/${user?.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    const json = await response.json();
    if (json.success) {
      enqueueSnackbar('Email updated successfully', { variant: 'success' });
      localStorage.setItem('user', JSON.stringify(json?.data));
    } else {
      enqueueSnackbar(response?.message || 'Something went wrong while updating the user email', { variant: 'error' });
    }
  };

  const handleUpdateName = async () => {
    const response = await fetch(`${SERVER_BASE_URL}/api/user/${user?.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name }),
    });
    const json = await response.json();

    if (json.success) {
      enqueueSnackbar('Name updated successfully', { variant: 'success' });
      localStorage.setItem('user', JSON.stringify(json?.data));
    } else {
      enqueueSnackbar(response?.message || 'Something went wrong while updating the user name', { variant: 'error' });
    }
  };

  const selectImage = () => {
    selectUserImage(user, enqueueSnackbar);
  };


  return (
    <>
      <Box mt={'50px'} p={'1rem 2rem'}>
        <Typography sx={{ fontSize: '1.25rem', fontWeight: '600' }}>Account</Typography>

        <Grid mt={'30px'} container>
          <Grid item xs={12} md={3}>
            <Typography sx={{ fontSize: '.875rem', fontWeight: '600', color: '' }}>Account info</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box display={'flex'} alignItems={'center'} gap={'20px'}>
              <Avatar
                sx={{ bgcolor: '#fe6d2963', color: 'white', cursor: 'pointer', width: '60px', height: '60px' }}
                src={user?.avatar || '/broken-image.jpg'}
                onClick={() => {
                  selectImage();
                }}
              />
              <Box>
                <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                  <Typography sx={{ fontSize: '.875rem', fontFamily: 'inter' }}>{name || ''}</Typography>{' '}
                  {/* <Typography sx={{ cursor: 'pointer', color: '#fe6d29', fontSize: '.875rem' }}>Change</Typography> */}
                  <ProfileNameModal
                    name={name}
                    handleUpdateName={handleUpdateName}
                    setName={setName}
                    enqueueSnackbar={enqueueSnackbar}
                  />
                </Box>
                <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                  <Typography sx={{ fontSize: '.875rem', fontFamily: 'inter' }}>{email || ''}</Typography>{' '}
                  {/* <Typography sx={{ cursor: 'pointer', color: '#fe6d29', fontSize: '.875rem' }}>Change</Typography> */}
                  {/* <ProfileEmailModal
                    email={email}
                    handleUpdateEmail={handleUpdateEmail}
                    setEmail={setEmail}
                    enqueueSnackbar={enqueueSnackbar}
                  /> */}
                </Box>
                <ProfilePasswordModal enqueueSnackbar={enqueueSnackbar} userId={localUser.id} displayAt="account" />
              </Box>
            </Box>
            <Typography mt={'10px'} sx={{ fontSize: '.75rem', color: '#7b7c8d' }}>
              Your name and profile picture will be visible to your collaborators.
            </Typography>
          </Grid>
        </Grid>

        <Box my={'40px'}>
          <Divider />
        </Box>

        <Grid mt={'30px'} container>
          <Grid mt={'30px'} item xs={12} md={3}>
            <Typography sx={{ fontSize: '.875rem', fontWeight: '600', color: '' }}>Script notifications</Typography>
          </Grid>

          <Grid mt={'30px'} item xs={12} md={9}>
            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
              <AntSwitch
                defaultChecked={scriptNotification}
                inputProps={{ 'aria-label': 'ant design' }}
                onChange={(e) => {
                  enableScriptNotification(e.target.checked, user, enqueueSnackbar);
                }}
              />

              <Typography
                sx={{
                  fontSize: '.875rem',
                }}
              >
                Receive notifications when somebody leaves a comment on a script
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box my={'40px'}>
          <Divider />
        </Box>

        <Grid mt={'30px'} container>
          <Grid item xs={12} md={3}>
            <Typography sx={{ fontSize: '.875rem', fontWeight: '600', color: '' }}>Writing schedule</Typography>
          </Grid>

          <Grid item xs={12} md={9}>
            <Typography sx={{ fontSize: '.875rem' }}>Remind me to write on the following days:</Typography>
            <ReminderDays />
            {/* <Grid mt={'15px'} item sx={12} display={'flex'} alignItems={'center'} gap={'10px'}>
              <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />

              <Typography
                sx={{
                  fontSize: '.875rem',
                  fontFamily: 'inter',
                }}
              >
                Send me progress reports the following day
              </Typography>
            </Grid> */}
          </Grid>
        </Grid>

        <Box my={'40px'}>
          <Divider />
        </Box>

        <Grid mt={'30px'} container>
          <Grid item xs={12} md={3}>
            <Typography sx={{ fontSize: '.875rem', fontWeight: '600', color: '' }}>
              Two-factor
              <br /> authentication
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <ProfileSetupModal displayAt="account" />
          </Grid>
        </Grid>

        <Box my={'40px'}>
          <Divider />
        </Box>

        <Grid mt={'30px'} container>
          <Grid item xs={12} md={3}>
            <Typography sx={{ fontSize: '.875rem', fontWeight: '600', color: '' }}>Session</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography
              sx={{ fontSize: '.875rem', cursor: 'pointer', fontWeight: '600', color: '#fe6d29' }}
              onClick={handleLogout}
            >
              Sign out from this device
            </Typography>
            <Typography
              sx={{ fontSize: '.875rem', cursor: 'pointer', fontWeight: '600', color: '#fe6d29' }}
              onClick={handleLogout}
            >
              Sign out from all devices
            </Typography>
          </Grid>
        </Grid>
        {/* 
        <Box my={'40px'}>
          <Divider />
        </Box>

        <Grid mt={'30px'} container>
          <Grid item xs={12} md={3}>
            <Typography sx={{ fontSize: '.875rem', fontWeight: '600', color: '' }}>Account</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography sx={{ cursor: 'pointer', fontSize: '.875rem', fontWeight: '600', color: '#fe6d29' }}>
              Close account
            </Typography>
          </Grid>
        </Grid> */}

        <Box my={'40px'}>
          <Divider />
        </Box>

        <Grid mt={'30px'} container>
          <Grid item xs={12} md={3}>
            <Typography sx={{ fontSize: '.875rem', fontWeight: '600', color: '' }}>About</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography sx={{ fontSize: '.875rem', fontWeight: '600', color: '#7b7c8d' }}>
              scrēnplā, LLC, 1112 Van Buren Ave, Oxford
            </Typography>

            <Typography sx={{ fontSize: '.875rem', fontWeight: '600', color: '#7b7c8d' }}>United States</Typography>

            <Box sx={{ '& a': { textDecoration: 'none' } }} display={'flex'} gap={'5px'} alignItems={'center'}>
              <Link target="_blank" to={'https://screnpla.com/privacy'}>
                <Typography
                  sx={{ borderBottom: '1px solid #b2b3c4' }}
                  mt={'.25rem'}
                  lineHeight={'1.25'}
                  color={'#b2b3c4'}
                  fontSize={'.75rem'}
                >
                  Privacy Policy.
                </Typography>
              </Link>

              <Typography
                sx={{ borderBottom: '1px solid #b2b3c4' }}
                mt={'.25rem'}
                lineHeight={'1.25'}
                color={'#b2b3c4'}
                fontSize={'.75rem'}
              >
                |
              </Typography>
              <Link
                target="_blank"
                sx={{ textDecoration: 'none' }}
                to={'https://screnpla.com/terms'}
              >
                <Typography
                  sx={{ borderBottom: '1px solid #b2b3c4' }}
                  mt={'.25rem'}
                  lineHeight={'1.25'}
                  color={'#b2b3c4'}
                  fontSize={'.75rem'}
                >
                  Terms of Service
                </Typography>
              </Link>
            </Box>
          </Grid>
        </Grid>

        <Box my={'40px'}>
          <Divider />
        </Box>
      </Box>
    </>
  );
};
export default Account;
