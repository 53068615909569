import { ReactEditor, useEditor } from "slate-react";
import { useCallback, useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

export default function NodePopover({
  node,
  children,
  editorOffsets,
  className,
  isBodyFullWidth,
  onClickOutside,
}) {
  const popoverRef = useRef(null);
  const editor = useEditor();
  const [isVisible, setIsVisible] = useState(false); // State for handling visibility for animation

  useEffect(() => {
    const editorEl = popoverRef.current;
    if (editorEl == null) {
      return;
    }

    const domNode = ReactEditor.toDOMNode(editor, node);
    const { x: nodeX, height: nodeHeight, y: nodeY } = domNode.getBoundingClientRect();

    // Get the bounding box of the CommentedText span
    const commentTextRect = domNode.getBoundingClientRect();
    const commentTextWidth = commentTextRect.width;

    editorEl.style.display = "block";
    editorEl.style.top = `${nodeY - editorOffsets.y + 30}px`;
    editorEl.style.right = `-360px`;
    editorEl.style.zIndex = 999999;
    editorEl.style.position = "absolute";
    editorEl.scrollIntoView(false);

    // Trigger visibility for animation
    setIsVisible(true);
  }, [editor, editorOffsets?.x, editorOffsets?.y, node]);

  const onMouseDown = useCallback(
    (event) => {
      if (
        popoverRef.current != null &&
        !popoverRef.current.contains(event.target) &&
        onClickOutside != null
      ) {
        onClickOutside(event);
      }
    },
    [onClickOutside]
  );

  useEffect(() => {
    document.addEventListener("mousedown", onMouseDown);

    return () => {
      document.removeEventListener("mousedown", onMouseDown);
    };
  }, [onMouseDown]);

  if (editorOffsets == null) {
    return null;
  }

  return (
    <Card
      ref={popoverRef}
      className={`${className} ${isVisible ? "slide-in" : ""}`} // Add animation class when visible
      style={{ width: "365px", borderRadius: 10 }}
    >
      <CardContent style={isBodyFullWidth ? { padding: 0 } : undefined} className="commentCardBody">
        {children}
      </CardContent>
    </Card>
  );
}
