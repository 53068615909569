import React from 'react';
import { Grid } from '@mui/material';
import SidebarItems from '../desk/Sidebar/Sidebar';
import Projects from '../desk/Sidebar/projects/Projects';

const ProjectsComponent = () => (
  <>
    <Grid container>
      <SidebarItems />
      <Grid item xs={12} md={9} lg={9.8}>
        <Projects />
      </Grid>
    </Grid>
  </>
);

export default ProjectsComponent;
