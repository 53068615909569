import { Box, Popover, Typography } from '@mui/material';
import React, { useState } from 'react';
import Iconify from 'src/components/iconify';

const SigninPopover = () => {
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = anchorEl;
  const id = openpop ? 'simple-popover' : undefined;

  return (
    <>
      <Box
        aria-describedby={id}
        onClick={handleClick}
        sx={{ backgroundColor: '#00017806', padding: '.25rem 0.5rem', borderRadius: '.25rem' }}
        display={'flex'}
        alignItems={'center'}
        gap={'5px'}
      >
        <Typography
          textAlign={'right'}
          sx={{
            fontSize: '.75rem',
            fontFamily: '',
            color: '',
          }}
        >
          indefinitely
        </Typography>
        <Iconify sx={{ marginLeft: '2px' }} icon="ep:arrow-down-bold" width={16} height={24} />
      </Box>

      <Popover
        id={id}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ borderRadius: '0px', '& .MuiPaper-root': { borderRadius: '5px' } }}
      >
        <Box sx={{ padding: '8px 10px' }} width={'200px'}>
          {/* <Typography variant="body1" sx={{ fontSize: '.875rem' }}> */}
          <Box>
            <Typography className="dark-text" sx={{ color: '#333', fontSize: '.875rem' }}>
              until i close my browser
            </Typography>
          </Box>
          <Box mt={'12px'}>
            <Typography className="dark-text" sx={{ color: '#333', fontSize: '.875rem' }}>
              30 days
            </Typography>
          </Box>
          <Box mt={'12px'}>
            <Typography className="dark-text" sx={{ color: '#333', fontSize: '.875rem' }}>
              90 days
            </Typography>
          </Box>
          <Box mt={'12px'}>
            <Typography className="dark-text" sx={{ color: '#333', fontSize: '.875rem' }}>
              indefinitely
            </Typography>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default SigninPopover;
