import React from 'react';
import Typography from '@mui/material/Typography';
import { Grid,Box,  } from '@mui/material';

import CourierprimeDropdown from '../../courierprimedropdown/CourierprimeDropdown';

const CustomElement = () => {
  const [state, setState] = React.useState();
  return (
    <>
      <Grid mb={'15px'} container>
        <Grid item xs={12}>
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'30px'}
            sx={{ boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)' }}
            width={'100%'}
          >
            <Box fontSize={'.75rem'} height={'30px'} width={'100%'} display={'flex'} justifyContent={''}>
              <Box
                sx={{
                  backgroundColor: '#ffd8a8',
                  width: '20%',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#e8590c',
                }}
              >
                1.5
              </Box>
              <Box
                sx={{
                  backgroundColor: '#a5d8ff',
                  width: '60%',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#1971c2',
                }}
              >
                characters
              </Box>
              <Box
                sx={{
                  backgroundColor: '#ffd8a8',
                  width: '20%',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#e8590c',
                }}
              >
                1.5
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Label
          </Typography>
        </Grid>
        <Grid item md={6}>
          {/* <Typography fontSize={'.875rem'} sx={{ opacity: '0.8', color: '#ccc', fontFamily: 'inter' }}>
            Add an element name
          </Typography> */}
          <input className='custom-input' placeholder='Add an element name' />

          
        </Grid>
      </Grid>

      <Grid mt={'15px'} container>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Spacing before
          </Typography>
        </Grid>

        <Grid item md={6}>
          <CourierprimeDropdown
            name={'spacingbefore'}
            option1={'0 lines'}
            option2={'1 line'}
            option3={'1 1/2 lines'}
            option4={'2 lines'}
            option5={'page break'}
          />
        </Grid>
      </Grid>

      <Grid mt={'15px'} container>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Shortcut
          </Typography>
        </Grid>
        <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} item md={6}>
          <Typography fontWeight={'.875rem'}>Ctrl+</Typography>
          <CourierprimeDropdown
            name={'shortcut'}
            option1={'1'}
            option2={'2'}
            option3={'3'}
            option4={'4'}
            option5={'5'}
          />
        </Grid>
      </Grid>

      <Grid mt={'15px'} container>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Element mode shortcut
          </Typography>
        </Grid>
        <Grid item md={6}>
          <CourierprimeDropdown
            name={'elementmodeshortcut'}
            option1={'A'}
            option2={'B'}
            option3={'C'}
            option4={'D'}
            option5={'E'}
            option6={'F'}
            option7={'G'}
          />
        </Grid>
      </Grid>

      <Grid mt={'15px'} container>
        <Grid item md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontFamily: 'inter' }}>
            Next element
          </Typography>
        </Grid>
        <Grid item md={6}>
          <CourierprimeDropdown
            name={'nextelement'}
            option1={'Scene Heading'}
            option2={'Action'}
            option3={'Character'}
            option4={'parenthetical'}
            option5={'Dialogue'}
            option6={'Shot'}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CustomElement;
