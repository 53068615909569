import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Iconify from 'src/components/iconify';
import { Input, TextField } from '@mui/material';
import Switch from '@mui/material/Switch';
import '../emailmodal/PEmailModal.css';

import styled from '@emotion/styled';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 500,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 2,
};

export default function ProfileSetupModal({ displayAt }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const user = localStorage.getItem('user') || null;
  const handleClose = () => setOpen(false);

  return (
    <div>
      {displayAt === 'settings' && (
        <Box onClick={handleOpen} sx={{cursor: 'pointer'}} className="vertical-change">
          <p>Setup Now</p>
        </Box>
      )}
      {displayAt === 'account' && (
        <Typography onClick={handleOpen}  sx={{ cursor: 'pointer', fontSize: '.875rem', fontWeight: '600', color: '#fe6d29' }}>
          Setup now
        </Typography>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ fontSize: '1rem' }}>
            In case you lose access to your authenticator app, send the token phrase highlighted below to customer
            support to re-gain access to your account.
          </Typography>
          <Box my={'20px'} sx={{ padding: '1rem', backgroundColor: '#ffe8cc', borderRadius: '.25rem' }}>
            <Typography sx={{ fontSize: '1rem', color: '#d9480f' }}>
              Make sure you write it down – if you lose this token phrase, you may lose access to your account.
            </Typography>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
              backgroundColor: '#f1f3f5',
              border: '.125rem solid #fe6d29',
              fontSize: '.875rem',
              borderRadius: '.25rem',
              padding: '.5rem',
            }}
          >
            <Typography sx={{ fontSize: '.875rem', color: '#fe6d29', fontFamily: 'courier-prime', fontWeight: '600' }}>
              {user ? JSON.parse(user)?.securityAnswer : 'No token found'}
            </Typography>
          </Box>

          <Box mt={'30px'} width={'100%'}>
            <Typography
              onClick={handleClose}
              sx={{
                textAlign: 'center',
                width: '100%',
                cursor: 'pointer',
                padding: '.5rem 2rem',
                backgroundColor: '#fe6d29',
                color: '#fff',
                borderRadius: '.25rem',
                fontSize: '.875rem',
                fontFamily: '600',
                '&:hover': {
                  backgroundColor: '#d0551c',
                },
              }}
            >
              I confirm that I wrote down the reset token above
            </Typography>
            <Typography
              mt={'10px'}
              onClick={handleClose}
              sx={{
                textAlign: 'center',
                width: '100%',
                padding: '.5rem 2rem',
                cursor: 'pointer',
                backgroundColor: '#fe6d291a',
                borderRadius: '.25rem',
                fontSize: '.875rem',
                '&:hover': {
                  backgroundColor: '#00017806',
                },
              }}
            >
              Cancel
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
