import styled from '@emotion/styled';
import { Box, MenuItem, MenuList, Popover, Switch, Typography } from '@mui/material';
import React, { useState } from 'react';
import Iconify from 'src/components/iconify';

const NewActPopover = () => {
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = anchorEl;
  const id = openpop ? 'simple-popover' : undefined;

  //   aria-describedby={id}
  //   onClick={handleClick}
  return (
    <>
      <Iconify aria-describedby={id} onClick={handleClick} sx={{ color: '' }} icon="ph:dots-three-bold" />

      <Popover
        id={id}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}
        sx={{ borderRadius: '0px', '& .MuiPaper-root': { borderRadius: '5px' } }}
      >
        <Box sx={{ padding: '10px 10px' }} width={'200px'}>
            <Typography sx={{fontSize:".875rem",}}>Remove act break</Typography>
        </Box>
      </Popover>
    </>
  );
};

export default NewActPopover;
