import { Box, Popover, Typography } from '@mui/material';
import React, { useState } from 'react';
import Iconify from 'src/components/iconify';
import './OutlineHashPopover.css';

const OutlineHashPopover = () => {
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = anchorEl;
  const id = openpop ? 'simple-popover' : undefined;

  //   aria-describedby={id}
  //   onClick={handleClick}
  return (
    <>
      <Iconify aria-describedby={id} onClick={handleClick} sx={{ color: '#ccc' }} icon="prime:hashtag" />

      <Popover
        id={id}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ borderRadius: '0px', '& .MuiPaper-root': { borderRadius: '5px' } }}
      >
        <Box sx={{ padding: '10px 10px' }} width={'250px'}>
          <Box>
            <input className="hash-input light-bg dark-text" />
            <Typography mt={'10px'} sx={{ fontSize: '.875rem', textAlign: 'center' }}>
              Type to search or create new
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ color: '#b2b3c4', fontSize: '.75rem', padding: '.375rem 0.5rem' }}>Storylines</Typography>
            <Box
              sx={{ '&:hover': { backgroundColor: '#fe6d291a' } }}
              display={'flex'}
              gap={'5px'}
              alignItems={'center'}
            >
              <Iconify sx={{ color: '#fe6d29' }} icon="iconoir:plus" />
              <Typography sx={{ '&:hover': { color: '#fe6d29' }, fontSize: '1rem' }}>Create storyline"</Typography>
            </Box>
          </Box>

          <Box>
            <Typography sx={{ color: '#b2b3c4', fontSize: '.75rem', padding: '.375rem 0.5rem' }}>Characters</Typography>
            <Box
              sx={{ '&:hover': { backgroundColor: '#fe6d291a' } }}
              display={'flex'}
              gap={'5px'}
              alignItems={'center'}
            >
              <Iconify sx={{ color: '#fe6d29' }} icon="iconoir:plus" />
              <Typography sx={{ '&:hover': { color: '#fe6d29' }, fontSize: '1rem' }}>Create character"</Typography>
            </Box>
          </Box>

          <Box>
            <Typography sx={{ color: '#b2b3c4', fontSize: '.75rem', padding: '.375rem 0.5rem' }}>Locations</Typography>
            <Box
              sx={{ '&:hover': { backgroundColor: '#fe6d291a' } }}
              display={'flex'}
              gap={'5px'}
              alignItems={'center'}
            >
              <Iconify sx={{ color: '#fe6d29' }} icon="iconoir:plus" />
              <Typography sx={{ '&:hover': { color: '#fe6d29' }, fontSize: '1rem' }}>Create location"</Typography>
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default OutlineHashPopover;
