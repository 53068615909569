import { Box, Modal,  Typography } from '@mui/material';
import React from 'react';
import Iconify from 'src/components/iconify';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  //   minHeight: 30,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 2,
};

const ExpandMagicModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <>
        <Box
          onClick={handleOpen}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ width: '40px', height: '40px', borderRadius: '50%', backgroundColor: '#fe6d29' }}
        >
          <Iconify sx={{ color: '#fff', fontSize: '25px' }} icon="ph:magic-wand-light" />
        </Box>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
          <Box>
            <Typography sx={{ fontWeight: '600', fontSize: '1rem', }} ml={"15px"}>
              AL research is in private beta
            </Typography>
            <Typography
            mt={"10px"}
              sx={{
              
                fontWeight: '600',
                fontSize: '1rem',
                padding: '0.5rem 1rem',
                marginBottom: '1rem',
                color: '#7b7c8d',
              }}
            >
              Fill out a very short form (30 seconds) and we will onboard you asap.
            </Typography>
          </Box>

            <Typography sx={{  '&:hover':{backgroundColor:"#d0551c"},backgroundColor:"#fe6d29",fontSize:".875rem",fontFamily:"inter",padding:".5rem 2rem",color:"#fff",borderRadius:"5px",textAlign:"center"}}>Take me to the onboarding form </Typography>
            <Typography mt={"10px"} sx={{'&:hover':{backgroundColor:"#fe6d291a"}, backgroundColor:"#00017806",fontSize:".875rem",fontFamily:"inter",padding:".5rem 2rem",color:"",borderRadius:"5px",textAlign:"center"}} onClick={handleClose}>Cancel </Typography>
          </Box>
        </Modal>
      </>
    </>
  );
};

export default ExpandMagicModal;
