import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import './OutlineEditor.css';
import OutlinePopover from './OutlinePopover';
import OutlineHashPopover from './OutlineHashPopover';

const OutlineEditor = ({ script, updateScriptApi }) => {
  const [scriptBox, setScriptBox] = useState([]);

  const handleInputChange = (e, index, key) => {
    const updatedScriptBox = [...scriptBox];
    updatedScriptBox[index][key] = e.target.value;
    setScriptBox(updatedScriptBox);
  };

  useEffect(() => {
    if (script) {
      setScriptBox(script?.scriptOutlines);
    }
  }, [script]);

  const handleKanBanCard = () => {
    setScriptBox((prevBox) => [...prevBox, { id: `${Date.now()}`, title: '', description: '' }]);
  };

  const saveOutlines = async () => {
    await updateScriptApi({ scriptOutlines: scriptBox });
  };

  const removeOutline = async (index) => {
    const updatedScriptBox = [...scriptBox];
    updatedScriptBox.splice(index, 1);
    setScriptBox(updatedScriptBox);
    await updateScriptApi({ scriptOutlines: updatedScriptBox });
  }


  return (
    <>
      <Box
        className="light-bg"
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        width={'750px'}
        p={'15px'}
        pt={'25px'}
        sx={{
          boxShadow: '1px 1px 5px rgba(0,0,0,0.5)',
          backgroundColor: '#fff',
          minHeight: '300px',
        }}
      >
        <Box>
          <Typography sx={{ fontSize: '1rem', color: '#000', fontFamily: 'inter' }}>New act</Typography>
        </Box>

        {scriptBox.map((outline, index) => (
          <Box mt={'10px'} key={outline.id} className="main-act">
            <Box className="dark-bgg" sx={{ backgroundColor: '#fff', borderLeft: '3px solid #0001781a', borderRadius: '10px' }} p={'10px'}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <input
                  className="out-input dark-bgg dark-text"
                  placeholder="Untitled"
                  value={outline.title}
                  onBlur={saveOutlines}
                  onChange={(e) => handleInputChange(e, index, 'title')}
                />
                <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                  <Box>
                    <OutlinePopover removeOutline={() => removeOutline(index)} handleKanBanCard={handleKanBanCard} />
                  </Box>
                  <Box>
                    <OutlineHashPopover />
                  </Box>
                </Box>
              </Box>

              <Box>
                <textarea
                  rows={2}
                  className="out-textarea dark-bgg dark-text"
                  placeholder="write here"
                  value={outline.description}
                  onBlur={saveOutlines}
                  onChange={(e) => handleInputChange(e, index, 'description')}
                />
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Box ml={'10px'}>
                    <OutlinePopover removeOutline={() => removeOutline(index)} handleKanBanCard={handleKanBanCard}  />
                  </Box>
                  <Box>
                    <OutlineHashPopover />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}

        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={'10px'}>
          <Box
            gap={'5px'}
            sx={{ width: '150px', backgroundColor: '#00017806', padding: '.25rem', borderRadius: '.25rem' }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Box
              onClick={handleKanBanCard}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              gap={'5px'}
              sx={{ '&:hover': { color: '#fe6d29' } }}
            >
              <Iconify sx={{ fontSize: '' }} icon="majesticons:plus-line" />
              <Typography sx={{ fontSize: '.75rem' }}>Add beat</Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Iconify sx={{ fontSize: '', color: '#868e96' }} icon="ph:arrow-fat-up-bold" />
            </Box>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Iconify sx={{ fontSize: '', color: '#868e96' }} icon="carbon:direction-right-01" />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OutlineEditor;
