import { Box, Popover } from '@mui/material';
import React from 'react';

import '../../navbar/EditorNavbar.css';

import DocumentPopperModal from './DocumentPopperModal';

const OptionDropdown = () => {
  const [anchorElCourierprime, setAnchorElCourierprime] = React.useState(null);
  const handleClickCourierprime = (event) => {
    setAnchorElCourierprime(event.currentTarget);
  };
  const handleCloseCourierprime = () => {
    setAnchorElCourierprime(null);
  };
  const openCourierprime = Boolean(anchorElCourierprime);
  const idCourierprime = openCourierprime ? 'simple-popover' : undefined;

  return (
    <>
      <Box
        className="scren-zoomlvl"
        aria-describedby={idCourierprime}
        onClick={handleClickCourierprime}
        // marginLeft={'10px'}
        display={'flex'}
        alignItems={'center'}
      >
        <Box sx={{ color: '#7b7c8d', cursor: 'pointer' }} fontSize={'.875rem'}>
          ...
        </Box>
      </Box>

      <Popover
        id={idCourierprime}
        open={openCourierprime}
        anchorEl={anchorElCourierprime}
        onClose={handleCloseCourierprime}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ padding: '0', borderRadius: '0px' }}
      >
        <Box
          sx={{
            width: '240px',
            backgroundColor: '#fff',
            borderRadius: '0px',
          }}
        >
          <Box p={1}>
            <DocumentPopperModal />
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default OptionDropdown;
