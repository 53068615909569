/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Box, Button, Divider, Drawer, MenuItem, MenuList, Popover, Switch, Tooltip, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import './NewProjectPopper.css';

const NewProjectPopper = () => {
  const [open, setOpen] = useState(false);
  const [project, setProject] = useState('tv');

  // Function to open the Drawer
  const openDrawer = () => {
    setOpen(true);
  };

  // Function to close the Drawer
  const closeDrawer = () => {
    setOpen(false);
  };

  const handleBackdropClick = () => {
    // Close the drawer when clicking on the backdrop
    closeDrawer();
  };
  return (
    <>
      <Typography
      className="dark-bg"
        onClick={openDrawer}
        sx={{
          '&:hover': { backgroundColor: '#fe6d291a' },
          fontSize: '.875rem',
          backgroundColor: '#00017806',
          fontFamily: 'inter',
          padding: '0.5rem 2rem',
          borderRadius: '.25rem',
          cursor:"pointer",
        }}
      >
        New Project
      </Typography>

      <Drawer
        anchor="top"
        open={open}
        onClose={closeDrawer}
        // disableBackdropTransition // Prevent background from being disabled
        PaperProps={{ style: { width:"100%",maxWidth: '600px',marginLeft:"auto",marginRight:"auto"} }}
        hideBackdrop
      >
        {open && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,

              zIndex: -99, // Behind the drawer content
            }}
            onClick={handleBackdropClick} // Close the drawer on backdrop click
          />
        )}

        <Box p={'30px'}>
          <Typography sx={{ fontSize: '1.25rem', fontFamily: 'inter' }}>New Project</Typography>

          <Box>
            <Box mt={'30px'}>
              <Typography sx={{ fontSize: '.875rem' }}>Project name</Typography>
              <input className="dashnew-input light-bg dark-text" />
            </Box>

            <Box display={'flex'} alignItems={'center'} mt={'30px'}>
              <Box
              className="light-bg"
                p={'.4rem 2rem'}
                onClick={() => setProject('tv')}
                sx={{
                  color: project === 'tv' ? '#fe6d29' : '',
                  backgroundColor: project === 'tv' ? '#fe6d291a' : '',
                  '&:hover': { border: '1px solid #fe6d29', color: '#fe6d29' },
                  border: '1px solid transparent',
                  cursor:"pointer",
                }}
                display={'flex'}
                alignItems={'center'}
                gap={'5px'}
              >
                <Iconify sx={{ fontSize: '20px', color: '#fe6d29' }} icon="jam:book" />
                <Typography>Tv series with outline</Typography>
              </Box>
              <Box
              className="light-bg"
                p={'.4rem 2rem'}
                onClick={() => setProject('folder')}
                sx={{
                  color: project === 'folder' ? '#fe6d29' : '',
                  backgroundColor: project === 'folder' ? '#fe6d291a' : '',
                  border: '1px solid transparent',
                  '&:hover': { border: '1px solid #fe6d29', color: '#fe6d29' },
                  cursor:"pointer",
                }}
                display={'flex'}
                alignItems={'center'}
                gap={'5px'}
              >
                <Iconify sx={{ fontSize: '15px', fontWeight: 'bold' }} icon="ph:folder-thin" />
                <Typography>Folder</Typography>
              </Box>
            </Box>

            <Box mt={'30px'} display={'flex'} alignItems={'center'} gap={'10px'}>
              <Box
                p={'0.5rem 2rem'}
                sx={{cursor:"pointer", backgroundColor: '#fe6d29', borderRadius: '.25rem', '&:hover': { backgroundColor: '#d0551c' } }}
              >
                <Typography sx={{ fontSize: '.875rem', fontFamily: 'inter', color: '#fff' }}>Create project</Typography>
              </Box>
              <Box
                onClick={closeDrawer}
                p={'0.5rem 2rem'}
                sx={{cursor:"pointer", backgroundColor: '#00017806', '&:hover': { backgroundColor: '#fe6d291a' } }}
              >
                <Typography sx={{ fontSize: '.875rem', fontFamily: 'inter' }}>Cancel</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default NewProjectPopper;
