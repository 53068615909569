import { Box, Checkbox, Divider, Popover, Typography } from '@mui/material';
import React, { useState } from 'react';
import Iconify from 'src/components/iconify';

const Cardpopover = () => {
  const [anchorElChange, setAnchorElChange] = React.useState(null);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [checkedOne, setCheckedOne] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [checkedThree, setCheckedThree] = useState(false);
  const [checkedFour, setCheckedFour] = useState(false);
  const handleClickChange = (event) => {
    setAnchorElChange(event.currentTarget);
  };
  const handleCloseChange = () => {
    setAnchorElChange(null);
  };

  const handleCheckboxChange = (event) => {
    const newValue = event.target.checked;
    setCheckboxValue(newValue);
  };

  const handleClose = () => {
    setAnchorElChange(null);
  };

  const openChange = Boolean(anchorElChange);
  const idChange = openChange ? 'simple-popover' : undefined;

  return (
    <>
      <Iconify
      className="dark-text"
        aria-describedby={idChange}
        onClick={handleClickChange}
        icon="eva:arrow-ios-downward-fill"
        sx={{ fontSize: '20px', color: '#fe6d29' }}
      />

      <Popover
        id={idChange}
        open={openChange}
        anchorEl={anchorElChange}
        onClose={handleCloseChange}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          sx={{
            width: '350px',
            maxWidth: 360,
            backgroundColor: '',
            // color: "white",
            padding: '20px',
          }}
        >
          <Box
            onClick={() => setCheckedOne(!checkedOne)}
            width={'100%'}
            display={'flex'}
            gap={'10px'}
            alignItems={'start'}
            justifyContent={'flex-start'}
          >
            <Box
              mt={'10px'}
              sx={{
                width: '30px',
                height: '20px',
                backgroundColor: checkedOne ? '#fe6d29' : '',
                borderRadius: '50%',
                border: '1px solid #ccc',
              }}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              {checkedOne && <Iconify sx={{ color: '#fff', fontSize: '10px' }} icon="mdi:check-bold" />}
            </Box>
            <Box>
              <Typography
                mt={'5px'}
                fontWeight={'600'}
                variant="body1"
                sx={{ fontSize: '.875rem' }}
                onClick={handleCloseChange}
              >
                Hide marks for me
              </Typography>
              <Typography sx={{ color: '#b2b3c4', fontSize: '.75rem' }} paragraph>
                It will keep marketing changes for others,but hide the change marks for you.
              </Typography>
            </Box>
          </Box>
          <Box
            onClick={() => setCheckedTwo(!checkedTwo)}
            display={'flex'}
            gap={'10px'}
            alignItems={'start'}
            justifyContent={'flex-start'}
          >
            <Box
              mt={'10px'}
              sx={{ width: '20px', height: '20px', backgroundColor: checkedTwo ? '#fe6d29' : '', borderRadius: '50%',border:"1px solid #ccc" }}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              {checkedTwo && <Iconify sx={{ color: '#fff', fontSize: '10px' }} icon="mdi:check-bold" />}
            </Box>
            <Box>
              <Typography
                fontWeight={'600'}
                mt={'10px'}
                variant="body1"
                sx={{ fontSize: '.875rem' }}
                onClick={() => {
                  handleCloseChange();
                  handleCheckboxChange();
                }}
              >
                By writer
              </Typography>
              <Typography sx={{ color: '#b2b3c4', fontSize: '.75rem' }} paragraph>
                Use different colors based on who made the changes
              </Typography>
            </Box>
          </Box>
          <Box
            onClick={() => setCheckedThree(!checkedThree)}
            display={'flex'}
            gap={'10px'}
            alignItems={'start'}
            justifyContent={'flex-start'}
          >
            <Box
              mt={'10px'}
              sx={{
                width: '20px',
                height: '20px',
                backgroundColor: checkedThree ? '#fe6d29' : '',
                borderRadius: '50%',
                border:"1px solid #ccc"
              }}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              {checkedThree && <Iconify sx={{ color: '#fff', fontSize: '10px' }} icon="mdi:check-bold" />}
            </Box>
            <Box>
              <Typography
                fontWeight={'600'}
                mb={'5px'}
                mt={'10px'}
                variant="body1"
                sx={{ fontSize: '.875rem' }}
                onClick={handleClose}
              >
                By revision
              </Typography>
              <Typography sx={{ color: '#b2b3c4', fontSize: '.75rem' }} paragraph>
                Mark changes in blue
              </Typography>
            </Box>
          </Box>
          <Box
            onClick={() => setCheckedFour(!checkedFour)}
            display={'flex'}
            gap={'10px'}
            alignItems={'start'}
            justifyContent={'flex-start'}
          >
            <Box
              mt={'10px'}
              sx={{
                width: '20px',
                height: '20px',
                backgroundColor: checkedFour ? '#fe6d29' : '',
                borderRadius: '50%',
                border:"1px solid #ccc"
              }}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              {checkedFour && <Iconify sx={{ color: '#fff', fontSize: '10px' }} icon="mdi:check-bold" />}
            </Box>
            <Box>
              <Typography
                fontWeight={'600'}
                mt={'10px'}
                variant="body1"
                sx={{ fontSize: '.875rem' }}
                onClick={handleClose}
              >
                Simple
              </Typography>
              <Typography sx={{ color: '#b2b3c4', fontSize: '.75rem' }} paragraph>
                Mark changes in blue
              </Typography>
            </Box>
          </Box>

          <Box mt={'10px'}>
            <Divider />
          </Box>
          <Typography
            mt={'10px'}
            pr={'2rem'}
            pl={'.5rem'}
            sx={{ fontFamily: 'inter', fontWeight: '600', fontSize: '.875rem' }}
          >
            Change snapshot we are making from
          </Typography>
          <Typography
            mt={'10px'}
            pr={'2rem'}
            pl={'.5rem'}
            sx={{ fontFamily: 'inter', fontWeight: '600', fontSize: '.875rem' }}
          >
            Turn it off for everyone
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

export default Cardpopover;
