import { Box, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router';
import { useFetch } from 'src/hooks/useFetch';
import { useSnackbar } from 'notistack';
import { getCurrentUser } from 'src/utils/helpers';
import Iconify from 'src/components/iconify';
import { SERVER_BASE_URL } from 'src/config';
import ProEditPopper from './ProEditpopper';
import NewScriptPoper from '../dashboard/NewScriptPoper';
import ProjectTopDrawer from './ProjectTopDrawer';
import DeleteModal from './DeleteModal';

const Projects = () => {
  const user = getCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const router = useNavigate();

  const drawerRef = useRef(null);
  const deleteModalRef = useRef(null);
  const [defaultScript, setDefaultScript] = React.useState(null);

  const [test, setTest] = React.useState(false);
  const [movie, setMovie] = React.useState(false);
  const [projects, setProjects] = React.useState([]);
  const [defaultProject, setDefaultProject] = React.useState(null);
  const [deleteProject, setDeleteProject] = React.useState(null);
  const [pagination, setPagination] = React.useState({
    totalPages: 0,
    currentPage: 1,
    totalRecords: 0,
  });

  const [getProjects, loading] = useFetch(
    `api/projects?pageSize=50&page=${pagination.currentPage}&ownerId=${user?.id}`,
    ``,
    '',
    'GET',
    false
  );

  React.useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    const response = await getProjects();
    if (response?.data?.projects) {
      setProjects(response?.data?.projects);
      setPagination({
        totalPages: response?.data?.totalPages,
        currentPage: response?.data?.currentPage,
        totalRecords: response?.data?.totalRecords,
      });
    }
  };

  const handleEditProject = (project) => {
    setDefaultProject(project);
    drawerRef.current.openDrawer();
  };

  const handleDeleteProject = (project) => {
    setDeleteProject(project);
    deleteModalRef.current.handleOpen();
  };

  const deleteProjectEndpoint = `${SERVER_BASE_URL}/api/projects/${deleteProject?.id}`;
  const onSubmitDelete = async () => {
    const response = await fetch(deleteProjectEndpoint, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken') || ''}`,
      },
    });
    const data = await response.json();
    if (data?.success) {
      enqueueSnackbar(`Project deleted successfully`, {
        variant: 'success',
      });
      deleteModalRef.current.handleClose();
      fetchProjects();
    }
  };

  return (
    <>
      <DeleteModal
        ref={deleteModalRef}
        title={'Delete Project'}
        message={'Are you sure you want to delete this project?'}
        onSubmit={() => {
          onSubmitDelete();
        }}
      />
      <Box sx={{}} mt={'50px'} p={'1rem 2rem'}>
          <>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography sx={{ fontSize: '1.25rem', fontWeight: '600', fontFamily: 'inter' }}>Projects</Typography>
              <ProjectTopDrawer
                ref={drawerRef}
                refreshData={fetchProjects}
                handleOpen={() => {}}
                defaultProject={defaultProject}
              />
            </Box>

            {projects && projects?.length > 0 && !loading ? (
              projects.map((project) => (
                <Box
                  key={project.id}
                  className="light-bg"
                  mt={'30px'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  p={'1rem  1rem'}
                  sx={{ border: '1px solid #fe6d291a', borderRadius: '.25rem' }}
                >
                  <Box onClick={() => {router(`/project/${project.id}`)}} display={'flex'} alignItems={'center'} gap={'5px'}>
                    <Typography
                      sx={(theme) => ({
                        [theme.breakpoints.down('sm')]: {
                          // Styles for screens smaller than or equal to 'sm' breakpoint
                          fontSize: '.75rem',
                        },
                        fontSize: '.975rem',
                        fontWeight: '600',
                        fontFamily: 'inter',
                        cursor: 'pointer',
                        textTransform: 'capitalize',
                      })}
                      //  sx={{ fontSize: '.975rem', fontWeight: '600', fontFamily: 'inter' }}
                    >
                      {project?.name || '--'}
                    </Typography>
                    <Iconify
                      sx={(theme) => ({
                        [theme.breakpoints.down('sm')]: {
                          // Styles for screens smaller than or equal to 'sm' breakpoint
                          fontSize: '10px',
                          color: '#fe6d29',
                          cursor: 'pointer',
                        },
                        fontSize: '20px',
                        color: '#fe6d29',
                      })}
                      icon="jam:book"
                    />
                  </Box>
                  <Box onClick={() => setTest(!test)}>
                    <Typography
                      sx={(theme) => ({
                        [theme.breakpoints.down('sm')]: {
                          // Styles for screens smaller than or equal to 'sm' breakpoint
                          fontSize: '.75rem',
                        },
                        fontSize: '.975rem',
                        cursor: 'pointer',
                      })}
                    >
                      0 scripts
                    </Typography>
                  </Box>

                  <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      sx={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                        color: '#fff',
                        backgroundColor: '#20a6a6',
                      }}
                      fontSize={'.75rem'}
                    >
                      {project?.owner?.name?.charAt(0) || '--'}
                    </Box>

                    <ProEditPopper
                      onEdit={() => {
                        handleEditProject(project);
                      }}
                      onDelete={() => {
                        handleDeleteProject(project);
                      }}
                    />
                  </Box>
                </Box>
              ))
            ) : (
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography sx={{ fontSize: '1.25rem', fontWeight: '600', fontFamily: 'inter' }}>
                  No Data Found
                </Typography>
              </Box>
            )}
          </>
      </Box>
    </>
  );
};

export default Projects;
