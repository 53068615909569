import React from 'react';
import './Toolbar.css';
import { Box, Checkbox, Popover, Tooltip, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';

const ToolBar = () => {
  const [anchorElSettings, setAnchorElSettings] = React.useState(null);

  const handleClickSettings = (event) => {
    setAnchorElSettings(event.currentTarget);
  };
  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };

  const openSettings = Boolean(anchorElSettings);
  const idSettings = openSettings ? 'simple-popover' : undefined;

  const [anchorElFunnel, setAnchorElFunnel] = React.useState(null);

  const handleClickFunnel = (event) => {
    setAnchorElFunnel(event.currentTarget);
  };
  const handleCloseFunnel = () => {
    setAnchorElFunnel(null);
  };

  const openFunnel = Boolean(anchorElFunnel);
  const idFunnel = openFunnel ? 'simple-popover' : undefined;

  return (
    <>
      <Box
      zIndex={"999"}
        mt={'100px'}
        mb={'-100px'}
        display={'flex'}
        alignItems={'center'}
        //  gap={"10px"}
        sx={{
          backgroundColor: '#212529',

          // padding: '5px',
          borderRadius: '10px',
          boxShadow: ' 0 1px 4px 0 rgba(59,65,94,.07), 0 2px 4px 0 rgba(0,0,0,.05)',
          color: '#fff',
        }}
      >
        <Box
          p={'.25rem'}
          px={'.8rem'}
          sx={{
            borderRight: '1px solid #353b40',
            // '&:hover': { backgroundColor: '#495057', color: '#fff', borderRadius: '5px' },
          }}
          display={'flex'}
          gap={'10px'}
          alignItems={'center'}
        >
          <Iconify fontSize={'20px'} sx={{ color: '#fff' }} icon="radix-icons:plus" />

          <Tooltip title="New column ⇧ Ctrl+A" arrow>
            <Typography sx={{ fontSize: '.75rem' }}>Add act</Typography>
          </Tooltip>
        </Box>
        <Box
          p={'.25rem'}
          px={'.8rem'}
          sx={{ borderRight: '1px solid #353b40' }}
          display={'flex'}
          gap={'10px'}
          alignItems={'center'}
        >
          {' '}
          <Tooltip title="Show all statuses" arrow>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                color: '#495057',
                width: '20px',
                height: '20px',
                marginBottom: '5px',
                borderRadius: '.25rem',
                '&:hover': { color: '#fff' },
              }}
            >
              <Iconify mt={'5px'} fontSize={'20px'} icon="icon-park-outline:loading-one" />
            </Box>
          </Tooltip>
          <Tooltip title="Show all comments" arrow>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                color: '#495057',
                width: '20px',
                marginBottom: '5px',
                height: '20px',
                borderRadius: '.25rem',
                '&:hover': { color: '#fff' },
              }}
            >
              <Iconify mt={'5px'} fontSize={'20px'} icon="majesticons:chat-line" />
            </Box>
          </Tooltip>
        </Box>

        <Box
          p={'.25rem'}
          px={'.8rem'}
          sx={{ borderRight: '1px solid #353b40' }}
          display={'flex'}
          gap={'10px'}
          alignItems={'center'}
        >
          {' '}
          <Tooltip title="Open all beats" arrow>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                color: '#495057',
                width: '20px',
                marginBottom: '5px',
                height: '20px',
                borderRadius: '.25rem',
                '&:hover': { color: '#fff' },
              }}
            >
              <Iconify mt={'5px'} fontSize={'20px'} icon="mdi:arrow-vertical-collapse" />
            </Box>
          </Tooltip>
          <Tooltip title="Close all beats" arrow>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                color: '#495057',
                width: '20px',
                marginBottom: '5px',
                height: '20px',
                borderRadius: '.25rem',
                '&:hover': { color: '#fff' },
              }}
            >
              <Iconify mt={'5px'} fontSize={'20px'} icon="bi:arrows-expand" />
            </Box>
          </Tooltip>
          <Tooltip title="Hightlight key beats ⇧Ctrl+K" arrow>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                color: '#495057',
                width: '20px',
                marginBottom: '5px',
                height: '20px',
                borderRadius: '.25rem',
                '&:hover': { color: '#fff' },
              }}
            >
              <Iconify mt={'5px'} fontSize={'20px'} icon="iconamoon:star-light" />
            </Box>
          </Tooltip>
          <Tooltip title="Filter beats ⇧Ctrl+F" arrow>
            <Box
              aria-describedby={idFunnel}
              onClick={handleClickFunnel}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                color: '#495057',
                width: '20px',
                height: '20px',
                marginBottom: '5px',
                borderRadius: '.25rem',
                '&:hover': { color: '#fff' },
              }}
            >
              <Iconify mt={'5px'} fontSize={'20px'} icon="iconamoon:funnel" />
            </Box>
          </Tooltip>
        </Box>

        <Box p={'.25rem'} px={'.8rem'} sx={{ borderRight: '1px solid #353b40' }}>
          {' '}
          <Tooltip title="Arc view" arrow>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              mt={'5px'}
              sx={{
                color: '#495057',
                width: '20px',
                marginBottom: '5px',
                height: '20px',
                borderRadius: '.25rem',
                '&:hover': { backgroundColor: '#495057', color: '#fff' },
              }}
            >
              <Iconify fontSize={'20px'} icon="icon-park-outline:loading-one" />
            </Box>
          </Tooltip>
        </Box>
        <Box p={'.25rem'} px={'.8rem'} sx={{ borderRight: 'none' }}>
          <Tooltip title="More..." arrow>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                color: '#495057',
                width: '20px',
                marginBottom: '5px',
                height: '20px',
                borderRadius: '.25rem',
                // '&:hover': { backgroundColor: '#495057', color: '#fff' },
              }}
              aria-describedby={idSettings}
              onClick={handleClickSettings}
              mt={'5px'}
            >
              <Iconify sx={{color:"#fff"}} fontSize={'20px'} icon="ph:dots-three-bold" />
            </Box>
          </Tooltip>
        </Box>
      </Box>

      <Popover
        id={idSettings}
        open={openSettings}
        anchorEl={anchorElSettings}
        onClose={handleCloseSettings}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ borderRadius: '0px', '& .MuiPaper-root': { borderRadius: '5px' } }}
      >
        <Box p={1} sx={{ backgroundColor: '#353b40', color: '#fff' }}>
          <Typography sx={{ fontSize: '.75rem' }}>Create template</Typography>
        </Box>
      </Popover>

      <Popover
        id={idFunnel}
        open={openFunnel}
        anchorEl={anchorElFunnel}
        onClose={handleCloseFunnel}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        paperProps={{ borderRadius: '0px' }}
      >
        <Box p={1}>
          <Box>
            <input className="funnel-input" />
            <Typography mt={'10px'} sx={{ fontSize: '.75rem', color: '#b2b3c4', textAlign: 'center' }}>
              Type to search for elements
            </Typography>
          </Box>
          <Typography mt={'10px'} sx={{ fontSize: '.75rem', color: '#b2b3c4' }}>
            Notes
          </Typography>
          <Box
            mt={'5px'}
            sx={{ backgroundColor: '#fe6d291a', borderRadius: '.25rem' }}
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
          >
            <Checkbox size="small" sx={{ '&.MuiButtonBase-root': { color: '#fe6d29' } }} />
            <Typography sx={{ fontSize: '.875rem' }}>Notes</Typography>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default ToolBar;
