import React from 'react';
import Typography from '@mui/material/Typography';
import {
  Grid,
  Avatar,
} from '@mui/material';
import ReminderDays from 'src/components/desk/Sidebar/accounts/Reminder';
import OffButtonGroup from '../../twobuttongroup/OffButtonGroup';
import { getCurrentUser } from 'src/utils/helpers';
import { enableScriptNotification } from 'src/utils/common';
import { useSnackbar } from 'notistack';

const Notifications = () => {
  const user = getCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [scriptNotification] = React.useState(user ? user?.scriptNotifications : false);
  console.log('scriptNotification', scriptNotification);
  return (
    <>
         <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <Typography className="dark-text" color={'#333'} fontWeight={'600'} fontSize={'.875rem'}>
                  Script notifications
                </Typography>
                <Typography mt={'.25rem'} lineHeight={'1.25'} color={'#b2b3c4'} fontSize={'.75rem'}>
                  Receive notifications when somebody <br /> leaves a comment on a script
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <OffButtonGroup first={'Off'} second={'On'}
                  active={scriptNotification ? 'On' : 'Off'}
                  onChange={(value) => {
                    console.log('value', value);
                    enableScriptNotification(value, user, enqueueSnackbar);
                  }}
                />
              </Grid>
            </Grid>

            <Grid mt={'10px'} container columnSpacing={2}>
              <Grid item xs={12}>
                <Typography className="dark-text" color={'#333'} fontWeight={'600'} fontSize={'.875rem'}>
                  Writing Schedule
                </Typography>
                <Typography mt={'.25rem'} lineHeight={'1.25'} color={'#b2b3c4'} fontSize={'.75rem'}>
                  Remind me to write on the following days.
                </Typography>
              </Grid>
            </Grid>

            <ReminderDays />

            {/* <Grid mt={'10px'} container columnSpacing={2}>
              <Grid display={'flex'} gap={'10px'} item xs={12} md={6}>
                <Typography className="dark-text" mt={'.25rem'} sx={{ fontSize: '.875rem', color: '#333', fontWeight: '600' }}>
                  Send me progress reports the <br /> following day
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <OffButtonGroup first={'Off'} second={'On'} />
              </Grid>
            </Grid> */}
    </>
  )
}

export default Notifications