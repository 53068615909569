const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const convertToFdx = async (editor) => {
  const { children } = editor;
  
  let fdxContent =
    '<?xml version="1.0" encoding="UTF-8" standalone="no" ?>\n<FinalDraft DocumentType="Script" Template="No" Version="5">\n\n  <Content>\n';

    children.forEach((paragraph) => {
      console.log(paragraph);
      const { type, children: textChildren } = paragraph;
      if (type) {
        let fdxParagraph = `    <Paragraph Type="${capitalizeFirstLetter(type)}">\n`;

        textChildren.forEach((text) => {
          const { text: textContent, bold } = text;
          const fdxText = `      <Text${bold ? ' Style="Bold"' : ''}>${textContent}</Text>\n`;
          fdxParagraph += fdxText;
        });

        fdxParagraph += '    </Paragraph>\n';
        fdxContent += fdxParagraph;
      }
    });

  fdxContent += '  </Content>\n\n</FinalDraft>';
  // return fdxContent;

  const blob = new Blob([fdxContent], { type: 'text/plain' });
  //   saveAs(blob, "example.docx");
  return blob;
};

const convertToXML = async (editor) => {
  const { children } = editor;

  let xmlContent = '<?xml version="1.0" encoding="UTF-8" standalone="no" ?>\n  <Content>\n';

  children.forEach((paragraph) => {
    const { type, children: textChildren } = paragraph;
    if (type) {
      let xmlParagraph = `    <Paragraph Type="${capitalizeFirstLetter(type)}">\n`;

      textChildren.forEach((text) => {
        const { text: textContent, bold } = text;
        const xmlText = `      <Text${bold ? ' Style="Bold"' : ''}>${textContent}</Text>\n`;
        xmlParagraph += xmlText;
      });

      xmlParagraph += '    </Paragraph>\n';
      xmlContent += xmlParagraph;
    }
  });

  xmlContent += '  </Content>\n';
  // return fdxContent;

  const blob = new Blob([xmlContent], { type: 'text/plain' });
  //   saveAs(blob, "example.docx");
  return blob;
};

export {
  convertToXML,
  convertToFdx,
};
