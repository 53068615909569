import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import SignIn from 'src/components/slate-editor/components/authentication/signin/SignIn';

// hooks
import useAuth from '../hooks/useAuth';
// pages

// components

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, error } = useAuth();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const user = localStorage.getItem('user') || null;

  console.log('isAuthenticated', {
    isAuthenticated,
    isInitialized,
    error,
    pathname,
    requestedLocation,
  });

  if (!isAuthenticated && !user) {
    return <SignIn />;
  }

  // if (!isInitialized) {
  //   return <LoadingScreen />;
  // }

  // if (!isAuthenticated) {
  //   if (error && error.length > 0) {
  //     enqueueSnackbar(error, {
  //       variant: 'error',
  //     });
  //   }
  //   if (pathname !== requestedLocation) {
  //     setRequestedLocation(pathname);
  //   }
  //   return <SignIn />
  // }

  // if (requestedLocation && pathname !== requestedLocation) {
  //   setRequestedLocation(null);
  //   return <Navigate to={requestedLocation} />;
  // }

  return <>{children}</>;
}
