import React, { useState, useEffect } from 'react';
import { Box, Tooltip } from '@mui/material';
import Iconify from 'src/components/iconify';
import AlarmPopover from './AlarmPopover';

const BottomLeftItem = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen((prev) => !prev);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const toggleFullScreen = () => {
    const element = document.documentElement;
    if (!isFullScreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      }
    } else if (document.exitFullscreen) {
        document.exitFullscreen();
      }
  };

  return (
    <>
      <Box
        display={{xs:"none",sm:'flex'}}
        alignItems={'center'}
        gap={'5px'}
        sx={{
          zIndex: '99',
          position: 'fixed',
          bottom: '20px',
          left: '10px',
          padding: '5px',
          borderRadius: '5px',
          backgroundColor: '#fe6d291a',
        }}
      >
        <Tooltip title={isFullScreen ? 'Exit Focus mode' : 'Enter Focus mode'} arrow>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ width: '25px', height: '25px', backgroundColor: '#', color: '#b2b3c4', cursor: 'pointer' }}
            onClick={toggleFullScreen}
          >
            <Iconify
              sx={{
                fontSize: '25px',
              }}
              icon={isFullScreen ? 'mingcute:fullscreen-exit-line' : 'game-icons:expand'}
            />
          </Box>
        </Tooltip>
        {/* <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ width: '25px', height: '25px', backgroundColor: '#', color: '#b2b3c4' }}
        >
          <AlarmPopover />
        </Box> */}
      </Box>
    </>
  );
};

export default BottomLeftItem;
