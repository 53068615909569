import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useFetch } from 'src/hooks/useFetch';
import Typography from '@mui/material/Typography';
import { Grid, Box, TextField } from '@mui/material';
import MultiTagInput from './TagInput';

const DocumentInfo = ({ script, refreshData }) => {
  const { id } = useParams();

  const [updateScript] = useFetch(`api/scripts/${id}`, ``, '', 'PATCH', false);

  const [state, setState] = useState({
    title: '',
    subTitle: '',
    writtenBy: '',
    genre: '',
    pageTarget: 0,
    logline: '',
    tags: [],
  });

  useEffect(() => {
    if (script) {
      setState({
        title: script.title,
        subTitle: script.subTitle,
        writtenBy: script.writtenBy,
        genre: script.genre,
        pageTarget: script.pageTarget,
        logline: script.logline,
        tags: script?.tags || [],
      });
    }
  }, [script]);

  const handleUpdateScript = async (chagedKey, changedValue) => {
    try {
      const payload = removeEmptyFields({ ...state });
      if (chagedKey) {
        payload[chagedKey] = changedValue;
      }
      const response = await updateScript({ ...payload });
      if (response) refreshData();
    } catch (error) {
      console.log(error);
    }
  };

  const removeEmptyFields = (obj) => {
    Object.keys(obj).forEach((key) => obj[key] === '' && delete obj[key]);
    return obj;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <Typography fontWeight={'600'} fontSize={'.875rem'}>
              Title
            </Typography>
            <input
              className="doc-title light-bg dark-text"
              placeholder="Title"
              value={state.title}
              onChange={(e) => setState({ ...state, title: e.target.value })}
              onBlur={handleUpdateScript}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid mt={'20px'} container columnSpacing={2}>
        {/* <Grid item xs={12} md={6}>
          <Box>
            <Typography fontSize={'.875rem'} fontWeight={'600'}>
              Subtitle
            </Typography>
            <input
              className="doc-title light-bg dark-text"
              placeholder="Subtitle"
              value={state.subTitle}
              onChange={(e) => setState({ ...state, subTitle: e.target.value })}
              onBlur={handleUpdateScript}
            />
          </Box>
        </Grid> */}
        <Grid item xs={12} md={6}>
          <Box>
            <Typography fontSize={'.875rem'}>Written by</Typography>
            <input
              className="doc-title light-bg dark-text"
              placeholder="written by"
              value={state.writtenBy}
              onChange={(e) => setState({ ...state, writtenBy: e.target.value })}
              onBlur={handleUpdateScript}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid mt={'20px'} container columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography fontSize={'.875rem'} fontWeight={'600'}>
              Page Target
            </Typography>
            <input
              className="doc-title light-bg dark-text"
              placeholder="Page target"
              value={state.pageTarget}
              onChange={(e) => setState({ ...state, pageTarget: e.target.value })}
              onBlur={handleUpdateScript}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography fontSize={'.875rem'}>Genre</Typography>
            <input
              className="doc-title light-bg dark-text"
              placeholder="Genre"
              value={state.genre}
              onChange={(e) => setState({ ...state, genre: e.target.value })}
              onBlur={handleUpdateScript}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid mt={'10px'} container>
        <Grid item xs={12}>
          <Box>
            <Typography fontWeight={'600'} fontSize={'.875rem'}>
              Logline
            </Typography>
            {/* <input className="doc-logline" placeholder="Title" /> */}
            <TextField
              className="doc-logline light-bg dark-text"
              id="outlined-multiline-flexible"
              multiline
              maxRows={2}
              sx={{
                '& .MuiInputBase-input': {
                  border: '1px solid transparent',

                  fontSize: ' 0.875rem',
                  backgroundColor: '#f9fafc',
                  borderRadius: '0.25em',
                  width: '100%',
                  color: '#000',
                  outline: 'none',
                  padding: 0,
                  '& :focus': {
                    borderColor: '#9775fa',
                  },
                },
              }}
              placeholder="Logline"
              value={state.logline}
              onChange={(e) => setState({ ...state, logline: e.target.value })}
              onBlur={handleUpdateScript}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid mt={'15px'} container>
        <Grid item xs={12}>
          <Typography sx={{ color: '#b2b3c4', fontFamily: 'inter' }} fontSize=".875rem">
            <span className="docinfo-tag">Tags</span> May contain '/' for nested folders, e.g.'sopranos/season1'
          </Typography>
          {/* <input className="doc-title light-bg dark-text" placeholder="Type to add new tag or search existing" /> */}
          <MultiTagInput
            placeholder={'Type to add new tag or search existing'}
            className={'doc-title light-bg dark-text'}
            tagsContainerClassName={'docinfo-tag'}
            tags={state.tags}
            onTagAdd={(tag) => {
              setState({ ...state, tags: [...state.tags, tag] });
              handleUpdateScript('tags', [...state.tags, tag]);
            }}
            onTagRemove={(tagId) => {
              setState({ ...state, tags: state.tags.filter((tag) => tag.id !== tagId) });
              handleUpdateScript(
                'tags',
                state.tags.filter((tag) => tag.id !== tagId)
              );
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DocumentInfo;
