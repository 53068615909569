/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useFetch } from 'src/hooks/useFetch';
import Typography from '@mui/material/Typography';
import { Grid, Box } from '@mui/material';
import OffButtonGroup from '../../twobuttongroup/OffButtonGroup';
import CourierprimeDropdown from '../../courierprimedropdown/CourierprimeDropdown';
import PageLayoutModal from './pagelayoutmodal/PageLayoutModal';

const PageLayout = ({ script, refreshData }) => {
  const { id } = useParams();

  const [updateScript] = useFetch(`api/scripts/${id}`, ``, '', 'PATCH', false);

  const [state, setState] = useState({
    elementTemplate: 'Film Screnplay',
    pageLayout: 'Standard - 60 characters per line',
    pageMargin: {
      type: 'Standard - 1.5" left margin',
      space: 'Page break',
    },
    sceneHeading: 'Regular',
    breakAction: false,
    autoMarkAsCount: false,
    pageCategory: 'Letter',
  });

  useEffect(() => {
    if (script) {
      setState({
        elementTemplate: script?.layout?.elementTemplate || 'Film Screnplay',
        pageLayout: script?.layout?.pageLayout || 'Standard - 60 characters per line',
        pageMargin: {
          type: script?.layout?.pageMargin?.type || 'Standard - 1.5" left margin',
          space: script?.layout?.pageMargin?.space || 'Page break',
        },
        sceneHeading: script?.layout?.sceneHeading || 'Regular',
        breakAction: script?.layout?.breakAction || false,
        autoMarkAsCount: script?.layout?.autoMarkAsCount || false,
        pageCategory: script?.layout?.pageCategory || 'Letter',
      });
    }
  }, [script]);

  const handleUpdateScript = async (chagedKey, changedValue) => {
    try {
      const payload = { ...state };
      if (chagedKey) {
        payload[chagedKey] = changedValue;
      }
      const response = await updateScript({ layout: { ...payload } });
      if (response) refreshData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <PageLayoutModal />
        </Grid>
      </Grid>

      <Grid display={'flex'} alignItems={'center'} container mt={'15px'}>
        <Grid item xs={12} md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontWeight: '600' }}>
            Elements template
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <CourierprimeDropdown
            name={'elementstemplate'}
            option1={'Film Screnplay'}
            option2={'Television Screnplay'}
            onChange={(value) => {
              setState({ ...state, elementTemplate: value });
            }}
            value={state.elementTemplate}
            onBlur={(value) => {
              handleUpdateScript('elementTemplate', value);
            }}
          />
        </Grid>
      </Grid>

      <Grid display={'flex'} alignItems={'center'} container mt={'15px'}>
        <Grid item xs={12} md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontWeight: '600' }}>
            Page Layout
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <CourierprimeDropdown
            name={'pagelayout'}
            option1={'Standard-60 characters per line'}
            option2={'Modern-61 characters per line'}
            onChange={(value) => {
              setState({ ...state, pageLayout: value });
            }}
            value={state.pageLayout}
            onBlur={(value) => {
              handleUpdateScript('pageLayout', value);
            }}
          />
        </Grid>
      </Grid>

      <Grid display={'flex'} alignItems={'center'} container mt={'15px'}>
        <Grid item xs={12} md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontWeight: '600' }}>
            Page Margins
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <CourierprimeDropdown
            name={'pagemargins'}
            option1={'Standard - 1.5"left margin'}
            option2={'Modern - 1.25"left margin'}
            onChange={(value) => {
              setState({ ...state, pageMargin: { ...state.pageMargin, type: value } });
            }}
            value={state.pageMargin.type}
            onBlur={(value) => {
              handleUpdateScript('pageMargin', { ...state.pageMargin, type: value });
            }}
          />
        </Grid>
      </Grid>

      <Grid display={'flex'} alignItems={'center'} container mt={'15px'}>
        <Grid item xs={12} md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontWeight: '600' }}>
            Scene Headings
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={'5px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography fontSize={'.75rem'}>Spacing before</Typography>
            <CourierprimeDropdown
              name={'sceneheadings'}
              option1={'Page break'}
              option2={'1'}
              option3={'1'}
              option4={'2'}
              onChange={(value) => {
                setState({ ...state, pageMargin: { ...state.pageMargin, space: value } });
              }}
              value={state.pageMargin.space}
              onBlur={(value) => {
                handleUpdateScript('pageMargin', { ...state.pageMargin, space: value });
              }}
            />
          </Box>

          <OffButtonGroup
            first={'Regular'}
            second={'Bold'}
            active={state.sceneHeading === 'Regular' ? 'Regular' : 'Bold'}
            onChange={(value) => setState({ ...state, sceneHeading: value })}
            onBlur={(value) => {
              const newValue = value === 'Regular' ? 'Regular' : 'Bold';
              handleUpdateScript('sceneHeading', newValue);
            }}
          />
        </Grid>
      </Grid>

      <Grid display={'flex'} alignItems={'center'} container mt={'15px'}>
        <Grid item xs={12} md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontWeight: '600' }}>
            Break action and dialogue at the <br /> end of a sentence
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <OffButtonGroup
            first={'Off'}
            second={'On'}
            active={state.breakAction ? 'On' : 'Off'}
            onChange={(value) => setState({ ...state, breakAction: value })}
            onBlur={(value) => {
              handleUpdateScript('breakAction', value);
            }}
          />
        </Grid>
      </Grid>

      <Grid display={'flex'} alignItems={'center'} container mt={'15px'}>
        <Grid item xs={12} md={6}>
          <Typography className="dark-text" fontSize={'.875rem'} sx={{ color: '#333', fontWeight: '600' }}>
            Automatically mark dialogue as
          </Typography>
          <Typography fontSize={'.875rem'} sx={{ color: '#7b7c8d' }} fontWeight={'400'}>
            (CONT'D)
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <OffButtonGroup
            first={'Off'}
            second={'On'}
            active={state.autoMarkAsCount ? 'On' : 'Off'}
            onChange={(value) => setState({ ...state, autoMarkAsCount: value })}
            onBlur={(value) => {
              handleUpdateScript('autoMarkAsCount', value);
            }}
          />
        </Grid>
      </Grid>

      <Grid display={'flex'} alignItems={'center'} container mt={'25px'}>
        <Grid item xs={12}>
          <OffButtonGroup
            first={'Letter'}
            second={'A4'}
            active={state.pageCategory === 'Letter' ? 'On' : 'Off'}
            onChange={(value) => setState({ ...state, pageCategory: value })}
            onBlur={(value) => {
              const newValue = value === 'Letter' ? 'Letter' : 'A4';
              handleUpdateScript('pageCategory', newValue);
            }}
          />
        </Grid>
      </Grid>

      <Grid mt={'15px'} container>
        <Grid item xs={12}>
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'300px'}
            sx={{ boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)' }}
            width={'100%'}
          >
            <Box fontSize={'.75rem'} height={'150px'} width={'100%'} display={'flex'} justifyContent={''}>
              <Box
                sx={{
                  backgroundColor: '#ffd8a8',
                  width: '20%',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#e8590c',
                }}
              >
                1.5
              </Box>
              <Box
                sx={{
                  backgroundColor: '#a5d8ff',
                  width: '60%',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#1971c2',
                }}
              >
                characters
              </Box>
              <Box
                sx={{
                  backgroundColor: '#ffd8a8',
                  width: '20%',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#e8590c',
                }}
              >
                1.5
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PageLayout;
