import styled from '@emotion/styled';
import { Box, MenuItem, MenuList, Popover, Switch, Typography } from '@mui/material';
import React, { useState } from 'react';
import Iconify from 'src/components/iconify';

const CardLoadingPopover = () => {
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = anchorEl;
  const id = openpop ? 'simple-popover' : undefined;
  return (
    <>
      <Iconify aria-describedby={id} onClick={handleClick} icon="icon-park-outline:loading-one" />
      <Popover
        id={id}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ borderRadius: '0px', '& .MuiPaper-root': { borderRadius: '5px' } }}
      >
        <Box sx={{ padding: '10px 10px' }} width={'200px'}>
          <Box>
            <Typography sx={{ fontSize: '.875rem' }}>Status</Typography>
            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: '#fe6d29' }}
              >
                <Iconify sx={{color:"#fff"}} icon="gg:check" />
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'red' }} />
                <Typography sx={{ fontSize: '.875rem' }}>  NOt Stared</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default CardLoadingPopover;
