/* eslint-disable react/prop-types */
import { Box, Divider, Drawer, Popover, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFetch } from 'src/hooks/useFetch';
import { useParams } from 'react-router';
import Iconify from 'src/components/iconify';
import ElementsCards from './ElementsCards';
import StashModal from './StashModal';
import './DrawerMenu.css';

const DrawerMenu = ({ script, refreshData }) => {
  const { id } = useParams();

  const [updateScript] = useFetch(`api/scripts/${id}`, ``, '', 'PATCH', false);

  const [open, setOpen] = useState(false);
  const [borderColors, setBorderColors] = useState([]);
  const [cards, setCards] = useState([]);
  const [characterCards, setCharacterCards] = useState([]);
  const [locationCards, setLocationCards] = useState([]);
  const [cardTrue, setCardTrue] = useState(false);
  const [characterCardTrue, setCharacterCardTrue] = useState(false);
  const [locationCardTrue, setLocationCardTrue] = useState(false);
  const [stashInputData, setStashInputData] = useState('Untitled');
  const [popupItem, setPopupItem] = useState('');

  useEffect(() => {
    if (script) {
      setCards(script?.detailedElements?.find((e) => e.label === 'Storylines')?.items || []);
      setCharacterCards(script?.detailedElements?.find((e) => e.label === 'Character')?.items || []);
      setLocationCards(script?.detailedElements?.find((e) => e.label === 'Locations')?.items || []);
      const stashText = script?.stashText && script?.stashText?.length > 0 ? script?.stashText : 'Untitled';
      setStashInputData(stashText);
    }
  }, [script]);

  const handleUpdateScript = async () => {
    try {
      const payload = {
        detailedElements: [
          {
            label: 'Storylines',
            items: cards || [],
          },
          {
            label: 'Character',
            items: characterCards || [],
          },
          {
            label: 'Locations',
            items: locationCards || [],
          },
        ],
      };
      const response = await updateScript({ ...payload });
      if (response) refreshData();
    } catch (error) {
      console.log(error);
    }
  };

  const removeCards = async (items, type) => {
    const payload = {
      detailedElements: [
        {
          label: 'Storylines',
          items: cards || [],
        },
        {
          label: 'Character',
          items: characterCards || [],
        },
        {
          label: 'Locations',
          items: locationCards || [],
        },
      ],
    };
    switch (type) {
      case 'storyline': {
        payload.detailedElements[0] = {
          label: 'Storylines',
          items,
        };
        const response = await updateScript({ ...payload });
        if (response) refreshData();
        break;
      }
      case 'character': {
        payload.detailedElements[1] = {
          label: 'Character',
          items,
        };
        const response = await updateScript({ ...payload });
        if (response) refreshData();
        break;
      }
      case 'location': {
        payload.detailedElements[2] = {
          label: 'Locations',
          items,
        };
        const response = await updateScript({ ...payload });
        if (response) refreshData();
        break;
      }
      default:
        break;
    }
  };

  // Function to open the Drawer
  const openDrawer = () => {
    setOpen(true);
  };

  // Function to close the Drawer
  const closeDrawer = () => {
    setOpen(false);
  };

  const [value, setValue] = useState('stash');
  const [showStash, setShowStash] = useState(true);
  const [isToggled, setIsToggled] = useState(false);
  // const [characterValue, setCharacterValue] = useState('stash');
  const [characterToggled, setCharacterToggled] = useState(false);
  const [locationToggled, setLocationToggled] = useState(false);

  // popover

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setPopupItem(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = anchorEl;
  const elementId = openpop ? 'simple-popover' : undefined;

  function getRandomColor() {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue
      .toString(16)
      .padStart(2, '0')}`;
  }

  const handleCard = () => {
    const newBorderColor = getRandomColor();
    setBorderColors((prevColors) => [...prevColors, newBorderColor]);
    setCards((prevCards) => [...prevCards, { borderColor: newBorderColor }]);
  };

  const handleCharacterCard = () => {
    const newBorderColor = getRandomColor();
    setBorderColors((prevColors) => [...prevColors, newBorderColor]);
    setCharacterCards((prevCards) => [...prevCards, { borderColor: newBorderColor }]);
  };

  const handleLocationCard = () => {
    const newBorderColor = getRandomColor();
    setBorderColors((prevColors) => [...prevColors, newBorderColor]);
    setLocationCards((prevCards) => [...prevCards, { borderColor: newBorderColor }]);
  };

  const resetStashText = async () => {
    await updateScript({ stashText: '' });
    setStashInputData('Untitled');
  };

  return (
    <>
      <Iconify onMouseEnter={openDrawer} onClick={openDrawer} sx={{}} icon="carbon:open-panel-top" rotate={1} />

      <Drawer
        anchor="right"
        open={open}
        onClose={closeDrawer}
        disableBackdropTransition // Prevent background from being disabled
        // hideBackdrop // Prevent backgroun
      >
        <Box sx={{ width: '350px', padding: '20px' }}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box display={'flex'} gap={'15px'}>
              <Box
                sx={{ color: value === 'stash' ? '#fe6d29' : '#7b7c8d', '&:hover': { color: '#fe6d29' } }}
                onClick={() => setValue('stash')}
                textAlign={'center'}
              >
                <Iconify
                  mb={'-5px'}
                  sx={{ fontSize: '15px', color: '', '&:hover': { color: '#fe6d29' } }}
                  icon="mdi:floppy-disc-alert"
                />
                <Typography sx={{ fontWeight: '600', fontSize: '.67rem', color: '', '&:hover': { color: '#fe6d29' } }}>
                  Stash
                </Typography>
              </Box>
              <Box
                sx={{ color: value === 'elements' ? '#fe6d29' : '#7b7c8d', '&:hover': { color: '#fe6d29' } }}
                onClick={() => setValue('elements')}
                textAlign={'center'}
              >
                <Iconify mb={'-5px'} sx={{ fontSize: '15px' }} icon="file-icons:elementaryos" />
                <Typography sx={{ fontWeight: '600', fontSize: '.67rem', color: '', '&:hover': { color: '#fe6d29' } }}>
                  Elements
                </Typography>
              </Box>
            </Box>

            <Tooltip title="Unpin sidebar" arrow>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                width={'30px'}
                height={'30px'}
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.067)', borderRadius: '5px' }}
              >
                <Iconify
                  rotate="270deg"
                  sx={{ fontSize: '20px', color: '#fe6d29', '&:hover': { color: '#fe6d29' } }}
                  icon="solar:pin-bold"
                />
              </Box>
            </Tooltip>
          </Box>

          {/* box one */}
          {value === 'stash' && (
            <>
              <Typography mt={'10px'} sx={{ fontWeight: '600', fontSize: '.875rem', fontFamily: 'inter' }}>
                Stash
              </Typography>
              <Box sx={{ marginTop: '10px' }}>
                <Divider />
              </Box>
              {showStash && (
                <Box mt={'15px'} sx={{ backgroundColor: '#00017806', padding: '1rem', borderRadius: '.5rem' }}>
                  <Box display={'flex'} alignItems={'center'}>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      sx={{
                        backgroundColor: '#3d9bed',
                        width: '30px',
                        color: '#fff',
                        fontSize: '1.25rem',
                        fontFamily: 'courier-prime',
                        height: '30px',
                        borderRadius: '5px',
                      }}
                    >
                      i
                    </Box>
                    <Typography
                      sx={{ fontSize: '1rem', fontWeight: '600', paddingLeft: '0.5rem', fontFamily: 'inter' }}
                    >
                      Your stash items
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="dark-text" mt={'1rem'} sx={{ color: '#4b4b4c', fontSize: '.875rem' }}>
                      Click to edit or drag & drop them back into your script
                    </Typography>
                    <Box display={'flex'} justifyContent={'flex-end'}>
                      <div style={{ color: '#8f8f91', borderBottom: '1px solid #8f8f91', width: '35px' }}>
                        <Typography
                          fontSize=".75rem"
                          sx={{
                            fontFamily: 'inter',
                            color: '#8f8f91',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setShowStash(false);
                          }}
                        >
                          Got it
                        </Typography>
                      </div>
                    </Box>
                  </Box>
                </Box>
              )}
              {/* box two */}
              <Box mt={'15px'} sx={{ borderRadius: '.5rem', border: '2px solid #00017806' }}>
                <Box sx={{ backgroundColor: '#00017806', padding: '1rem', borderRadius: '.5rem' }}>
                  <Box
                    sx={{ fontFamily: 'inter' }}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <input
                      value={stashInputData}
                      onChange={(e) => setStashInputData(e.target.value)}
                      onBlur={(e) => {
                        updateScript({ stashText: e.target.value });
                      }}
                      placeholder="Untitled"
                      className="right-input"
                    />

                    <Tooltip title="Delete stash" arrow>
                      <Box
                        sx={{
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          resetStashText();
                        }}
                      >
                        <Iconify sx={{ fontSize: '23px', color: '#b2b3c4' }} icon="ri:delete-bin-line" />
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
                <StashModal
                  setStashInputData={setStashInputData}
                  stashInputData={stashInputData}
                  script={script}
                  updateScript={updateScript}
                />

                {/* <Box  sx={{ padding: '1rem', fontSize: '.875rem' }}>{stashInputData}</Box> */}
              </Box>
            </>
          )}

          {value === 'elements' && (
            <>
              <Box>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Box
                    onClick={() => {
                      setIsToggled(!isToggled);
                      setCardTrue(true);
                    }}
                    mt={'10px'}
                    display={'flex'}
                    gap={'5px'}
                    alignItems={'center'}
                    justifyContent={''}
                  >
                    <Iconify
                      icon={isToggled ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
                      sx={{ fontSize: '30px', color: '#868e96' }}
                    />
                    <Typography
                      className="dark-text"
                      fontSize={'.875rem'}
                      sx={{ fontFamily: 'inter', color: '#5f5f5f' }}
                    >
                      Storylines
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      '&:hover': {
                        width: '40px',
                        height: '40px',
                        borderRadius: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#00017806',
                      },
                      width: '40px',
                      height: '40px',
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => {
                      handleCard();
                      setCardTrue(true);
                    }}
                  >
                    <Iconify
                      className="dark-text"
                      icon={isToggled ? 'ic:baseline-plus' : ''}
                      sx={{ fontSize: '20px', color: '#868e96' }}
                    />
                  </Box>
                </Box>

                {cardTrue
                  ? isToggled &&
                    cards.map((item, i) => (
                      // eslint-disable-next-line react/jsx-key
                      <Box mt={'10px'}>
                        <ElementsCards
                          value={item?.content || ''}
                          borderColor={item.borderColor}
                          onRemove={() => {
                            const newCards = [...cards];
                            newCards.splice(i, 1);
                            removeCards(newCards, 'storyline');
                          }}
                          onChange={(e) => {
                            setCards((prevCards) => {
                              const newCards = [...prevCards];
                              newCards[i].content = e;
                              return newCards;
                            });
                          }}
                          descriptionValue = {item?.description || ''}
                          onChangeDescription={(e) => {
                            setCards((prevCards) => {
                              const newCards = [...prevCards];
                              newCards[i].description = e;
                              return newCards;
                            });
                          }}
                          onBlur={() => {
                            handleUpdateScript();
                          }}
                        />
                      </Box>
                    ))
                  : isToggled && (
                      <Box mt={'10px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Typography
                          className="dark-text"
                          aria-describedby={elementId}
                          onClick={(e) => {
                            handleClick(e, 'storyline');
                          }}
                          sx={{
                            backgroundColor: '#00017806',
                            fontFamily: 'inter',
                            padding: '0.5rem 2rem',
                            borderRadius: '.25rem',
                            fontSize: '.875rem',
                          }}
                        >
                          Import from script
                        </Typography>
                      </Box>
                    )}

                {/* card */}
              </Box>

              {/* character */}

              <Box>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Box
                    onClick={() => {
                      setCharacterToggled(!characterToggled);
                      setCharacterCardTrue(true);
                    }}
                    mt={'10px'}
                    display={'flex'}
                    gap={'5px'}
                    alignItems={'center'}
                    justifyContent={''}
                  >
                    <Iconify
                      icon={characterToggled ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
                      sx={{ fontSize: '30px', color: '#868e96' }}
                    />
                    <Typography
                      className="dark-text"
                      fontSize={'.875rem'}
                      sx={{ fontFamily: 'inter', color: '#5f5f5f' }}
                    >
                      Character
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      '&:hover': {
                        width: '40px',
                        height: '40px',
                        borderRadius: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#00017806',
                      },
                      width: '40px',
                      height: '40px',
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => {
                      handleCharacterCard();
                      setCharacterCardTrue(true);
                    }}
                  >
                    <Iconify
                      className="dark-text"
                      icon={characterToggled ? 'ic:baseline-plus' : ''}
                      sx={{ fontSize: '20px', color: '#868e96' }}
                    />
                  </Box>
                </Box>

                {characterCardTrue
                  ? characterToggled &&
                    characterCards.map((item, i) => (
                      // eslint-disable-next-line react/jsx-key
                      <Box mt={'10px'}>
                        <ElementsCards
                          borderColor={item.borderColor}
                          value={item?.content || ''}
                          onRemove={() => {
                            const newCards = [...characterCards];
                            newCards.splice(i, 1);
                            removeCards(newCards, 'character');
                          }}
                          onChange={(e) => {
                            setCharacterCards((prevCards) => {
                              const newCards = [...prevCards];
                              newCards[i].content = e;
                              return newCards;
                            });
                          }}
                          descriptionValue = {item?.description || ''}
                          onChangeDescription={(e) => {
                            setCharacterCards((prevCards) => {
                              const newCards = [...prevCards];
                              newCards[i].description = e;
                              return newCards;
                            });
                          }}
                          onBlur={() => {
                            handleUpdateScript();
                          }}
                        />
                      </Box>
                    ))
                  : characterToggled && (
                      <Typography className="dark-text" mt={'10px'} sx={{ fontSize: '.75rem', textAlign: 'center' }}>
                        No characters yet
                      </Typography>
                    )}

                {/* <Box mt={'10px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <Typography
                    className="dark-text"
                    aria-describedby={elementId}
                    onClick={(e) => {
                      handleClick(e, 'character');
                    }}
                    sx={{
                      backgroundColor: '#00017806',
                      fontFamily: 'inter',
                      padding: '0.5rem 2rem',
                      borderRadius: '.25rem',
                      fontSize: '.875rem',
                    }}
                  >
                    Import from script
                  </Typography>
                </Box> */}
              </Box>

              {/* locations */}
              <Box>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Box
                    onClick={() => {
                      setLocationToggled(!locationToggled);
                      setLocationCardTrue(true);
                    }}
                    mt={'10px'}
                    display={'flex'}
                    gap={'5px'}
                    alignItems={'center'}
                    justifyContent={''}
                  >
                    <Iconify
                      icon={locationToggled ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
                      sx={{ fontSize: '30px', color: '#868e96' }}
                    />
                    <Typography
                      className="dark-text"
                      fontSize={'.875rem'}
                      sx={{ fontFamily: 'inter', color: '#5f5f5f' }}
                    >
                      Locations
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      '&:hover': {
                        width: '40px',
                        height: '40px',
                        borderRadius: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#00017806',
                      },
                      width: '40px',
                      height: '40px',
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => {
                      handleLocationCard();
                      setLocationCardTrue(true);
                    }}
                  >
                    <Iconify
                      className="dark-text"
                      icon={locationToggled ? 'ic:baseline-plus' : ''}
                      sx={{ fontSize: '20px' }}
                    />
                  </Box>
                </Box>
                {locationCardTrue
                  ? locationToggled &&
                    locationCards.map((item, i) => (
                      // eslint-disable-next-line react/jsx-key
                      <Box mt={'10px'}>
                        <ElementsCards
                          borderColor={item.borderColor}
                          value={item?.content || ''}
                          onRemove={() => {
                            const newCards = [...locationCards];
                            newCards.splice(i, 1);
                            removeCards(newCards, 'location');
                          }}
                          onChange={(e) => {
                            setLocationCards((prevCards) => {
                              const newCards = [...prevCards];
                              newCards[i].content = e;
                              return newCards;
                            });
                          }}
                          descriptionValue = {item?.description || ''}
                          onChangeDescription={(e) => {
                            setLocationCards((prevCards) => {
                              const newCards = [...prevCards];
                              newCards[i].description = e;
                              return newCards;
                            });
                          }}
                          onBlur={() => {
                            handleUpdateScript();
                          }}
                        />
                      </Box>
                    ))
                  : locationToggled && (
                      <Typography className="dark-text" mt={'10px'} sx={{ fontSize: '.75rem', textAlign: 'center' }}>
                        No locations yet
                      </Typography>
                    )}
              </Box>

              {/* <Box mt={'10px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Typography
                  className="dark-text"
                  aria-describedby={elementId}
                  onClick={(e) => {
                    handleClick(e, 'location');
                  }}
                  sx={{
                    backgroundColor: '#00017806',
                    fontFamily: 'inter',
                    padding: '0.5rem 2rem',
                    borderRadius: '.25rem',
                    fontSize: '.875rem',
                  }}
                >
                  Import from script
                </Typography>
              </Box> */}

              {/* popover */}
              <div>
                <Popover
                  id={elementId}
                  open={openpop}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                  <Box sx={{ padding: '10px' }}>
                    <input className="right-elements-input dark-bg" placeholder="Type to filter..." />

                    {popupItem === 'storyline' && (
                      <>
                        <Typography
                          className="dark-text"
                          mt={'5px'}
                          sx={{
                            fontFamily: 'inter',
                            color: '#5f5f5f',
                            padding: '0.25rem 0.5rem',
                            borderRadius: '0.25rem',
                            backgroundColor: '#00017806',
                            fontSize: '.875rem',
                          }}
                        >
                          Park
                        </Typography>
                      </>
                    )}
                    {popupItem === 'character' && (
                      <>
                        <Typography
                          mt={'5px'}
                          className="dark-text"
                          sx={{
                            fontFamily: 'inter',
                            color: '#5f5f5f',
                            padding: '0.25rem 0.5rem',
                            borderRadius: '0.25rem',
                            backgroundColor: '#00017806',
                            fontSize: '.875rem',
                          }}
                        >
                          Victoria
                        </Typography>
                      </>
                    )}

                    {popupItem === 'location' && (
                      <>
                        <Typography
                          className="dark-text"
                          mt={'5px'}
                          sx={{
                            fontFamily: 'inter',
                            color: '#5f5f5f',
                            padding: '0.25rem 0.5rem',
                            borderRadius: '0.25rem',
                            backgroundColor: '#00017806',
                            fontSize: '.875rem',
                          }}
                        >
                          Park
                        </Typography>
                      </>
                    )}
                  </Box>
                </Popover>
              </div>
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default DrawerMenu;
