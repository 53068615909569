import styled from '@emotion/styled';
import { Switch } from '@mui/material';
import React from 'react'





const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 20,
    height: 12,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 10,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(5px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(8px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#fe6d29',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#FFF',
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 8,
      height: 8,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));
const SwitchButton = () => (
    <>
          <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
    </>
  )

export default SwitchButton