// import { Box, Card, CardHeader, Heading, Text } from "@chakra-ui/react";
// import "../style.css";
// import { GiCheckMark } from "react-icons/gi";
import { Box } from '@mui/material';

import KanBanCard from './BoardKanbanCard';

const TaskItem = ({ task }) => (
    <>
      {/* <Card className="complete-main-card">
        <CardHeader>
          <Box position={"relative"}>
            <Heading size="md">{task.title}</Heading>
            <Text>{task.description}</Text>
            {task.completed && (
              <Box className="gi-checkMark">
                <Box className="rotate-sqr">
                  <GiCheckMark />
                </Box>
              </Box>
            )}
          </Box>
        </CardHeader>
      </Card> */}
      {/* <Box > */}
      <KanBanCard />
      {/* </Box> */}
    </>
  );

export default TaskItem;
