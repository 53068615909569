import { useState, useRef } from 'react';
import axios from 'axios';
import { Drawer } from '@mui/material';
import './components/LeftDrawer/LeftDrawer.css';  // Import your existing CSS for styling
import './ChatBot.css'
import { RiCopilotFill } from "react-icons/ri";

const apiKey = process.env.REACT_APP_API_kEY || '';

const Chatbot = () => {
  const [userMessage, setUserMessage] = useState('');
  const [messages, setMessages] = useState([{ text: 'Hi there 👋\nHow can I help you today?', type: 'incoming' }]);
  const [isChatbotVisible, setIsChatbotVisible] = useState(false);
  const chatInputRef = useRef(null);
  const chatboxRef = useRef(null);

  const handleMessageChange = (e) => {
    setUserMessage(e.target.value);
  };

  const handleSendMessage = async () => {
    if (!userMessage.trim()) return;

    setMessages([...messages, { text: userMessage, type: 'outgoing' }]);
    setUserMessage('');

    chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;

    setMessages((prevMessages) => [
      ...prevMessages,
      { text: 'Thinking...', type: 'incoming' }
    ]);
    chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'user', content: userMessage }
          ]
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`
          }
        }
      );

      const { data } = response;
      if (response.status !== 200) throw new Error(data.error.message);

      setMessages((prevMessages) => [
        ...prevMessages.slice(0, prevMessages.length - 1),
        { text: data.choices[0].message.content, type: 'incoming' }
      ]);
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, prevMessages.length - 1),
        { text: error.message, type: 'incoming error' }
      ]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const toggleChatbot = () => {
    setIsChatbotVisible(!isChatbotVisible);
  };

  return (
    <>
      <button className="chatbot-toggler" onClick={toggleChatbot}>
        {/* <span className="material-symbols-rounded">mode_comment</span> */}
        {/* <span className="material-symbols-outlined">close</span> */}
        <RiCopilotFill
          size={30}
          color="#fff"
        />
      </button>

      {isChatbotVisible && (
        <Drawer
          anchor="left"
          open={isChatbotVisible}
          onClose={toggleChatbot}
        >
          <div className="chatbot">
            <header>
              <h2>Co-Writer</h2>
              <span
                className="close-btn material-symbols-outlined"
                onClick={toggleChatbot}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    toggleChatbot();  // Trigger close on Enter or Space key
                  }
                }}
                role="button"
                aria-label="Close chatbot"
                tabIndex="0"
              >
                close
              </span>

            </header>

            <ul className="chatbox" ref={chatboxRef}>
              {messages.map((message, index) => (
                <li key={index} className={`chat ${message.type}`}>
                  {message.type === 'outgoing' ? (
                    <p>{message.text}</p>
                  ) : (
                    <>
                      {/* <span className="material-symbols-outlined">smart_toy</span> */}
                      <span>

                        <RiCopilotFill
                          size={20}
                          color="#fff"
                          style={{ marginTop: '5px' }}
                        />
                      </span>
                      <p>{message.text}</p>
                    </>
                  )}
                </li>
              ))}
            </ul>

            <div className="chat-input">
              <textarea
                ref={chatInputRef}
                value={userMessage}
                onChange={handleMessageChange}
                onKeyDown={handleKeyDown}
                placeholder="Enter a message..."
                spellCheck="false"
                required
                aria-label="Chat message input"
              />
              <span
                className="material-symbols-rounded"
                onClick={handleSendMessage}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleSendMessage();  // Trigger send on Enter or Space key
                  }
                }}
                role="button"
                aria-label="Send message"
                tabIndex="0"  // Makes the span focusable, enabling keyboard interaction
              >
                send
              </span>

            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default Chatbot;
