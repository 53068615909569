/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router';
import { getLogoPath, googleAuthenticator } from 'src/utils/common';
import './SignIn.css';
import SigninPopover from './SigninPopover';
import { useGoogleLogin } from '@react-oauth/google';
import { useSnackbar } from 'notistack';
import { useFetch } from 'src/hooks/useFetch';

const SignIn = () => {
  const { login, loading } = useAuth();
  const params = new URLSearchParams(window.location.search);
  const [state, setState] = React.useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (params.get('loginMethod') === 'google') {
       setTimeout(() => {
        const googleButton = document.querySelector('.google-login-button');
        googleButton?.click();
       }, 500);
       // remove the query params
        window.history.replaceState({}, document.title, window.location.pathname);
      }
  }, [params]);

  useEffect(() => {
    // clear local storage
    localStorage.clear();
  }, []);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [getUser] = useFetch(
    'api/auth/getUser',
    `Get user successfully`,
    'Something went wrong while getting the user',
    'POST',
    false
  );
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  if (user) {
    navigate('/desk');
  }
  const handleClick = async () => {
    if (!loading) {
      await login(state.email.toLowerCase(), state.password, enqueueSnackbar);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const { code } = codeResponse;
      const response = await googleAuthenticator(code);
      try {
        const { email, id } = response;
        const userResponse = await getUser({ email });
        if (userResponse?.message === 'User not found!') {
          enqueueSnackbar("Email doesn't exist, please sign up. ", {
            variant: 'error',
          });
          navigate('/signup');  
          return;
        }
        
        await login(email, id, enqueueSnackbar);
      } catch (e) {
        console.log(e);
      }
    },
    flow: 'auth-code',
    onError: () => {
      navigate('/login');
      enqueueSnackbar('Login Failed', {
        variant: 'error',
      });
    },
  });

  return (
    <>
      <Box py={'20px'} display={'flex'} justifyContent={'center'}>
        <img src={getLogoPath()} alt="" width={'200px'} />
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <Box p={'10px'} display={'flex'} alignItems={'center'} flexDirection={'column'} width={'440px'}>
          <Typography sx={{ fontSize: '1.52rem', fontFamily: 'inter', color: '' }}>Sign in</Typography>

          <Box mt={'20px'} width={'100%'}>
            <Typography sx={{ fontSize: '.875rem', fontFamily: '', color: '' }}>Email address</Typography>
            <input
              className="signin-input"
              value={state.email}
              onChange={(e) => setState({ ...state, email: e.target.value })}
            />
          </Box>

          <Box mt={'10px'} width={'100%'}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography sx={{ fontSize: '.875rem', fontFamily: '', color: '' }}>Password</Typography>
              <Typography
                textAlign={'right'}
                sx={{ fontSize: '.875rem', fontFamily: '', color: '', cursor: 'pointer' }}
                onClick={() => {
                  navigate('/forgot-password');
                }}
              >
                <span className="sn-create-account"> Forgot password </span>
              </Typography>
            </Box>

            <input
              className="signin-input"
              type="password"
              value={state.password}
              onChange={(e) => setState({ ...state, password: e.target.value })}
            />
          </Box>

          <Box mt={'10px'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: '.875rem', fontFamily: '', color: '' }}>Keep me signed in</Typography>
            <SigninPopover />
          </Box>

          <Typography
            onClick={handleClick}
            mt={'10px'}
            width={'100%'}
            textAlign={'center'}
            sx={{
              fontSize: '.875rem',
              color: '#fff',
              padding: '1rem 2rem',
              border: '1px solid #fe6d29',
              fontWeight: '400',
              cursor: 'pointer',
              backgroundColor: '#fe6d29',
              borderRadius: '.25rem',
              fontFamily: 'inter',
              '&:hover': { backgroundColor: '#d0551c' },
            }}
          >
            {loading ? 'loading...' : 'Sign in'}
          </Typography>
          <Typography my={'15px'} sx={{ fontSize: '1rem', textAlign: 'center', fontWeight: '600', color: '' }}>
            or
          </Typography>
          {/* <Typography
            width={'100%'}
            mt={'10px'}
            sx={{
              fontSize: '.875rem',
              borderRadius: '.25rem',
              color: '',
              cursor: 'pointer',
              backgroundColor: '#00017806',
              padding: '.5rem 2rem',
              textAlign: 'center',
              fontWeight: '600',
            }}
          >
            Sign in with Apple
          </Typography> */}
          <Typography
            width={'100%'}
            mt={'10px'}
            className="google-login-button"
            onClick={() => googleLogin()}
            sx={{
              fontSize: '.875rem',
              borderRadius: '.25rem',
              color: '',
              backgroundColor: '#00017806',
              padding: '.5rem 2rem',
              textAlign: 'center',
              cursor: 'pointer',
              fontWeight: '600',
            }}
          >
            Continue with Google
          </Typography>
          {/* <GoogleLogin
          onSuccess={credentialResponse => {
            console.log(credentialResponse);
          }}
          onError={() => {
            console.log('Login Failed');
          }}
        /> */}
          <Typography
            className="link"
            mt={'20px'}
            sx={{ fontSize: '.875rem', textAlign: 'justify', color: '#b2b3c4', lineHeight: '1.5' }}
          >
            By creating an account or clicking "Sign in with Google" you acknowledge that you have read and agree with
            our <a href="https://screnpla.com/terms">Terms and Conditions </a> and <a href="https://screnpla.com/privacy"> Privacy Policy </a>.
          </Typography>
          <Typography mt={'20px'} sx={{ fontSize: '.875rem', color: '#b2b3c4' }}>
            New to scrēnplā?{' '}
            <span
              className="sn-create-account"
              onClick={() => {
                navigate('/signup');
              }}
              style={{
                cursor: 'pointer',
              }}
            >
              Create an account
            </span>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default SignIn;
