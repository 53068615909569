import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Iconify from 'src/components/iconify';
import { Divider, Input, TextField } from '@mui/material';
import Switch from '@mui/material/Switch';

import styled from '@emotion/styled';
import './ReferFriend.css';
import { useSnackbar } from 'notistack';
import { useFetch } from 'src/hooks/useFetch';
import { isValidEmail } from 'src/utils/helpers';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 550,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 2,
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 20,
  height: 12,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 10,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(5px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(8px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#fe6d29',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#FFF',
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 8,
    height: 8,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export default function ReferFriend({ activeTab }) {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();

  const [inviteUser, smLoading] = useFetch('api/auth/inviteUser', ``, '', 'POST', false);

  const user = localStorage.getItem('user') || null;
  const referLink = `https://app.screnpla.com/signup?ref=${user ? JSON.parse(user).id : ''}`;

  const copyToClipboardd = async (str) => {
    try {
      await navigator.clipboard.writeText(str);
      enqueueSnackbar('Link copied to clipboard', { variant: 'success' });
    } catch (error) {
      console.error('Error copying to clipboard:', error);
      enqueueSnackbar('Failed to copy link to clipboard', { variant: 'error' });
    }
  };

  const inviteUserApi = async () => {
    try {
      if (isValidEmail(email)) {
        const response = await inviteUser({ email });
        console.log(response);
        if (response.success) {
          enqueueSnackbar('Invitation sent successfully', { variant: 'success' });
          setEmail('');
        }
        else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      } else {
        enqueueSnackbar('Please enter a valid email address', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Failed to send invitation', { variant: 'error' });
    }
  };

  return (
    <div>
      <Box
        onClick={handleOpen}
        mt={'20px'}
        display={'flex'}
        alignItems={'center'}
        gap={'10px'}
        sx={{
          padding: '0.5rem 1rem',
          borderRadius: '.25rem',
          backgroundColor: activeTab === 'referfriend' ? '#fe6d291a' : '',
          color: activeTab === 'referfriend' ? '#fe6d29' : '',
          cursor: 'pointer',
        }}
      >
        <Iconify sx={{ fontSize: '20px' }} icon="ph:gift-light" />
        <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Refer a friend</Typography>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: '1.25rem', fontWeight: '600' }}>Refer a friend</Typography>
            <Box
              onClick={handleClose}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                width: '30px',
                height: '30px',
                borderRadius: '.25rem',
                '&:hover': { backgroundColor: '#fe6d291a' },
              }}
            >
              <Iconify sx={{ fontSize: '20px' }} icon="ant-design:close-outlined" />
            </Box>
          </Box>

          {/* <Box my={'20px'}>
            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>
              Invite a friend to Screnpla to give them a $25 discount.
            </Typography>
            <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>
              You also get $25 Credit if they subscribe.
            </Typography>
          </Box> */}

          <Box>
            <Typography mb={'30px'} sx={{ fontSize: '1rem', fontWeight: '600' }}>
              Copy your personal invite link
            </Typography>
            <Box
              className="light-bg"
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              pb={'.25rem'}
              sx={{ backgroundColor: '#f1f3f5', color: '#fe6d29', padding: '1rem', borderRadius: '.25rem' }}
            >
              <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>{referLink}</Typography>
              <Iconify
                sx={{ fontSize: '20px', cursor: 'pointer' }}
                onClick={() => {
                  copyToClipboardd(referLink);
                }}
                icon="gridicons:clipboard"
              />
            </Box>
            <Typography mt={'40px'} pl={'.5rem'} sx={{ fontSize: '.75rem', color: '#7b7c8d' }}>
              'Your name will be shared with people who have access to this link'
            </Typography>
          </Box>

          <Typography mt={'20px'} sx={{ fontSize: '1rem', fontFamily: 'inter' }}>
            Or send an invitation by entering their email address
          </Typography>

          <Box mt={'30px'} display={'flex'} alignItems={'center'} gap={'5px'}>
            <input
              className="refer-inp dark-bg dark-text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Enter friends email"
            />
            <Typography
              className="light-bg"
              p={'0.5rem 2rem'}
              sx={{
                cursor: 'pointer',
                color: email.length > 3 ? '#fff' : '#7b7c8d',
                backgroundColor: email.length > 3 ? '#fe6d29' : '#00017806',
                fontSize: '.875rem',
              }}
              onClick={() => {
                inviteUserApi();
              }}
            >
              invite
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
