import { Box, Grid, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useFetch } from 'src/hooks/useFetch';
import { useNavigate } from 'react-router';
import { SERVER_BASE_URL } from 'src/config';
import { useSnackbar } from 'notistack';
import { getCurrentUser } from 'src/utils/helpers';
import useDebounce from 'src/hooks/useDebounce';

import EditingPopover from './EditingPopover';
import NewScriptPoper from './NewScriptPoper';
import NewProjectPopper from './NewProjectPopper';
import ProjectTopDrawer from '../projects/ProjectTopDrawer';

const Dashboard = () => {
  const user = getCurrentUser();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();


  const drawerRef = useRef(null);
  const drawerProjRef = useRef(null);
  const deleteModalRef = useRef(null);
  const [scripts, setScripts] = React.useState([]);
  const [defaultScript, setDefaultScript] = React.useState(null);
  const [deleteScript, setDeleteScript] = React.useState(null);
  const [showAllScripts, setShowAllScripts] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    totalPages: 0,
    currentPage: 1,
    totalRecords: 0,
  });

  const [searchTerm, setSearchTerm] = React.useState('');

  const [getScripts, loading] = useFetch(
    `api/scripts?pageSize=50&page=${pagination.currentPage}&ownerId=${user?.id}`,
    ``,
    '',
    'GET',
    false
  );

  const debouncedSearchTerm = useDebounce(searchTerm.toString(), 1000);

  React.useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
      const basedUrl = `${SERVER_BASE_URL}/api/scripts?pageSize=50&page=${1}&ownerId=${
        user?.id
      }&search=${debouncedSearchTerm}`;
      getSearchedScripts(basedUrl);
    }
    if (debouncedSearchTerm.length === 0) {
      fetchScripts();
    }
  }, [debouncedSearchTerm]);

  const getSearchedScripts = async (url) => {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken') || ''}`,
      },
    });
    const json = await response.json();
    if (json?.success) {
      setScripts(json?.data?.scripts);
      setPagination({
        totalPages: json?.data?.totalPages,
        currentPage: json?.data?.currentPage,
        totalRecords: json?.data?.totalRecords,
      });
    }
  };

  React.useEffect(() => {
    fetchScripts();
  }, []);

  const fetchScripts = async () => {
    const response = await getScripts();
    if (response?.data?.scripts) {
      setScripts(response?.data?.scripts);
      setPagination({
        totalPages: response?.data?.totalPages,
        currentPage: response?.data?.currentPage,
        totalRecords: response?.data?.totalRecords,
      });
    }
  };

  const handleEditScript = (script) => {
    setDefaultScript(script);
    drawerRef.current.openDrawer();
  };

  const handleDeleteScript = (script) => {
    setDeleteScript(script);
    deleteModalRef.current.handleOpen();
  };

  const onSubmitDelete = async () => {
    const deleteScriptEndpoint = `${SERVER_BASE_URL}/api/scripts/${deleteScript?.id}`;
    const response = await fetch(deleteScriptEndpoint, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken') || ''}`,
      },
    });
    const data = await response.json();
    if (data?.success) {
      enqueueSnackbar(`Script deleted successfully`, {
        variant: 'success',
      });
      deleteModalRef.current.handleClose();
      fetchScripts();
    }
  };
  return (
    <>
      <Box width={"100%"} sx={{}} mt={'50px'} p={'1rem 2rem'}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: '1.25rem', fontWeight: '600' }}>Recently edited</Typography>
        </Grid>
        <Grid   item xs={12} md={6}>
        <Box 
         sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                // Styles for screens smaller than or equal to 'sm' breakpoint
        justifyContent:"flex-start",
        marginTop:"10px"
              },
            })}
          ml={"auto"} display={'flex'} gap={'10px'} alignItems={'center'} justifyContent={"flex-end"}>
            {/* <NewProjectPopper/> */}
            <ProjectTopDrawer
              ref={drawerProjRef}
              refreshData={() => {}}
              handleOpen={() => {}}
              defaultProject={null}
            />
            <NewScriptPoper
              refreshData={fetchScripts}
              ref={drawerRef}
              defaultScript={defaultScript}
              setDefaultScript={setDefaultScript}
            />
          </Box>
        </Grid>
      </Grid>

       {showAllScripts 
       ?
        <Box>
          {scripts &&
            !loading &&
            scripts.map((script) => (
              <Box
                key={script.id}
                className="light-bg"
                mt={'20px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{ border: '1px solid #fe6d291a', borderRadius: '.5rem .5rem 0 0' }}
                p={'1rem 1.5rem'}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={'20px'}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    window.location = `/script/${script.id}`;
                    
                  }}
                >
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ width: '50px', height: '50px', borderRadius: '50%', border: '3px solid #fe6d291a' }}
                  >
                    <Typography sx={{ fontSize: '.875rem', fontWeight: '600', fontFamily: 'inter' }}>0%</Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>{script?.title || ''}</Typography>
                    <Typography
                      mt={'5px'}
                      sx={{
                        '&:hover': { backgroundColor: '#fe6d291a' },
                        fontSize: '.75rem',
                        padding: '.375rem',
                        backgroundColor: '#00017806',
                        cursor: 'pointer',
                      }}
                    >
                      Sync latest version
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  {/* <EditingPopover       
                    stared={script.stared}  
                    fetchScripts={fetchScripts}           
                    selectedId={script.id}
                    onEdit={() => {
                      handleEditScript(script);
                    }}
                    onDelete={() => {
                      handleDeleteScript(script);
                    }}
                  /> */}
                </Box>
              </Box>
            ))}
        </Box>
        :
        <Box>
        {scripts &&
          !loading &&
          scripts.slice(0, 5).map((script) => (
            <Box
              key={script.id}
              className="light-bg"
              mt={'20px'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{ border: '1px solid #fe6d291a', borderRadius: '.5rem .5rem 0 0' }}
              p={'1rem 1.5rem'}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={'20px'}
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  window.location = `/script/${script.id}`;
                  
                }}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  sx={{ width: '50px', height: '50px', borderRadius: '50%', border: '3px solid #fe6d291a' }}
                >
                  <Typography sx={{ fontSize: '.875rem', fontWeight: '600', fontFamily: 'inter' }}>0%</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>{script?.title || ''}</Typography>
                  <Typography
                    mt={'5px'}
                    sx={{
                      '&:hover': { backgroundColor: '#fe6d291a' },
                      fontSize: '.75rem',
                      padding: '.375rem',
                      backgroundColor: '#00017806',
                      cursor: 'pointer',
                    }}
                  >
                    Sync latest version
                  </Typography>
                </Box>
              </Box>
              <Box>
                {/* <EditingPopover       
                  stared={script.stared}  
                  fetchScripts={fetchScripts}           
                  selectedId={script.id}
                  onEdit={() => {
                    handleEditScript(script);
                  }}
                  onDelete={() => {
                    handleDeleteScript(script);
                  }}
                /> */}
              </Box>
            </Box>
          ))}
      </Box>
        }

        {/* NO SCRIPTS VIEW */}
        {scripts.length === 0 && !loading && (
          <Box
            className="light-bg"
            mt={'20px'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ border: '1px solid #fe6d291a', borderRadius: '.5rem .5rem 0 0' }}
            p={'1rem 1.5rem'}
          >
            <Typography sx={{ fontSize: '.875rem', fontWeight: '600', fontFamily: 'inter' }}>
              No scripts available
            </Typography>
          </Box>
        )}

      {!showAllScripts && scripts.length > 5 && (
          <Box mt={'1rem'}>
            <Typography
              sx={{ cursor: 'pointer', color: '#fe6d29', fontSize: '.75rem', fontWeight: '600' }}
              onClick={() => setShowAllScripts(true)}
            >
              Show all scripts ({scripts.length})
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Dashboard;
