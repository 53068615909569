/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Box, Button, Divider, Drawer, MenuItem, MenuList, Popover, Switch, Tooltip, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import './SnapshotDrawer.css';
import styled from '@emotion/styled';
import { useSnackbar } from 'notistack';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 20,
  height: 12,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 10,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(5px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(8px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#fe6d29',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#FFF',
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 8,
    height: 8,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const SnapDrawer = ({position, script, updateScriptApi }) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [description, setDescription] = React.useState('');
  const snapShotContents = script?.contents || [];
  console.log("snapShotContents", snapShotContents);
  // Function to open the Drawer
  const openDrawer = () => {
    setOpen(true);
  };

  // Function to close the Drawer
  const closeDrawer = () => {
    setOpen(false);
  };

  const handleBackdropClick = () => {
    // Close the drawer when clicking on the backdrop
    closeDrawer();
  };
  const localUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  const saveSnapshot = async () => {
    snapShotContents.push({
      draftContent: script?.content,
      description,
      snap: true,
      author: localUser?.name
    })

    // Call updateScriptApi with the modified scriptComments
    await updateScriptApi({ contents: snapShotContents });

    enqueueSnackbar('Snapshot created successfully', { variant: 'success' });
    closeDrawer();
  }

  return (
    <>
      {
        position === "top" && 
        <Typography mt={'10px'} variant="body1" sx={{ fontSize: '.9rem' }} onClick={openDrawer}>
            Take snapshot
          </Typography>
          }
      {
        position === "sidebar" && 
        <Box
        onClick={openDrawer}
        sx={{ '&:hover': { backgroundColor: '#fe6d291a' }, padding: '4px', borderRadius: '5px', color: '#fe6d29' }}
        display={'flex'}
        alignItems={'center'}
        gap={'2px'}
      >
        <Iconify className="dark-text" icon="system-uicons:plus" sx={{ fontSize: '20px', color: '#fe6d29 !important', fontWeight: '600' }} />
        <Typography className="dark-light-text" sx={{ fontSize: '.75rem', color: '#fe6d29', fontWeight: '600' }}>Snapshot</Typography>
      </Box>}

      <Drawer
        anchor="top"
        open={open}
        onClose={closeDrawer}
        // disableBackdropTransition // Prevent background from being disabled
        PaperProps={{ style: { marginLeft: 'auto',marginRight:"auto", maxWidth: '600px',width:"100%", zIndex: 999999 } }}
  
      >
        {open && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,

              zIndex: -99, // Behind the drawer content
            }}
            onClick={handleBackdropClick} // Close the drawer on backdrop click
          />
        )}

        <Box p={'20px'}>
          <Box pb={'2rem'}>
            <Typography className="dark-text" sx={{ fontWeight: '600', fontSize: '1rem', color: '#333', fontFamily: 'inter' }}>
              Take a Snapshot
            </Typography>
            <Typography sx={{ color: '#b2b3c4', marginTop: '.25rem', fontSize: '.875rem', fontFamily: 'inter' }}>
              Capture the file and the current state of the document
            </Typography>
          </Box>
          <Box>
            <Typography className="dark-text" sx={{ fontWeight: '600', fontSize: '1rem', color: '#333', fontFamily: 'inter' }}>
              Snapshot Name
            </Typography>
            <Typography sx={{ color: '#b2b3c4', marginTop: '.25rem', fontSize: '.75rem', fontFamily: 'inter' }}>
              What changes are currently being filled?
            </Typography>
          </Box>
          <Box mt={'10px'}>
            <textarea rows={4}
              style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #fe6d29' }}
              
            className="snap-input-style dark-bg dark-text"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Box>

          <Box
            sx={{ backgroundColor: '#00017806', padding: '1rem', borderRadius: '0.25rem' }}
            mt={'2rem'}
            mb={'2rem'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box display={'flex'} alignItems={'center'} gap={'5px'}>
              <Typography className="dark-text" sx={{ color: '#333', fontWeight: '600', fontSize: '.875rem', fontFamily: 'inter' }}>
                Colored Revision
              </Typography>
              <Tooltip title="Activate to assing this snapshot a revision color,for colored pages" arrow>
              <Box  display={'flex'} alignItems={'center'}>
              <Iconify icon="material-symbols:info-outline" sx={{ fontSize: '13px', color: '#7b7c8d' }} />
              </Box>
              </Tooltip>
            </Box>
            <Box>
              <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
            </Box>
          </Box>
          <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <button className="snap-cancel dark-text" onClick={closeDrawer} >Cancel</button>
              <button className="snap-create" onClick={saveSnapshot}>Save</button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default SnapDrawer;
