import React, { useState } from 'react';
// import { Box, Progress, Text } from "@chakra-ui/react";
import { useDroppable } from '@dnd-kit/core';
import { Box, Typography } from '@mui/material';
import { SortableContext, horizontalListSortingStrategy, verticalListSortingStrategy } from '@dnd-kit/sortable';
import Iconify from 'src/components/iconify';
import SortableTaskItem from './SortableTaskItem';
import './BoardDnd.css';
import TaskItem from './TaskItem';
import NewActPopover from './NewActPopover';
import BoardKanbanCard from './BoardKanbanCard';

const style = {
  cursor: 'default',
  zIndex: '999',
};
const BoardSection = ({ id, title, tasks }) => {
  // const [kanbanCard, setKanbanCard] = useState([{}]);
  // const handleKanBanCard = () => {
  //   setKanbanCard((prevCards) => [...prevCards, {}]);
  // };

  const { setNodeRef } = useDroppable({
    id,
  });

  const addBeat = () => {
    console.log('add beat');
  };

  return (
    <Box
         className="light-bg"
      maxHeight={'540px'}
      height={'fit-content'}
      sx={{
        overflowY: 'auto',
        width: '300px',
        backgroundColor: '#f9fafc',
        padding: '0.5rem 1rem',
        paddingBottom: '30px',
        borderRadius: '0.5rem',
        border: '1px solid #fe6d291a',
      }}
    >
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Box display={'flex'} alignItems={'center'} gap={'10px'}>
          <Iconify sx={{ color: '' }} icon="ep:arrow-down" />
          <Typography sx={{ fontSize: '.875rem' }}>New act</Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={'10px'}>
          <Box>
            <NewActPopover />
          </Box>
          <Box>
            <Iconify sx={{ color: '' }} icon="majesticons:plus" />
          </Box>
        </Box>
      </Box>

      <SortableContext id={id} items={tasks} strategy={horizontalListSortingStrategy}>
        <div ref={setNodeRef}>
          {tasks.map((task, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <SortableTaskItem id={task.id}>
                {/* <TaskItem key={task.id} task={task} title={title} /> */}
                <BoardKanbanCard />
              </SortableTaskItem>
            </Box>
          ))}
        </div>
      </SortableContext>

      <Box onClick={addBeat}>Add Beat</Box>
    </Box>

    // {/* <Box sx={{}} className="available-main-div">

    //   <SortableContext id={id} items={tasks} strategy={horizontalListSortingStrategy}>
    //     <div ref={setNodeRef}>
    //       {tasks.map((task) => (
    //         <Box key={task.id} sx={{ mb: 2 }}>
    //           <SortableTaskItem id={task.id}>
    //             <TaskItem task={task} title={title} />
    //           </SortableTaskItem>
    //         </Box>
    //       ))}
    //     </div>
    //   </SortableContext>

    // </Box> */}
  );
};

export default BoardSection;
