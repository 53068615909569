import { Box, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router';
import { useFetch } from 'src/hooks/useFetch';
import { SERVER_BASE_URL } from 'src/config';
import { getCurrentUser } from 'src/utils/helpers';
import { useSnackbar } from 'notistack';
import useDebounce from 'src/hooks/useDebounce';
import './Scripts.css';
import Iconify from 'src/components/iconify';
import EditingPopover from '../dashboard/EditingPopover';
import NewScriptPoper from '../dashboard/NewScriptPoper';
import DeleteModal from './DeleteModal';

const Scripts = () => {
  const user = getCurrentUser();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [activeInput, setActiveInput] = React.useState(false);
  const [progressIndicator, setProgressIndicator] = React.useState(true);
  const [archive, setArchive] = React.useState(false);
  const [cardReview, setCardReview] = React.useState(false);
  const [listView, setListView] = React.useState(true);

  const drawerRef = useRef(null);
  const deleteModalRef = useRef(null);
  const [scripts, setScripts] = React.useState([]);
  const [defaultScript, setDefaultScript] = React.useState(null);
  const [deleteScript, setDeleteScript] = React.useState(null);
  const [pagination, setPagination] = React.useState({
    totalPages: 0,
    currentPage: 1,
    totalRecords: 0,
  });

  const [searchTerm, setSearchTerm] = React.useState('');

  const [getScripts, loading] = useFetch(
    `api/scripts?pageSize=50&page=${pagination.currentPage}&ownerId=${user?.id}&archieved=${archive}`,
    ``,
    '',
    'GET',
    false
  );

  const debouncedSearchTerm = useDebounce(searchTerm.toString(), 1000);

  React.useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
      const basedUrl = `${SERVER_BASE_URL}/api/scripts?pageSize=50&page=${1}&ownerId=${
        user?.id
      }&search=${debouncedSearchTerm}`;
      getSearchedScripts(basedUrl);
    }
    if (debouncedSearchTerm.length === 0) {
      fetchScripts();
    }
  }, [debouncedSearchTerm]);

  const getSearchedScripts = async (url) => {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken') || ''}`,
      },
    });
    const json = await response.json();
    if (json?.success) {
      setScripts(json?.data?.scripts);
      setPagination({
        totalPages: json?.data?.totalPages,
        currentPage: json?.data?.currentPage,
        totalRecords: json?.data?.totalRecords,
      });
    }
  };

  React.useEffect(() => {
    fetchScripts();
  }, []);

  React.useEffect(() => {
    const basedUrl = `${SERVER_BASE_URL}/api/scripts?pageSize=50&page=${1}&ownerId=${user?.id}&archieved=${archive}`;
    getSearchedScripts(basedUrl);
  }, [archive]);

  React.useEffect(() => {
    if (scripts.length > 0) {
      const staredScripts = scripts.filter((script) => script.stared);
      localStorage.setItem('staredScripts', staredScripts.length);
    }
  }, [scripts]);

  const fetchScripts = async () => {
    const response = await getScripts();
    if (response?.data?.scripts) {
      setScripts(response?.data?.scripts);
      setPagination({
        totalPages: response?.data?.totalPages,
        currentPage: response?.data?.currentPage,
        totalRecords: response?.data?.totalRecords,
      });
    }
  };

  const handleEditScript = (script) => {
    setDefaultScript(script);
    drawerRef.current.openDrawer();
  };

  const handleDeleteScript = (script) => {
    setDeleteScript(script);
    deleteModalRef.current.handleOpen();
  };

  const onSubmitDelete = async () => {
    const deleteScriptEndpoint = `${SERVER_BASE_URL}/api/scripts/${deleteScript?.id}`;
    const response = await fetch(deleteScriptEndpoint, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken') || ''}`,
      },
    });
    const data = await response.json();
    if (data?.success) {
      enqueueSnackbar(`Script deleted successfully`, {
        variant: 'success',
      });
      deleteModalRef.current.handleClose();
      fetchScripts();
    }
  };

  return (
    <>
      <Box sx={{}} mt={'50px'} p={'1rem 2rem'}>
        <DeleteModal
          ref={deleteModalRef}
          title={'Delete Script'}
          message={'Are you sure you want to delete this script?'}
          onSubmit={() => {
            onSubmitDelete();
          }}
        />
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography sx={{ fontSize: '1.25rem', fontWeight: '600', fontFamily: 'inter' }}>Your scripts</Typography>

          <NewScriptPoper
            refreshData={fetchScripts}
            ref={drawerRef}
            defaultScript={defaultScript}
            setDefaultScript={setDefaultScript}
          />
        </Box>

        <Box
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              // Styles for screens smaller than or equal to 'sm' breakpoint
              flexDirection: 'column',
              // justifyContent:"flex-start",
              // marginTop:"10px"
            },
          })}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          gap={'10px'}
          mt={'20px'}
        >
          <Box width={'100%'} display={'flex'} alignItems={'center'}>
            <Box
              width={'100%'}
              sx={{
                padding: '.5rem',
                border: activeInput ? '1px solid #339af0' : '1px solid transparent',

                borderRadius: '.25rem',
              }}
              display={'flex'}
              alignItems={'center'}
              gap={'5px'}
            >
              <Iconify sx={{ fontSize: '20px' }} icon="ic:sharp-search" />
              <input
                onClick={() => setActiveInput(!activeInput)}
                className="spt-search dark-dash-bg dark-text"
                placeholder="search scripts..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>
            <Box
              className="dark-bg"
              onClick={() => setProgressIndicator(!progressIndicator)}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '.25rem',
                backgroundColor: progressIndicator ? '#fe6d291a' : '',
              }}
            >
              {' '}
              <Iconify sx={{ fontSize: '25px', color: '#fe6d29' }} icon="bi:speedometer2" />
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                // Styles for screens smaller than or equal to 'sm' breakpoint
                width: '100%',
                justifyContent: 'space-between',

                //  backgroundColor:'red'
              },
            })}
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
          >
            <Box
              className="dark-bg"
              onClick={() => setArchive(!archive)}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '.25rem',
                backgroundColor: archive ? '#fe6d291a' : '',
              }}
            >
              {' '}
              <Iconify sx={{ fontSize: '25px', color: '#fe6d29' }} icon="tabler:archive" />
            </Box>

            <Box
              className="dark-bg"
              onClick={() => setCardReview(!cardReview)}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '.25rem',
                backgroundColor: cardReview ? '#fe6d291a' : '',
              }}
            >
              {' '}
              <Iconify sx={{ fontSize: '25px', color: '#fe6d29' }} icon="radix-icons:dashboard" />
            </Box>

            <Box
              className="dark-bg"
              onClick={() => setCardReview(!cardReview)}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '.25rem',
                backgroundColor: !cardReview ? '#fe6d291a' : '',
              }}
            >
              {' '}
              <Iconify sx={{ fontSize: '25px', color: '#fe6d29' }} icon="typcn:equals-outline" />
            </Box>
          </Box>
        </Box>

        {cardReview ? <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          >
          {scripts &&
            !loading &&
            scripts.map((script) => (
              <Box
              key={script.id}
              display="flex"
              className="light-bg"
              position={'relative'}
              width="calc(25% - 10px)" // Set width for each card to occupy 25% of the container minus margin
              mt={'20px'}
              mb={'20px'}
              sx={{
                border: '1px solid #fe6d291a',
                borderRadius: '.5rem',
                backgroundColor: '#fff', // Background color for the card
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)', // Box shadow for the card
                p: '1rem', // Padding for the card content
                '&:hover': {
                  backgroundColor: '#f0f0f0', // Background color on hover
                },
              }}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={'20px'}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    window.location = `/script/${script.id}`;
                  }}
                >
                  {script?.stared && (
                    <Iconify
                      sx={{ fontSize: '14px', color: '#f98e29', position: 'absolute', left: '5px', top: '5px' }}
                      icon="ic:sharp-star"
                    />
                  )}

                  {progressIndicator && (
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      sx={{ width: '50px', height: '50px', borderRadius: '50%', border: '3px solid #fe6d291a' }}
                    >
                      <Typography sx={{ fontSize: '.875rem', fontWeight: '600', fontFamily: 'inter' }}>0%</Typography>
                    </Box>
                  )}
                  <Box>
                    <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>{script?.title || ''}</Typography>
                    <Typography
                      mt={'5px'}
                      sx={{
                        '&:hover': { backgroundColor: '#fe6d291a' },
                        fontSize: '.75rem',
                        padding: '.375rem',
                        backgroundColor: '#00017806',
                        cursor: 'pointer',
                      }}
                    >
                      Sync latest version
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{
                  position: 'absolute',
                  right: '1rem',
                }}>
                  <EditingPopover
                    stared={script.stared}
                    fetchScripts={fetchScripts}
                    selectedId={script.id}
                    onEdit={() => {
                      handleEditScript(script);
                    }}
                    onDelete={() => {
                      handleDeleteScript(script);
                    }}
                  />
                </Box>
              </Box>
            ))}
        </Box> : 
          <Box>
          {scripts &&
            !loading &&
            scripts.map((script) => (
              <Box
                key={script.id}
                className="light-bg"
                mt={'20px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{ border: '1px solid #fe6d291a', position: 'relative', borderRadius: '.5rem .5rem 0 0' }}
                p={'1rem 1.5rem'}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={'20px'}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    window.location = `/script/${script.id}`;
                  }}
                >
                  {script?.stared && (
                    <Iconify
                      sx={{ fontSize: '14px', color: '#f98e29', position: 'absolute', left: '5px', top: '5px' }}
                      icon="ic:sharp-star"
                    />
                  )}

                  {progressIndicator && (
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      sx={{ width: '50px', height: '50px', borderRadius: '50%', border: '3px solid #fe6d291a' }}
                    >
                      <Typography sx={{ fontSize: '.875rem', fontWeight: '600', fontFamily: 'inter' }}>0%</Typography>
                    </Box>
                  )}
                  <Box>
                    <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>{script?.title || ''}</Typography>
                    <Typography
                      mt={'5px'}
                      sx={{
                        '&:hover': { backgroundColor: '#fe6d291a' },
                        fontSize: '.75rem',
                        padding: '.375rem',
                        backgroundColor: '#00017806',
                        cursor: 'pointer',
                      }}
                    >
                      Sync latest version
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <EditingPopover
                    stared={script.stared}
                    fetchScripts={fetchScripts}
                    selectedId={script.id}
                    onEdit={() => {
                      handleEditScript(script);
                    }}
                    onDelete={() => {
                      handleDeleteScript(script);
                    }}
                  />
                </Box>
              </Box>
            ))}
        </Box>
        }

        {/* NO SCRIPTS VIEW */}
        {scripts.length === 0 && !loading && (
          <Box
            className="light-bg"
            mt={'20px'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ border: '1px solid #fe6d291a', borderRadius: '.5rem .5rem 0 0' }}
            p={'1rem 1.5rem'}
          >
            <Typography sx={{ fontSize: '.875rem', fontWeight: '600', fontFamily: 'inter' }}>
              No scripts available
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Scripts;
