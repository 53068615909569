import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router";
import { useFetch } from "../../hooks/useFetch";
import { Box } from "@mui/material";

const ImageSelector = ({ script }) => {
  const { id } = useParams();
  const [refresh, setRefresh] = useState(false);
  console.log("script", script);
  const [image, setImage] = useState(script?.scriptLogo || null);
  const [showImageSelector, setShowImageSelector] = useState(true);
  const [updateScriptApi] = useFetch(`api/scripts/${id}`, ``, '', 'PATCH', false);
  const handleImageSelect = (e) => {
    const selectedImage = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = async () => {
      setImage(reader.result);
      await updateScriptApi({ scriptLogo: reader.result });
      setShowImageSelector(false);
    };
    if (selectedImage) {
      reader.readAsDataURL(selectedImage);
    }
  };

  useEffect(() => {
    if (script?.scriptLogo) {
      setImage(script?.scriptLogo);
      setShowImageSelector(false);
    }
  }, [script]);

  const handleImageClear = async () => {
    setImage(null);
    await updateScriptApi({ scriptLogo: null });
    setShowImageSelector(true);
  };

  return (
    <div className="imageSelector">
      {showImageSelector ? (
        <>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageSelect}
            style={{ display: "none" }}
            id="imageInput"
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="imageInput" className="imageSelectLabel" >
          {/* <Box htmlFor="imageInput" className="imageSelectLabel" display={{xs:"none",sm:"block"}}> */}
            <div className="imageSelectorBox" >
              Click to add image...
              <br />
              256x256 recommended
            </div>
          {/* </Box> */}
            </label>

          {/* <Box htmlFor="imageInput"  className="imageSelectorBox imageSelectLabel" display={{xs:"block",sm:"none"}}>
              Click to add image...
              
              256x256 recommended
            </Box> */}
        </>
      ) : (
        <div className="imageSelectorDiv" >
          <img src={image} alt="Selected " className="imageSelectorImg" />
          <FontAwesomeIcon
            icon={faTrashAlt}
            className="imageSelectorIcon print-hide"
            onClick={handleImageClear}
          />
        </div>
      )}
    </div>
  );
};

export default ImageSelector;