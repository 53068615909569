import * as React from 'react';
import Box from '@mui/material/Box';
import { SERVER_BASE_URL } from 'src/config';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Iconify from 'src/components/iconify';
import { Input, TextField } from '@mui/material';
import Switch from '@mui/material/Switch';
// import './ProfileEmailModal.css';

import styled from '@emotion/styled';
import { isEmpty, isStrongPassword } from 'src/utils/helpers';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 2,
};

export default function ProfilePasswordModal({ enqueueSnackbar, userId, displayAt }) {
  const [state, setState] = React.useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });
  const localUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  const [open, setOpen] = React.useState(false);
  const message = (
    <div>
      You cannot change password,
      <br />
      Because you continue google.
    </div>
  );
  const handleOpen = () => {
    if (localUser?.provider === 'google') {
      enqueueSnackbar(message, { variant: 'error' });
      return;
    }
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  const handleUpdatePassword = () => {
    if (isEmpty(state.oldPassword)) {
      enqueueSnackbar('Old password is required', { variant: 'error' });
      return;
    }
    if (isEmpty(state.password)) {
      enqueueSnackbar('New password is required', { variant: 'error' });
      return;
    }
    if (isEmpty(state.confirmPassword)) {
      enqueueSnackbar('Confirm password is required', { variant: 'error' });
      return;
    }
    if (state.password !== state.confirmPassword) {
      enqueueSnackbar('Confirm password is not matched', { variant: 'error' });
      return;
    }

    if (state.password.length < 8) {
      enqueueSnackbar('Password must be at least 8 characters', { variant: 'error' });
      return;
    }
    handleClose();
    handleSubmit();
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${SERVER_BASE_URL}/api/user/updatePassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken') || ''}`,
        },
        body: JSON.stringify({
          userId,
          currentPassword: state.oldPassword,
          password: state.password,
        }),
      });

      const parsedResponse = await response.json();
      if (parsedResponse.error) {
        enqueueSnackbar(parsedResponse.message, { variant: 'error' });
      } else {
        enqueueSnackbar(parsedResponse.message, { variant: 'success' });
        setState({
          oldPassword: '',
          password: '',
          confirmPassword: '',
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <div>
      {displayAt === 'settingModal' && (
        <Box
          onClick={handleOpen}
          sx={{ cursor: localUser?.provider === 'google' ? 'not-allowed !important' : 'pointer !important' }}
          disabled={localUser?.provider === 'google'}
          className="vertical-change"
        >
          <p>Change</p>
        </Box>
      )}
      {displayAt === 'account' && (
        <Box
          onClick={handleOpen}
          sx={{ cursor: localUser?.provider === 'google' ? 'not-allowed !important' : 'pointer !important' }}
          disabled={localUser?.provider === 'google'}
          display={'flex'}
          alignItems={'center'}
          gap={'10px'}
        >
          <Typography sx={{ cursor: 'pointer', color: '#fe6d29', fontSize: '.875rem' }}>Change password</Typography>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography mb={'25px'} sx={{ fontSize: '1rem' }}>
            Change your password
          </Typography>
          <Box>
            <Typography sx={{ fontSize: '.875rem' }}>Old password</Typography>
            <input
              type="password"
              className="pro-email-input"
              placeholder="......"
              value={state.oldPassword}
              onChange={(e) => setState({ ...state, oldPassword: e.target.value })}
            />

            <Typography sx={{ fontSize: '.875rem' }}>New password</Typography>
            <input
              type="password"
              className="pro-email-input"
              placeholder="......"
              value={state.password}
              onChange={(e) => setState({ ...state, password: e.target.value })}
            />

            <Typography sx={{ fontSize: '.875rem' }}>Confirm password</Typography>
            <input
              type="password"
              className="pro-email-input"
              placeholder="......"
              value={state.confirmPassword}
              onChange={(e) => setState({ ...state, confirmPassword: e.target.value })}
            />

            <Typography mt={'20px'} sx={{ fontSize: '.875rem' }}>
              New password minimum 8 characters
            </Typography>
          </Box>

          <Box mt={'30px'} display={'flex'} width={'100%'} alignItems={'center'} gap={'10px'}>
            <Typography
              onClick={handleUpdatePassword}
              sx={{
                textAlign: 'center',
                width: '100%',
                padding: '.5rem 2rem',
                backgroundColor: '#fe6d29',
                color: '#fff',
                borderRadius: '.25rem',
                fontSize: '.875rem',
                '&:hover': {
                  backgroundColor: '#d0551c',
                },
                cursor: 'pointer',
              }}
            >
              Ok
            </Typography>
            <Typography
              onClick={handleClose}
              sx={{
                textAlign: 'center',
                width: '100%',
                padding: '.5rem 2rem',
                backgroundColor: '#fe6d291a',
                borderRadius: '.25rem',
                fontSize: '.875rem',
                '&:hover': {
                  backgroundColor: '#00017806',
                },
                cursor: 'pointer',
              }}
            >
              Cancel
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
