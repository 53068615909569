import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FooterVoid, HeaderVoid } from "../components/slate-editor/customElements";
import { getMarkForCommentThreadID } from "./common";
import ImageSelector from "../components/slate-editor/imageSelector";


export const blocks = [
  {
    type: "h1",
    title: "Heading 1",
    icon: <strong>H1</strong>,
    renderBlock: (props) => <h1 {...props.attributes}>{props.children}</h1>,
  },
  {
    type: "h2",
    title: "Heading 2",
    icon: <strong>H2</strong>,
    renderBlock: (props) => <h2 {...props.attributes}>{props.children}</h2>,
  },
  {
    type: "h3",
    title: "Heading 1",
    icon: <strong>H3</strong>,
    renderBlock: (props) => <h3 {...props.attributes}>{props.children}</h3>,
  },
  {
    type: "numbered-list",
    title: "Heading 1",
    icon: <strong>NL</strong>,
    renderBlock: (props) => <ol {...props.attributes}>{props.children}</ol>,
  },
  {
    type: "list-item",
    title: "list Item",
    icon: <strong>LI</strong>,
    isHiddenInToolbar: true,
    renderBlock: ({ attributes, children }) => (
      <li {...attributes}>{children}</li>
    ),
  },
  {
    type: "bulleted-list",
    title: "Bullet List",
    icon: <strong>UL</strong>,
    renderBlock: (props) => <ul {...props.attributes}>{props.children}</ul>,
  },
  {
    type: "page",
    title: "Page",
    icon: <>p</>,
    isHiddenInToolbar: true,
    renderBlock: ({ attributes, children, index }) => (
      <div
        className="page printablePageShadow"
        {...attributes}
        style={{
          position: "relative",
          marginLeft: "auto",
          marginRight: "auto",
          width: "8.27in",
          height: "11.69in",
          color: "#333333",
          lineHeight: "17px",
          fontSize: "12px",
          fontWeight: 400,
          fontFamily: "CourierPrimeCodeRegular",
          padding: "60px",
          background: "#fff",
        }}
      >
        <div className="mainHeader">
          <HeaderVoid />
          <div className="header-page" style={{ display: 'none' }} >
            <div contentEditable="false"> Page # {index + 1} </div>
          </div>
        </div>
        {children}
        <div className="mainFooter">
          <FooterVoid />
          <div className="footer-page" style={{ display: 'none' }}>
            <div contentEditable="false"> Page # {index  + 1} </div>
          </div>
        </div>
      </div>
    ),
  },
  {
    type: "getting-started",
    title: "Getting Started",
    icon: <>p</>,
    isHiddenInToolbar: true,
    renderBlock: ({ attributes, children, index, changeGetStartedExpanded, getStartedExpanded }) => (
      <div
        className="getting-started-page printablePageShadow"
        {...attributes}
        style={{
          position: "relative",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: index === 0 ? "20px" : "0px",
          width: "8.27in",
          height: `${getStartedExpanded ? "8.69in" : "120px"}`,
          paddingTop: `${getStartedExpanded ? "3in" : "40px"}`,
          color: "#333333",
          lineHeight: "12px",
          fontSize: "12px",
          fontWeight: 400,
          fontFamily: "CourierPrimeCodeRegular",
          transition: "height 500ms ease, padding-top 500ms ease",
          padding: "60px",
          background: "#fff",
        }}
      >
        <div contentEditable="false">
          {getStartedExpanded && <ImageSelector />}
        </div>

        <div style={getStartedExpanded ? { lineHeight: "2" } : { lineHeight: "3" }}>
          {children}
        </div>

        <FontAwesomeIcon
          className="gettingStartedToggle print-hide"
          icon={getStartedExpanded ? faAngleUp : faAngleDown}
          onClick={() => {
            changeGetStartedExpanded();
          }}
        />

      </div>
    ),
  },

];

export const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

export const LIST_TYPES = ["numbered-list", "bulleted-list"];
export const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

export const artifactList = [
  // { name: "General", value: "general" },
  { name: "Scene Heading", value: "sceneHeading" },
  { name: "Action", value: "action" },
  { name: "Character", value: "character" },
  { name: "Enable Dictation", value: "voice-dialogue" },
  { name: "Dialogue", value: "dialogue" },
  { name: "Dual Dialogue", value: "duo-dialogue" },
  { name: "Parenthetical", value: "parenthetical" },
  { name: "Transition", value: "transition" },
  { name: "Shot", value: "shot" },
  { name: "Normal", value: "normal" },
  // { name: "Cast List", value: "castList" },
  // { name: "New Act", value: "newAct" },
  // { name: "End Of Act", value: "endOfAct" },
  // { name: "Outline Body", value: "outlineBody" },
  // { name: "Outline 1", value: "outline1" },
  // { name: "Outline 2", value: "outline2" },
  // { name: "Outline 3", value: "outline3" },
  // { name: "Outline 4", value: "outline4" },
];


export const initialValue = [

  
      {
        type: "action",
        children: [
          {
            text: "",
          },
        ],
      },

  
  
];

export const gettingStarted = [
  {
    type: "getting-started",
    children: [
      {
        type: "getting-started-element",
        children: [{ text: "Getting Started" }],
      },
      {
        type: "getting-started-element",
        children: [{ text: "Written By" }],
      },
      {
        type: "getting-started-element",
        children: [{ text: "Author" }],
      },
    ],
  },
];


export const ProjectsType = {
  TVSeriesWithOutline: "TVSeriesWithOutline",
  Folder: "Folder",
}

export const BoldTextTooltip = (
  <div>
    <strong>Bold</strong> <span style={{ fontSize: "10px" }}>(Ctrl + B)</span>
    <br />
    <div style={{ fontSize: "10px" }}>
      Set or unset the bold style
      <br />
      to the selected text.
    </div>
  </div>
);

export const ItalicTextTooltip = (
  <div>
    <strong>Italic</strong> <span style={{ fontSize: "10px" }}>(Ctrl + I)</span>
    <br />
    <div style={{ fontSize: "10px" }}>
      Set or unset the italic style
      <br />
      to the selected text.
    </div>
  </div>
);

export const underLineTextTooltip = (
  <div>
    <strong>Underline</strong> <span style={{ fontSize: "10px" }}>(Ctrl + U)</span>
    <br />
    <div style={{ fontSize: "10px" }}>
      Set or unset the underline style
      <br />
      to the selected text.
    </div>
  </div>
);

export const strikeOutTextTooltip = (
  <div>
    <strong>Strikeout</strong>
    <br />
    <div style={{ fontSize: "10px" }}>
      Set or unset the strikeout style
      <br />
      to the selected text.
    </div>
  </div>
);

export const highlightTextTooltip = (
  <div>
    <strong>Highlight</strong>
    <br />
    <div style={{ fontSize: "10px" }}>
      Highlight the selected text.
    </div>
  </div>
);

export const textcolorTextTooltip = (
  <div>
    <strong>Text Color</strong>
    <br />
    <div style={{ fontSize: "10px" }}>
      Change the color of the
      <br />
      selected text.
    </div>
  </div>
);

export const capitalizeTextTooltip = (
  <div>
    <strong>Capitalize</strong>
    <br />
    <div style={{ fontSize: "10px" }}>
      Set or unset the capitalize style
      <br />
      to the selected text.
    </div>
  </div>
);

export const allCapsTextTooltip = (
  <div>
    <strong>All Caps</strong>
    <br />
    <div style={{ fontSize: "10px" }}>
      Set or unset the all caps style
      <br />
      to the selected text.
    </div>
  </div>
);

export const quoteTextTooltip = (
  <div>
    <strong>Quote</strong>
    <br />
    <div style={{ fontSize: "10px" }}>
      Set or unset the quote style
      <br />
      to the selected text.
    </div>
  </div>
);

export const doubleDialogueTextTooltip = (
  <div>
    <strong>Double-Dialogue</strong>
    <br />
    <div style={{ fontSize: "10px" }}>
      Add double dialogue
      <br />
      text to the editor.
    </div>
  </div>
);

export const alignLeftTextTooltip = (
  <div>
    <strong>Align Left</strong>
    <br />
    <div style={{ fontSize: "10px" }}>
      Align the selected text to the left.
    </div>
  </div>
);

export const alignCenterTextTooltip = (
  <div>
    <strong>Align Center</strong>
    <br />
    <div style={{ fontSize: "10px" }}>
      Align the selected text to the center.
    </div>
  </div>
);

export const alignRightTextTooltip = (
  <div>
    <strong>Align Right</strong>
    <br />
    <div style={{ fontSize: "10px" }}>
      Align the selected text to the right.
    </div>
  </div>
);

export const justifyTextTooltip = (
  <div>
    <strong>Justify</strong>
    <br />
    <div style={{ fontSize: "10px" }}>
      Justify the selected text.
    </div>
  </div>
);

export const DuoDialogueCharacters = [
  {
    type: 'table',
    children: [
      {
        type: 'table-row',
        children: [

          {
            type: 'table-cell',
            children: [{ text: 'Character 1', bold: true, uppercase: true }],
          },
          {
            type: 'table-cell',
            children: [{ text: 'Character 2', bold: true, uppercase: true }],
          },
        ],
      },
      {
        type: 'table-row',
        children: [
          {
            type: 'table-cell',
            children: [{ text: 'Hi Dear, How are you doing?' }],
          },
          {
            type: 'table-cell',
            children: [{ text: 'I am doing great, Thank you for asking :)' }],
          },

        ],
      },

    ],
  },
  {
    type: 'action',
    children: [
      {
        text: "",
      },
    ],
  },
]


export const VoiceMessageDialogue = [
  {
    type: 'voice-message',
    children: [
      {
        type: 'voice-message-element',
        url: "",
        children: [{ text: ""  }],
      },
    ],
  },  
  {
    type: 'paragraph',
    children: [
      {
        text: "",
      },
    ],
  },
]

export const VoiceMessageDialogue2 = [

  {
    type: 'voice-message',
    children: [
      {
        type: 'voice-message-element',
        url: "",
        alignCenter: true,
        children: [{ text: ""  }],
      },
    ],
  },  
  {
    type: 'dialogue',
    children: [
      {
        text: "",
      },
    ],
  },
]

export const CHARACTERS = [
  'INT.', 'EXT.', 'I/E.', 'Tom Hanks', 'Meryl Streep', 'Leonardo DiCaprio', 'Julia Roberts', 'Denzel Washington', 'Cate Blanchett', 'Brad Pitt', 'Nicole Kidman', 'Morgan Freeman', 'Charlize Theron', 'Johnny Depp', 'Jennifer Lawrence', 'Robert De Niro', 'Kate Winslet', 'Al Pacino', 'Angelina Jolie', 'George Clooney', 'Emma Stone', 'Daniel Day-Lewis', 'Natalie Portman', 'Will Smith', 'Sandra Bullock', 'Tom Cruise', 'Anne Hathaway', 'Matt Damon', 'Viola Davis', 'Christian Bale', 'Scarlett Johansson', 'Mark Wahlberg', 'Jennifer Aniston', 'Ryan Gosling', 'Amy Adams', 'Samuel L. Jackson', 'Julianne Moore', 'Hugh Jackman', 'Rachel McAdams', 'Jamie Foxx', 'Reese Witherspoon', 'Harrison Ford', 'Emma Watson', 'Sean Penn', 'Gwyneth Paltrow', 'Michael Fassbender', 'Anne Bancroft', 'Joaquin Phoenix', 'Marion Cotillard', 'Kevin Spacey', 'Diane Keaton', 'Hugh Grant', 'Michelle Pfeiffer', 'Jake Gyllenhaal', 'Renee Zellweger', 'Bruce Willis', 'Sharon Stone', 'Colin Firth', 'Emily Blunt', 'Eddie Redmayne', 'Jodie Foster', 'Chris Hemsworth', 'Lupita Nyong\'o', 'Woody Harrelson', 'Tilda Swinton', 'Owen Wilson', 'Halle Berry', 'Javier Bardem', 'Brie Larson', 'Russell Crowe', 'Helen Mirren', 'Tom Hardy', 'Glenn Close', 'Matthew McConaughey', 'Sigourney Weaver', 'Ryan Reynolds', 'Octavia Spencer', 'Steve Carell', 'Alicia Vikander', 'Tom Hiddleston', 'Naomi Watts', 'Joseph Gordon-Levitt', 'Judi Dench', 'Seth Rogen', 'Anne Hathaway', 'Daniel Craig', 'Charlize Theron', 'Robin Williams', 'Emma Thompson', 'Peter Dinklage', 'Catherine Zeta-Jones', 'Paul Newman', 'Diane Lane', 'Samuel L. Jackson', 'Greta Gerwig', 'Colin Farrell', 'Jennifer Garner', 'Daniel Radcliffe', 'Drew Barrymore', 'Eddie Murphy', 'Julianne Moore', 'Zachary Quinto', 'Nicole Kidman', 'Mahershala Ali'
]



export const writingSchedule = [
  { name: 'MON', checked: false },
  { name: 'TUE', checked: false },
  { name: 'WED', checked: false },
  { name: 'THU', checked: false },
  { name: 'FRI', checked: false },
  { name: 'SAT', checked: false },
  { name: 'SUN', checked: false },
]